import React, { useState, useEffect } from 'react';
import Layout from '../templetes/Layout';
import { TextField, Button, Typography, Paper, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import ExhibitorInfoForm from '../templetes/ExhibitorInfoForm';
import MultiLangText from '../templetes/MultiLangText';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';

const Template013: React.FC = () => {
  const { language } = useAuth();
  const isJapanese = language === 'ja';
  
  const [formData, setFormData] = useState({
    exhibitorName: '',
    boothNumber: '',
    tel: '',
    departmentPosition: '',
    contactPerson: '',
    postalCode: '',
    prefecture: '',
    city: '',
    address: '',
    email: '',
    Company_ID: '',
    buttonState: {
      reset: false,
      submit: false
    }
  });

  // 外部JSONファイルからボタンの状態を取得する関数
const loadButtonState = async () => {
  try {
    const timestamp = new Date().getTime();
    const response = await axios.get(`/json/buttonState13.json?_=${timestamp}`);  // 外部JSONファイルのパス
    const buttonStateData = response.data;
    setFormData(prevState => ({
      ...prevState,
      buttonState: buttonStateData.buttonState
    }));
  } catch (error) {
    console.error('ボタン状態の読み込み中にエラーが発生しました:', error);
  }
};

  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    const savedData = localStorage.getItem('formData010');
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);

  useEffect(() => {
    if (formData.exhibitorName) {
      checkDuplicate();
    }
  }, [formData.exhibitorName]);

  const checkDuplicate = async () => {
    try {
      const response = await fetch('https://xs525443.xsrv.jp/admin_ui/check013.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          exhibitorName: formData.exhibitorName,
          Company_ID: formData.Company_ID
        }),
      });

      const text = await response.text();
      console.log('レスポンステキスト:', text);
      const result = JSON.parse(text);

      if (result.isDuplicate && result.additionalData) {
        try {
          const parsedData = JSON.parse(result.additionalData);
          setFormData(prevState => ({
            ...prevState,
            ...parsedData,
          }));
          setIsDuplicate(true);
          setIsReadOnly(true);
        } catch (error) {
          console.error('JSONデータのパース中にエラーが発生しました:', error);
        }
      } else {
        setIsDuplicate(false);
        setIsReadOnly(false);
      }
    } catch (error) {
      console.error('重複チェック中にエラーが発生しました:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('exhibitorName', formData.exhibitorName);
    formDataToSend.append('boothNumber', formData.boothNumber);
    formDataToSend.append('tel', formData.tel);
    formDataToSend.append('departmentPosition', formData.departmentPosition);
    formDataToSend.append('contactPerson', formData.contactPerson);
    formDataToSend.append('postalCode', formData.postalCode);
    formDataToSend.append('prefecture', formData.prefecture);
    formDataToSend.append('city', formData.city);
    formDataToSend.append('address', formData.address);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('Company_ID', formData.Company_ID);

    try {
      const response = await fetch('https://xs525443.xsrv.jp/admin_ui/submit013.php', {
        method: 'POST',
        body: formDataToSend
      });

      const successMessage = language === 'ja' ? 'データが正常に送信されました。' : 'Submission Completed';
    const failureMessage = language === 'ja' ? 'データの送信に失敗しました: ' : 'Failed to submit data: ';
    const errorMessage = language === 'ja' ? 'データの送信に失敗しました。' : 'Failed to submit data.';

      if (response.ok) {
       // alert(successMessage);
        setIsSubmitted(true);
        setIsReadOnly(true);
      } else {
        alert('データの送信中にエラーが発生しました。');
      }
    } catch (error) {
      console.error('データ送信エラー:', error);
      alert('データの送信中にエラーが発生しました。');
    }
  };

  const handleReset = async () => {
  const confirmMessage = language === 'ja' ? '本当に削除していいですか？' : 'Are you sure you want to reset?';
  const resetSuccessMessage = language === 'ja' ? 'フォームとファイルがリセットされました。' : 'Reset Completed';
  const deleteErrorMessage = language === 'ja' ? 'データの削除に失敗しました。' : 'Failed to delete data.';
    if (window.confirm(confirmMessage)) {
      try {
        const response = await fetch('https://xs525443.xsrv.jp/admin_ui/delete013.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
          exhibitorName: formData.exhibitorName,
          Company_ID: formData.Company_ID
        }),
        });
  
        const result = await response.json();
  
        if (result.success) {
          localStorage.removeItem('formData010');
          alert(resetSuccessMessage);
          checkDuplicate();
        } else {
          alert(deleteErrorMessage + ': ' + result.message);
        }
      } catch (error) {
        console.error('データの削除中にエラーが発生しました:', error);
        alert(deleteErrorMessage);
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> ) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    loadButtonState(); // コンポーネントのマウント時にボタン状態を読み込む
  }, []);

  return (
    <Layout title={<MultiLangText
      language={language}
      textJa="申請書類管理　13．小間内電気工事（二次側配線工事）申込み"
      textEn="Application Forms 13.Application for Electrical Work within Booth (Secondary Wiring)"
    />}>
      <Paper style={{ padding: 16 }}>
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          <MultiLangText
            language={language}
            textJa="■出展者情報"
            textEn="■Exhibitor information"
          />
        </Typography>
        <hr />
        <form onSubmit={handleSubmit}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault(); // Enterキーによるフォーム送信を防ぐ
             // アラートを表示
          }
        }}>
          <ExhibitorInfoForm
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
          />

          <br /><br />

          <Typography gutterBottom mt={5} style={{ fontWeight: 'bold' }}>
            <MultiLangText
              language={language}
              textJa="■小間内電気工事申込みについて"
              textEn="■Application for Electrical Work inside Booth"
            />
          </Typography>
          <hr></hr>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="内容"
                textEn="Details"
              />　
            </Grid>
            <Grid item xs={10} sm={10}>
              <MultiLangText
                language={language}
                textJa="
                      小間内電気工事（二次側配線工事）は事務局電気施工会社でも承ります。<br/>
                      必要とする出展者様は、下記より申込書をダウンロードして頂き、<br/>
                      直接事務局工事会社に申込書をご提出ください。
                "
                textEn="The official contractor  also do secondary wiring work inside an exhibitor's booth.<br/>
                      If you would like to apply for secondary electrical work, please contact directly to the official contractor.
                "
              />
            </Grid>

            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={10} sm={10}>
              <MultiLangText
                language={language}
                textJa="・申込書は<a href='https://xs525443.xsrv.jp/admin_ui/pdf/No13_ja.pdf' target='_blank'>こちら</a>からダウンロード"
                textEn="The application form can be downloaded <a href='https://xs525443.xsrv.jp/admin_ui/pdf/No13_en.pdf' target='_blank'>here:</a>"
              />
            </Grid>
          </Grid>

          <Typography gutterBottom mt={5} style={{ fontWeight: 'bold' }}>
            <MultiLangText
              language={language}
              textJa="■ お問い合わせ先について(事務局電気施工会社)　※備考・その他は、下記まで直接ご連絡ください。"
              textEn="■Contact Information　(Official Electrician)If you have any questions or concerns, please inquire with the following contact."
            />
          </Typography>
          <hr></hr>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sm={12}>
              <MultiLangText
                language={language}
                textJa="
                      お問い合わせ先会社：事務局電気施工会社 （株）鈴木電機<br/>
                      〒111-0033 東京都台東区花川戸2-12-5<br/>
                      TEL：03-3842-8201<br/>
                      E-mail：JA2024@suzukidenki.co.jp<br/>
                      担当：荒井、戸田
                "
                textEn="SUZUKIDENKI Co., Ltd.<br/>
                      2-12-5 Hanakawado,Taito-ku, Tokyo 111-0033<br/>
                      TEL: +81-3-3842-8201<br/>
                      E-mail:JA2024@suzukidenki.co.jp<br/>
                      Arai, Toda"
              />
            </Grid>
          </Grid>
          <Grid item mt={5} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginRight: 8 }}
            onClick={handleReset}
            disabled={formData.buttonState.reset} // JSONから取得した値を使用
            sx={{ filter: formData.buttonState.reset ? 'grayscale(1)' : 'none' }} // グレーアウト
          >
            <MultiLangText language={language} textJa="リセット" textEn="Reset" />
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={formData.buttonState.submit || isDuplicate || isReadOnly} // JSONと他の状態を組み合わせる
            sx={{ filter: (formData.buttonState.submit || isDuplicate || isReadOnly) ? 'grayscale(1)' : 'none' }} // グレーアウト
          >
            <MultiLangText language={language} textJa="確定" textEn="Submit" />
          </Button>

    </Grid>

    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <MultiLangText
                language={language}
                textJa="
                ※申込不要のお客様も確定ボタンを押してください。リセットボタンにて再度フォームのご利用が可能です。
                "
                textEn="
                *Please click the SUBMIT button even if you do not need to apply. You can use the form again by clicking the RESET button.
                "
              />
            </Grid>

        </form>
      </Paper>
    </Layout>
  );
};

export default Template013;
