import React, { useState, useEffect } from 'react';
import Layout from '../templetes/Layout';
import { TextField, Button, Typography, Paper, Grid, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import ExhibitorInfoForm from '../templetes/ExhibitorInfoForm';
import MultiLangText from '../templetes/MultiLangText';
import { useAuth } from '../../context/AuthContext';
import ContactInfo3 from '../templetes/ContactInfo3';
import axios from 'axios';

const Template024: React.FC = () => {
  const { language } = useAuth();
  const isJapanese = language === 'ja';
  
  const [formData, setFormData] = useState({
    exhibitorName: '',
    boothNumber: '',
    tel: '',
    departmentPosition: '',
    contactPerson: '',
    postalCode: '',
    prefecture: '',
    city: '',
    address: '',
    email: '',
    Company_ID: '',
    buttonState: {
      reset: false,
      submit: false
    }
  });

  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

      // 外部JSONファイルからボタンの状態を取得する関数
      const loadButtonState = async () => {
        try {
          const timestamp = new Date().getTime();
          const response = await axios.get(`/json/buttonState24.json?_=${timestamp}`);  // 外部JSONファイルのパス
          const buttonStateData = response.data;
          setFormData(prevState => ({
            ...prevState,
            buttonState: buttonStateData.buttonState
          }));
        } catch (error) {
          console.error('ボタン状態の読み込み中にエラーが発生しました:', error);
        }
      };

  useEffect(() => {
    if (formData.exhibitorName) {
      checkDuplicate();
    }
  }, [formData.exhibitorName]);

  const checkDuplicate = async () => {
    try {
      const response = await fetch('https://xs525443.xsrv.jp/admin_ui/check024.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          exhibitorName: formData.exhibitorName,
          Company_ID: formData.Company_ID
        }),
      });

      const text = await response.text();
      console.log('レスポンステキスト:', text);
      const result = JSON.parse(text);

      if (result.isDuplicate && result.additionalData) {
        try {
          const parsedData = JSON.parse(result.additionalData);
          setFormData(prevState => ({
            ...prevState,
            ...parsedData,
          }));
          setIsDuplicate(true);
          setIsReadOnly(true);
        } catch (error) {
          console.error('JSONデータのパース中にエラーが発生しました:', error);
        }
      } else {
        setIsDuplicate(false);
        setIsReadOnly(false);
      }
    } catch (error) {
      console.error('重複チェック中にエラーが発生しました:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('exhibitorName', formData.exhibitorName);
    formDataToSend.append('boothNumber', formData.boothNumber);
    formDataToSend.append('tel', formData.tel);
    formDataToSend.append('departmentPosition', formData.departmentPosition);
    formDataToSend.append('contactPerson', formData.contactPerson);
    formDataToSend.append('postalCode', formData.postalCode);
    formDataToSend.append('prefecture', formData.prefecture);
    formDataToSend.append('city', formData.city);
    formDataToSend.append('address', formData.address);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('Company_ID', formData.Company_ID);

    try {
      const response = await fetch('https://xs525443.xsrv.jp/admin_ui/submit024.php', {
        method: 'POST',
        body: formDataToSend
      });

      const successMessage = language === 'ja' ? 'データが正常に送信されました。' : 'Submission Completed';
    const failureMessage = language === 'ja' ? 'データの送信に失敗しました: ' : 'Failed to submit data: ';
    const errorMessage = language === 'ja' ? 'データの送信に失敗しました。' : 'Failed to submit data.';

      if (response.ok) {
        //alert(successMessage);
        setIsSubmitted(true);
        setIsReadOnly(true);
      } else {
        alert('データの送信中にエラーが発生しました。');
      }
    } catch (error) {
      console.error('データ送信エラー:', error);
      alert('データの送信中にエラーが発生しました。');
    }
  };

  const handleReset = async () => {
  const confirmMessage = language === 'ja' ? '本当に削除していいですか？' : 'Are you sure you want to reset?';
  const resetSuccessMessage = language === 'ja' ? 'フォームとファイルがリセットされました。' : 'Reset Completed';
  const deleteErrorMessage = language === 'ja' ? 'データの削除に失敗しました。' : 'Failed to delete data.';
    if (window.confirm(confirmMessage)) {
      try {
        const response = await fetch('https://xs525443.xsrv.jp/admin_ui/delete024.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
          exhibitorName: formData.exhibitorName,
          Company_ID: formData.Company_ID
        }),
        });
  
        const result = await response.json();
  
        if (result.success) {
          alert(resetSuccessMessage);
          checkDuplicate();
        } else {
          alert(deleteErrorMessage + ': ' + result.message);
        }
      } catch (error) {
        console.error('データの削除中にエラーが発生しました:', error);
        alert(deleteErrorMessage);
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> ) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  
  useEffect(() => {
    loadButtonState(); // コンポーネントのマウント時にボタン状態を読み込む
  }, []);

  return (
    <Layout title={<MultiLangText
      language={language}
      textJa="申請書類管理　24．小間内清掃 申込み"
      textEn='Application Forms 24.Application for Cleaning in the booth'
    />}>
      <Paper style={{ padding: 16 }}>
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="■出展者情報"
                textEn="■Exhibitor information"
                />
        </Typography>
        <hr/>
        <form onSubmit={handleSubmit}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault(); // Enterキーによるフォーム送信を防ぐ
             // アラートを表示
          }
        }}>
        <ExhibitorInfoForm
          formData={formData}
          setFormData={setFormData}
          handleChange={handleChange}
        />


    {/* セクションスタート */}
    <Typography gutterBottom mt={5} style={{ fontWeight: 'bold' }}>
    <MultiLangText
                language={language}
                textJa="■小間内清掃申込みについて"
                textEn="■Cleaning in the booth"
                />
      </Typography>
      <hr></hr>
      <Grid container spacing={3} alignItems="center">

      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>

      </Grid>
      <Grid item xs={10} sm={10}>
      <MultiLangText
                language={language}
                textJa="※清掃は、搬入最終日10 月15 日（火）と会期中10 月16 日（水）～ 18 日（金）の4 日間で、いずれも夕方に行います。<br/>
      ※清掃内容は、床面の掃除機かけ、またはモップによる水拭きになります。展示台、ショーケース、出展物等の清掃は含まれません。<br/>
      ※清掃料金：4,290円/1小間（9㎡）（税込）<br/>
      ※小間内掃除を希望する出展者は直接下記の事務局工事会社までお問い合わせの上、申込みください。"
                textEn="
                *Period of cleaning: October 15 (Tue.) – October 18 (Sat.) in the evening.<br/>
                *Cleaning in the booth includes cleaning of the floors only.(Vacuum or mopping depends on the type of flooring.)The cleaning of the exhibit table, showcase and exhibited items is not included.<br/>
                *Cleaning Fee：JPY 4,290 /booth (9 ㎡) (Tax included)<br/>
                *If you would like to order cleaning in your booth, please contact official contractor for your application.
"
                />
      </Grid>


      </Grid> 
      {/* セクションここまで */}
{/* セクションスタート */}
<Typography gutterBottom mt={5} style={{ fontWeight: 'bold' }}>
<MultiLangText
                language={language}
                textJa="■ お問い合わせ先について　※備考・その他は、下記まで直接ご連絡ください。"
                textEn="■Contact Information　If you have any questions or concerns, please inquire with the following contact."
                />
      </Typography>
      <hr></hr>
      <Grid container spacing={3} alignItems="center">

      {/* カラム */}
      <Grid item xs={12} sm={12}>
<MultiLangText
                language={language}
                textJa="（株）ビッグサイトサービス<br/>
〒135-0063 東京都江東区有明3-11-1<br/>
TEL：03-5530-1290<br/>
E-mail：bss-koma@bigsight-services.com<br/>
担当：池上、日向"
                textEn="
                Big Sight Services Corporation.<br/>
3-11-1, Ariake, Koto-ku, Tokyo 135-0063<br/>
TEL：+81-3-5530-1290<br/>
E-mail：bss-koma@bigsight-services.com<br/>
Ikegami, Hinata
"
                />
      </Grid>

      </Grid> 
      {/* セクションここまで */}
      <Grid item mt={5} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
      <Button
            variant="contained"
            color="secondary"
            style={{ marginRight: 8 }}
            onClick={handleReset}
            disabled={formData.buttonState.reset} // JSONから取得した値を使用
            sx={{ filter: formData.buttonState.reset ? 'grayscale(1)' : 'none' }} // グレーアウト
          >
            <MultiLangText language={language} textJa="リセット" textEn="Reset" />
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={formData.buttonState.submit || isDuplicate || isReadOnly} // JSONと他の状態を組み合わせる
            sx={{ filter: (formData.buttonState.submit || isDuplicate || isReadOnly) ? 'grayscale(1)' : 'none' }} // グレーアウト
          >
            <MultiLangText language={language} textJa="確定" textEn="Submit" />
          </Button>
    </Grid>

    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
      <MultiLangText
                language={language}
                textJa="
                ※申込不要のお客様も確定ボタンを押してください。リセットボタンにて再度フォームのご利用が可能です。
                "
                textEn="
                *Please click the SUBMIT button even if you do not need to apply. You can use the form again by clicking the RESET button.
                "
              />
      </Grid>
      
        </form>
      </Paper>
    </Layout>
  );
};

export default Template024;
