import React from 'react';
import { Grid, Typography, Paper, Container } from '@mui/material';
import MultiLangText from '../templetes/MultiLangText';
import { useAuth } from '../../context/AuthContext';

const ContactInfo: React.FC = () => {
  const { language } = useAuth();

  return (
    <Container>
        <Typography gutterBottom mt={5} style={{ fontWeight: 'bold' }}>
        <MultiLangText
                language={language}
                textJa="■ お問い合わせ先について　※備考・その他は、下記まで直接ご連絡ください。"
                textEn="■Contact Information　If you have any questions or concerns, please inquire with the following contact."
                />
          </Typography>
          <hr/>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              <MultiLangText
                language={language}
                textJa="
                事務局施工会社 （株）ムラヤマ<br/>
              〒135-0061 東京都江東区豊洲3-2-24 豊洲フォレシア<br/>
              TEL：03-6221-1960 （平日：10：00AM～18：00PM）<br/>
              E-mail：ja2024@murayama.co.jp"
                textEn="
                MURAYAMA INC.<br/>
                Toyosuforesia.3-2-24,Toyosu,Koto-ku,Tokyo 135-0061<br/>
                TEL:+81-3‐6221-1960（Weekday 09:30am-18:00pm）<br/>
                E-mail：ja2024@murayama.co.jp<br/>
                Norose,Takamura "
                />
            </Typography>
          </Grid>
        </Grid>
    </Container>
  );
};

export default ContactInfo;
