import React, { useState, useEffect } from 'react';
import Layout from '../templetes/Layout';
import { TextField, Button, Typography, Paper, Grid, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import ExhibitorInfoForm from '../templetes/ExhibitorInfoForm';
import MultiLangText from '../templetes/MultiLangText';
import { useAuth } from '../../context/AuthContext';
import ContactInfo3 from '../templetes/ContactInfo3';
import axios from 'axios';

const Template021: React.FC = () => {
  const { language } = useAuth();
  const isJapanese = language === 'ja';
  
  const [formData, setFormData] = useState({
    exhibitorName: '',
    boothNumber: '',
    tel: '',
    departmentPosition: '',
    contactPerson: '',
    postalCode: '',
    prefecture: '',
    city: '',
    address: '',
    email: '',
    Company_ID: '',
    buttonState: {
      reset: false,
      submit: false
    }
  });

  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

      // 外部JSONファイルからボタンの状態を取得する関数
const loadButtonState = async () => {
  try {
    const timestamp = new Date().getTime();
    const response = await axios.get(`/json/buttonState21.json?_=${timestamp}`);  // 外部JSONファイルのパス
    const buttonStateData = response.data;
    setFormData(prevState => ({
      ...prevState,
      buttonState: buttonStateData.buttonState
    }));
  } catch (error) {
    console.error('ボタン状態の読み込み中にエラーが発生しました:', error);
  }
};


  useEffect(() => {
    if (formData.exhibitorName) {
      checkDuplicate();
    }
  }, [formData.exhibitorName]);

  const checkDuplicate = async () => {
    try {
      const response = await fetch('https://xs525443.xsrv.jp/admin_ui/check021.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          exhibitorName: formData.exhibitorName,
          Company_ID: formData.Company_ID
        }),
      });

      const text = await response.text();
      console.log('レスポンステキスト:', text);
      const result = JSON.parse(text);

      if (result.isDuplicate && result.additionalData) {
        try {
          const parsedData = JSON.parse(result.additionalData);
          setFormData(prevState => ({
            ...prevState,
            ...parsedData,
          }));
          setIsDuplicate(true);
          setIsReadOnly(true);
        } catch (error) {
          console.error('JSONデータのパース中にエラーが発生しました:', error);
        }
      } else {
        setIsDuplicate(false);
        setIsReadOnly(false);
      }
    } catch (error) {
      console.error('重複チェック中にエラーが発生しました:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('exhibitorName', formData.exhibitorName);
    formDataToSend.append('boothNumber', formData.boothNumber);
    formDataToSend.append('tel', formData.tel);
    formDataToSend.append('departmentPosition', formData.departmentPosition);
    formDataToSend.append('contactPerson', formData.contactPerson);
    formDataToSend.append('postalCode', formData.postalCode);
    formDataToSend.append('prefecture', formData.prefecture);
    formDataToSend.append('city', formData.city);
    formDataToSend.append('address', formData.address);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('Company_ID', formData.Company_ID);

    try {
      const response = await fetch('https://xs525443.xsrv.jp/admin_ui/submit021.php', {
        method: 'POST',
        body: formDataToSend
      });

      const successMessage = language === 'ja' ? 'データが正常に送信されました。' : 'Submission Completed';
    const failureMessage = language === 'ja' ? 'データの送信に失敗しました: ' : 'Failed to submit data: ';
    const errorMessage = language === 'ja' ? 'データの送信に失敗しました。' : 'Failed to submit data.';

      if (response.ok) {
       // alert(successMessage);
        setIsSubmitted(true);
        setIsReadOnly(true);
      } else {
        alert('データの送信中にエラーが発生しました。');
      }
    } catch (error) {
      console.error('データ送信エラー:', error);
      alert('データの送信中にエラーが発生しました。');
    }
  };

  const handleReset = async () => {
  const confirmMessage = language === 'ja' ? '本当に削除していいですか？' : 'Are you sure you want to reset?';
  const resetSuccessMessage = language === 'ja' ? 'フォームとファイルがリセットされました。' : 'Reset Completed';
  const deleteErrorMessage = language === 'ja' ? 'データの削除に失敗しました。' : 'Failed to delete data.';
    if (window.confirm(confirmMessage)) {
      try {
        const response = await fetch('https://xs525443.xsrv.jp/admin_ui/delete021.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
          exhibitorName: formData.exhibitorName,
          Company_ID: formData.Company_ID
        }),
        });
  
        const result = await response.json();
  
        if (result.success) {
          alert(resetSuccessMessage);
          checkDuplicate();
        } else {
          alert(deleteErrorMessage + ': ' + result.message);
        }
      } catch (error) {
        console.error('データの削除中にエラーが発生しました:', error);
        alert(deleteErrorMessage);
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> ) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    loadButtonState(); // コンポーネントのマウント時にボタン状態を読み込む
  }, []);
  
  return (
<Layout title={<MultiLangText
      language={language}
      textJa="申請書類管理　21．食品取扱届 申込み"
      textEn="Application Forms 21.Application for In-Stand Food/Beverage Handling"
    />}>
      <Paper style={{ padding: 16 }}>
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="■出展者情報"
                textEn="■Exhibitor information"
                />
        </Typography>
        <hr/>
        <form onSubmit={handleSubmit}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault(); // Enterキーによるフォーム送信を防ぐ
             // アラートを表示
          }
        }}>
        <ExhibitorInfoForm
          formData={formData}
          setFormData={setFormData}
          handleChange={handleChange}
        />


    {/* セクションスタート */}
    <Typography gutterBottom mt={5} style={{ fontWeight: 'bold' }}>
    <MultiLangText
                language={language}
                textJa="■食品取扱いの届出について"
                textEn="■In-Stand Food/Beverage Handling"
                />
      </Typography>
      <hr></hr>
      <Grid container spacing={3} alignItems="center">

      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      <MultiLangText
                language={language}
                textJa="内容"
                textEn="Details"
                />　
      </Grid>
      <Grid item xs={10} sm={10}>
      <MultiLangText
                language={language}
                textJa="出展ブース内で食品の取扱いがある場合、下記より申込書をダウンロードの上、下記事務局施工会社までご提出ください。"
                textEn="If you provide food and beverage at your booth, please download the application form below and submit it  to official contractor by e-mail."
                />
      </Grid>

      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={10} sm={10}>
      <MultiLangText
                language={language}
                textJa='申込書は<a href="https://xs525443.xsrv.jp/admin_ui/pdf/21.pdf" target="_blink">こちら</a>からダウンロード'
                textEn='The application form can be downloaded <a href="https://xs525443.xsrv.jp/admin_ui/pdf/21_en.pdf" target="_blink">Application Forms 21.Application for In-Stand Food/Beverage Handling.pdf</a>'
                />
      </Grid>

      </Grid> 
      {/* セクションここまで */}
      <br></br><br></br>


{/* セクションスタート */}
<Typography gutterBottom mt={5} style={{ fontWeight: 'bold' }}>
<MultiLangText
                language={language}
                textJa="■ お問い合わせ先について　※備考・その他は、下記まで直接ご連絡ください。"
                textEn="■Contact Information　If you have any questions or concerns, please inquire with the following contact."
                />
      </Typography>
      <hr></hr>
      <Grid container spacing={3} alignItems="center">

      {/* カラム */}
      <Grid item xs={12} sm={12}>
<MultiLangText
                language={language}
                textJa="
                      事務局施工会社 （株）ムラヤマ<br/>
〒135-0061 東京都江東区豊洲3-2-24 豊洲フォレシア<br/>
TEL：03-6221-1960（平日:10:00AM〜18:00PM）<br/>
E-mail：ja2024@murayama.co.jp<br/>
担当:伊藤、髙村、野呂瀬
                "
                textEn="MURAYAMA INC.<br/>
Toyosuforesia.3-2-24,Toyosu,Koto-ku,Tokyo 135-0061<br/>
TEL:+81-3‐6221-1960（Weekday 10:00AM〜18:00PM）<br/>
E-mail：ja2024@murayama.co.jp<br/>
Norose,Takamura "
                />
      </Grid>

      </Grid> 
      {/* セクションここまで */}
      <Grid item mt={5} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
      <Button
            variant="contained"
            color="secondary"
            style={{ marginRight: 8 }}
            onClick={handleReset}
            disabled={formData.buttonState.reset} // JSONから取得した値を使用
            sx={{ filter: formData.buttonState.reset ? 'grayscale(1)' : 'none' }} // グレーアウト
          >
            <MultiLangText language={language} textJa="リセット" textEn="Reset" />
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={formData.buttonState.submit || isDuplicate || isReadOnly} // JSONと他の状態を組み合わせる
            sx={{ filter: (formData.buttonState.submit || isDuplicate || isReadOnly) ? 'grayscale(1)' : 'none' }} // グレーアウト
          >
            <MultiLangText language={language} textJa="確定" textEn="Submit" />
          </Button>
    </Grid>

    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
      <MultiLangText
                language={language}
                textJa="
                ※申込不要のお客様も確定ボタンを押してください。リセットボタンにて再度フォームのご利用が可能です。
                "
                textEn="
                *Please click the SUBMIT button even if you do not need to apply. You can use the form again by clicking the RESET button.
                "
              />
      </Grid>

        </form>
      </Paper>
    </Layout>
  );
};

export default Template021;
