import React from 'react';
import { styled, Paper } from '@mui/material';
import MultiLangText from '../templetes/MultiLangText';
import { useAuth } from '../../context/AuthContext';

const StyledPaper = styled(Paper)({
  padding: '3px 12px',
  backgroundColor: '#3c71ac',
  color: 'white',
  fontSize: '12px',
  fontWeight: 'bold',
  height: '24px',
  width: '70px', // 幅を固定
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0 // これによりサイズが圧縮されることを防ぐ
});

const RequiredLabel = () => {
  const { language } = useAuth();
  return (
    <StyledPaper>
      <MultiLangText
                language={language}
                textJa="必須"
                textEn="Required"
                />
    </StyledPaper>
  );
};

export default RequiredLabel;
