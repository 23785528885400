import React, { useState, useEffect, useRef } from 'react';
import Layout from '../templetes/Layout';
import { 
  TextField, Button, Typography, Paper, Grid, FormControlLabel, Radio, RadioGroup, Select, MenuItem, InputLabel, FormControl
} from '@mui/material';
import ExhibitorInfoForm from '../templetes/ExhibitorInfoForm';
import { SelectChangeEvent } from '@mui/material';
import ContactInfo3 from '../templetes/ContactInfo3';
import RequiredLabel from '../templetes/RequiredLabel';
import MultiLangText from '../templetes/MultiLangText';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';

const Template018: React.FC = () => {
  const { language } = useAuth();
  const isJapanese = language === 'ja';
  
  const [formData, setFormData] = useState({
    exhibitorName: '',
    boothNumber: '',
    tel: '',
    departmentPosition: '',
    contactPerson: '',
    postalCode: '',
    prefecture: '',
    city: '',
    address: '',
    email: '',
    Company_ID: '',
    exhibitPlan: '',
    punchCarpet: '',
    wallSheet: '',
    signboardColor: '',
    remarks: '',
    billingOption: '',
    billingCompanyName: '',
    billingMobile: '',
    billingTel: '',
    billingDepartmentPosition: '',
    billingContactPerson: '',
    billingPostalCode: '',
    billingPrefecture: '',
    billingCity: '',
    billingAddress: '',
    billingEmail: '',
    jsonData: '',
    buttonState: {
      reset: false,  // リセットボタンの状態
      save: false,   // 保存ボタンの状態
      submit: false  // 確定ボタンの状態
    }
  });

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    const loadButtonState = async () => {
      try {
        const timestamp = new Date().getTime();
        const response = await axios.get(`/json/buttonState18.json?_=${timestamp}`);  // 外部JSONのパスを指定
        const buttonStateData = response.data;
        setFormData(prevState => ({
          ...prevState,
          buttonState: buttonStateData.buttonState
        }));
        setIsReadOnly(buttonStateData.buttonState.disabled);
      } catch (error) {
        console.error('ボタン状態の読み込み中にエラーが発生しました:', error);
      }
    };
  
    loadButtonState();  // コンポーネントの初回レンダリング時に実行
  }, []);

  useEffect(() => {
    const savedData = localStorage.getItem('formData018');
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);

  useEffect(() => {
    if (formData.exhibitorName) {
      checkDuplicate();
    }
  }, [formData.exhibitorName]);

  const checkDuplicate = async () => {
    try {
      const response = await fetch('https://xs525443.xsrv.jp/admin_ui/check018.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          exhibitorName: formData.exhibitorName,
          Company_ID: formData.Company_ID
        }),
      });

      const result = await response.json();
      if (result.isDuplicate && result.additionalData) {
        const parsedData = JSON.parse(result.additionalData);
        setFormData(prevState => ({
          ...prevState,
          ...parsedData,
        }));
        setIsDuplicate(true);
        setIsReadOnly(true);
      } else {
        setIsDuplicate(false);
      }
    } catch (error) {
      console.error('重複チェック中にエラーが発生しました:', error);
    }
  };

  const handleSave = () => {
    const jsonData = {
      exhibitPlan: formData.exhibitPlan,
      punchCarpet: formData.punchCarpet,
      wallSheet: formData.wallSheet,
      signboardColor: formData.signboardColor,
      remarks: formData.remarks,
      billingCompanyName: formData.billingCompanyName,
      billingMobile: formData.billingMobile,
      billingTel: formData.billingTel,
      billingDepartmentPosition: formData.billingDepartmentPosition,
      billingContactPerson: formData.billingContactPerson,
      billingPostalCode: formData.billingPostalCode,
      billingPrefecture: formData.billingPrefecture,
      billingCity: formData.billingCity,
      billingAddress: formData.billingAddress,
      billingEmail: formData.billingEmail,
      filePath: selectedFile ? `${selectedFile.name}` : ''
    };
  
    const updatedFormData = {
      ...formData,
      jsonData: JSON.stringify(jsonData)
    };
  
    setFormData(updatedFormData);
    localStorage.setItem('formData018', JSON.stringify(updatedFormData));
  
    alert('データが正常に保存されました。');
  };
  
  

  
  const handleReset = async () => {
  const confirmMessage = language === 'ja' ? '本当に削除していいですか？' : 'Are you sure you want to reset?';
  const resetSuccessMessage = language === 'ja' ? 'フォームとファイルがリセットされました。' : 'Reset Completed';
  const deleteErrorMessage = language === 'ja' ? 'データの削除に失敗しました。' : 'Failed to delete data.';
    if (window.confirm(confirmMessage)) {
      try {
        const response = await fetch('https://xs525443.xsrv.jp/admin_ui/delete018.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
          exhibitorName: formData.exhibitorName,
          Company_ID: formData.Company_ID
        }),
        });
  
        const result = await response.json();
  
        if (result.success) {
          console.log('ファイル削除とデータ削除が成功しました:', result);
          setFormData(prevState => ({
            ...prevState,
            exhibitPlan: '',
            punchCarpet: '',
            wallSheet: '',
            signboardColor: '',
            remarks: '',
            billingOption: '',
            billingCompanyName: '',
            billingMobile: '',
            billingTel: '',
            billingDepartmentPosition: '',
            billingContactPerson: '',
            billingPostalCode: '',
            billingPrefecture: '',
            billingCity: '',
            billingAddress: '',
            billingEmail: '',
            jsonData: ''
          }));
          localStorage.removeItem('formData018');
          setSelectedFile(null);
          setFileName('');
          setIsReadOnly(false);
          setIsSubmitted(false);
          alert(resetSuccessMessage);
          checkDuplicate(); // 再度重複チェックを実行
        } else {
          alert('ファイルまたはデータの削除に失敗しました: ' + result.message);
        }
      } catch (error) {
        console.error('データの削除中にエラーが発生しました:', error);
        alert(deleteErrorMessage);
      }
    }
  };
    
  
  
  

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    const formDataToSend = new FormData();
  
    formDataToSend.append('exhibitorName', formData.exhibitorName);
    formDataToSend.append('boothNumber', formData.boothNumber);
    formDataToSend.append('tel', formData.tel);
    formDataToSend.append('departmentPosition', formData.departmentPosition);
    formDataToSend.append('contactPerson', formData.contactPerson);
    formDataToSend.append('postalCode', formData.postalCode);
    formDataToSend.append('prefecture', formData.prefecture);
    formDataToSend.append('city', formData.city);
    formDataToSend.append('address', formData.address);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('Company_ID', formData.Company_ID);
    // jsonDataを追加
    const jsonData = {
      exhibitPlan: formData.exhibitPlan,
      punchCarpet: formData.punchCarpet,
      wallSheet: formData.wallSheet,
      signboardColor: formData.signboardColor,
      remarks: formData.remarks,
      billingOption: formData.billingOption,
      billingCompanyName: formData.billingCompanyName,
      billingMobile: formData.billingMobile,
      billingTel: formData.billingTel,
      billingDepartmentPosition: formData.billingDepartmentPosition,
      billingContactPerson: formData.billingContactPerson,
      billingPostalCode: formData.billingPostalCode,
      billingPrefecture: formData.billingPrefecture,
      billingCity: formData.billingCity,
      billingAddress: formData.billingAddress,
      billingEmail: formData.billingEmail,
      filePath: selectedFile ? `018up/${selectedFile.name}` : ''
    };
  
    formDataToSend.append('jsonData', JSON.stringify(jsonData));
  
    if (selectedFile) {
      formDataToSend.append('file', selectedFile);
    }
  
    try {
      const response = await fetch('https://xs525443.xsrv.jp/admin_ui/submit018.php', {
        method: 'POST',
        body: formDataToSend
      });

      const successMessage = language === 'ja' ? 'データが正常に送信されました。' : 'Submission Completed';
    const failureMessage = language === 'ja' ? 'データの送信に失敗しました: ' : 'Failed to submit data: ';
    const errorMessage = language === 'ja' ? 'データの送信に失敗しました。' : 'Failed to submit data.';
  
      if (response.ok) {
       // alert(successMessage);
        setIsSubmitted(true);
        setIsReadOnly(true);
      } else {
        alert('データの送信中にエラーが発生しました。');
      }
    } catch (error) {
      console.error('データ送信エラー:', error);
      alert('データの送信中にエラーが発生しました。');
    }
  };
  

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<string>('');

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      const timestamp = Math.floor(Date.now() / 1000);
      const newFile = new File([file], `${timestamp}_${file.name}`, { type: file.type });
      setSelectedFile(newFile);
      setFileName(file.name);
    }
  };

  const handlePostalCodeChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const cleanedValue = value.replace(/-/g, '');

    setFormData(prevState => ({
      ...prevState,
      [name]: cleanedValue
    }));

    if (cleanedValue.length === 7) {
      try {
        const response = await fetch(`https://api.zipaddress.net/?zipcode=${cleanedValue}`);
        const data = await response.json();
        if (data.code === 200) {
          if (name === 'billingPostalCode') {
            setFormData(prevState => ({
              ...prevState,
              billingPrefecture: data.data.pref,
              billingCity: data.data.city,
              billingAddress: data.data.town
            }));
          } else if (name === 'shippingPostalCode') {
            setFormData(prevState => ({
              ...prevState,
              shippingPrefecture: data.data.pref,
              shippingCity: data.data.city,
              shippingAddress: data.data.town
            }));
          }
        } else {
          alert('郵便番号から住所を取得できませんでした。');
        }
      } catch (error) {
        console.error('郵便番号から住所を取得中にエラーが発生しました:', error);
      }
    }
  };

  const prefectures = ["北海道", "青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県", "茨城県", "栃木県", "群馬県", "埼玉県", "千葉県", "東京都", "神奈川県", "新潟県", "富山県", "石川県", "福井県", "山梨県", "長野県", "岐阜県", "静岡県", "愛知県", "三重県", "滋賀県", "京都府", "大阪府", "兵庫県", "奈良県", "和歌山県", "鳥取県", "島根県", "岡山県", "広島県", "山口県", "徳島県", "香川県", "愛媛県", "高知県", "福岡県", "佐賀県", "長崎県", "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県"];

  return (
    <Layout title={<MultiLangText
      language={language}
      textJa="申請書類管理　18．パッケージブース 申込み"
      textEn="Application Forms 18.Application for the Packaged Booth"
    />}>
      <Paper style={{ padding: 16 }}>
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="■出展者情報"
                textEn="■Exhibitor information"
          />
        </Typography>
        <hr />
        <form onSubmit={handleSubmit}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault(); // Enterキーによるフォーム送信を防ぐ
             // アラートを表示
          }
        }}>
          <ExhibitorInfoForm
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
          />
          <br /><br />
          <Typography gutterBottom mt={5} style={{ fontWeight: 'bold' }}>
            <MultiLangText
              language={language}
              textJa="■　パッケージブースの申込について（下記の選択してください）"
              textEn="■　Packaged Booth (please select)"
            />
          </Typography>
          <hr />
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={3} sm={3} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="①パッケージブース"
                textEn="1. Packaged Booth"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>
              <RadioGroup
                row
                aria-label="exhibitPlan"
                name="exhibitPlan"
                value={formData.exhibitPlan}
                onChange={handleChange}
              >
                <Grid item xs={6} sm={6}>
                  <FormControlLabel value="１小間 Aタイプ" control={<Radio/>} required label={
                    <MultiLangText
                      language={language}
                      textJa="1小間Aタイプ"
                      textEn="1 Booth Type A"
                    />
                  } disabled={isReadOnly} />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <FormControlLabel value="２小間 A タイプ" control={<Radio/>} required label={<MultiLangText
                    language={language}
                    textJa="2小間Aタイプ"
                    textEn="2 Booths Type A"
                  />} disabled={isReadOnly} />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <FormControlLabel value="１小間 Bタイプ" control={<Radio/>} required label={<MultiLangText
                    language={language}
                    textJa="1小間Bタイプ"
                    textEn="1 Booth Type B"
                  />} disabled={isReadOnly} />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <FormControlLabel value="２小間 B タイプ" control={<Radio/>} required label={<MultiLangText
                    language={language}
                    textJa="2小間Bタイプ"
                    textEn="2 Booths Type B"
                  />} disabled={isReadOnly} />
                </Grid>
              </RadioGroup>
            </Grid>

            <Grid item xs={3} sm={3} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="②パンチカーペット"
                textEn="2. Needle-punch carpet"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>
              <RadioGroup
                row
                aria-label="punchCarpet"
                name="punchCarpet"
                value={formData.punchCarpet}
                onChange={handleChange}
              >
                <Grid item xs={4} sm={4}>
                  <FormControlLabel value="レッド" control={<Radio/>} required label={<MultiLangText
                    language={language}
                    textJa="レッド"
                    textEn="Red"
                  />} disabled={isReadOnly} />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <FormControlLabel value="オレンジ" control={<Radio/>} required label={<MultiLangText
                    language={language}
                    textJa="オレンジ"
                    textEn="Orange"
                  />} disabled={isReadOnly} />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <FormControlLabel value="グリーン" control={<Radio/>} required label={
                    <MultiLangText
                      language={language}
                      textJa="グリーン"
                      textEn="Green"
                    />
                  } disabled={isReadOnly} />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <FormControlLabel value="ブルー" control={<Radio/>} required label={<MultiLangText
                    language={language}
                    textJa="ブルー"
                    textEn="Blue"
                  />} disabled={isReadOnly} />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <FormControlLabel value="グレー" control={<Radio/>} required label={<MultiLangText
                    language={language}
                    textJa="グレー"
                    textEn="Grey"
                  />} disabled={isReadOnly} />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <FormControlLabel value="ブラック" control={<Radio/>} required label={<MultiLangText
                    language={language}
                    textJa="ブラック"
                    textEn="Black"
                  />} disabled={isReadOnly} />
                </Grid>
              </RadioGroup>
            </Grid>

            <Grid item xs={3} sm={3} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="③壁面・展示台シート貼り（別途有料）"
                textEn="③Color sheets applied to system panels and display box (additional cost)"
              />
            </Grid>
            <Grid item xs={9} sm={9}>
              <RadioGroup
                row
                aria-label="wallSheet"
                name="wallSheet"
                value={formData.wallSheet}
                onChange={handleChange}
              >
                <Grid item xs={3} sm={3}>
                  <FormControlLabel value="イエロー" control={<Radio/>} label={<MultiLangText
                    language={language}
                    textJa="イエロー"
                    textEn="Yellow"
                  />} disabled={isReadOnly} />
                </Grid>
                <Grid item xs={3} sm={3}>
                  <FormControlLabel value="レッド" control={<Radio/>} label={<MultiLangText
                    language={language}
                    textJa="レッド"
                    textEn="Red"
                  />} disabled={isReadOnly} />
                </Grid>
                <Grid item xs={3} sm={3}>
                  <FormControlLabel value="グリーン" control={<Radio/>} label={<MultiLangText
                    language={language}
                    textJa="グリーン"
                    textEn="Green"
                  />} disabled={isReadOnly} />
                </Grid>
                <Grid item xs={3} sm={3}>
                  <FormControlLabel value="スカイブルー" control={<Radio/>} label={<MultiLangText
                    language={language}
                    textJa="スカイブルー"
                    textEn="Sky blue"
                  />} disabled={isReadOnly} />
                </Grid>
                <Grid item xs={3} sm={3}>
                  <FormControlLabel value="ブルー" control={<Radio/>} label={
                    <MultiLangText
                      language={language}
                      textJa="ブルー"
                      textEn="Blue"
                    />
                  } disabled={isReadOnly} />
                </Grid>
                <Grid item xs={3} sm={3}>
                  <FormControlLabel value="ネイビー" control={<Radio/>} label={
                    <MultiLangText
                      language={language}
                      textJa="ネイビー"
                      textEn="Navy"
                    />
                  } disabled={isReadOnly} />
                </Grid>
                <Grid item xs={3} sm={3}>
                  <FormControlLabel value="グレー" control={<Radio/>} label={
                    <MultiLangText
                      language={language}
                      textJa="グレー"
                      textEn="Grey"
                    />
                  } disabled={isReadOnly} />
                </Grid>
                <Grid item xs={3} sm={3}>
                  <FormControlLabel value="ブラック" control={<Radio/>} label={
                    <MultiLangText
                      language={language}
                      textJa="ブラック"
                      textEn="Black"
                    />
                  } disabled={isReadOnly} />
                </Grid>
              </RadioGroup>
            </Grid>

            <Grid item xs={3} sm={3} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="④社名板文字カラー"
                textEn="④.Color of text to be printed company name board"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>
              <RadioGroup
                row
                aria-label="signboardColor"
                name="signboardColor"
                value={formData.signboardColor}
                onChange={handleChange}
              >
                <Grid item xs={4} sm={4}>
                  <FormControlLabel value="ブルー" control={<Radio/>} required label={<MultiLangText
                    language={language}
                    textJa="ブルー"
                    textEn="Blue"
                  />} disabled={isReadOnly} />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <FormControlLabel value="イエロー" control={<Radio/>} required label={<MultiLangText
                    language={language}
                    textJa="イエロー"
                    textEn="Yellow"
                  />} disabled={isReadOnly} />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <FormControlLabel value="レッド" control={<Radio/>} required label={<MultiLangText
                    language={language}
                    textJa="レッド"
                    textEn="Red"
                  />} disabled={isReadOnly} />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <FormControlLabel value="グリーン" control={<Radio/>} required label={
                    <MultiLangText
                      language={language}
                      textJa="グリーン"
                      textEn="Green"
                    />
                  } disabled={isReadOnly} />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <FormControlLabel value="ブラック" control={<Radio/>} required label={<MultiLangText
                    language={language}
                    textJa="ブラック"
                    textEn="Black"
                  />} disabled={isReadOnly} />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <FormControlLabel value="別カラー" control={<Radio/>} required label={<MultiLangText
                    language={language}
                    textJa="別カラー"
                    textEn="Other Color"
                  />} disabled={isReadOnly} />
                </Grid>
              </RadioGroup>
              <MultiLangText
                language={language}
                textJa="※パッケージブース備品にご要望レイアウトがある場合は、下記より図面をアップロードしてください。"
                textEn="*If you have a requested layout, please upload the drawing below."
              />
            </Grid>

            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={2} sm={2}>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
                accept="image/*,application/pdf,application/zip"
                disabled={isReadOnly}
              />
              <Button variant="contained" onClick={handleButtonClick} disabled={isReadOnly}>
                <MultiLangText
                  language={language}
                  textJa="ファイルを選択"
                  textEn="Select a file"
                />
              </Button>
              {fileName && <Typography variant="body2">{fileName}</Typography>}
            </Grid>
            <Grid item xs={8} sm={8}>
              <MultiLangText
                language={language}
                textJa="
                アップロードが可能なサイズ容量は10MBまでとなります。<br/>
              複数のデータがある場合は１つに統合してください。<br/>
              (zip、PDF、jpg、png)
                "
                textEn="
                The maximum upload size is 10MB.<br/>
                If you have multiple files, please combine them into one.<br/>
                (zip, PDF, jpg, png)
                "
              />
            </Grid>

            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="備考・その他（任意）"
                textEn="Remarks"
              />
            </Grid>
            <Grid item xs={9} sm={9}>
              <TextField
                name="remarks"
                value={formData.remarks}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>
          </Grid>

          <br /><br />
          <Typography gutterBottom mt={5} style={{ fontWeight: 'bold' }}>
            <MultiLangText
              language={language}
              textJa="■ 請求先について（出展者以外のご請求の場合は、下記にご記入下さい。会期後、事務局より請求となります。）"
              textEn="■Invoice recipient　(Please enter the following if invoice recipient is different from the exhibitor.)"
            />
          </Typography>
          <hr />
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="お選びください"
                textEn="Please select"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>
              <RadioGroup
                row
                aria-label="billingOption"
                name="billingOption"
                value={formData.billingOption}
                onChange={(e) => setFormData({ ...formData, billingOption: e.target.value })}
              >
                <Grid item xs={3} sm={3}>
                  <FormControlLabel value="出展者" control={<Radio required />} label={<MultiLangText
                    language={language}
                    textJa="出展者"
                    textEn="Exhibitor"
                  />} disabled={isReadOnly} />
                </Grid>
                <Grid item xs={3} sm={3}>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <FormControlLabel value="その他（下記へご記入下さい）" control={<Radio required />} label={<MultiLangText
                    language={language}
                    textJa="その他（下記へご記入ください）"
                    textEn="Other (please fill in below)"
                  />} disabled={isReadOnly} />
                </Grid>
              </RadioGroup>
            </Grid>

            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="■請求先社名"
                textEn="Invoice recipient"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>
              <TextField
                name="billingCompanyName"
                value={formData.billingCompanyName}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>

            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="担当者携帯電話番号"
                textEn="Mobile Phone Number"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                name="billingMobile"
                value={formData.billingMobile}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              TEL　<RequiredLabel />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                name="billingTel"
                value={formData.billingTel}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>

            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="担当者部署・役職"
                textEn="Title / Department of the Contact Person"
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                name="billingDepartmentPosition"
                value={formData.billingDepartmentPosition}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="担当者名"
                textEn="Name of Contact Person"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                name="billingContactPerson"
                value={formData.billingContactPerson}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>

            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="住所"
                textEn="Address"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>
              <Grid container>
                <Grid item xs={5} sm={5}>
                  <TextField
                    label={<MultiLangText
                      language={language}
                      textJa="〒"
                      textEn="Postal Code"
                    />}
                    name="billingPostalCode"
                    value={formData.billingPostalCode}
                    onChange={handlePostalCodeChange}
                    fullWidth
                    disabled={isReadOnly}
                  />
                </Grid>

                <Grid item xs={0} sm={0.1}></Grid>

                {
                  isJapanese && (
                    <Grid item xs={5} sm={5}>
                  <FormControl fullWidth>
                    <InputLabel id="prefecture-label">都道府県</InputLabel>
                    <Select
                      labelId="prefecture-label"
                      id="billingPrefecture"
                      name="billingPrefecture"
                      value={formData.billingPrefecture}
                      label="都道府県"
                      onChange={(e) => setFormData({ ...formData, billingPrefecture: e.target.value })}
                      disabled={isReadOnly}
                    >
                      {prefectures.map((prefecture, index) => (
                        <MenuItem key={index} value={prefecture}>{prefecture}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                  )
                }
                {
                  !isJapanese && (
                    <Grid item xs={5} sm={5}>
                    </Grid>
                  )
                }
                
              </Grid>
            </Grid>

            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={9} sm={9}>
              <TextField
                label={<MultiLangText
                language={language}
                textJa="市区町村名（例：大阪市北区）"
                textEn="city"
                />}

                name="billingCity"
                value={formData.billingCity}
                onChange={handleChange}
                fullWidth
                disabled={isReadOnly}
              />
            </Grid>

            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={9} sm={9}>
              <TextField
                label={<MultiLangText
                language={language}
                textJa="番地・ビル名（例：〇〇１丁目2-3）"
                textEn="Address and Building Name"
                />}
                name="billingAddress"
                value={formData.billingAddress}
                onChange={handleChange}
                fullWidth
                disabled={isReadOnly}
              />
            </Grid>

            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="メールアドレス"
                textEn="E-mail"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>
              <TextField
                label="XXXXX@xxxxxx.com"
                name="billingEmail"
                value={formData.billingEmail}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>

            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={9} sm={9}>
            <MultiLangText
                language={language}
                textJa=""
                textEn="*Billing invoice will be issued from the JA2024 management office."
              />
            </Grid>

            <ContactInfo3 />
            {/* カラム */}
            <Grid item xs={12} sm={12} style={{ display: 'flex'}}>
              <MultiLangText
                language={language}
                textJa="
※下記、「確定」ボタンを押して、申請してください。「保存」中は修正が可能です。<br/>
              ※「リセット」ボタンがある申請書については、万一、確定後に修正が必要な場合も、内容の修正が可能です。
"
                textEn='
*Please click the "SUBMIT" button to submit your application. You can "SAVE” the form while you still need to modify it.<br/>
*Some forms have “RESET” button, which you can use when you need to reset and fill in the form again after submitting the form.
'
              />
            </Grid>
          </Grid>
          <Grid container mt={5} spacing={1} justifyContent="center">
            <Grid item>
              <Button
                onClick={handleReset}
                variant="contained"
                color="secondary"
                disabled={formData.buttonState.reset}  // JSONで制御
                sx={{ filter: formData.buttonState.reset ? 'grayscale(1)' : 'none' }}  // グレーアウト
              >
                <MultiLangText
                  language={language}
                  textJa="リセット"
                  textEn="Reset"
                />
              </Button>
            </Grid>

            <Grid item>
              <Button
                onClick={handleSave}
                variant="contained"
                color="primary"
                disabled={formData.buttonState.save}  // JSONで制御
                sx={{ filter: formData.buttonState.save ? 'grayscale(1)' : 'none' }}  // グレーアウト
              >
                <MultiLangText
                  language={language}
                  textJa="保存"
                  textEn="Save"
                />
              </Button>
            </Grid>

            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={formData.buttonState.submit || isDuplicate || isSubmitted}  // JSONで制御 + 重複や送信済みの場合
                sx={{ filter: (formData.buttonState.submit || isDuplicate || isSubmitted) ? 'grayscale(1)' : 'none' }}  // グレーアウト
              >
                <MultiLangText
                  language={language}
                  textJa="確定"
                  textEn="Submit"
                />
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Layout>
  );
};

export default Template018;
