import React, { useState, useEffect } from 'react';
import Layout from '../templetes/Layout';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

interface Exhibitor {
  id: number;
  exhibitorName: string;
  boothNumber: string;
  tel: string;
  departmentPosition: string;
  contactPersonLastName: string;
  contactPersonFirstName: string;
  postalCode: string;
  prefecture: string;
  city: string;
  address: string;
  email: string;
  createdAt: string;
}

const UserManagement: React.FC = () => {
  const [exhibitors, setExhibitors] = useState<Exhibitor[]>([]);

  useEffect(() => {
    fetch('https://xs525443.xsrv.jp/admin_ui/get_exhibitors.php')
      .then(response => response.json())
      .then(data => setExhibitors(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
<Layout title="ユーザー管理">
      <TableContainer component={Paper} style={{ width: '100%' }}>
        <Table  sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: 'auto' }}>企業名</TableCell>
              <TableCell sx={{ width: '20%' }}>ブース番号</TableCell>
              <TableCell sx={{ width: '2%' }}>電話番号</TableCell>
              <TableCell sx={{ width: '20%' }}>担当者</TableCell>
              <TableCell sx={{ width: '20%' }}>都道府県</TableCell>
              <TableCell sx={{ width: '15%' }}>メールアドレス</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {exhibitors.map((exhibitor) => (
              <TableRow key={exhibitor.id}>
                <TableCell sx={{ width: 'auto' }}>{exhibitor.exhibitorName}</TableCell>
                <TableCell sx={{ width: '20%' }}>{exhibitor.boothNumber}</TableCell>
                <TableCell sx={{ width: '2%' }}>{exhibitor.tel}</TableCell>
                <TableCell sx={{ width: '20%' }}>{exhibitor.contactPersonLastName} {exhibitor.contactPersonFirstName}</TableCell>
                <TableCell sx={{ width: '20%' }}>{exhibitor.prefecture}</TableCell>
                <TableCell sx={{ width: '15%' }}>{exhibitor.email}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Layout>
  );
};

export default UserManagement;
