import React, { useEffect } from 'react';
import axios from 'axios';
import { Grid, TextField } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import MultiLangText from '../templetes/MultiLangText';

interface ExhibitorInfoFormProps {
  formData: any;
  setFormData: (data: any) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const ExhibitorInfoForm: React.FC<ExhibitorInfoFormProps> = ({ formData, setFormData, handleChange }) => {
  const { user } = useAuth();
  const { language } = useAuth();

  
  useEffect(() => {
    if (user) {
      const Company_ID = user.Company_ID; // Company_ID を user オブジェクトから取得
      axios.post('https://xs525443.xsrv.jp/admin_ui/getExhibitors.php', { Company_ID })
        .then(response => {
    
          if (response.data && !response.data.error) {
            const data = response.data;
            const contactPerson = `${data.contactPersonLastName} ${data.contactPersonFirstName}`;
            setFormData((prevFormData: typeof formData) => ({
              ...prevFormData,
              exhibitorName: data.exhibitorName,
              boothNumber: data.boothNumber,
              tel: data.tel,
              departmentPosition: data.departmentPosition,
              contactPerson: contactPerson,
              postalCode: data.postalCode,
              prefecture: data.prefecture,
              city: data.city,
              address: data.buildingName,
              email: data.email,
              Company_ID: data.Company_ID
            }));
          } else {
            console.warn('No data or error received from API', response.data);
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
  }, [user, setFormData]);
  
  

  return (
    <Grid container spacing={0} alignItems="center">
        <TextField
            type="hidden"
            name="Company_ID"
            value={formData.Company_ID || ''}
            onChange={handleChange}
        />
        <Grid item xs={2} sm={2} style={{ flexShrink: 0 }}>
            <div style={{ whiteSpace: 'normal' }}>
                <MultiLangText
                    language={language}
                    textJa="出展者名"
                    textEn="Exhibitor's Name"
                />
            </div>
        </Grid>
        <Grid item xs={10} sm={10}>
            <TextField
                label=""
                name="exhibitorName"
                value={formData.exhibitorName || ''}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
                disabled
                InputProps={{
                    readOnly: true,
                }}
            />
        </Grid>
        <Grid item xs={2} sm={2} style={{ flexShrink: 0 }}>
            <div style={{ whiteSpace: 'normal' }}>
                <MultiLangText
                    language={language}
                    textJa="小間番号"
                    textEn="Booth No."
                />
            </div>
        </Grid>
        <Grid item xs={4} sm={4}>
            <TextField
                label=""
                name="boothNumber"
                value={formData.boothNumber || ''}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
                disabled
                InputProps={{
                    readOnly: true,
                }}
            />
        </Grid>
        <Grid item xs={2} sm={2} style={{ flexShrink: 0 }}>
            <div style={{ whiteSpace: 'normal' }}>　TEL</div>
        </Grid>
        <Grid item xs={4} sm={4}>
            <TextField
                label=""
                name="tel"
                value={formData.tel || ''}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
                disabled
                InputProps={{
                    readOnly: true,
                }}
            />
        </Grid>
        <Grid item xs={2} sm={2} style={{ flexShrink: 0 }}>
            <div style={{ whiteSpace: 'normal' }}>
                <MultiLangText
                    language={language}
                    textJa="担当者部署・役職"
                    textEn="Title / Department of the Contact Person"
                />
            </div>
        </Grid>
        <Grid item xs={4} sm={4}>
            <TextField
                label=""
                name="departmentPosition"
                value={formData.departmentPosition || ''}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
                disabled
                InputProps={{
                    readOnly: true,
                }}
            />
        </Grid>
        <Grid item xs={2} sm={2} style={{ flexShrink: 0 }}>
            <div style={{ whiteSpace: 'normal' }}>
                <MultiLangText
                    language={language}
                    textJa="　担当者名"
                    textEn="Name of Contact Person"
                />
            </div>
        </Grid>
        <Grid item xs={4} sm={4}>
            <TextField
                label=""
                name="contactPerson"
                value={formData.contactPerson || ''}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
                disabled
                InputProps={{
                    readOnly: true,
                }}
            />
        </Grid>
        <Grid item xs={1} sm={1} style={{ flexShrink: 0 }}>
            <div style={{ whiteSpace: 'normal' }}>
                <MultiLangText
                    language={language}
                    textJa="住所"
                    textEn="Address"
                />
            </div>
        </Grid>
        <Grid item xs={1} sm={1} />
        <Grid item xs={3} sm={3}>
            <TextField
                label=""
                name="postalCode"
                value={formData.postalCode || ''}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
                disabled
                InputProps={{
                    readOnly: true,
                }}
            />
        </Grid>
        <Grid item xs={1} sm={1} />
        <Grid item xs={3} sm={3}>
            <TextField
                label=""
                name="prefecture"
                value={formData.prefecture || ''}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
                disabled
                InputProps={{
                    readOnly: true,
                }}
            />
        </Grid>
        <Grid item xs={3} sm={3} />
        <Grid item xs={1} sm={1} />
        <Grid item xs={1} sm={1} />
        <Grid item xs={10} sm={10}>
            <TextField
                label=""
                name="city"
                value={formData.city || ''}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
                disabled
                InputProps={{
                    readOnly: true,
                }}
            />
        </Grid>
        <Grid item xs={1} sm={1} />
        <Grid item xs={1} sm={1} />
        <Grid item xs={10} sm={10}>
            <TextField
                label=""
                name="address"
                value={formData.address || ''}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
                disabled
                InputProps={{
                    readOnly: true,
                }}
            />
        </Grid>
        <Grid item xs={2} sm={2} style={{ flexShrink: 0 }}>
            <div style={{ whiteSpace: 'normal' }}>
                <MultiLangText
                    language={language}
                    textJa="担当者メールアドレス"
                    textEn="E-mail"
                />
            </div>
        </Grid>
        <Grid item xs={10} sm={10}>
            <TextField
                label=""
                name="email"
                value={formData.email || ''}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
                disabled
                InputProps={{
                    readOnly: true,
                }}
            />
        </Grid>
        <Grid item xs={12} sm={12}>
            <MultiLangText
                language={language}
                textJa="※出展者情報の変更をご希望の場合、直接事務局までメールにてご連絡ください。　E-mail：bigsight-ja@tokyo-bigsight.co.jp"
                textEn="*If you would like to change the exhibitor information, please contact the JA2024 management office.　E-mail：bigsight-ja@tokyo-bigsight.co.jp"
            />
        </Grid>
    </Grid>
);

  
};

export default ExhibitorInfoForm;
