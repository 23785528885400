import React, { useState, useEffect, useRef } from 'react';
import Layout from '../templetes/Layout';
import { Button, TextField, Typography, Paper, Grid, FormControlLabel, Select, MenuItem, InputLabel, FormControl, RadioGroup, Radio, SelectChangeEvent } from '@mui/material';
import RequiredLabel from '../templetes/RequiredLabel';
import ExhibitorInfoForm from '../templetes/ExhibitorInfoForm';
import ContactInfo_suzuki from '../templetes/ContactInfo_suzuki';
import MultiLangText from '../templetes/MultiLangText';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';


interface FormData {
  exhibitorName: string;
  boothNumber: string;
  tel: string;
  departmentPosition: string;
  contactPerson: string;
  postalCode: string;
  prefecture: string;
  prefecture1: string;
  prefecture2: string;
  prefecture3: string;
  city: string;
  address: string;
  email: string;
  Company_ID: string;
  decoration3mm: string;
  decoration3mm_1: string;
  decoration3mm_2: string;
  singlePhase100V: string;
  singlePhase200V: string;
  threePhase200V: string;
  decorationCompanyName: string;
  decorationContactMobile: string;
  decorationContactTel: string;
  decorationContactPosition: string;
  decorationContactName: string;
  decorationAddressPostal: string;
  decorationAddressCity: string;
  decorationAddressDetails: string;
  decorationContactEmail: string;
  electricCompanyName: string;
  electricContactMobile: string;
  electricContactTel: string;
  electricContactPosition: string;
  electricContactName: string;
  electricAddressPostal: string;
  electricAddressCity: string;
  electricAddressDetails: string;
  electricContactEmail: string;
  billingCompanyName: string;
  billingContactMobile: string;
  billingContactTel: string;
  billingContactPosition: string;
  billingContactName: string;
  billingAddressPostal: string;
  billingAddressCity: string;
  billingAddressDetails: string;
  billingContactEmail: string;
  filePath: string;
  buttonState: { 
    reset: boolean;
    save: boolean;
    submit: boolean;
  };
}

const initialFormData: FormData = {
  exhibitorName: '',
  boothNumber: '',
  tel: '',
  departmentPosition: '',
  contactPerson: '',
  postalCode: '',
  prefecture: '',
  prefecture1: '',
  prefecture2: '',
  prefecture3: '',
  city: '',
  address: '',
  email: '',
  Company_ID: '',
  decoration3mm: 'なし',
  decoration3mm_1: 'なし',
  decoration3mm_2: 'なし',
  singlePhase100V: '',
  singlePhase200V: '',
  threePhase200V: '',
  decorationCompanyName: '',
  decorationContactMobile: '',
  decorationContactTel: '',
  decorationContactPosition: '',
  decorationContactName: '',
  decorationAddressPostal: '',
  decorationAddressCity: '',
  decorationAddressDetails: '',
  decorationContactEmail: '',
  electricCompanyName: '',
  electricContactMobile: '',
  electricContactTel: '',
  electricContactPosition: '',
  electricContactName: '',
  electricAddressPostal: '',
  electricAddressCity: '',
  electricAddressDetails: '',
  electricContactEmail: '',
  billingCompanyName: '',
  billingContactMobile: '',
  billingContactTel: '',
  billingContactPosition: '',
  billingContactName: '',
  billingAddressPostal: '',
  billingAddressCity: '',
  billingAddressDetails: '',
  billingContactEmail: '',
  filePath: '',
  buttonState: {
    reset: false,
    submit: false,
    save: false
  }
};

const Template005: React.FC = () => {
  const { language } = useAuth();
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const isJapanese = language === 'ja';

  const checkDuplicate = async () => {
    try {
      const response = await fetch('https://xs525443.xsrv.jp/admin_ui/check05.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          exhibitorName: formData.exhibitorName,
          Company_ID: formData.Company_ID
        }),
      });

      const result = await response.json();
      setIsDuplicate(result.isDuplicate);

      if (result.isDuplicate && result.additionalData) {
        const parsedJsonData = JSON.parse(result.additionalData);
        setFormData(prevState => ({
          ...prevState,
          ...parsedJsonData
        }));
        setIsReadOnly(true);
      } 
    } catch (error) {
      console.error('重複チェック中にエラーが発生しました:', error);
    }
  };

  const loadButtonState = async () => {
    try {
      const timestamp = new Date().getTime();
      const response = await axios.get(`/json/buttonState5.json?_=${timestamp}`);  // 外部JSONファイルのパス
      const buttonStateData = response.data;
      setFormData(prevState => ({
        ...prevState,
        buttonState: buttonStateData.buttonState
      }));
      setIsReadOnly(buttonStateData.buttonState.disabled);
    } catch (error) {
      console.error('ボタン状態の読み込み中にエラーが発生しました:', error);
    }
  };

  useEffect(() => {
    const savedData = localStorage.getItem('formData005');
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);

  useEffect(() => {
    if (formData.exhibitorName) {
      checkDuplicate();
    }
  }, [formData.exhibitorName]);

  const handleSave = () => {
    localStorage.setItem('formData005', JSON.stringify(formData));
    const alertMessage = language === 'ja' ? 'データを保存しました。' : 'Save Completed';
  alert(alertMessage);
  };

  const [fileName, setFileName] = useState<string>('');

  // ファイル選択ボタンと表示する部分
  const handleFileButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  
  const fileInputRef = useRef<HTMLInputElement>(null);
  
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      const timestamp = Math.floor(Date.now() / 1000);
      const newFile = new File([selectedFile], `${timestamp}_${selectedFile.name}`, { type: selectedFile.type });
      setFile(newFile);
      setFileName(selectedFile.name); // ファイル名を設定
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(formData);
  
    const formDataToSend = new FormData();
    formDataToSend.append('formData', JSON.stringify({
      exhibitorName: formData.exhibitorName,
      boothNumber: formData.boothNumber,
      tel: formData.tel,
      departmentPosition: formData.departmentPosition,
      contactPerson: formData.contactPerson,
      postalCode: formData.postalCode,
      prefecture: formData.prefecture,
      city: formData.city,
      address: formData.address,
      email: formData.email,
      Company_ID: formData.Company_ID,
      jsonData: {
        decoration3mm: formData.decoration3mm,
        decoration3mm_1: formData.decoration3mm_1,
        decoration3mm_2: formData.decoration3mm_2,
        singlePhase100V: formData.singlePhase100V,
        singlePhase200V: formData.singlePhase200V,
        threePhase200V: formData.threePhase200V,
        decorationCompanyName: formData.decorationCompanyName,
        decorationContactMobile: formData.decorationContactMobile,
        decorationContactTel: formData.decorationContactTel,
        decorationContactPosition: formData.decorationContactPosition,
        decorationContactName: formData.decorationContactName,
        decorationAddressPostal: formData.decorationAddressPostal,
        decorationAddressCity: formData.decorationAddressCity,
        decorationAddressDetails: formData.decorationAddressDetails,
        decorationContactEmail: formData.decorationContactEmail,
        decorationAddressPrefecture: formData.prefecture1,
        electricCompanyName: formData.electricCompanyName,
        electricContactMobile: formData.electricContactMobile,
        electricContactTel: formData.electricContactTel,
        electricContactPosition: formData.electricContactPosition,
        electricContactName: formData.electricContactName,
        electricAddressPostal: formData.electricAddressPostal,
        electricAddressCity: formData.electricAddressCity,
        electricAddressDetails: formData.electricAddressDetails,
        electricContactEmail: formData.electricContactEmail,
        electricAddressPrefecture: formData.prefecture2,
        billingCompanyName: formData.billingCompanyName,
        billingContactMobile: formData.billingContactMobile,
        billingContactTel: formData.billingContactTel,
        billingContactPosition: formData.billingContactPosition,
        billingContactName: formData.billingContactName,
        billingAddressPostal: formData.billingAddressPostal,
        billingAddressCity: formData.billingAddressCity,
        billingAddressDetails: formData.billingAddressDetails,
        billingContactEmail: formData.billingContactEmail,
        billingAddressPrefecture: formData.prefecture3,
      }
    }));
  
    if (file) {
      formDataToSend.append('file', file);
    }
  
    try {
      const response = await fetch('https://xs525443.xsrv.jp/admin_ui/submit005.php', {
        method: 'POST',
        body: formDataToSend,
      });
  
      const responseText = await response.text();
      try {
        const result = JSON.parse(responseText);
  
        const successMessage = language === 'ja' ? 'データが正常に送信されました。' : 'Submission Completed';
    const failureMessage = language === 'ja' ? 'データの送信に失敗しました: ' : 'Failed to submit data: ';
    const errorMessage = language === 'ja' ? 'データの送信に失敗しました。' : 'Failed to submit data.';

        if (result.success) {
          //alert(successMessage);
          setIsSubmitted(true);
          checkDuplicate();
        } else {
          alert('データの送信に失敗しました: ' + result.message);
        }
      } catch (jsonError) {
        console.error('レスポンスのJSONパースエラー:', jsonError);
        console.error('レスポンス内容:', responseText);
        alert('サーバーからのレスポンスが無効です。');
      }
    } catch (error) {
      console.error('データ送信エラー:', error);
      alert('データの送信中にエラーが発生しました。');
    }
  };

  
  
  const handleReset = async () => {
  const confirmMessage = language === 'ja' ? '本当に削除していいですか？' : 'Are you sure you want to reset?';
  const resetSuccessMessage = language === 'ja' ? 'フォームとファイルがリセットされました。' : 'Reset Completed';
  const deleteErrorMessage = language === 'ja' ? 'データの削除に失敗しました。' : 'Failed to delete data.';
    if (window.confirm(confirmMessage)) {
        try {
            const response = await fetch('https://xs525443.xsrv.jp/admin_ui/delete05.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    exhibitorName: formData.exhibitorName,
                    Company_ID: formData.Company_ID,
                    filePath: formData.filePath,
                }),
            });

            const responseText = await response.text();
            try {
                const result = JSON.parse(responseText);

                if (result.success) {
                    setFormData(prevState => ({
                        ...prevState,
                        decoration3mm: 'なし',
                        decoration3mm_1: 'なし',
                        decoration3mm_2: 'なし',
                        singlePhase100V: '',
                        singlePhase200V: '',
                        threePhase200V: '',
                        decorationCompanyName: '',
                        decorationContactMobile: '',
                        decorationContactTel: '',
                        decorationContactPosition: '',
                        decorationContactName: '',
                        decorationAddressPostal: '',
                        decorationAddressCity: '',
                        decorationAddressDetails: '',
                        decorationContactEmail: '',
                        electricCompanyName: '',
                        electricContactMobile: '',
                        electricContactTel: '',
                        electricContactPosition: '',
                        electricContactName: '',
                        electricAddressPostal: '',
                        electricAddressCity: '',
                        electricAddressDetails: '',
                        electricContactEmail: '',
                        billingCompanyName: '',
                        billingContactMobile: '',
                        billingContactTel: '',
                        billingContactPosition: '',
                        billingContactName: '',
                        billingAddressPostal: '',
                        billingAddressCity: '',
                        billingAddressDetails: '',
                        billingContactEmail: '',
                        filePath: ''
                    }));
                    setIsReadOnly(false);
                    localStorage.removeItem('formData005');
                    alert(resetSuccessMessage);
                } else {
                    alert(deleteErrorMessage + ': ' + result.message);
                }
            } catch (jsonError) {
                console.error('データ削除レスポンスのJSONパースエラー:', jsonError);
                console.error('データ削除レスポンス内容:', responseText);
                alert('サーバーからのデータ削除レスポンスが無効です。');
            }
        } catch (error) {
            console.error('データの削除中にエラーが発生しました:', error);
            alert(deleteErrorMessage);
        }
    }
};


  
  
  

  const handlePostalCodeChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const cleanedPostalCode = value.replace(/-/g, ''); 
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    if (cleanedPostalCode.length === 7) {
      const address = await fetchAddress(cleanedPostalCode);
      if (address) {
        if (name === 'decorationAddressPostal') {
          setFormData(prevState => ({
            ...prevState,
            decorationAddressCity: address.city,
            prefecture1: address.prefecture
          }));
        } else if (name === 'electricAddressPostal') {
          setFormData(prevState => ({
            ...prevState,
            electricAddressCity: address.city,
            prefecture2: address.prefecture
          }));
        } else if (name === 'billingAddressPostal') {
          setFormData(prevState => ({
            ...prevState,
            billingAddressCity: address.city,
            prefecture3: address.prefecture
          }));
        }
      }
    }
  };

  const fetchAddress = async (postalCode: string) => {
    try {
      const response = await fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postalCode}`);
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        const result = data.results[0];
        const prefecture = result.address1;
        const city = result.address2 + result.address3;
        return { prefecture, city };
      } else {
        alert('住所が見つかりませんでした。');
        return null;
      }
    } catch (error) {
      console.error('住所取得エラー:', error);
      alert('住所取得中にエラーが発生しました。');
      return null;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const prefectures = ["北海道", "青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県", "茨城県", "栃木県", "群馬県", "埼玉県", "千葉県", "東京都", "神奈川県", "新潟県", "富山県", "石川県", "福井県", "山梨県", "長野県", "岐阜県", "静岡県", "愛知県", "三重県", "滋賀県", "京都府", "大阪府", "兵庫県", "奈良県", "和歌山県", "鳥取県", "島根県", "岡山県", "広島県", "山口県", "徳島県", "香川県", "愛媛県", "高知県", "福岡県", "佐賀県", "長崎県", "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県"];

  useEffect(() => {
    loadButtonState(); // コンポーネントのマウント時にボタン状態を読み込む
  }, []);


  return (
    <Layout title={<MultiLangText
      language={language}
      textJa="申請書類管理　５．電気供給申込み　※全出展者提出"
      textEn="Application Forms 5.Application for Electricity Supply　*All Exhibitors(Required)"
      />}>
      <Paper style={{ padding: 16 }}>
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="■出展者情報"
                textEn="■Exhibitor information"
                />
        </Typography>
        <hr/>
        <form onSubmit={handleSubmit}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault(); // Enterキーによるフォーム送信を防ぐ
             // アラートを表示
          }
        }}>
          <ExhibitorInfoForm
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
          />

          {/* セクションスタート */}
          <Typography gutterBottom mt={5} style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="■電気供給について（下記の通り、電気供給を申込みます）"
                textEn="Electricity Supply　We apply Electric power supply as below:"
                />
                <MultiLangText
                language={language}
                textJa="※パッケージブースを申込んだ方は、プランに含まれない超過分のみお申込みください。"
                textEn="*Please apply extra power except the power supply included in the Packaged Booth if you are the Packaged Booth exhibitor."
                />
          </Typography>
          <hr/>
          <Grid container spacing={3} alignItems="center">

          {/* カラム */}
          <Grid item xs={3} sm={3} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="①供給の有無　"
                textEn="①Electricity Supply"
                />　<RequiredLabel/>
          </Grid>

          <Grid item xs={9} sm={9}>
          <RadioGroup
              row
              aria-label="decoration3mm"
              name="decoration3mm"
              value={formData.decoration3mm}
              onChange={handleChange}
            >
          <Grid item xs={3} sm={3}>
          <FormControlLabel value="あり" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="あり"
                textEn="Necessary (We apply)"
                />} disabled={isReadOnly}/>
          </Grid>
          <Grid item xs={1} sm={1}></Grid>
          <Grid item xs={8} sm={8}>
          <FormControlLabel value="なし" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="なし"
                textEn="Unnecessary (We do not need electric power)"
                />} disabled={isReadOnly}/>
          </Grid>
          </RadioGroup>
          </Grid>

          {/* カラム */}
          <Grid item xs={3} sm={3} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="②申込み電気容量　"
                textEn="②Selecting electric capacity"
                />
          　<RequiredLabel/>
          </Grid>
          <Grid item xs={9} sm={9}>
            <Grid container spacing={3} alignItems="center">
            <Grid item xs={4} sm={4} style={{ display: 'flex', alignItems: 'center' }}>
            <MultiLangText
                language={language}
                textJa="単相100V　"
                textEn="Single Phase 100V"
                />　
            <TextField
              label=""
              name="singlePhase100V"
              value={formData.singlePhase100V}
              onChange={handleChange}
              sx={{ width: 70 }}
              margin="normal"
              disabled={isReadOnly}
            />　kW　
            </Grid>
            <Grid item xs={4} sm={4} style={{ display: 'flex', alignItems: 'center' }}>
            <MultiLangText
                language={language}
                textJa="単相200V "
                textEn="Single Phase 200V"
                />　
            <TextField
              label=""
              name="singlePhase200V"
              value={formData.singlePhase200V}
              onChange={handleChange}
              sx={{ width: 70 }}
              margin="normal"
              disabled={isReadOnly}
            />　kW　
            </Grid>
            <Grid item xs={4} sm={4} style={{ display: 'flex', alignItems: 'center' }}>
            <MultiLangText
                language={language}
                textJa="三相200V "
                textEn="Three Phase 200V"
                />　
            <TextField
              label=""
              name="threePhase200V"
              value={formData.threePhase200V}
              onChange={handleChange}
              sx={{ width: 70 }}
              margin="normal"
              disabled={isReadOnly}
            />　kW　
            </Grid>
            </Grid>
          </Grid>


          {/* カラム */}
          <Grid item xs={3} sm={3} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="③パッケージブース申込み"
                textEn="③Apply for the Packaged Booth"
                />　<RequiredLabel/>
          </Grid>

          <Grid item xs={9} sm={9}>
          <RadioGroup
              row
              aria-label="decoration3mm_1"
              name="decoration3mm_1"
              value={formData.decoration3mm_1}
              onChange={handleChange}
            >
          <Grid item xs={3} sm={3}>
          <FormControlLabel value="あり" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="あり"
                textEn="Yes"
                />} disabled={isReadOnly}/>
          </Grid>
          <Grid item xs={1} sm={1}></Grid>
          <Grid item xs={3} sm={3}>
          <FormControlLabel value="なし" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="なし"
                textEn="No"
                />} disabled={isReadOnly}/>
          </Grid>
          <Grid item xs={3} sm={3}></Grid>
          </RadioGroup>
          </Grid>

          {/* カラム */}
          <Grid item xs={3} sm={3} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="④夜間送電の有無"
                textEn="④Night time power transmission"
                />　<RequiredLabel/>
          </Grid>

          <Grid item xs={9} sm={9}>
          <RadioGroup
              row
              aria-label="decoration3mm_2"
              name="decoration3mm_2"
              value={formData.decoration3mm_2}
              onChange={handleChange}
            >
          <Grid item xs={3} sm={3}>
          <FormControlLabel value="あり" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="あり"
                textEn="Yes (We need electric power for night time)"
                />} disabled={isReadOnly}/>
          </Grid>
          <Grid item xs={1} sm={1}></Grid>
          <Grid item xs={8} sm={8}>
          <FormControlLabel value="なし" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="なし"
                textEn="No (We do not need electric power for night time)"
                />} disabled={isReadOnly}/>
          </Grid>
          </RadioGroup>
          </Grid>


          </Grid>

          
          {/* セクションここまで */}
        <br />

          {/* セクションスタート */}
          <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="■小間内装飾・工事会社名"
                textEn="■Decoration Contractor's Information"
                />
          </Typography>
          <hr/>
          <Grid container spacing={2} alignItems="center">

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="業者名"
                textEn="Company Name "
                />　
          </Grid>
          <Grid item xs={9} sm={9}>
          <TextField
            name="decorationCompanyName"
            value={formData.decorationCompanyName}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={isReadOnly}
          />
          </Grid>

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="担当者携帯電話番号　"
                textEn="Mobile Phone Number"
                />　
          </Grid>
          <Grid item xs={4} sm={4}>
          <TextField
            name="decorationContactMobile"
            value={formData.decorationContactMobile}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={isReadOnly}
          />
          </Grid>
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          TEL　
          </Grid>
          <Grid item xs={4} sm={4}>
          <TextField
            name="decorationContactTel"
            value={formData.decorationContactTel}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={isReadOnly}
          />
          </Grid>

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="担当者部署・役職"
                textEn="Title / Department of the Contact Person"
                />
          </Grid>
          <Grid item xs={4} sm={4}>
          <TextField
            
            name="decorationContactPosition"
            value={formData.decorationContactPosition}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={isReadOnly}
          />
          </Grid>
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="担当者名　"
                textEn="Name of Contact Person"
                />
          </Grid>
          <Grid item xs={4} sm={4}>
          <TextField
            name="decorationContactName"
            value={formData.decorationContactName}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={isReadOnly}
          />
          </Grid>

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="住所　"
                textEn="Address"
                />
          </Grid>
          <Grid item xs={9} sm={9}>

            <Grid container>
            <Grid item xs={5} sm={5}>
            <TextField
              label={<MultiLangText
                language={language}
                textJa="〒"
                textEn="Postal Code"
                />}
              name="decorationAddressPostal"
              value={formData.decorationAddressPostal}
              onChange={handlePostalCodeChange}
              fullWidth
              disabled={isReadOnly}
            />
            </Grid>

            <Grid item xs={0} sm={0.1}></Grid>

            {
              isJapanese && (
                <Grid item xs={5} sm={5}>
                <FormControl fullWidth>
                  <InputLabel id="prefecture1-label">都道府県</InputLabel>
                  <Select
                    labelId="prefecture1-label"
                    id="prefecture1"
                    name="prefecture1"
                    value={formData.prefecture1}
                    label="都道府県"
                    onChange={handleChange}
                    disabled={isReadOnly}
                  >
                    {prefectures.map((prefecture, index) => (
                      <MenuItem key={index} value={prefecture}>{prefecture}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                </Grid>
              )
            }
            {
              !isJapanese && (
                <Grid item xs={5} sm={5}>
                </Grid>
              )
            }


            </Grid>
          </Grid>

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}></Grid>
          <Grid item xs={9} sm={9}>
          <TextField
            label={<MultiLangText
                language={language}
                textJa="市区町村名（例：大阪市北区）"
                textEn="city"
                />}

            name="decorationAddressCity"
            value={formData.decorationAddressCity}
            onChange={handleChange}
            fullWidth
            disabled={isReadOnly}
          />
          </Grid>

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}></Grid>
          <Grid item xs={9} sm={9}>
          <TextField
            label={<MultiLangText
                language={language}
                textJa="番地・ビル名（例：〇〇１丁目2-3）"
                textEn="Address and Building Name"
                />}
            name="decorationAddressDetails"
            value={formData.decorationAddressDetails}
            onChange={handleChange}
            fullWidth
            disabled={isReadOnly}
          />
          </Grid>

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="メールアドレス"
                textEn="E-mail"
                />　
          </Grid>
          <Grid item xs={9} sm={9}>
          <TextField
            label="XXXXX@xxxxxx.com"
            name="decorationContactEmail"
            value={formData.decorationContactEmail}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={isReadOnly}
          />
          </Grid>

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}></Grid>
          <Grid item xs={9} sm={9}>
          <MultiLangText
                language={language}
                textJa="※こちらの入力は必須ではありません。　※事務局施工会社以外へご依頼の場合は、必ずご記入ください。"
                textEn="*If you assign non-official contractor for your booth decoration, please fill in above."
                />
          </Grid>

          </Grid>
          {/* セクションここまで */}
        <br /><br />

          {/* セクションスタート */}
          <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="■ 小間内電気工事会社名"
                textEn="■ Electrical work contractors"
                />
          </Typography>
          <hr/>
          <Grid container spacing={2} alignItems="center">

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="業者名"
                textEn="Company Name "
                />　<RequiredLabel/>
          </Grid>
          <Grid item xs={9} sm={9}>
          <TextField
            name="electricCompanyName"
            value={formData.electricCompanyName}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={isReadOnly}
          />
          </Grid>

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="担当者携帯電話番号　"
                textEn="Mobile Phone Number"
                />　<RequiredLabel/>
          </Grid>
          <Grid item xs={4} sm={4}>
          <TextField
            
            name="electricContactMobile"
            value={formData.electricContactMobile}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={isReadOnly}
          />
          </Grid>
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          TEL　<RequiredLabel/>
          </Grid>
          <Grid item xs={4} sm={4}>
          <TextField
            
            name="electricContactTel"
            value={formData.electricContactTel}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={isReadOnly}
          />
          </Grid>

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="担当者部署・役職"
                textEn="Title / Department of the Contact Person"
                />
          </Grid>
          <Grid item xs={4} sm={4}>
          <TextField
            
            name="electricContactPosition"
            value={formData.electricContactPosition}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={isReadOnly}
          />
          </Grid>
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="担当者名　"
                textEn="Name of Contact Person"
                />　<RequiredLabel/>
          </Grid>
          <Grid item xs={4} sm={4}>
          <TextField
            
            name="electricContactName"
            value={formData.electricContactName}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={isReadOnly}
          />
          </Grid>

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="住所　"
                textEn="Address"
                />　<RequiredLabel/>
          </Grid>
          <Grid item xs={9} sm={9}>

            <Grid container>
            <Grid item xs={5} sm={5}>
            <TextField
              label={<MultiLangText
                language={language}
                textJa="〒"
                textEn="Postal Code"
                />}
              name="electricAddressPostal"
              value={formData.electricAddressPostal}
              onChange={handlePostalCodeChange}
              fullWidth
              disabled={isReadOnly}
            />
            </Grid>

            <Grid item xs={0} sm={0.1}></Grid>

            {
              isJapanese && (
                <Grid item xs={5} sm={5}>
                <FormControl fullWidth>
                  <InputLabel id="prefecture2-label">都道府県</InputLabel>
                  <Select
                    labelId="prefecture2-label"
                    id="prefecture2"
                    name="prefecture2"
                    value={formData.prefecture2}
                    label="都道府県"
                    onChange={handleChange}
                    disabled={isReadOnly}
                  >
                    {prefectures.map((prefecture, index) => (
                      <MenuItem key={index} value={prefecture}>{prefecture}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                </Grid>
              )
            }
            {
              !isJapanese && (
                <Grid item xs={5} sm={5}>
                </Grid>
              )
            }
            
            </Grid>
          </Grid>

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}></Grid>
          <Grid item xs={9} sm={9}>
          <TextField
            label={<MultiLangText
                language={language}
                textJa="市区町村名（例：大阪市北区）"
                textEn="city"
                />}

            name="electricAddressCity"
            value={formData.electricAddressCity}
            onChange={handleChange}
            fullWidth
            disabled={isReadOnly}
          />
          </Grid>

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}></Grid>
          <Grid item xs={9} sm={9}>
          <TextField
            label={<MultiLangText
                language={language}
                textJa="番地・ビル名（例：〇〇１丁目2-3）"
                textEn="Address and Building Name"
                />}
            name="electricAddressDetails"
            value={formData.electricAddressDetails}
            onChange={handleChange}
            fullWidth
            disabled={isReadOnly}
          />
          </Grid>

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="メールアドレス"
                textEn="E-mail"
                />　<RequiredLabel/>
          </Grid>
          <Grid item xs={9} sm={9}>
          <TextField
            label="XXXXX@xxxxxx.com"
            name="electricContactEmail"
            value={formData.electricContactEmail}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={isReadOnly}
          />
          </Grid>

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}></Grid>
          <Grid item xs={9} sm={9}>
          <MultiLangText
                language={language}
                textJa="※事務局電気施工会社以外に依頼される場合は、必ずご記入ください。"
                textEn="*If you assign non-official contractor for your electrical work within booth, you are required to fill in above."
                />
          </Grid>

          </Grid>
          {/* セクションここまで */}
        <br /><br />

          {/* セクションスタート */}
          <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="■電気工事設計図"
                textEn="Electrical work design drawing"
                />
          </Typography>
          <hr/>
          <Grid container spacing={3} alignItems="center">
          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="アップロードするファイルを選択"
                textEn="Please upload the file. "
                />
          </Grid>
          <Grid item xs={3} sm={3}>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: 'none' }}
            accept="image/*,application/pdf,application/zip"
            disabled={isReadOnly}
          />
          <Button variant="contained" onClick={handleFileButtonClick} disabled={isReadOnly}>
            <MultiLangText
              language={language}
              textJa="ファイルを選択"
              textEn="Select a file"
            />
          </Button>
          {fileName && <Typography variant="body2">{fileName}</Typography>}
          </Grid>
          <Grid item xs={5} sm={5}>
          <MultiLangText
                language={language}
                textJa="
                アップロードが可能なサイズ容量は10MBまでとなります。<br/>
              複数のデータがある場合は１つに統合してください。<br/>
              (zip、PDF、jpg、png)
                "
                textEn="
                The maximum upload size is 10MB.<br/>
                If you have multiple files, please combine them into one.<br/>
                (zip、PDF、jpg、png)
                "
                />
          </Grid>
          <Grid item xs={12} sm={12}>
          <MultiLangText
                language={language}
                textJa="主幹開閉器ブレーカーの位置と、小間の向きを必ず記載ください。　記載が無い場合は、事務局で任意に設置していきます。"
                textEn="It is strictly requested to show the location of the main breaker and booth orientation, otherwise the management office decides the main breaker location."
                />
          </Grid>

          </Grid>
          {/* セクションここまで */}
        <br /><br />

          {/* セクションスタート */}
          <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="■料金請求先　（出展者と異なる場合）"
                textEn="■Invoice Recipient (Please enter the following if invoice recipient is different from the exhibitor.)"
                />
          </Typography>
          <hr/>
          <Grid container spacing={2} alignItems="center">

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="業者名"
                textEn="Company Name"
                />　<RequiredLabel/>
          </Grid>
          <Grid item xs={9} sm={9}>
          <TextField
            name="billingCompanyName"
            value={formData.billingCompanyName}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={isReadOnly}
          />
          </Grid>

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="担当者携帯電話番号"
                textEn="Mobile Phone Number"
                />　<RequiredLabel/>
          </Grid>
          <Grid item xs={4} sm={4}>
          <TextField
            name="billingContactMobile"
            value={formData.billingContactMobile}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={isReadOnly}
          />
          </Grid>
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="TEL"
                textEn="TEL"
                />　<RequiredLabel/>
          </Grid>
          <Grid item xs={4} sm={4}>
          <TextField
            name="billingContactTel"
            value={formData.billingContactTel}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={isReadOnly}
          />
          </Grid>

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="担当者部署・役職"
                textEn="Title / Department of the Contact Person"
                />
          </Grid>
          <Grid item xs={4} sm={4}>
          <TextField
            name="billingContactPosition"
            value={formData.billingContactPosition}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={isReadOnly}
          />
          </Grid>
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="担当者名"
                textEn="Name of Contact Person"
                />　<RequiredLabel/>
          </Grid>
          <Grid item xs={4} sm={4}>
          <TextField
            name="billingContactName"
            value={formData.billingContactName}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={isReadOnly}
          />
          </Grid>

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="住所"
                textEn="Address"
                />　<RequiredLabel/>
          </Grid>
          <Grid item xs={9} sm={9}>

            <Grid container>
            <Grid item xs={5} sm={5}>
            <TextField
              label={<MultiLangText
                language={language}
                textJa="〒"
                textEn="Postal Code"
                />}
              name="billingAddressPostal"
              value={formData.billingAddressPostal}
              onChange={handlePostalCodeChange}
              fullWidth
              disabled={isReadOnly}
            />
            </Grid>

            <Grid item xs={0} sm={0.1}></Grid>
            
            {
              isJapanese && (
                <Grid item xs={5} sm={5}>
                <FormControl fullWidth>
                  <InputLabel id="prefecture3-label">都道府県</InputLabel>
                  <Select
                    labelId="prefecture3-label"
                    id="prefecture3"
                    name="prefecture3"
                    value={formData.prefecture3}
                    label="都道府県"
                    onChange={handleChange}
                    disabled={isReadOnly}
                  >
                    {prefectures.map((prefecture, index) => (
                      <MenuItem key={index} value={prefecture}>{prefecture}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                </Grid>
              )
            }
            {
              !isJapanese && (
                <Grid item xs={5} sm={5}>
                </Grid>
              )
            }

            </Grid>
          </Grid>

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}></Grid>
          <Grid item xs={9} sm={9}>
          <TextField
            label={<MultiLangText
                language={language}
                textJa="市区町村名（例：大阪市北区）"
                textEn="city"
                />}

            name="billingAddressCity"
            value={formData.billingAddressCity}
            onChange={handleChange}
            fullWidth
            disabled={isReadOnly}
          />
          </Grid>

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}></Grid>
          <Grid item xs={9} sm={9}>
          <TextField
            label={<MultiLangText
                language={language}
                textJa="番地・ビル名（例：〇〇１丁目2-3）"
                textEn="Address and Building Name"
                />}
            name="billingAddressDetails"
            value={formData.billingAddressDetails}
            onChange={handleChange}
            fullWidth
            disabled={isReadOnly}
          />
          </Grid>

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="メールアドレス"
                textEn="E-mail"
                />　<RequiredLabel/>
          </Grid>
          <Grid item xs={9} sm={9}>
          <TextField
            label="XXXXX@xxxxxx.com"
            name="billingContactEmail"
            value={formData.billingContactEmail}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={isReadOnly}
          />
          </Grid>

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}></Grid>
          <Grid item xs={9} sm={9}>
          <MultiLangText
                language={language}
                textJa="※幹線工事費の請求先が出展者と異なる場合に記入してください。　※会期後、事務局より請求となります。"
                textEn="*Please fill in above if the billing information is different from the exhibitor."
                />
          </Grid>

          <ContactInfo_suzuki />
          {/* カラム */}
          <Grid item xs={12} sm={12} style={{ display: 'flex'}}>
              <MultiLangText
                language={language}
                textJa="
※下記、「確定」ボタンを押して、申請してください。「保存」中は修正が可能です。<br/>
              ※「リセット」ボタンがある申請書については、万一、確定後に修正が必要な場合も、内容の修正が可能です。
"
                textEn='
*Please click the "SUBMIT" button to submit your application. You can "SAVE” the form while you still need to modify it.<br/>
*Some forms have “RESET” button, which you can use when you need to reset and fill in the form again after submitting the form.
'
              />
            </Grid>
          </Grid>

          {/* セクションここまで */}
          <Grid container mt={5} spacing={1} justifyContent="center">
            <Grid item>
              <Button
                onClick={handleReset}
                variant="contained"
                color="secondary"
                disabled={formData.buttonState.reset}  // JSONで制御
                sx={{ filter: formData.buttonState.reset ? 'grayscale(1)' : 'none' }}  // グレーアウト
              >
                <MultiLangText
                  language={language}
                  textJa="リセット"
                  textEn="Reset"
                />
              </Button>
            </Grid>

            <Grid item>
              <Button
                onClick={handleSave}
                variant="contained"
                color="primary"
                disabled={formData.buttonState.save}  // JSONで制御
                sx={{ filter: formData.buttonState.save ? 'grayscale(1)' : 'none' }}  // グレーアウト
              >
                <MultiLangText
                  language={language}
                  textJa="保存"
                  textEn="Save"
                />
              </Button>
            </Grid>

            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={formData.buttonState.submit || isDuplicate || isSubmitted}  // JSONで制御 + 重複や送信済みの場合
                sx={{ filter: (formData.buttonState.submit || isDuplicate || isSubmitted) ? 'grayscale(1)' : 'none' }}  // グレーアウト
              >
                <MultiLangText
                  language={language}
                  textJa="確定"
                  textEn="Submit"
                />
              </Button>
            </Grid>
          </Grid>


        </form>
      </Paper>
    </Layout>
  );
};

export default Template005;
