import React from 'react';
import { Grid, Typography, Paper, Container } from '@mui/material';
import MultiLangText from '../templetes/MultiLangText';
import { useAuth } from '../../context/AuthContext';

const ContactInfo: React.FC = () => {
  const { language } = useAuth();

  return (
    <Container>
        <Typography gutterBottom mt={5} style={{ fontWeight: 'bold' }}>
        <MultiLangText
                language={language}
                textJa="■ お問い合わせ先について　※備考・その他は、下記まで直接ご連絡ください。"
                textEn="■Contact Information　If you have any questions or concerns, please inquire with the following contact."
                />
          </Typography>
          <hr/>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
            <MultiLangText
                language={language}
                textJa="（株）ビッグサイトサービス 計画搬入出デスク<br/>
                〒135-0061 東京都江東区有明 3-11-1<br/>
                TEL：03-5530-1290<br/>
                 E-mail：bssjakeikaku@bigsight-services.com<br/>
                 担当：池上・日向・石井
                "
                textEn="
                Big Sight Services Corporation. Move-in / Move-out Desk<br/>
                3-11-1, Ariake, Koto-ku, Tokyo 135-0063<br/>
                TEL:+81-3-5530-1290<br/>
                E-mail:bssjakeikaku@bigsight-services.com<br/>
                Ikegami, Hinata, Ishii<br/>
                "
                />
            </Typography>
          </Grid>
        </Grid>
    </Container>
  );
};

export default ContactInfo;
