import React, { useState, useEffect } from 'react';
import Layout from '../templetes/Layout';
import { TextField, Button, Typography, Paper, Grid, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import UserInfo from '../templetes/UserInfo';
import MultiLangText from '../templetes/MultiLangText';
import { useAuth } from '../../context/AuthContext';
import ContactInfo3 from '../templetes/ContactInfo3';

const UserPage: React.FC = () => {
  const { language } = useAuth();
  const isJapanese = language === 'ja';
  
  const [formData, setFormData] = useState({
    exhibitorName: '',
    boothNumber: '',
    tel: '',
    departmentPosition: '',
    contactPerson: '',
    postalCode: '',
    prefecture: '',
    city: '',
    address: '',
    email: '',
  });




  return (
    <Layout title={<MultiLangText
      language={language}
      textJa="出展者情報"
      textEn=''
    />}>
      <Paper style={{ padding: 16 }}>
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="■出展者情報"
                textEn="■Exhibitor information"
                />
        </Typography>
        <hr/>

        <UserInfo
          formData={formData}
          setFormData={setFormData}
        />


      </Paper>
    </Layout>
  );
};

export default UserPage;
