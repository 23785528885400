import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../templetes/Layout';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from '@mui/material';
import MultiLangText from '../templetes/MultiLangText';
import { useAuth } from '../../context/AuthContext';

interface HomeProps {
  onLogout: () => void;
}

const Home: React.FC<HomeProps> = ({ onLogout }) => {
  const navigate = useNavigate();
  const { language, user } = useAuth();
  const [statuses, setStatuses] = useState<{ [key: string]: string }>({});
  const [hiddenTemplates, setHiddenTemplates] = useState<string[]>([]);


  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch('/json/config.json');
        const config = await response.json();
        setHiddenTemplates(config.hiddenTemplates || []);
      } catch (error) {
        console.error('Error fetching config:', error);
      }
    };
  
    fetchConfig();
  }, []);

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const newStatuses: { [key: string]: string } = {};
        for (const row of rows) {
          if (row.tableName) { // tableName が存在する場合のみステータスをチェック
            const response = await fetch(`https://xs525443.xsrv.jp/admin_ui/check_status.php`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ tableName: row.tableName, Company_ID: user?.Company_ID }),
            });
            const result = await response.json();
            if (!result.success) {
              console.error(`Error checking status for ${row.tableName}:`, result.message);
            } else {
              newStatuses[row.templateNumber] = result.status ? 'true' : 'false';
            }
          } else {
            newStatuses[row.templateNumber] = row.status === '未入力' ? 'false' : 'true';
          }
        }
        setStatuses(newStatuses);
      } catch (error) {
        console.error('ステータス取得中にエラーが発生しました:', error);
      }
    };

    if (user?.Company_ID) {
      fetchStatuses();
    }
  }, [user?.Company_ID]);

  const handleOpen = (templateNumber: string) => {
    const path = `/template/${templateNumber}`;
    navigate(path);
  };

  const rows = [
    {
      eventNumber: '001',
      templateNumber: '001',
      templateNameJa: '1．装飾施工者 登録　※全出展者提出',
      templateNameEn: '1.Decoration Contractor Registration　※All Exhibitors(Required)',
      dayJa: '9/6(金)',
      dayEn: 'Sep 6 (Fri)',
      status: 'ー', // 未入力または確定
      tableName: 'table_001'
    },
    {
      eventNumber: '001',
      templateNumber: '002',
      templateNameJa: '2．装飾・出展物等 確認（小間図面の提出）　※全出展者提出',
      templateNameEn: '2.Decoration and Exhibits Confirmation(Submission of booth drawing)　※All Exhibitors(Required)',
      dayJa: '9/6(金)',
      dayEn: 'Sep 6 (Fri)',
      status: 'ー', // 未入力または確定
      tableName: 'table_002'
    },
    {
      eventNumber: '001',
      templateNumber: '003',
      templateNameJa: '3．火気・危険物等に関する申請　※全出展者提出',
      templateNameEn: '3.Fire and Hazardous Materials Application　※All Exhibitors(Required)',
      dayJa: '9/6(金)',
      dayEn: 'Sep 6 (Fri)',
      status: 'ー', // 未入力または確定
      tableName: 'table_003'
    },
    {
      eventNumber: '001',
      templateNumber: '004',
      templateNameJa: '4．搬入・搬出計画届　※全出展者提出',
      templateNameEn: '4.Submission of the Exhibitor\'s Move-In/Out Plan　※All Exhibitors(Required)',
      dayJa: '9/6(金)',
      dayEn: 'Sep 6 (Fri)',
      status: 'ー', // 未入力または確定
      tableName: 'table_004'
    },
    {
      eventNumber: '001',
      templateNumber: '005',
      templateNameJa: '5．電気供給 申込み　※全出展者提出',
      templateNameEn: '5.Application for Electricity Supply　※All Exhibitors(Required)',
      dayJa: '9/6(金)',
      dayEn: 'Sep 6 (Fri)',
      status: 'ー', // 未入力または確定
      tableName: 'table_005'
    },
    {
      eventNumber: '001',
      templateNumber: '006',
      templateNameJa: '6．出展者プレゼンテーション利用申込み',
      templateNameEn: '6.Application for Exhibitor\'s Presentation',
      dayJa: '別途ご案内済み',
      dayEn: 'To be announced separately',
      status: '', // 未入力または確定
      tableName: ''
    },
    {
      eventNumber: '001',
      templateNumber: '007',
      templateNameJa: '7．公式Web サイトバナー広告・会場案内広告・来場者バッジストラップ広告掲載申込み',
      templateNameEn: '7.Submission of the Advertisement on Website Banner, Guide map, Visitor Badge Holder',
      dayJa: '8/2(金)',
      dayEn: 'Aug 2 (Fri)',
      status: 'ー', // 未入力または確定
      tableName: 'table_007'
    },
    {
      eventNumber: '001',
      templateNumber: '008',
      templateNameJa: '8．共同出展者登録 申込み',
      templateNameEn: '8.Co-Exhibitor(s) Registration',
      dayJa: '別途ご案内済み',
      dayEn: 'To be announced separately',
      status: '', // 未入力または確定
      tableName: ''
    },
    {
      eventNumber: '001',
      templateNumber: '009',
      templateNameJa: '9．アトリウムステージ広告 申込み',
      templateNameEn: '9.Submission of the Advertisement at Atrium Stage',
      dayJa: '別途ご案内済み',
      dayEn: 'To be announced separately',
      status: '', // 未入力または確定
      tableName: ''
    },
    {
      eventNumber: '001',
      templateNumber: '010',
      templateNameJa: '10．保税展示物 申請 申込み',
      templateNameEn: '10.Application for Bonded Display Items',
      dayJa: '8/7(水)',
      dayEn: 'Aug 7 (Wed)',
      status: 'ー', // 未入力または確定
      tableName: 'table_010'
    },
    {
      eventNumber: '001',
      templateNumber: '011',
      templateNameJa: '11．会場内広告・小間上空バナー広告 申込み',
      templateNameEn: '11.Application for Onsite Advertisement and Hanging Banner Advertisement ',
      dayJa: '8/30(金)',
      dayEn: 'Aug 30 (Fri)',
      status: 'ー', // 未入力または確定
      tableName: 'table_011'
    },
    {
      eventNumber: '001',
      templateNumber: '012',
      templateNameJa: '12．床面工事 申請',
      templateNameEn: '12.Application for Floor Work (Anchoring)',
      dayJa: '9/6(金)',
      dayEn: 'Sep 6 (Fri)',
      status: 'ー', // 未入力または確定
      tableName: 'table_012'
    },
    {
      eventNumber: '001',
      templateNumber: '013',
      templateNameJa: '13．小間内電気工事（二次側配線工事）申込み',
      templateNameEn: '13.Application for Electrical Work within Booth (Secondary Wiring) ',
      dayJa: '9/6(金)',
      dayEn: 'Sep 6 (Fri)',
      status: 'ー', // 未入力または確定
      tableName: 'table_013'
    },
    {
      eventNumber: '001',
      templateNumber: '014',
      templateNameJa: '14．給排水 エア（圧縮空気）工事 申込み',
      templateNameEn: '14.Application for Water Supply and Drainage,  Compressed Air Supply',
      dayJa: '9/6(金)',
      dayEn: 'Sep 6 (Fri)',
      status: 'ー', // 未入力または確定
      tableName: 'table_014'
    },
    {
      eventNumber: '001',
      templateNumber: '015',
      templateNameJa: '15．通信回線 申込み',
      templateNameEn: '15.Application for Communication Equipment',
      dayJa: '9/6(金)',
      dayEn: 'Sep 6 (Fri)',
      status: 'ー', // 未入力または確定
      tableName: 'table_015'
    },
    {
      eventNumber: '001',
      templateNumber: '016',
      templateNameJa: '16．出展者証・施工者証追加 申込み',
      templateNameEn: '16.Application for the Exhibitor Pass and Contractor Pass',
      dayJa: '9/6(金)',
      dayEn: 'Sep 6 (Fri)',
      status: 'ー', // 未入力または確定
      tableName: 'table_016'
    },
    {
      eventNumber: '001',
      templateNumber: '017',
      templateNameJa: '17．来場案内リーフレット・パブリックデー招待券追加 申込み',
      templateNameEn: '17.Application for the Additional Leaflets and Invitation Ticket for Public Day',
      dayJa: '9/13(金)',
      dayEn: 'Sep 13 (Fri)',
      status: 'ー', // 未入力または確定
      tableName: 'table_017'
    },
    {
      eventNumber: '001',
      templateNumber: '018',
      templateNameJa: '18．パッケージブース 申込み',
      templateNameEn: '18.Application for the Packaged Booth',
      dayJa: '9/6(金)',
      dayEn: 'Sep 6 (Fri)',
      status: 'ー', // 未入力または確定
      tableName: 'table_018'
    },
    {
      eventNumber: '001',
      templateNumber: '019',
      templateNameJa: '19．レンタル備品 申込み',
      templateNameEn: '19.Application for Rental Equipment',
      dayJa: '9/6(金)',
      dayEn: 'Sep 6 (Fri)',
      status: 'ー', // 未入力または確定
      tableName: 'table_019'
    },
    {
      eventNumber: '001',
      templateNumber: '020',
      templateNameJa: '20．ストックルーム（出展者用倉庫）設置 申込み',
      templateNameEn: '20.Application for Stockroom',
      dayJa: '9/12(木)',
      dayEn: 'Sep 12 (Thu)',
      status: 'ー', // 未入力または確定
      tableName: 'table_020'
    },
    {
      eventNumber: '001',
      templateNumber: '021',
      templateNameJa: '21．食品取扱届 申込み',
      templateNameEn: '21.Application for In-Stand Food/Beverage Handling',
      dayJa: '9/13(金)',
      dayEn: 'Sep 13 (Fri)',
      status: 'ー', // 未入力または確定
      tableName: 'table_021'
    },
    {
      eventNumber: '001',
      templateNumber: '022',
      templateNameJa: '22．宿泊予約 申込み',
      templateNameEn: '22.Accommodation',
      dayJa: '9/10(火)',
      dayEn: 'Sep 10 (Tue)',
      status: 'ー', // 未入力または確定
      tableName: 'table_022'
    },
    {
      eventNumber: '001',
      templateNumber: '023',
      templateNameJa: '23．ビッグサイトカード 申込み',
      templateNameEn: '23.Application for the "Big Sight Card"',
      dayJa: '9/18(水)',
      dayEn: 'Sep 18 (Wed)',
      status: 'ー', // 未入力または確定
      tableName: 'table_023'
    },
    {
      eventNumber: '001',
      templateNumber: '024',
      templateNameJa: '24．小間内清掃 申込み',
      templateNameEn: '24.Application for Cleaning in the booth',
      dayJa: '10/11(金)',
      dayEn: 'Oct 11 (Fri)',
      status: 'ー', // 未入力または確定
      tableName: 'table_024'
    },
    {
      eventNumber: '001',
      templateNumber: '025',
      templateNameJa: '25．入場証リーダー端末申込み',
      templateNameEn: '25.Application for Admission Pass Reader',
      dayJa: '9/20(金)',
      dayEn: 'Sep 20 (Fri)',
      status: 'ー', // 未入力または確定
      tableName: 'table_025'
    },
    {
      eventNumber: '001',
      templateNumber: '026',
      templateNameJa: '26．資料ダウンロード（マニュアル・小間割・ピット図等）',
      templateNameEn: '26.Download (Exhibitor\'s Manual / Floor Layout Plan / Floor Layout Plan with Pits)',
      dayJa: 'ー',
      dayEn: '-',
      status: 'ー', // 未入力または確定
      tableName: 'table_026'
    },
    // 他のデータ...
  ];
  

  const getStatusColor = (status: string) => {
    return status === '入力済み' ? 'blue' : 'red';
  };

  const getStatusText = (status: string) => {
    return status === 'true'
      ? <span style={{ color: 'blue' }}>
          <MultiLangText language={language} textJa="入力済" textEn="Completed" />
        </span>
      : <MultiLangText language={language} textJa="未入力" textEn="Not Completed" />;
  };
  
  return (
    <Layout title="Home">
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
  <TableHead>
    <TableRow>
      <TableCell align="left" sx={{ width: '120px' }}>
        <MultiLangText
          language={language}
          textJa="イベント番号"
          textEn="Event No."
        />
      </TableCell>
      <TableCell align="left" sx={{ width: '90px' }}>
        <MultiLangText
          language={language}
          textJa="申請No."
          textEn="Form No."
        />
      </TableCell>
      <TableCell align="left" sx={{ width: 'auto' }}>
        <MultiLangText
          language={language}
          textJa="申請内容"
          textEn="Application Forms"
        />
      </TableCell>
      <TableCell align="center" sx={{ width: '100px' }}>
        <MultiLangText
          language={language}
          textJa="申請期限"
          textEn="Deadline"
        />
      </TableCell>
      <TableCell align="center" sx={{ width: '120px' }}>
        <MultiLangText
          language={language}
          textJa="ステータス"
          textEn="Status"
        />
      </TableCell>
      <TableCell align="center" sx={{ width: '120px' }}>
        <MultiLangText
          language={language}
          textJa="編集・確認"
          textEn="Data Editing / Confirmation"
        />
      </TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    {rows.map((row, index) => (
      <TableRow key={index}>
        <TableCell component="th" scope="row" align="left" sx={{ width: '120px' }}>
          {row.eventNumber}
        </TableCell>
        <TableCell align="left" sx={{ width: '90px' }}>{row.templateNumber}</TableCell>
        <TableCell align="left" sx={{ width: 'auto' }}>{language === 'ja' ? row.templateNameJa : row.templateNameEn}</TableCell>
        <TableCell align="center" sx={{ width: '100px', color: 'red' }}>
          {language === 'ja' ? row.dayJa : row.dayEn}
        </TableCell>
        <TableCell align="center" sx={{ width: '120px', color: getStatusColor(statuses[row.templateNumber]) }}>
          {getStatusText(statuses[row.templateNumber])}
        </TableCell>
        <TableCell align="center" sx={{ width: '120px' }}>
        <Button
          variant="contained"
          onClick={() => handleOpen(row.templateNumber)}
          disabled={hiddenTemplates.includes(row.templateNumber)}
          sx={{ backgroundColor: '#3c71ac', '&:hover': { backgroundColor: '#325c85' }}}
        >
          <MultiLangText
            language={language}
            textJa="開く"
            textEn="Open"
          />
        </Button>
        </TableCell>
      </TableRow>
    ))}
  </TableBody>
</Table>

      </TableContainer>
    </Layout>
  );
};

export default Home;