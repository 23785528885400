import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface AuthContextType {
  user: { username: string, exhibitorName: string, isAdmin: boolean, Company_ID: string } | null;
  language: string;
  loading: boolean;
  login: (user: { username: string, exhibitorName: string, isAdmin: boolean, Company_ID: string }) => void;
  logout: () => void;
  setLanguage: (language: string) => void;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  language: 'ja', // default language
  loading: true,
  login: () => {},
  logout: () => {},
  setLanguage: () => {},
});

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<{ username: string, exhibitorName: string, isAdmin: boolean, Company_ID: string } | null>(null);
  const [language, setLanguage] = useState<string>('ja');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = sessionStorage.getItem('user');
    const storedLanguage = localStorage.getItem('language') || 'ja';

    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    setLanguage(storedLanguage);
    setLoading(false);
  }, []);

  const login = (user: { username: string, exhibitorName: string, isAdmin: boolean, Company_ID: string }) => {
    setUser(user);
    sessionStorage.setItem('user', JSON.stringify(user));
    console.log('User logged in:', user);
    navigate('/');
  };

  const logout = () => {
    setUser(null);
    sessionStorage.removeItem('user');
    console.log('User logged out'); // デバッグメッセージ
    navigate('/login');
  };

  const updateLanguage = (language: string) => {
    setLanguage(language);
    localStorage.setItem('language', language);
  };

  return (
    <AuthContext.Provider value={{ user, language, loading, login, logout, setLanguage: updateLanguage }}>
      {children}
    </AuthContext.Provider>
  );
};
