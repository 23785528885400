import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Typography, Paper, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuth } from '../../context/AuthContext';
import MultiLangText from '../templetes/MultiLangText';

interface ExhibitorInfoFormProps {
  formData: any;
  setFormData: (data: any) => void;
}

const UserInfo: React.FC<ExhibitorInfoFormProps> = ({ formData, setFormData }) => {
  const { user } = useAuth();
  const { language } = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (user) {
        const Company_ID = user.Company_ID; // user オブジェクトから Company_ID を取得
        axios.post('https://xs525443.xsrv.jp/admin_ui/getExhibitors.php', { Company_ID })
            .then(response => {

                if (response.data && !response.data.error) {
                    const data = response.data;
                    const contactPerson = `${data.contactPersonLastName} ${data.contactPersonFirstName}`;
                    setFormData({
                        ...formData,
                        exhibitorName: data.exhibitorName,
                        boothNumber: data.boothNumber,
                        tel: data.tel,
                        departmentPosition: data.departmentPosition,
                        contactPerson: contactPerson,
                        postalCode: data.postalCode,
                        prefecture: data.prefecture,
                        city: data.city,
                        address: data.buildingName,
                        email: data.email,
                        login_id: data.login_id,
                        login_password: data.login_password
                    });
                } else {
                    console.warn('No data or error received from API', response.data);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }
}, [user]);

// `formData`が更新されるたびにログを表示する
useEffect(() => {
}, [formData]);



  return (
    <Paper style={{ padding: 16 }}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            <MultiLangText language={language} textJa="出展者名" textEn="Exhibitor's Name" />
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>{formData.exhibitorName}</Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="subtitle1">
            <MultiLangText language={language} textJa="小間番号" textEn="Booth No." />
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>{formData.boothNumber}</Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="subtitle1">TEL</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>{formData.tel}</Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="subtitle1">
            <MultiLangText language={language} textJa="担当者部署・役職" textEn="Title / Department of the Contact Person" />
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>{formData.departmentPosition}</Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="subtitle1">
            <MultiLangText language={language} textJa="担当者名" textEn="Name of Contact Person" />
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>{formData.contactPerson}</Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="subtitle1">
            <MultiLangText language={language} textJa="住所" textEn="Address" />
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>{`${formData.postalCode} ${formData.prefecture} ${formData.city} ${formData.address}`}</Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="subtitle1">
            <MultiLangText language={language} textJa="担当者メールアドレス" textEn="E-mail" />
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>{formData.email}</Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="subtitle1">
            <MultiLangText language={language} textJa="ログインID" textEn="Login ID" />
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>{formData.login_id}</Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="subtitle1">
            <MultiLangText language={language} textJa="パスワード" textEn="Password" />
          </Typography>
        </Grid>
        <Grid item xs={9} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography>{showPassword ? formData.login_password : '••••••••'}</Typography>
          <IconButton onClick={togglePasswordVisibility}>
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default UserInfo;
