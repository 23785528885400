import React, { useState } from 'react';
import Layout from '../templetes/Layout';
import { 
  TextField, Button, Box, Typography, Paper, Grid, FormControlLabel, Checkbox, Select, MenuItem, InputLabel, FormControl
} from '@mui/material';
import ExhibitorInfoForm from '../templetes/ExhibitorInfoForm';
import { SelectChangeEvent } from '@mui/material';
import { Radio, RadioGroup } from '@mui/material';
import RequiredLabel from '../templetes/RequiredLabel';
import MultiLangText from '../templetes/MultiLangText';
import { useAuth } from '../../context/AuthContext';

const Template001: React.FC = () => {
  const { language } = useAuth();
  
  const [formData, setFormData] = useState({
    exhibitorName: '',
    boothNumber: '',
    tel: '',
    departmentPosition: '',
    contactPerson: '',
    postalCode: '',
    prefecture: '',
    city: '',
    address: '',
    email: '',
    Company_ID: '',
    decoration3mm: ''
  });


  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // フォームデータの送信処理をここに追加する
    console.log(formData);
    // 例: APIにデータを送信する、またはフォームのリセットなど
    setFormData({
      exhibitorName: '',
      boothNumber: '',
      tel: '',
      departmentPosition: '',
      contactPerson: '',
      postalCode: '',
      prefecture: '',
      city: '',
      address: '',
      email: '',
      Company_ID: '',
      decoration3mm: ''
    });
  };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name as string]: value
    }));
  };

  const prefectures = ["北海道", "青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県", "茨城県", "栃木県", "群馬県", "埼玉県", "千葉県", "東京都", "神奈川県", "新潟県", "富山県", "石川県", "福井県", "山梨県", "長野県", "岐阜県", "静岡県", "愛知県", "三重県", "滋賀県", "京都府", "大阪府", "兵庫県", "奈良県", "和歌山県", "鳥取県", "島根県", "岡山県", "広島県", "山口県", "徳島県", "香川県", "愛媛県", "高知県", "福岡県", "佐賀県", "長崎県", "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県"];

  
  return (
    <Layout title="申請書類管理　９．会場内広告掲載 お申込み　※希望者のみ">
      <Paper style={{ padding: 16 }}>
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="■出展者情報"
                textEn="■Exhibitor information"
                />
        </Typography>
        <form onSubmit={handleSubmit}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault(); // Enterキーによるフォーム送信を防ぐ
             // アラートを表示
          }
        }}>
          


    {/* セクションスタート */}
    <Typography gutterBottom style={{ fontWeight: 'bold' }}>
      ■ 会場案内広告掲載　（希望する種類にお申込み数量をご記入下さい）※広告サイズ：W 90×H 55（単位：mm）
      </Typography>
      <hr></hr>
      <Grid container alignItems="center">

      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      メニュー内容　<RequiredLabel/>
      </Grid>
      <Grid item xs={10} sm={10}>
        <Grid container spacing={3} alignItems="center">
        <Grid item xs={8} sm={8}>
        ① 西ホール 2F⇔4Fエスカレーター上床面広告（4F 部分）　￥400,000（限定数：1）
        </Grid>
        <Grid item xs={2} sm={2}>
        <FormControlLabel
                control={<Checkbox name="checkedB" color="primary" />}
                label="申込"
              />
        </Grid>
        <Grid item xs={2} sm={2} >
        <TextField
          label="枠"
          name="exhibitorName"
          value={formData.exhibitorName}
          onChange={handleChange}
          sx={{ width: 70 }}
          margin="normal"
          required
        />
        </Grid>
        </Grid>
      </Grid>

      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={10} sm={10}>
        <Grid container spacing={3} alignItems="center">
        <Grid item xs={8} sm={8}>
        ② 西ホール 1F⇔4Fエスカレーター上床面広告（4F 部分）　￥400,000（限定数：1）
        </Grid>
        <Grid item xs={2} sm={2}>
        <FormControlLabel
                control={<Checkbox name="checkedB" color="primary" />}
                label="申込"
              />
        </Grid>
        <Grid item xs={2} sm={2} >
        <TextField
          label="枠"
          name="exhibitorName"
          value={formData.exhibitorName}
          onChange={handleChange}
          sx={{ width: 70 }}
          margin="normal"
          required
        />
        </Grid>
        </Grid>
      </Grid>

      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={10} sm={10}>
        <Grid container spacing={3} alignItems="center">
        <Grid item xs={8} sm={8}>
        ③ 西ホール 4F⇔1Fエスカレーター下床面広告（1F 部分）　￥400,000（限定数：1）
        </Grid>
        <Grid item xs={2} sm={2}>
        <FormControlLabel
                control={<Checkbox name="checkedB" color="primary" />}
                label="申込"
              />
        </Grid>
        <Grid item xs={2} sm={2} >
        <TextField
          label="枠"
          name="exhibitorName"
          value={formData.exhibitorName}
          onChange={handleChange}
          sx={{ width: 70 }}
          margin="normal"
          required
        />
        </Grid>
        </Grid>
      </Grid>

      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={10} sm={10}>
        <Grid container spacing={3} alignItems="center">
        <Grid item xs={8} sm={8}>
        ④ 西ホール 1F　円柱 巻き広告　￥200,000（限定数：14）
        </Grid>
        <Grid item xs={2} sm={2}>
        <FormControlLabel
                control={<Checkbox name="checkedB" color="primary" />}
                label="申込"
              />
        </Grid>
        <Grid item xs={2} sm={2} >
        <TextField
          label="枠"
          name="exhibitorName"
          value={formData.exhibitorName}
          onChange={handleChange}
          sx={{ width: 70 }}
          margin="normal"
          required
        />
        </Grid>
        </Grid>
      </Grid>

      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={10} sm={10}>
        <Grid container spacing={3} alignItems="center">
        <Grid item xs={8} sm={8}>
        ⑤ 西ホール 4F　角柱 巻き広告　￥200,000（限定数：２）
        </Grid>
        <Grid item xs={2} sm={2}>
        <FormControlLabel
                control={<Checkbox name="checkedB" color="primary" />}
                label="申込"
              />
        </Grid>
        <Grid item xs={2} sm={2} >
        <TextField
          label="枠"
          name="exhibitorName"
          value={formData.exhibitorName}
          onChange={handleChange}
          sx={{ width: 70 }}
          margin="normal"
          required
        />
        </Grid>
        </Grid>
      </Grid>

      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={10} sm={10}>
        <Grid container spacing={3} alignItems="center">
        <Grid item xs={8} sm={8}>
        ⑥ 館内天井吊り下げ広告 A【1 面タイプ】￥450,000（限定数：ー）
        </Grid>
        <Grid item xs={2} sm={2}>
        <FormControlLabel
                control={<Checkbox name="checkedB" color="primary" />}
                label="申込"
              />
        </Grid>
        <Grid item xs={2} sm={2} >
        <TextField
          label="枠"
          name="exhibitorName"
          value={formData.exhibitorName}
          onChange={handleChange}
          sx={{ width: 70 }}
          margin="normal"
          required
        />
        </Grid>
        </Grid>
      </Grid>

      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={10} sm={10}>
        <Grid container spacing={3} alignItems="center">
        <Grid item xs={8} sm={8}>
        ⑦ 館内天井吊り下げ広告 A【2 面タイプ】￥550,000（限定数：ー）
        </Grid>
        <Grid item xs={2} sm={2}>
        <FormControlLabel
                control={<Checkbox name="checkedB" color="primary" />}
                label="申込"
              />
        </Grid>
        <Grid item xs={2} sm={2} >
        <TextField
          label="枠"
          name="exhibitorName"
          value={formData.exhibitorName}
          onChange={handleChange}
          sx={{ width: 70 }}
          margin="normal"
          required
        />
        </Grid>
        </Grid>
      </Grid>

      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={10} sm={10}>
        <Grid container spacing={3} alignItems="center">
        <Grid item xs={8} sm={8}>
        ⑧ 館内天井吊り下げ広告 A【3 面タイプ】￥750,000（限定数：ー）
        </Grid>
        <Grid item xs={2} sm={2}>
        <FormControlLabel
                control={<Checkbox name="checkedB" color="primary" />}
                label="申込"
              />
        </Grid>
        <Grid item xs={2} sm={2} >
        <TextField
          label="枠"
          name="exhibitorName"
          value={formData.exhibitorName}
          onChange={handleChange}
          sx={{ width: 70 }}
          margin="normal"
          required
        />
        </Grid>
        </Grid>
      </Grid>

      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={10} sm={10}>
        <Grid container spacing={3} alignItems="center">
        <Grid item xs={8} sm={8}>
        ⑨ 誘導看板（小）A【片面】￥200,000（限定数：ー）
        </Grid>
        <Grid item xs={2} sm={2}>
        <FormControlLabel
                control={<Checkbox name="checkedB" color="primary" />}
                label="申込"
              />
        </Grid>
        <Grid item xs={2} sm={2} >
        <TextField
          label="枠"
          name="exhibitorName"
          value={formData.exhibitorName}
          onChange={handleChange}
          sx={{ width: 70 }}
          margin="normal"
          required
        />
        </Grid>
        </Grid>
      </Grid>

      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={10} sm={10}>
        <Grid container spacing={3} alignItems="center">
        <Grid item xs={8} sm={8}>
        ⑩ 誘導看板（小）B【両面】￥250,000（限定数：ー）
        </Grid>
        <Grid item xs={2} sm={2}>
        <FormControlLabel
                control={<Checkbox name="checkedB" color="primary" />}
                label="申込"
              />
        </Grid>
        <Grid item xs={2} sm={2} >
        <TextField
          label="枠"
          name="exhibitorName"
          value={formData.exhibitorName}
          onChange={handleChange}
          sx={{ width: 70 }}
          margin="normal"
          required
        />
        </Grid>
        </Grid>
      </Grid>

      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={10} sm={10}>
        <Grid container spacing={3} alignItems="center">
        <Grid item xs={8} sm={8}>
        ※原稿入稿<br />
　・入稿期限：9 月21 日（金）<br />
　・入稿先：（株）東京ビッグサイト2024 国際航空宇宙展事務所<br />
　　E-mail：bigsight-jp@tokyo-bigsight.co.jp<br />
　・データ形式：Illustrator またはPDF
        </Grid>
        <Grid item xs={2} sm={2}>
        </Grid>
        <Grid item xs={2} sm={2} >
        </Grid>
        </Grid>
      </Grid>


      </Grid> 
      {/* セクションここまで */} 
<br></br><br></br>
{/* セクションスタート */}
<Typography gutterBottom style={{ fontWeight: 'bold' }}>
      ■ 請求先について
      </Typography>
      <hr></hr>
      <Grid container spacing={2} alignItems="center">

       {/* カラム */}
       <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      お選び下さい　<RequiredLabel/>
      </Grid>
      <Grid item xs={9} sm={9}>
      <RadioGroup
          row
          aria-label="decoration"
          name="decoration3mm"
          value={formData.decoration3mm}
          onChange={(e) => setFormData({ ...formData, decoration3mm: e.target.value })}
        >
      <Grid item xs={3} sm={3}>
      <FormControlLabel value="出展者" control={<Radio required />} label="出展者" />
      </Grid>
      <Grid item xs={3} sm={3}>
      <FormControlLabel value="お申込者" control={<Radio required />} label="お申込者" />
      </Grid>
      <Grid item xs={6} sm={6}>
      <FormControlLabel value="その他（下記へご記入下さい）" control={<Radio required />} label="その他（下記へご記入下さい）" />
      </Grid>
      </RadioGroup>
      </Grid>

      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      請求先社名　<RequiredLabel/>
      </Grid>
      <Grid item xs={9} sm={9}>
      <TextField
        
        name="exhibitorName"
        value={formData.exhibitorName}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      </Grid>

      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      担当者携帯　<RequiredLabel/>
      </Grid>
      <Grid item xs={4} sm={4}>
      <TextField
        
        name="exhibitorName"
        value={formData.exhibitorName}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      </Grid>
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      TEL　<RequiredLabel/>
      </Grid>
      <Grid item xs={4} sm={4}>
      <TextField
        
        name="exhibitorName"
        value={formData.exhibitorName}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      </Grid>

      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      担当者部署・役職
      </Grid>
      <Grid item xs={4} sm={4}>
      <TextField
        
        name="exhibitorName"
        value={formData.exhibitorName}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      </Grid>
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      担当者名　<RequiredLabel/>
      </Grid>
      <Grid item xs={4} sm={4}>
      <TextField
        
        name="exhibitorName"
        value={formData.exhibitorName}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      </Grid>

      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      住所　<RequiredLabel/>
      </Grid>
      <Grid item xs={9} sm={9}>

        <Grid container>
        <Grid item xs={5} sm={5}>
        <TextField
          label={<MultiLangText
                language={language}
                textJa="〒"
                textEn="Postal Code"
                />}
          name="exhibitorName"
          value={formData.exhibitorName}
          onChange={handleChange}
          fullWidth
          required
        />
        </Grid>

        <Grid item xs={0} sm={0.1}></Grid>

        <Grid item xs={5} sm={5}>
        <FormControl fullWidth>
          <InputLabel id="prefecture-label">都道府県</InputLabel>
          <Select
            labelId="prefecture-label"
            id="prefecture"
            name="prefecture"
            value={formData.prefecture}
            label="都道府県"
            onChange={(e) => setFormData({ ...formData, prefecture: e.target.value })}
            required
          >
            {prefectures.map((prefecture, index) => (
              <MenuItem key={index} value={prefecture}>{prefecture}</MenuItem>
            ))}
          </Select>
        </FormControl>
        </Grid>
        
        </Grid>
      </Grid>


      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={9} sm={9}>
      <TextField
        label={<MultiLangText
                language={language}
                textJa="市区町村名（例：大阪市北区）"
                textEn="city"
                />}

        name="exhibitorName"
        value={formData.exhibitorName}
        onChange={handleChange}
        fullWidth
        required
      />
      </Grid>

      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={9} sm={9}>
      <TextField
        label={<MultiLangText
                language={language}
                textJa="番地・ビル名（例：〇〇１丁目2-3）"
                textEn="Address and Building Name"
                />}
        name="exhibitorName"
        value={formData.exhibitorName}
        onChange={handleChange}
        fullWidth
        required
      />
      </Grid>


      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      メールアドレス　<RequiredLabel/>
      </Grid>
      <Grid item xs={9} sm={9}>
      <TextField
        label="XXXXX@xxxxxx.com"
        name="exhibitorName"
        value={formData.exhibitorName}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      </Grid>

      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={9} sm={9}>
      ※〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇
      </Grid>

      </Grid> 
      {/* セクションここまで */} 

        </form>
      </Paper>
    </Layout>
  );
};

export default Template001;
