import React, { useState, useEffect } from 'react';
import Layout from '../templetes/Layout';
import { 
  TextField, Button, Typography, Paper, Grid, FormControlLabel, Checkbox, Select, MenuItem, InputLabel, FormControl
} from '@mui/material';
import ExhibitorInfoForm from '../templetes/ExhibitorInfoForm';
import { SelectChangeEvent } from '@mui/material';
import { Radio, RadioGroup } from '@mui/material';
import RequiredLabel from '../templetes/RequiredLabel';
import ContactInfo from '../templetes/ContactInfo';
import MultiLangText from '../templetes/MultiLangText';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';

const Template016: React.FC = () => {
  const { language } = useAuth();
  const isJapanese = language === 'ja';

  const [formData, setFormData] = useState({
    exhibitorName: '',
    boothNumber: '',
    tel: '',
    departmentPosition: '',
    contactPerson: '',
    postalCode: '',
    prefecture: '',
    city: '',
    address: '',
    email: '',
    Company_ID: '',
    decoration3mm: '',
    additionalPasses: '',
    billingCompanyName: '',
    billingMobile: '',
    billingTel: '',
    billingDepartmentPosition: '',
    billingContactPerson: '',
    billingPostalCode: '',
    billingPrefecture: '',
    billingCity: '',
    billingAddress: '',
    billingEmail: '',
    buttonState: {
      reset: false,  // リセットボタンの状態
      save: false,   // 保存ボタンの状態
      submit: false  // 確定ボタンの状態
    }
  });

  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);


  useEffect(() => {
    const loadButtonState = async () => {
      try {
        const timestamp = new Date().getTime();
        const response = await axios.get(`/json/buttonState16.json?_=${timestamp}`);  // 外部JSONのパスを指定
        const buttonStateData = response.data;
        setFormData(prevState => ({
          ...prevState,
          buttonState: buttonStateData.buttonState
        }));
        setIsReadOnly(buttonStateData.buttonState.disabled);
      } catch (error) {
        console.error('ボタン状態の読み込み中にエラーが発生しました:', error);
      }
    };
  
    loadButtonState();  // コンポーネントの初回レンダリング時に実行
  }, []);

  useEffect(() => {
    const savedData = localStorage.getItem('formData016');
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);
  
  useEffect(() => {
    if (formData.exhibitorName) {
      checkDuplicate();
    }
  }, [formData.exhibitorName]);
  

  const checkDuplicate = async () => {
    try {
      const response = await fetch('https://xs525443.xsrv.jp/admin_ui/check016.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          exhibitorName: formData.exhibitorName,
          Company_ID: formData.Company_ID
        }),
      });
  
      const result = await response.json();
      console.log('Check Duplicate Response:', result);
  
      if (result.isDuplicate && result.additionalData) {
        const parsedData = JSON.parse(result.additionalData);
        setFormData(prevState => ({
          ...prevState,
          ...parsedData,
        }));
        setIsDuplicate(true);
        setIsReadOnly(true);
      } else {
        setIsDuplicate(false);
      }
    } catch (error) {
      console.error('重複チェック中にエラーが発生しました:', error);
    }
  };
  

  const handleSave = () => {
    localStorage.setItem('formData016', JSON.stringify(formData));
    const alertMessage = language === 'ja' ? 'データを保存しました。' : 'Save Completed';
  alert(alertMessage);
  };

  const handleReset = async () => {
  const confirmMessage = language === 'ja' ? '本当に削除していいですか？' : 'Are you sure you want to reset?';
  const resetSuccessMessage = language === 'ja' ? 'フォームとファイルがリセットされました。' : 'Reset Completed';
  const deleteErrorMessage = language === 'ja' ? 'データの削除に失敗しました。' : 'Failed to delete data.';
    if (window.confirm(confirmMessage)) {
      try {
        const response = await fetch('https://xs525443.xsrv.jp/admin_ui/delete016.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
          exhibitorName: formData.exhibitorName,
          Company_ID: formData.Company_ID
        }),
        });
  
        const result = await response.json();
  
        if (result.success) {
          setFormData(prevState => ({
            ...prevState,
            decoration3mm: '',
            additionalPasses: '',
            billingCompanyName: '',
            billingMobile: '',
            billingTel: '',
            billingDepartmentPosition: '',
            billingContactPerson: '',
            billingPostalCode: '',
            billingPrefecture: '',
            billingCity: '',
            billingAddress: '',
            billingEmail: ''
          }));
          localStorage.removeItem('formData016');
          setIsReadOnly(false);
          setIsSubmitted(false);
          alert(resetSuccessMessage);
          checkDuplicate();
        } else {
          alert(deleteErrorMessage + ': ' + result.message);
        }
      } catch (error) {
        console.error('データの削除中にエラーが発生しました:', error);
        alert(deleteErrorMessage);
      }
    }
  };
  

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    const additionalData = {
      decoration3mm: formData.decoration3mm,
      additionalPasses: formData.additionalPasses,
      billingCompanyName: formData.billingCompanyName,
      billingMobile: formData.billingMobile,
      billingTel: formData.billingTel,
      billingDepartmentPosition: formData.billingDepartmentPosition,
      billingContactPerson: formData.billingContactPerson,
      billingPostalCode: formData.billingPostalCode,
      billingPrefecture: formData.billingPrefecture,
      billingCity: formData.billingCity,
      billingAddress: formData.billingAddress,
      billingEmail: formData.billingEmail
    };
  
    const formDataToSend = {
      exhibitorName: formData.exhibitorName,
      boothNumber: formData.boothNumber,
      tel: formData.tel,
      departmentPosition: formData.departmentPosition,
      contactPerson: formData.contactPerson,
      postalCode: formData.postalCode,
      prefecture: formData.prefecture,
      city: formData.city,
      address: formData.address,
      email: formData.email,
      Company_ID: formData.Company_ID,
      additionalData: JSON.stringify(additionalData)
    };
  
    console.log('Form Data to Send:', formDataToSend);
  
    try {
      const response = await fetch('https://xs525443.xsrv.jp/admin_ui/submit016.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formDataToSend),
      });
  
      const result = await response.json();
      console.log('Response:', result);

      const successMessage = language === 'ja' ? 'データが正常に送信されました。' : 'Submission Completed';
    const failureMessage = language === 'ja' ? 'データの送信に失敗しました: ' : 'Failed to submit data: ';
    const errorMessage = language === 'ja' ? 'データの送信に失敗しました。' : 'Failed to submit data.';
  
      if (result.success) {
       // alert(successMessage);
        setIsSubmitted(true);
        setIsReadOnly(true);
      } else {
        alert('データの送信に失敗しました: ' + result.message);
      }
    } catch (error) {
      console.error('データ送信エラー:', error);
      alert('データの送信中にエラーが発生しました。');
    }
  };
  
  

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    const { name, value } = e.target;
  
    if (name === 'postalCode' || name === 'billingPostalCode') {
      handlePostalCodeChange(name, value);
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name as string]: value
      }));
    }
  };
  

  const handlePostalCodeChange = async (name: string, value: string) => {
    const cleanedValue = value.replace(/-/g, ''); // ハイフンを除去
  
    setFormData(prevState => ({
      ...prevState,
      [name]: cleanedValue
    }));
  
    if (cleanedValue.length === 7) {
      try {
        const response = await fetch(`https://api.zipaddress.net/?zipcode=${cleanedValue}`);
        const data = await response.json();
        if (data.code === 200) {
          const addressFields = name === 'postalCode'
            ? {
                prefecture: data.data.pref,
                city: data.data.city,
                address: data.data.town
              }
            : {
                billingPrefecture: data.data.pref,
                billingCity: data.data.city,
                billingAddress: data.data.town
              };
  
          setFormData(prevState => ({
            ...prevState,
            ...addressFields
          }));
        } else {
          alert('郵便番号から住所を取得できませんでした。');
        }
      } catch (error) {
        console.error('郵便番号から住所を取得中にエラーが発生しました:', error);
      }
    }
  };

  
  const prefectures = ["北海道", "青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県", "茨城県", "栃木県", "群馬県", "埼玉県", "千葉県", "東京都", "神奈川県", "新潟県", "富山県", "石川県", "福井県", "山梨県", "長野県", "岐阜県", "静岡県", "愛知県", "三重県", "滋賀県", "京都府", "大阪府", "兵庫県", "奈良県", "和歌山県", "鳥取県", "島根県", "岡山県", "広島県", "山口県", "徳島県", "香川県", "愛媛県", "高知県", "福岡県", "佐賀県", "長崎県", "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県"];
  const hours = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));
  const minutes = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0'));

  return (
    <Layout title={<MultiLangText
      language={language}
      textJa="申請書類管理　16．出展者証・施工者証追加 申込み"
      textEn="Application Forms 16.Application for the Exhibitor Pass and Contractor Pass"
    />}>
      <Paper style={{ padding: 16 }}>
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          <MultiLangText
            language={language}
            textJa="■出展者情報"
            textEn="■Exhibitor information"
          />
        </Typography>
        <hr />
        <form onSubmit={handleSubmit}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault(); // Enterキーによるフォーム送信を防ぐ
             // アラートを表示
          }
        }}>
          <ExhibitorInfoForm
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
          />

          <br /><br />
          {/* セクションスタート */}
          <Typography gutterBottom style={{ fontWeight: 'bold' }}>
            <MultiLangText
              language={language}
              textJa=" ■ 出展者証申請ページ　※規定枚数：5 枚/出展面積9㎡（最大100 枚）"
              textEn="Application form for exhibitor passes　*Specified quantity: 5 passes per 9㎡ exhibition dimension (maximum of 100)"
            />
          </Typography>
          <hr />
          <Grid container spacing={3} alignItems="center">

            {/* カラム */}
            <Grid item xs={3} sm={3} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="右記リンク先URLより出展者証の登録をお願いします。"
                textEn="Please apply for exhibitor passes via the URL on the right."
              />
              <RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>
              
              <MultiLangText
                language={language}
                textJa="
                 <a href='https://ja2024-exhibitor-badge.smktg.jp/public/application/add/98?lang=ja' target='_blank' rel='noopener noreferrer'>
                https://ja2024-exhibitor-badge.smktg.jp/public/application/add/98?lang=ja
              </a>
                "
                textEn="
                <a href='https://ja2024-exhibitor-badge.smktg.jp/public/application/add/98?lang=en' target='_blank' rel='noopener noreferrer'>
                https://ja2024-exhibitor-badge.smktg.jp/public/application/add/98?lang=en
              </a>
                "
              />
              <br />
              <MultiLangText
                language={language}
                textJa="
                ※本ページは8月上旬公開予定です。<br/>
                 ※出展者証に印字される出展者名を上記出展者申請ページよりご登録ください。<br/>
                ※追加が必要な場合も上記出展者証申請ページよりお申込みください。
                "
                textEn="
                *This website will open in early August<br/>
                *Register exhibitor name to be printed on the exhibitor pass though above website.<br/>
                *If additional exhibitor passes are required, please apply via the URL above too."
              />
            </Grid>
          </Grid>
          {/* セクションここまで */}
          <br /><br />

          {/* セクションスタート */}
          <Typography gutterBottom style={{ fontWeight: 'bold' }}>
            <MultiLangText
              language={language}
              textJa="■　施工者証 追加お申込み　※規定枚数：10 枚（27㎡以下）、20 枚（27㎡超〜81㎡以下）、40 枚（81㎡超〜180㎡以下）、60 枚（180㎡超〜）"
              textEn="■　Additional Contractor Passes　*Specified quantity:10 passes(～27㎡), 20 passes(28㎡～81㎡), 40 passes(82㎡～180㎡), 60 passes(181㎡～)"
            />
          </Typography>
          <hr />
          <Grid container spacing={3} alignItems="center">

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="追加枚数　"
                textEn="Quantities　"
              />
              <RequiredLabel />
            </Grid>
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="規定枚数より追加される枚数を記入してください。"
                textEn="Please input number you add on specified quantities."
              />
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                name="additionalPasses"
                value={formData.additionalPasses}
                onChange={handleChange}
                fullWidth
                required
                margin="normal"
                disabled={isReadOnly}
              />
              <MultiLangText
                language={language}
                textJa="※施工者証では、本展の会期中の入場は出来ません。<br/>
※出展者証・施工者証は、登録された出展者担当宛に送付致します。<br/>
※出展者証4,400円/枚（税込）、施工者証110円/枚（税込）の追加料金が発生します。代金は展示会後に事務局から請求します。"
                textEn="
                *Contractor Passes are invalid during the exhibition period.<br/>
*Exhibitor Passes and Contractor Passes will be sent to exhibitor’s contact person.<br/>
*Additional fees of JPY4,400 per pass (tax included) for the exhibitor pass and JPY 110 per pass (tax included) for the contractor pass will be charged. The fee will be invoiced after the exhibition.
                "
              />
            </Grid>
          </Grid>
          {/* セクションここまで */}
          <br /><br />

          {/* セクションスタート */}
          <Typography gutterBottom style={{ fontWeight: 'bold' }}>
            <MultiLangText
              language={language}
              textJa="■　施工者証追加分請求先について"
              textEn="■　Invoice Recipient "
            />
          </Typography>
          <hr />
          <Grid container spacing={2} alignItems="center">

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="お選び下さい"
                textEn="Please select"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>
              <RadioGroup
                row
                aria-label="decoration"
                name="decoration3mm"
                value={formData.decoration3mm}
                onChange={(e) => setFormData({ ...formData, decoration3mm: e.target.value })}
              >
                <Grid item xs={3} sm={3}>
                  <FormControlLabel value="出展者" control={<Radio required />} label={<MultiLangText
                    language={language}
                    textJa="出展者"
                    textEn="Exhibitor"
                  />} disabled={isReadOnly}/>
                </Grid>
                <Grid item xs={3} sm={3}>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <FormControlLabel value="その他（下記へご記入下さい）" control={<Radio required />} label={<MultiLangText
                    language={language}
                    textJa="その他（下記へご記入下さい）"
                    textEn="Other(Fill out the following blanks)"
                  />} disabled={isReadOnly}/>
                </Grid>
              </RadioGroup>
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="請求先社名"
                textEn="Company Name"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>
              <TextField
                name="billingCompanyName"
                value={formData.billingCompanyName}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="担当者携帯"
                textEn="Mobile Phone Number"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                name="billingMobile"
                value={formData.billingMobile}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              TEL　<RequiredLabel />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                name="billingTel"
                value={formData.billingTel}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="担当者部署・役職"
                textEn="Title / Department of the Contact Person"
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                name="billingDepartmentPosition"
                value={formData.billingDepartmentPosition}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="担当者名"
                textEn="Name of Contact Person"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                name="billingContactPerson"
                value={formData.billingContactPerson}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="住所"
                textEn="Address"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>

              <Grid container>
                <Grid item xs={5} sm={5}>
                  <TextField
                    label={<MultiLangText
                      language={language}
                      textJa="〒"
                      textEn="Postal Code"
                    />}
                    name="billingPostalCode"
                    value={formData.billingPostalCode}
                    onChange={handleChange}
                    fullWidth
                    disabled={isReadOnly}
                  />
                </Grid>

                <Grid item xs={0} sm={0.1}></Grid>

                {
                  isJapanese && (
                    <Grid item xs={5} sm={5}>
                    <FormControl fullWidth>
                      <InputLabel id="billing-prefecture-label">都道府県</InputLabel>
                      <Select
                        labelId="billing-prefecture-label"
                        id="billing-prefecture"
                        name="billingPrefecture"
                        value={formData.billingPrefecture}
                        label="都道府県"
                        onChange={handleChange}
                        disabled={isReadOnly}
                      >
                        {prefectures.map((prefecture, index) => (
                          <MenuItem key={index} value={prefecture}>{prefecture}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  )
                }
                {
                  !isJapanese && (
                    <Grid item xs={5} sm={5}>
                    </Grid>
                  )
                }


              </Grid>
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={9} sm={9}>
              <TextField
                label={<MultiLangText
                language={language}
                textJa="市区町村名（例：大阪市北区）"
                textEn="city"
                />}

                name="billingCity"
                value={formData.billingCity}
                onChange={handleChange}
                fullWidth
                disabled={isReadOnly}
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={9} sm={9}>
              <TextField
                label={<MultiLangText
                language={language}
                textJa="番地・ビル名（例：〇〇１丁目2-3）"
                textEn="Address and Building Name"
                />}
                name="billingAddress"
                value={formData.billingAddress}
                onChange={handleChange}
                fullWidth
                disabled={isReadOnly}
              />
            </Grid>


            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="メールアドレス"
                textEn="E-mail"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>
              <TextField
                label="XXXXX@xxxxxx.com"
                name="billingEmail"
                value={formData.billingEmail}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={9} sm={9}>
              <MultiLangText
                language={language}
                textJa="※上記の請求先が出展者と異なる場合に記入してください。"
                textEn="*Please fill in above if the billing information is different from the exhibitor."
              />
            </Grid>
          </Grid>
          {/* セクションここまで */}
          {/* セクションスタート */}
          <Typography gutterBottom mt={5} style={{ fontWeight: 'bold' }}>
            <MultiLangText
              language={language}
              textJa="■お問い合わせ先について"
              textEn="■Contact Information"
            />
          </Typography>
          <hr />
          <Grid container spacing={3} alignItems="center">

            {/* カラム */}
            <Grid item xs={12} sm={12}>
              <MultiLangText
                language={language}
                textJa="
                      （株）東京ビッグサイト　2024国際航空宇宙展事務局<br/>
                      〒135-0063 東京都江東区有明3-11-1<br/>
TEL：03-5530-1324<br/>
Email：bigsight-ja@tokyo-bigsight.co.jp
                "
                textEn="JA2024 Management Office (Tokyo Big Sight Inc.)<br/>
                3-11-1, Ariake, Koto-ku, Tokyo 135-0063<br/>
TEL：+81-3-5530-1324　<br/>
E-mail：bigsight-ja@tokyo-bigsight.co.jp"
              />
            </Grid>
            {/* カラム */}
            <Grid item xs={12} sm={12} style={{ display: 'flex'}}>
              <MultiLangText
                language={language}
                textJa="
※下記、「確定」ボタンを押して、申請してください。「保存」中は修正が可能です。<br/>
              ※「リセット」ボタンがある申請書については、万一、確定後に修正が必要な場合も、内容の修正が可能です。
"
                textEn='
*Please click the "SUBMIT" button to submit your application. You can "SAVE” the form while you still need to modify it.<br/>
*Some forms have “RESET” button, which you can use when you need to reset and fill in the form again after submitting the form.
'
              />
            </Grid>
          </Grid>
          {/* セクションここまで */}
          <Grid container mt={5} spacing={1} justifyContent="center">
            <Grid item>
              <Button
                onClick={handleReset}
                variant="contained"
                color="secondary"
                disabled={formData.buttonState.reset}  // JSONで制御
                sx={{ filter: formData.buttonState.reset ? 'grayscale(1)' : 'none' }}  // グレーアウト
              >
                <MultiLangText
                  language={language}
                  textJa="リセット"
                  textEn="Reset"
                />
              </Button>
            </Grid>

            <Grid item>
              <Button
                onClick={handleSave}
                variant="contained"
                color="primary"
                disabled={formData.buttonState.save}  // JSONで制御
                sx={{ filter: formData.buttonState.save ? 'grayscale(1)' : 'none' }}  // グレーアウト
              >
                <MultiLangText
                  language={language}
                  textJa="保存"
                  textEn="Save"
                />
              </Button>
            </Grid>

            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={formData.buttonState.submit || isDuplicate || isSubmitted}  // JSONで制御 + 重複や送信済みの場合
                sx={{ filter: (formData.buttonState.submit || isDuplicate || isSubmitted) ? 'grayscale(1)' : 'none' }}  // グレーアウト
              >
                <MultiLangText
                  language={language}
                  textJa="確定"
                  textEn="Submit"
                />
              </Button>
            </Grid>
          </Grid>
          
        </form>
      </Paper>
    </Layout>
  );
};

export default Template016;
