import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from '../templetes/Layout';
import { TextField, Button, Typography, Paper, Grid, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import ExhibitorInfoForm from '../templetes/ExhibitorInfoForm';
import ContactInfo from '../templetes/ContactInfo';
import RequiredLabel from '../templetes/RequiredLabel';
import MultiLangText from '../templetes/MultiLangText';
import { useAuth } from '../../context/AuthContext';

const Template001: React.FC = () => {


  const [formData, setFormData] = useState({
    exhibitorName: '',
    boothNumber: '',
    tel: '',
    departmentPosition: '',
    contactPerson: '',
    postalCode: '',
    prefecture: '',
    city: '',
    address: '',
    email: '',
    jsonData: '', // JSON用フィールド
    decoratorCompanyName: '',
    decoratorPostalCode: '',
    decoratorPrefecture: '',
    decoratorCity: '',
    decoratorAddress: '',
    decoratorTel: '',
    decoratorDepartmentPosition: '',
    decoratorContactPerson: '',
    decoratorEmail: '',
    decoratorMobile: '',
    decorationMethod: 'パッケージブースを申込む', // ラジオボタンの選択肢
    Company_ID: '',
    buttonState: {
      reset: false,
      submit: false,
      save: false
    }
  });
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);

// 外部JSONファイルからボタンの状態を取得する関数
const loadButtonState = async () => {
  try {
    const timestamp = new Date().getTime();
    const response = await axios.get(`/json/buttonState1.json?_=${timestamp}`);  // 外部JSONファイルのパス
    const buttonStateData = response.data;
    setFormData(prevState => ({
      ...prevState,
      buttonState: buttonStateData.buttonState
    }));
    setIsReadOnly(buttonStateData.buttonState.disabled);
  } catch (error) {
    console.error('ボタン状態の読み込み中にエラーが発生しました:', error);
  }
};


  const checkDuplicate = async () => {
    try {
      const response = await fetch('https://xs525443.xsrv.jp/admin_ui/check01.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          exhibitorName: formData.exhibitorName,
          Company_ID: formData.Company_ID
        }),
      });
  
      const result = await response.json();
      setIsDuplicate(result.isDuplicate);
  
      if (result.isDuplicate && result.jsonData) {
        const parsedJsonData = JSON.parse(result.jsonData);
        setFormData(prevState => ({
          ...prevState,
          ...parsedJsonData,
        }));
        setIsReadOnly(true);
      }
    } catch (error) {
      console.error('重複チェック中にエラーが発生しました:', error);
    }
  };
  

  
  useEffect(() => {
    if (formData.exhibitorName) {
      checkDuplicate();
    }
  }, [formData.exhibitorName]);
  

  useEffect(() => {
    const savedData = localStorage.getItem('decoratorData');
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      console.log('Loaded data from localStorage:', parsedData);
      setFormData(parsedData);
    }
  }, []);

  const handleSave = () => {
    const decoratorData = {
      decoratorCompanyName: formData.decoratorCompanyName,
      decoratorPostalCode: formData.decoratorPostalCode,
      decoratorPrefecture: formData.decoratorPrefecture,
      decoratorCity: formData.decoratorCity,
      decoratorAddress: formData.decoratorAddress,
      decoratorTel: formData.decoratorTel,
      decoratorDepartmentPosition: formData.decoratorDepartmentPosition,
      decoratorContactPerson: formData.decoratorContactPerson,
      decoratorEmail: formData.decoratorEmail,
      decoratorMobile: formData.decoratorMobile,
      decorationMethod: formData.decorationMethod
    };

    const mergedData = { ...formData, jsonData: JSON.stringify(decoratorData) };

    console.log('Saving data to localStorage:', mergedData);
    localStorage.setItem('decoratorData', JSON.stringify(mergedData));
    const alertMessage = language === 'ja' ? 'データを保存しました。' : 'Save Completed';
  alert(alertMessage);
  };

  const [resetKey, setResetKey] = useState(0);

  const handleReset = async () => {
  const confirmMessage = language === 'ja' ? '本当に削除していいですか？' : 'Are you sure you want to reset?';
  const resetSuccessMessage = language === 'ja' ? 'フォームとファイルがリセットされました。' : 'Reset Completed';
  const deleteErrorMessage = language === 'ja' ? 'データの削除に失敗しました。' : 'Failed to delete data.';
    if (window.confirm(confirmMessage)) {
      try {
        const response = await fetch('https://xs525443.xsrv.jp/admin_ui/delete01.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ Company_ID: formData.Company_ID }),
        });
  
        const result = await response.json();
  
        if (result.success) {
          setFormData(prevState => ({
            ...prevState,
            decoratorCompanyName: '',
            decoratorPostalCode: '',
            decoratorPrefecture: '',
            decoratorCity: '',
            decoratorAddress: '',
            decoratorTel: '',
            decoratorDepartmentPosition: '',
            decoratorContactPerson: '',
            decoratorEmail: '',
            decoratorMobile: '',
            decorationMethod: 'パッケージブースを申込む'
          }));
          setIsReadOnly(false); // フォームを編集可能にする
          setIsSubmitted(false); // 確定ボタンを有効にする
          localStorage.removeItem('decoratorData');
          alert(resetSuccessMessage);
          checkDuplicate(); // 再度重複チェックを実行
        } else {
          alert(deleteErrorMessage + ': ' + result.message);
        }
      } catch (error) {
        console.error('データの削除中にエラーが発生しました:', error);
        alert(deleteErrorMessage);
      }
    }
  };
  
  

 // 追加する状態変数
const [isSubmitted, setIsSubmitted] = useState(false);

const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
  
  event.preventDefault();

  const decoratorData = {
    decoratorCompanyName: formData.decoratorCompanyName,
    decoratorPostalCode: formData.decoratorPostalCode,
    decoratorPrefecture: formData.decoratorPrefecture,
    decoratorCity: formData.decoratorCity,
    decoratorAddress: formData.decoratorAddress,
    decoratorTel: formData.decoratorTel,
    decoratorDepartmentPosition: formData.decoratorDepartmentPosition,
    decoratorContactPerson: formData.decoratorContactPerson,
    decoratorEmail: formData.decoratorEmail,
    decoratorMobile: formData.decoratorMobile,
    decorationMethod: formData.decorationMethod
  };

  const formDataToSend = { ...formData, jsonData: JSON.stringify(decoratorData) };

  console.log('Form Data:', formDataToSend);

  try {
    const response = await fetch('https://xs525443.xsrv.jp/admin_ui/submit001.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formDataToSend),
    });

    const result = await response.json();

    const successMessage = language === 'ja' ? 'データが正常に送信されました。' : 'Submission Completed';
    const failureMessage = language === 'ja' ? 'データの送信に失敗しました: ' : 'Failed to submit data: ';
    const errorMessage = language === 'ja' ? 'データの送信に失敗しました。' : 'Failed to submit data.';

    
    if (result.success) {
     // alert(successMessage);
      localStorage.removeItem('decoratorData');
      setIsSubmitted(true); // 送信が成功したらボタンをグレースケールにする
      checkDuplicate(); // 再度重複チェックを実行
    } else {
      alert('データの送信に失敗しました: ' + result.message);
    }
  } catch (error) {
    alert('データの送信に失敗しました。');
    console.error('Error:', error);
  }
};



  const handleChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    const { name, value } = e.target as HTMLInputElement | { name: string; value: unknown };
    console.log(`Changing field: ${name} to value: ${value}`);

    if (name === 'decoratorPostalCode' && (value as string).replace('-', '').length === 7) {
      try {
        const cleanedValue = (value as string).replace('-', ''); // ハイフンを削除
        const response = await axios.get(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${cleanedValue}`);
        if (response.data.results && response.data.results.length > 0) {
          const addressData = response.data.results[0];
          setFormData(prevState => ({
            ...prevState,
            decoratorPostalCode: value as string,
            decoratorPrefecture: addressData.address1,
            decoratorCity: addressData.address2,
            decoratorAddress: addressData.address3
          }));
          return;
        }
      } catch (error) {
        console.error('郵便番号から住所を取得中にエラーが発生しました:', error);
      }
    }

    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.log(`Changing radio button: ${name} to value: ${value}`);
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  

  const prefectures = ["北海道", "青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県", "茨城県", "栃木県", "群馬県", "埼玉県", "千葉県", "東京都", "神奈川県", "新潟県", "富山県", "石川県", "福井県", "山梨県", "長野県", "岐阜県", "静岡県", "愛知県", "三重県", "滋賀県", "京都府", "大阪府", "兵庫県", "奈良県", "和歌山県", "鳥取県", "島根県", "岡山県", "広島県", "山口県", "徳島県", "香川県", "愛媛県", "高知県", "福岡県", "佐賀県", "長崎県", "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県"];

  const getInitialValue = (field: string) => {
    const savedData = localStorage.getItem('decoratorData');
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      return parsedData[field] || '';
    }
    return '';
  };

  const { language } = useAuth();
  const isJapanese = language === 'ja';


  useEffect(() => {
    loadButtonState(); // コンポーネントのマウント時にボタン状態を読み込む
  }, []);
  
  return (
    <Layout title={
      <MultiLangText
        language={language}
        textJa="申請書類管理　1．装飾施工者 登録 ※全出展者提出"
        textEn="Application Forms 1. Decoration Contractor Registration　*All Exhibitors(Required)"
      />
    }>
      <Paper style={{ padding: 16 }}>
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
        <MultiLangText
                language={language}
                textJa="■　出展者情報"
                textEn="■　Exhibitor information"
                />
        </Typography>
        <hr/>
        <ExhibitorInfoForm
          formData={formData}
          setFormData={setFormData}
          handleChange={handleChange}
        />
        <form onSubmit={handleSubmit}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault(); // Enterキーによるフォーム送信を防ぐ
             // アラートを表示
          }
        }}
        >
          <Typography gutterBottom mt={5} style={{ fontWeight: 'bold' }}>
            <MultiLangText
              language={language}
              textJa="■ 小間装飾会社の選定　※下記よりいずれかにチェックしてください。"
              textEn="■ Selection of booth decoration company: Please check one of the following."
            />
          </Typography>
          <hr/>
          <Grid container spacing={3}>
            <Grid item xs={3} sm={3} style={{ display: 'flex' }}>
            <RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>
            <RadioGroup
              name="decorationMethod"
              value={formData.decorationMethod}
              onChange={handleRadioChange}
              row  // ここで横並びに設定します
            >
              <FormControlLabel value="パッケージブースを申込む" control={<Radio color="primary" />} label={
                <MultiLangText
                  language={language}
                  textJa="パッケージブースを申込む"
                  textEn="Apply for the Packaged Booth"
                />
      } disabled={isReadOnly}/>
              <FormControlLabel value="自社で行う" control={<Radio color="primary" />} label={<MultiLangText
                language={language}
                textJa="自社で行う"
                textEn="Exhibitor's own construction/dismantling"
                />} disabled={isReadOnly}/>
              <FormControlLabel value="下記の会社へ依頼する。" control={<Radio color="primary" />} label={<MultiLangText
                language={language}
                textJa="下記の会社へ依頼する"
                textEn="Request the Booth Construction to the Contractor below:"
                />} disabled={isReadOnly}/>
            </RadioGroup>
            <MultiLangText
                language={language}
                textJa="※パッケージブースを申込む出展者は、「18.パッケージブース申込み」にてお申込みください。"
                textEn="*Exhibitors applying for the Packaged Booth should apply at 18. Application for the Packaged Booth ."
                />
            </Grid>
          </Grid>
          <br />
          <Typography gutterBottom mt={5} style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="■工事会社情報"
                textEn="Contractor's Information"
                />
          </Typography>
          <hr/>
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={1} sm={1} style={{ display: 'flex' }}>
            <MultiLangText
                language={language}
                textJa="装飾・工事会社名　"
                textEn="Company Name"
                />
            </Grid>
            <Grid item xs={1} sm={1} style={{ display: 'flex' }}>
              <RequiredLabel/>
            </Grid>
            <Grid item xs={10} sm={10}>
              <TextField
                name="decoratorCompanyName"
                value={formData.decoratorCompanyName || getInitialValue('decoratorCompanyName')}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>

            <Grid item xs={1} sm={1} style={{ display: 'flex' }}>
            <MultiLangText
                language={language}
                textJa="住所　"
                textEn="Address"
                />
            </Grid>
            <Grid item xs={1} sm={1} style={{ display: 'flex' }}>
            <RequiredLabel/>
            </Grid>
            <Grid item xs={3} sm={3}>
              <TextField
              label={<MultiLangText
                language={language}
                textJa="〒"
                textEn="Postal Code"
                />}
                name="decoratorPostalCode"
                value={formData.decoratorPostalCode || getInitialValue('decoratorPostalCode')}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={1} sm={1}></Grid>
            
{
  isJapanese && (
    <Grid item xs={3} sm={3}>
    <FormControl fullWidth>
    <InputLabel id="decorator-prefecture-label">都道府県</InputLabel>
    <Select
      labelId="decorator-prefecture-label"
      id="decorator-prefecture"
      name="decoratorPrefecture"
      value={formData.decoratorPrefecture || getInitialValue('decoratorPrefecture')}
      label="都道府県"
      onChange={handleChange}
      disabled={isReadOnly}
    >
      {prefectures.map((prefecture, index) => (
        <MenuItem key={index} value={prefecture}>{prefecture}</MenuItem>
      ))}
    </Select>
  </FormControl>
  </Grid>
  )
}
{
  !isJapanese && (
    <Grid item xs={3} sm={3}>
    </Grid>
  )
}

            <Grid item xs={3} sm={3}></Grid>
            
            <Grid item xs={1} sm={1} style={{ display: 'flex' }}></Grid>
            <Grid item xs={1} sm={1} style={{ display: 'flex' }}></Grid>
            <Grid item xs={10} sm={10}>
              <TextField
                label={<MultiLangText
                language={language}
                textJa="市区町村名（例：大阪市北区）"
                textEn="city"
                />}

                name="decoratorCity"
                value={formData.decoratorCity || getInitialValue('decoratorCity')}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>
            
            <Grid item xs={1} sm={1} style={{ display: 'flex' }}></Grid>
            <Grid item xs={1} sm={1} style={{ display: 'flex' }}></Grid>
            <Grid item xs={10} sm={10}>
              <TextField
                label={<MultiLangText
                language={language}
                textJa="番地・ビル名（例：〇〇１丁目2-3）"
                textEn="Address and Building Name"
                />}
                name="decoratorAddress"
                value={formData.decoratorAddress || getInitialValue('decoratorAddress')}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>
            
            <Grid item xs={1} sm={1} style={{ display: 'flex' }}>
              TEL
            </Grid>
            <Grid item xs={1} sm={1} style={{ display: 'flex' }}>
            <RequiredLabel/>
            </Grid>
            <Grid item xs={10} sm={10}>
              <TextField
                name="decoratorTel"
                value={formData.decoratorTel || getInitialValue('decoratorTel')}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>

            <Grid item xs={1} sm={1} style={{ display: 'flex' }}>
            <MultiLangText
                language={language}
                textJa="担当者部署・役職"
                textEn="Title / Department of the Contact Person"
                />
            </Grid>
            <Grid item xs={1} sm={1} style={{ display: 'flex' }}></Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                name="decoratorDepartmentPosition"
                value={formData.decoratorDepartmentPosition || getInitialValue('decoratorDepartmentPosition')}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={1} sm={1} style={{ display: 'flex' }}>
            　<MultiLangText
                language={language}
                textJa="担当者名　"
                textEn="Name of Contact Person"
                />
            </Grid>
            <Grid item xs={1} sm={1} style={{ display: 'flex' }}>
            <RequiredLabel/>
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                name="decoratorContactPerson"
                value={formData.decoratorContactPerson || getInitialValue('decoratorContactPerson')}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>

            <Grid item xs={1} sm={1} style={{ display: 'flex' }}>
            <MultiLangText
                language={language}
                textJa="担当者メールアドレス　"
                textEn="E-mail"
                />
            </Grid>
            <Grid item xs={1} sm={1} style={{ display: 'flex' }}>
            <RequiredLabel/>
            </Grid>
            <Grid item xs={10} sm={10}>
              <TextField
                name="decoratorEmail"
                value={formData.decoratorEmail || getInitialValue('decoratorEmail')}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>
            
            <Grid item xs={1} sm={1} style={{ display: 'flex' }}>
            <MultiLangText
                language={language}
                textJa="担当者携帯電話番号　"
                textEn="Mobile Phone Number"
                />
            </Grid>
            <Grid item xs={1} sm={1} style={{ display: 'flex' }}>
            <RequiredLabel/>
            </Grid>
            <Grid item xs={10} sm={10}>
              <TextField
                name="decoratorMobile"
                value={formData.decoratorMobile || getInitialValue('decoratorMobile')}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
            </Grid>
            <Grid item xs={10} sm={10} style={{ display: 'flex'}}>
            <MultiLangText
                language={language}
                textJa="※パッケージブースを申込む場合、自社で行う場合、入力は不要です。"
                textEn="*If you select the packaged booth or own construction/dismantling, you do not need to fill in above."
                />
              
              
            </Grid>
            
        <Typography gutterBottom mt={5} style={{ fontWeight: 'bold' }}>
            <MultiLangText
                language={language}
                textJa="■ お問い合わせ先について　※備考・その他は、下記まで直接ご連絡ください。"
                textEn="■Contact Information　If you have any questions or concerns, please inquire with the following contact."
                />
          </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
            <hr/>
              <MultiLangText
                language={language}
                textJa="
                事務局施工会社 （株）ムラヤマ<br/>
              〒135-0061 東京都江東区豊洲3-2-24 豊洲フォレシア<br/>
              TEL: 03-6221-1960 （平日：10:00AM～18:00PM）<br/>
              E-mail: ja2024@murayama.co.jp"
                textEn="
                MURAYAMA INC.<br/>
                Toyosuforesia.3-2-24,Toyosu,Koto-ku,Tokyo 135-0061<br/>
                TEL:+81-3‐6221-1960（Weekday 10:00am-18:00pm）<br/>
                E-mail：ja2024@murayama.co.jp<br/>
                Norose,Takamura "
                />
            </Typography>
          </Grid>
        </Grid>
            {/* カラム */}
            <Grid item xs={12} sm={12} style={{ display: 'flex'}}>
              <MultiLangText
                language={language}
                textJa="
※下記、「確定」ボタンを押して、申請してください。「保存」中は修正が可能です。<br/>
              ※「リセット」ボタンがある申請書については、万一、確定後に修正が必要な場合も、内容の修正が可能です。
"
                textEn='
                <br/>
*Please click the "SUBMIT" button to submit your application. You can "SAVE” the form while you still need to modify it.<br/>
*Some forms have “RESET” button, which you can use when you need to reset and fill in the form again after submitting the form.
'
              />
            </Grid>
            
          <Grid container mt={5} spacing={1} justifyContent="center">
            <Grid item>
              <Button
                onClick={handleReset}
                variant="contained"
                color="secondary"
                disabled={formData.buttonState.reset}  // JSONで制御
                sx={{ filter: formData.buttonState.reset ? 'grayscale(1)' : 'none' }}  // グレーアウト
              >
                <MultiLangText
                  language={language}
                  textJa="リセット"
                  textEn="Reset"
                />
              </Button>
            </Grid>

            <Grid item>
              <Button
                onClick={handleSave}
                variant="contained"
                color="primary"
                disabled={formData.buttonState.save}  // JSONで制御
                sx={{ filter: formData.buttonState.save ? 'grayscale(1)' : 'none' }}  // グレーアウト
              >
                <MultiLangText
                  language={language}
                  textJa="保存"
                  textEn="Save"
                />
              </Button>
            </Grid>

            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={formData.buttonState.submit || isDuplicate || isSubmitted}  // JSONで制御 + 重複や送信済みの場合
                sx={{ filter: (formData.buttonState.submit || isDuplicate || isSubmitted) ? 'grayscale(1)' : 'none' }}  // グレーアウト
              >
                <MultiLangText
                  language={language}
                  textJa="確定"
                  textEn="Submit"
                />
              </Button>
            </Grid>
          </Grid>

          </Grid>
        </form>

      </Paper>
    </Layout>
  );
};

export default Template001;
