import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Paper, Container, FormControlLabel, Radio, RadioGroup } from '@mui/material';

interface LoginPageProps {
  onLogin: (user: { username: string; exhibitorName: string; isAdmin: boolean; Company_ID: string }, language: string) => void;
}


const LoginPage: React.FC<LoginPageProps> = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'ja');

  const handleLanguageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
    localStorage.setItem('language', selectedLanguage);
  };

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
  
    if (username === 'admin' && password === 'pass') {
      onLogin({ username, exhibitorName: '管理者', isAdmin: true, Company_ID: 'admin_company_id' }, language); // 'admin_company_id' を適切な値に置き換えてください
    } else {
      try {
        const response = await fetch('https://xs525443.xsrv.jp/admin_ui/login.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ username, password })
        });
        const data = await response.json();
        if (data.success) {
          onLogin({
            username,
            exhibitorName: data.user.exhibitorName,
            isAdmin: false,
            Company_ID: data.user.Company_ID
          }, language);
        } else {
          alert('Invalid credentials');
        }
      } catch (error) {
        console.error('Error during login:', error);
        alert(`Failed to fetch: ${error}`);
      }
    }
  };
  

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={6} sx={{ marginTop: 8, padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          <img src="loginlogo.png" alt="Login Logo" style={{ width: 150, height: 150 }} />
        </Typography>
        <Box component="form" onSubmit={handleLogin} sx={{ mt: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          <RadioGroup row value={language} onChange={handleLanguageChange} sx={{ mb: 2 }}>
            <FormControlLabel value="ja" control={<Radio />} label="日本語" />
            <FormControlLabel value="en" control={<Radio />} label="English" />
          </RadioGroup>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="ログイン ID / Login ID"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="パスワード / Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            ログイン / Login
          </Button>
        </Box>
      </Paper>
      <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 2, fontSize: '0.75rem' }}>
        Copyright © 2024 Murayama CO.,LTD. All Rights Reserved.
      </Typography>
    </Container>
  );
};

export default LoginPage;
