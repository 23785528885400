import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import MultiLangText from '../templetes/MultiLangText';
import { useAuth } from '../../context/AuthContext';

interface SideMenuProps {
  isAdmin: boolean;
}

const SideMenu: React.FC<SideMenuProps> = ({ isAdmin }) => {
  const [openAdmin, setOpenAdmin] = useState(true);
  const navigate = useNavigate();

  const handleClickAdmin = () => {
    setOpenAdmin(!openAdmin);
  };

  const handleNavigateToHome = () => {
    if (isAdmin) {
      navigate('/home-admin'); // 管理者用ホームページにリダイレクト
    } else {
      navigate('/');
    }
  };

  const handleNavigateToUserManagement = () => {
    navigate('/user-management');
  };

  const handleNavigateToUserManagement2 = () => {
    navigate('/user-page');
  };

  

  const { language } = useAuth();

  return (
    <List component="nav">
      {isAdmin ? (
  <>
    <ListItemButton
      onClick={handleClickAdmin}
      sx={{
        backgroundColor: '#2196d5',
        color: 'white',
        '&:hover': {
          backgroundColor: '#2196d5',
        },
      }}
    >
      <ListItemIcon sx={{ color: 'inherit' }}>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="管理" />
      {openAdmin ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={openAdmin} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <ListItemButton sx={{ pl: 4 }} onClick={handleNavigateToHome}>
          <ListItemIcon>
            <ShoppingCartIcon />
          </ListItemIcon>
          <ListItemText primary="申請書類管理" />
        </ListItemButton>
        <ListItemButton sx={{ pl: 4 }} onClick={handleNavigateToUserManagement}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="ユーザー管理" />
        </ListItemButton>
      </List>
    </Collapse>
  </>
) : (
  <>
    <ListItemButton onClick={handleNavigateToHome}>
      <ListItemIcon>
        <ShoppingCartIcon />
      </ListItemIcon>
      <ListItemText primary={<MultiLangText
            language={language}
            textJa="申請書類管理"
            textEn="Application Forms"
            />} />
    </ListItemButton>
    <ListItemButton onClick={handleNavigateToUserManagement2}>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary={<MultiLangText
            language={language}
            textJa="ユーザー管理"
            textEn="User"
            />} />
    </ListItemButton>
  </>
)}

    </List>
  );
};

export default SideMenu;
