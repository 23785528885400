// PrivateRoute.tsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

interface PrivateRouteProps {
  children: React.ReactNode;
  isAdminOnly?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, isAdminOnly }) => {
  const { user, loading } = useAuth();


  if (loading) {
    return <div>Loading...</div>; // Wait until user info is loaded
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (isAdminOnly && !user.isAdmin) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
