import React, { useState, useEffect } from 'react';
import Layout from '../templetes/Layout';
import {
  TextField, Button, Typography, Paper, Grid, Select, MenuItem, InputLabel, FormControl
} from '@mui/material';
import ExhibitorInfoForm from '../templetes/ExhibitorInfoForm';
import { SelectChangeEvent } from '@mui/material';
import RequiredLabel from '../templetes/RequiredLabel';
import ContactInfo from '../templetes/ContactInfo';
import MultiLangText from '../templetes/MultiLangText';
import { useAuth } from '../../context/AuthContext';

const Template008: React.FC = () => {
  const { language } = useAuth();
  
  const [formData, setFormData] = useState({
    exhibitorName: '',
    boothNumber: '',
    tel: '',
    departmentPosition: '',
    contactPerson: '',
    postalCode: '',
    prefecture: '',
    city: '',
    address: '',
    email: '',
    Company_ID: '',
    coExhibitors: [{ name: '', tel: '', departmentPosition: '', contactPerson: '', postalCode: '', prefecture: '', city: '', address: '', email: '', category: '', products: '' }]
  });

  useEffect(() => {
    const savedData = localStorage.getItem('formData008');
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);

  const handleSave = () => {
    localStorage.setItem('formData008', JSON.stringify(formData));
    const alertMessage = language === 'ja' ? 'データを保存しました。' : 'Save Completed';
  alert(alertMessage);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(formData);

    try {
      const response = await fetch('https://xs525443.xsrv.jp/admin_ui/submit008.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData),
      });

      const successMessage = language === 'ja' ? 'データが正常に送信されました。' : 'Submission Completed';
    const failureMessage = language === 'ja' ? 'データの送信に失敗しました: ' : 'Failed to submit data: ';
    const errorMessage = language === 'ja' ? 'データの送信に失敗しました。' : 'Failed to submit data.';

      if (response.ok) {
      //  alert(successMessage);
        /*
        setFormData({
          exhibitorName: '',
          boothNumber: '',
          tel: '',
          departmentPosition: '',
          contactPerson: '',
          postalCode: '',
          prefecture: '',
          city: '',
          address: '',
          email: '',
          decoration3mm: '',
          coExhibitors: [{ name: '', tel: '', departmentPosition: '', contactPerson: '', postalCode: '', prefecture: '', city: '', address: '', email: '', category: '', products: '' }]
        });
        localStorage.removeItem('formData008');
        */
      } else {
        alert('データの送信中にエラーが発生しました。');
      }
    } catch (error) {
      console.error('データ送信エラー:', error);
      alert('データの送信中にエラーが発生しました。');
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name as string]: value
    }));
  };

  const handleCoExhibitorChange = (index: number, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    const { name, value } = e.target;
    const updatedCoExhibitors = formData.coExhibitors.map((coExhibitor, i) => {
      if (i === index) {
        return { ...coExhibitor, [name]: value };
      }
      return coExhibitor;
    });
    setFormData(prevState => ({
      ...prevState,
      coExhibitors: updatedCoExhibitors
    }));
  };

  const addCoExhibitor = () => {
    setFormData(prevState => ({
      ...prevState,
      coExhibitors: [...prevState.coExhibitors, { name: '', tel: '', departmentPosition: '', contactPerson: '', postalCode: '', prefecture: '', city: '', address: '', email: '', category: '', products: '' }]
    }));
  };

  const prefectures = ["北海道", "青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県", "茨城県", "栃木県", "群馬県", "埼玉県", "千葉県", "東京都", "神奈川県", "新潟県", "富山県", "石川県", "福井県", "山梨県", "長野県", "岐阜県", "静岡県", "愛知県", "三重県", "滋賀県", "京都府", "大阪府", "兵庫県", "奈良県", "和歌山県", "鳥取県", "島根県", "岡山県", "広島県", "山口県", "徳島県", "香川県", "愛媛県", "高知県", "福岡県", "佐賀県", "長崎県", "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県"];

  const categories = [
    "機体装備品", "機内装備品", "航空機エンジン", "エンジン関連機器", "運航", "運航支援", "サービス", "販売", "物流", 
    "航空関連素材/ 部品", "燃料等消耗資材（航空）", "製造・加工（航空）", "検査・測定（航空）", "整備", "宇宙機器", 
    "宇宙利用及び宇宙関連サービス", "宇宙素材/ 部品", "燃料等消耗資材（宇宙）", "製造・加工（宇宙）", "検査・測定（宇宙）", 
    "アーバン・エア・モビリティ（UAM）", "脱炭素", "IT ソリューション", "設計/ 試作支援", "行政機関", "研究機関", 
    "エンターテインメント"
  ];

  return (
    <Layout title="8．共同出展者登録 申込み">
      <Paper style={{ padding: 16 }}>
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="■出展者情報"
                textEn="■Exhibitor information"
                />
        </Typography>
        <hr/>
        <form onSubmit={handleSubmit}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault(); // Enterキーによるフォーム送信を防ぐ
             // アラートを表示
          }
        }}>
          <ExhibitorInfoForm
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
          />

          {formData.coExhibitors.map((coExhibitor, index) => (
            <React.Fragment key={index}>
              <Typography gutterBottom mt={5} style={{ fontWeight: 'bold' }}>
                ■ 共同出展者情報 {index + 1}
              </Typography>
              <hr />
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
                  共同出展者名 <RequiredLabel />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <TextField
                    
                    name="name"
                    value={coExhibitor.name}
                    onChange={(e) => handleCoExhibitorChange(index, e)}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
                  TEL <RequiredLabel />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <TextField
                    
                    name="tel"
                    value={coExhibitor.tel}
                    onChange={(e) => handleCoExhibitorChange(index, e)}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>

                <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
                  担当者部署・役職
                </Grid>
                <Grid item xs={4} sm={4}>
                  <TextField
                    
                    name="departmentPosition"
                    value={coExhibitor.departmentPosition}
                    onChange={(e) => handleCoExhibitorChange(index, e)}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
                  担当者名 <RequiredLabel />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <TextField
                    
                    name="contactPerson"
                    value={coExhibitor.contactPerson}
                    onChange={(e) => handleCoExhibitorChange(index, e)}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>

                <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
                  住所 <RequiredLabel />
                </Grid>
                <Grid item xs={9} sm={9}>
                  <Grid container>
                    <Grid item xs={5} sm={5}>
                      <TextField
                        label={<MultiLangText
                language={language}
                textJa="〒"
                textEn="Postal Code"
                />}
                        name="postalCode"
                        value={coExhibitor.postalCode}
                        onChange={(e) => handleCoExhibitorChange(index, e)}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={0} sm={0.1}></Grid>
                    <Grid item xs={5} sm={5}>
                      <FormControl fullWidth>
                        <InputLabel id="prefecture-label">都道府県</InputLabel>
                        <Select
                          labelId="prefecture-label"
                          id="prefecture"
                          name="prefecture"
                          value={coExhibitor.prefecture}
                          label="都道府県"
                          onChange={(e) => handleCoExhibitorChange(index, e)}
                          required
                        >
                          {prefectures.map((prefecture, index) => (
                            <MenuItem key={index} value={prefecture}>
                              {prefecture}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={2} sm={2} style={{ display: 'flex' }}></Grid>
                <Grid item xs={9} sm={9}>
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="市区町村名（例：大阪市北区）"
                textEn="city"
                />}

                    name="city"
                    value={coExhibitor.city}
                    onChange={(e) => handleCoExhibitorChange(index, e)}
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={2} sm={2} style={{ display: 'flex' }}></Grid>
                <Grid item xs={9} sm={9}>
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="番地・ビル名（例：〇〇１丁目2-3）"
                textEn="Address and Building Name"
                />}
                    name="address"
                    value={coExhibitor.address}
                    onChange={(e) => handleCoExhibitorChange(index, e)}
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
                  メールアドレス <RequiredLabel />
                </Grid>
                <Grid item xs={9} sm={9}>
                  <TextField
                    label="XXXXX@xxxxxx.com"
                    name="email"
                    value={coExhibitor.email}
                    onChange={(e) => handleCoExhibitorChange(index, e)}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>

                <Grid item xs={2} sm={2} style={{ display: 'flex' }}></Grid>
                <Grid item xs={4} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel id="category-label">カテゴリー選択</InputLabel>
                    <Select
                      labelId="category-label"
                      id="category"
                      name="category"
                      value={coExhibitor.category}
                      label="カテゴリー選択"
                      onChange={(e) => handleCoExhibitorChange(index, e)}
                      required
                    >
                      {categories.map((category, index) => (
                        <MenuItem key={index} value={category}>
                          {category}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
                  主な出展製品 <RequiredLabel />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <TextField
                    label="主な出展製品"
                    name="products"
                    value={coExhibitor.products}
                    onChange={(e) => handleCoExhibitorChange(index, e)}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          ))}

          <Grid container spacing={3} justifyContent="center" style={{ marginTop: '16px' }}>
            <Button variant="contained" color="primary" onClick={addCoExhibitor}>
              共同出展者を追加
            </Button>
          </Grid>

          <ContactInfo />
          <Grid item mt={5} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={handleSave} variant="contained" color="primary" style={{ marginRight: 8 }}>
              保存
            </Button>
            <Button type="submit" variant="contained" color="primary" style={{ marginLeft: 8 }}>
              確定
            </Button>
          </Grid>
        </form>
      </Paper>
    </Layout>
  );
};

export default Template008;
