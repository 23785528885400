import React from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../templetes/Layout';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from '@mui/material';

const HomeAdmin: React.FC = () => {
  const navigate = useNavigate();


  const handleDownload = (url: string) => {
    window.location.href = url;
  };


  const rows = [
    {
      eventNumber: '001',
      templateNumber: '001',
      templateName: '1．装飾施工者 登録　※全出展者提出',
      day: '9/6(金)',
      url: 'https://xs525443.xsrv.jp/admin_ui/csv1.php', 
      urllist: 'https://xs525443.xsrv.jp/admin_ui/no_csv1.php', 
    },
    {
      eventNumber: '001',
      templateNumber: '002',
      templateName: '2．装飾・出展物等 確認（小間図面の提出）　※全出展者提出',
      day: '9/6(金)',
      url: 'https://xs525443.xsrv.jp/admin_ui/csv2.php', 
      urllist: 'https://xs525443.xsrv.jp/admin_ui/no_csv2.php', 
    },
    {
      eventNumber: '001',
      templateNumber: '003',
      templateName: '3．火気・危険物等に関する申請　※全出展者提出',
      day: '9/6(金)',
      url: 'https://xs525443.xsrv.jp/admin_ui/csv3.php', 
      urllist: 'https://xs525443.xsrv.jp/admin_ui/no_csv3.php', 
    },
    {
      eventNumber: '001',
      templateNumber: '004',
      templateName: '4．搬入・搬出計画届　※全出展者提出',
      day: '9/6(金)',
      url: 'https://xs525443.xsrv.jp/admin_ui/csv4.php', 
      urllist: 'https://xs525443.xsrv.jp/admin_ui/no_csv4.php', 
    },
    {
      eventNumber: '001',
      templateNumber: '005',
      templateName: '5．電気供給 申込み　※全出展者提出',
      day: '9/6(金)',
      url: 'https://xs525443.xsrv.jp/admin_ui/csv5.php', 
      urllist: 'https://xs525443.xsrv.jp/admin_ui/no_csv5.php', 
    },
    {
      eventNumber: '001',
      templateNumber: '006',
      templateName: '6．出展者プレゼンテーション利用申込み',
      day: '別途ご案内済み',
      url: '', 
      urllist: '', 
    },
    {
      eventNumber: '001',
      templateNumber: '007',
      templateName: '7．公式Web サイトバナー広告・会場案内広告・来場者バッジストラップ広告掲載申込み',
      day: '8/2(金)',
      url: 'https://xs525443.xsrv.jp/admin_ui/csv7.php', 
      urllist: 'https://xs525443.xsrv.jp/admin_ui/no_csv7.php', 
    },
    {
      eventNumber: '001',
      templateNumber: '008',
      templateName: '8．共同出展者登録 申込み',
      day: '別途ご案内済み',
      url: '', 
      urllist: '', 
    },
    {
      eventNumber: '001',
      templateNumber: '009',
      templateName: '9．アトリウムステージ広告 申込み',
      day: '別途ご案内済み',
      url: '', 
      urllist: '', 
    },
    {
      eventNumber: '001',
      templateNumber: '010',
      templateName: '10．保税展示物 申請 申込み',
      day: '8/7(水)',
      url: 'https://xs525443.xsrv.jp/admin_ui/csv10n.php', 
      urllist: 'https://xs525443.xsrv.jp/admin_ui/no_csv10.php', 
    },
    {
      eventNumber: '001',
      templateNumber: '011',
      templateName: '11．会場内広告・小間上空バナー広告 申込み',
      day: '8/30(金)',
      url: 'https://xs525443.xsrv.jp/admin_ui/csv11.php', 
      urllist: 'https://xs525443.xsrv.jp/admin_ui/no_csv11.php', 
    },
    {
      eventNumber: '001',
      templateNumber: '012',
      templateName: '12．床面工事 申請',
      day: '9/6(金)',
      url: 'https://xs525443.xsrv.jp/admin_ui/csv12.php', 
      urllist: 'https://xs525443.xsrv.jp/admin_ui/no_csv12.php', 
    },
    {
      eventNumber: '001',
      templateNumber: '013',
      templateName: '13．小間内電気工事（二次側配線工事）申込み',
      day: '9/6(金)',
      url: 'https://xs525443.xsrv.jp/admin_ui/csv13n.php', 
      urllist: 'https://xs525443.xsrv.jp/admin_ui/no_csv13.php', 
    },
    {
      eventNumber: '001',
      templateNumber: '014',
      templateName: '14．給排水 エア（圧縮空気）工事 申込み',
      day: '9/6(金)',
      url: 'https://xs525443.xsrv.jp/admin_ui/csv14n.php', 
      urllist: 'https://xs525443.xsrv.jp/admin_ui/no_csv14.php', 
    },
    {
      eventNumber: '001',
      templateNumber: '015',
      templateName: '15．通信回線 申込み',
      day: '9/6(金)',
      url: 'https://xs525443.xsrv.jp/admin_ui/csv15n.php', 
      urllist: 'https://xs525443.xsrv.jp/admin_ui/no_csv15.php', 
    },
    {
      eventNumber: '001',
      templateNumber: '016',
      templateName: '16．出展者証・施工者証追加 申込み',
      day: '9/6(金)',
      url: 'https://xs525443.xsrv.jp/admin_ui/csv16.php', 
      urllist: 'https://xs525443.xsrv.jp/admin_ui/no_csv16.php', 
    },
    {
      eventNumber: '001',
      templateNumber: '017',
      templateName: '17．来場案内リーフレット・パブリックデー招待券追加 申込み',
      day: '9/13(金)',
      url: 'https://xs525443.xsrv.jp/admin_ui/csv17.php', 
      urllist: 'https://xs525443.xsrv.jp/admin_ui/no_csv17.php', 
    },
    {
      eventNumber: '001',
      templateNumber: '018',
      templateName: '18．パッケージブース 申込み',
      day: '9/6(金)',
      url: 'https://xs525443.xsrv.jp/admin_ui/csv18.php', 
      urllist: 'https://xs525443.xsrv.jp/admin_ui/no_csv18.php', 
    },
    {
      eventNumber: '001',
      templateNumber: '019',
      templateName: '19．レンタル備品 申込み',
      day: '9/6(金)',
      url: 'https://xs525443.xsrv.jp/admin_ui/csv19n.php', 
      urllist: 'https://xs525443.xsrv.jp/admin_ui/no_csv19.php', 
    },
    {
      eventNumber: '001',
      templateNumber: '020',
      templateName: '20．ストックルーム（出展者用倉庫）設置 申込み',
      day: '9/12(木)',
      url: 'https://xs525443.xsrv.jp/admin_ui/csv20.php', 
      urllist: 'https://xs525443.xsrv.jp/admin_ui/no_csv20.php', 
    },
    {
      eventNumber: '001',
      templateNumber: '021',
      templateName: '21．食品取扱届 申込み',
      day: '9/13(金曜)',
      url: 'https://xs525443.xsrv.jp/admin_ui/csv21n.php', 
      urllist: 'https://xs525443.xsrv.jp/admin_ui/no_csv21.php', 
    },
    {
      eventNumber: '001',
      templateNumber: '022',
      templateName: '22．宿泊予約 申込み',
      day: '9/10(火)',
      url: 'https://xs525443.xsrv.jp/admin_ui/csv22n.php', 
      urllist: 'https://xs525443.xsrv.jp/admin_ui/no_csv22.php', 
    },
    {
      eventNumber: '001',
      templateNumber: '023',
      templateName: '23．ビッグサイトカード 申込み',
      day: '9/18(水)',
      url: 'https://xs525443.xsrv.jp/admin_ui/csv23n.php', 
      urllist: 'https://xs525443.xsrv.jp/admin_ui/no_csv23.php', 
    },
    {
      eventNumber: '001',
      templateNumber: '024',
      templateName: '24．小間内清掃 申込み',
      day: '10/11(金)',
      url: 'https://xs525443.xsrv.jp/admin_ui/csv24n.php', 
      urllist: 'https://xs525443.xsrv.jp/admin_ui/no_csv24.php', 
    },
    {
      eventNumber: '001',
      templateNumber: '025',
      templateName: '25．入場証リーダー端末申込み',
      day: '9/20(金)',
      url: 'https://xs525443.xsrv.jp/admin_ui/csv25n.php', 
      urllist: 'https://xs525443.xsrv.jp/admin_ui/no_csv25.php', 
    },
    {
      eventNumber: '001',
      templateNumber: '026',
      templateName: '26．資料ダウンロード（マニュアル・小間割・ビット図等）',
      day: 'ー',
      url: 'https://xs525443.xsrv.jp/admin_ui/csv26n.php', 
      urllist: 'https://xs525443.xsrv.jp/admin_ui/no_csv26.php', 
    },
    // 他のデータ...
  ];

  const getStatusColor = (status: string) => {
    return status === '確定' ? 'blue' : 'red';
  };

  return (
    <Layout title="Home Admin">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={{ width: '120px' }}>イベント番号</TableCell>
              <TableCell align="left" sx={{ width: '100px' }}>申請No.</TableCell>
              <TableCell align="left" sx={{ width: 'auto' }}>申請内容・申込み期限</TableCell>
              <TableCell align="left" sx={{ width: '150px' }}>申請期限の日付</TableCell>
              <TableCell align="left" sx={{ width: '120px' }}>未入力リスト</TableCell>
              <TableCell align="center" sx={{ width: '150px' }}>ダウンロード</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row" align="left" sx={{ width: '120px' }}>
                  {row.eventNumber}
                </TableCell>
                <TableCell align="left" sx={{ width: '100px' }}>{row.templateNumber}</TableCell>
                <TableCell align="left" sx={{ width: 'auto' }}>{row.templateName}</TableCell>
                <TableCell align="center" sx={{ width: '150px', color: getStatusColor(row.day) }}>
                  {row.day}
                </TableCell>
                <TableCell align="center" sx={{ width: '120px' }}>
                  <Button
                    variant="contained"
                    onClick={() => handleDownload(row.urllist)}
                    disabled={row.templateNumber === '006' || row.templateNumber === '008' || row.templateNumber === '009'}
                    sx={{ backgroundColor: '#3c71ac', '&:hover': { backgroundColor: '#325c85' }}}
                  >
                    Download
                  </Button>
                </TableCell>
                <TableCell align="center" sx={{ width: '150px' }}>
                  <Button
                    variant="contained"
                    onClick={() => handleDownload(row.url)}
                    disabled={row.templateNumber === '006' || row.templateNumber === '008' || row.templateNumber === '009'}
                    sx={{ backgroundColor: '#3c71ac', '&:hover': { backgroundColor: '#325c85' }}}
                  >
                    Download
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Layout>
  );
};

export default HomeAdmin;
