import React, { useState, useEffect, useRef } from 'react';
import Layout from '../templetes/Layout';
import {
  TextField, Button, Typography, Paper, Grid, FormControlLabel, Checkbox, Select, MenuItem, InputLabel, FormControl
} from '@mui/material';
import ExhibitorInfoForm from '../templetes/ExhibitorInfoForm';
import { SelectChangeEvent } from '@mui/material';
import { Radio, RadioGroup } from '@mui/material';
import RequiredLabel from '../templetes/RequiredLabel';
import ContactInfo2 from '../templetes/ContactInfo2';
import MultiLangText from '../templetes/MultiLangText';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';

const Template012: React.FC = () => {
  const { language } = useAuth();
  const isJapanese = language === 'ja';
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<string>('');

  const [formData, setFormData] = useState({
    exhibitorName: '',
    boothNumber: '',
    tel: '',
    departmentPosition: '',
    contactPerson: '',
    postalCode: '',
    prefecture: '',
    city: '',
    address: '',
    email: '',
    Company_ID: '',
    decoration3mm: '',
    firstHour: '',
    firstMinute: '',
    secondHour: '',
    secondMinute: '',
    thirdHour: '',
    thirdMinute: '',
    textArea: '',
    constructionCompanyName: '',
    constructionAddressPostal: '',
    constructionAddressPrefecture: '',
    constructionAddressCity: '',
    constructionAddressDetail: '',
    constructionContactMobile: '',
    constructionContactTel: '',
    constructionContactPosition: '',
    constructionContactName: '',
    constructionContactEmail: '',
    anchorDiameter: '',
    anchorLength: '',
    constructionLocation: '',
    constructionLocationCount: '',
    totalAnchors: '',
    billingName: '',
    billingMobile: '',
    billingTel: '',
    billingDepartmentPosition: '',
    billingContactPerson: '',
    billingPostalCode: '',
    billingPrefecture: '',
    billingCity: '',
    billingAddress: '',
    billingEmail: '',
    buttonState: {
      reset: false,  // リセットボタンの状態
      save: false,   // 保存ボタンの状態
      submit: false  // 確定ボタンの状態
    }
  });

  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    const savedData = localStorage.getItem('formData012');
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
    setIsSubmitted(false); // isSubmittedの初期化
    setIsDuplicate(false); // isDuplicateの初期化
  }, []);

  const checkDuplicate = async () => {
    try {
      const response = await fetch('https://xs525443.xsrv.jp/admin_ui/check012.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          exhibitorName: formData.exhibitorName,
          Company_ID: formData.Company_ID
        }),
      });

      const result = await response.json();
      if (result.isDuplicate && result.additionalData) {
        const parsedData = JSON.parse(result.additionalData);
        setFormData(prevState => ({
          ...prevState,
          ...parsedData,
        }));
        setIsDuplicate(true);
        setIsReadOnly(true);
      } else {
        setIsDuplicate(false);
      }
    } catch (error) {
      console.error('重複チェック中にエラーが発生しました:', error);
    }
  };

  useEffect(() => {
    const loadButtonState = async () => {
      try {
        const timestamp = new Date().getTime();
        const response = await axios.get(`/json/buttonState12.json?_=${timestamp}`);  // 外部JSONのパスを指定
        const buttonStateData = response.data;
        setFormData(prevState => ({
          ...prevState,
          buttonState: buttonStateData.buttonState
        }));
        setIsReadOnly(buttonStateData.buttonState.disabled);
      } catch (error) {
        console.error('ボタン状態の読み込み中にエラーが発生しました:', error);
      }
    };
  
    loadButtonState();  // コンポーネントの初回レンダリング時に実行
  }, []);

  useEffect(() => {
    if (formData.exhibitorName) {
      checkDuplicate();
    }
  }, [formData.exhibitorName]);

  const handleSave = () => {
    localStorage.setItem('formData012', JSON.stringify(formData));
    const alertMessage = language === 'ja' ? 'データを保存しました。' : 'Save Completed';
  alert(alertMessage);
  };

  
  const handleReset = async () => {
  const confirmMessage = language === 'ja' ? '本当に削除していいですか？' : 'Are you sure you want to reset?';
  const resetSuccessMessage = language === 'ja' ? 'フォームとファイルがリセットされました。' : 'Reset Completed';
  const deleteErrorMessage = language === 'ja' ? 'データの削除に失敗しました。' : 'Failed to delete data.';
    if (window.confirm(confirmMessage)) {
        try {
            const response = await fetch('https://xs525443.xsrv.jp/admin_ui/delete012.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
          exhibitorName: formData.exhibitorName,
          Company_ID: formData.Company_ID
        }),
            });

            const result = await response.json();

            if (result.success) {
                setFormData(prevState => ({
                    ...prevState,
                    decoration3mm: '',
                    firstHour: '',
                    firstMinute: '',
                    secondHour: '',
                    secondMinute: '',
                    thirdHour: '',
                    thirdMinute: '',
                    textArea: '',
                    constructionCompanyName: '',
                    constructionAddressPostal: '',
                    constructionAddressPrefecture: '',
                    constructionAddressCity: '',
                    constructionAddressDetail: '',
                    constructionContactMobile: '',
                    constructionContactTel: '',
                    constructionContactPosition: '',
                    constructionContactName: '',
                    constructionContactEmail: '',
                    anchorDiameter: '',
                    anchorLength: '',
                    constructionLocation: '',
                    constructionLocationCount: '',
                    totalAnchors: '',
                    billingName: '',
                    billingMobile: '',
                    billingTel: '',
                    billingDepartmentPosition: '',
                    billingContactPerson: '',
                    billingPostalCode: '',
                    billingPrefecture: '',
                    billingCity: '',
                    billingAddress: '',
                    billingEmail: ''
                }));
                localStorage.removeItem('formData012');
                setSelectedFile(null);
                setFileName('');
                setIsReadOnly(false);
                setIsSubmitted(false);
                alert(resetSuccessMessage);
                checkDuplicate(); // 再度重複チェックを実行
            } else {
                alert('削除に失敗しました: ' + result.message);
            }
        } catch (error) {
            console.error('削除中にエラーが発生しました:', error);
            alert('削除に失敗しました。');
        }
    }
};

  
  
  
  

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formDataToSend = new FormData();

    const jsonData = {
      ...formData,
      filePath: selectedFile ? '012up/' + selectedFile.name : undefined
    };

    formDataToSend.append('jsonData', JSON.stringify(jsonData));

    if (selectedFile) {
      formDataToSend.append('file', selectedFile);
    }

    try {
      const response = await fetch('https://xs525443.xsrv.jp/admin_ui/submit012.php', {
        method: 'POST',
        body: formDataToSend
      });

      const successMessage = language === 'ja' ? 'データが正常に送信されました。' : 'Submission Completed';
    const failureMessage = language === 'ja' ? 'データの送信に失敗しました: ' : 'Failed to submit data: ';
    const errorMessage = language === 'ja' ? 'データの送信に失敗しました。' : 'Failed to submit data.';

      if (response.ok) {
       // alert(successMessage);
        setIsSubmitted(true);
        setIsReadOnly(true);
      } else {
        alert('データの送信中にエラーが発生しました。');
      }
    } catch (error) {
      console.error('データ送信エラー:', error);
      alert('データの送信中にエラーが発生しました。');
    }
  };

  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
    const { name, value } = e.target;
  
    if (e.target instanceof HTMLInputElement && (name === 'constructionAddressPostal' || name === 'billingPostalCode')) {
      handlePostalCodeChange(e as React.ChangeEvent<HTMLInputElement>);
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };
  
  const handlePostalCodeChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const cleanedValue = value.replace(/-/g, ''); // ハイフンを除去
  
    setFormData(prevState => ({
      ...prevState,
      [name]: cleanedValue
    }));
  
    if (cleanedValue.length === 7) {
      try {
        const response = await fetch(`https://api.zipaddress.net/?zipcode=${cleanedValue}`);
        const data = await response.json();
        if (data.code === 200) {
          const addressFields = name === 'constructionAddressPostal' 
            ? {
                constructionAddressPrefecture: data.data.pref,
                constructionAddressCity: data.data.city,
                constructionAddressDetail: data.data.town
              }
            : {
                billingPrefecture: data.data.pref,
                billingCity: data.data.city,
                billingAddress: data.data.town
              };
  
          setFormData(prevState => ({
            ...prevState,
            ...addressFields
          }));
        } else {
          alert('郵便番号から住所を取得できませんでした。');
        }
      } catch (error) {
        console.error('郵便番号から住所を取得中にエラーが発生しました:', error);
      }
    }
  };
  
  

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  // ファイル選択ボタンのクリックイベント
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // ファイル選択時の処理
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      const timestamp = Math.floor(Date.now() / 1000);
      const newFile = new File([file], `${timestamp}_${file.name}`, { type: file.type });
      setSelectedFile(newFile);
      setFileName(newFile.name); // ファイル名を設定
    }
  };
  

  const prefectures = ["北海道", "青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県", "茨城県", "栃木県", "群馬県", "埼玉県", "千葉県", "東京都", "神奈川県", "新潟県", "富山県", "石川県", "福井県", "山梨県", "長野県", "岐阜県", "静岡県", "愛知県", "三重県", "滋賀県", "京都府", "大阪府", "兵庫県", "奈良県", "和歌山県", "鳥取県", "島根県", "岡山県", "広島県", "山口県", "徳島県", "香川県", "愛媛県", "高知県", "福岡県", "佐賀県", "長崎県", "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県"];
  const hours = ['ー', ...Array.from({ length: 11 }, (_, i) => (i + 9).toString().padStart(2, '0'))];
  const minutes = ['ー', '00', '30'];
  

  return (
    <Layout title={<MultiLangText
      language={language}
      textJa="申請書類管理　12．床面工事 申請"
      textEn="Application Forms 12.Application for Floor Work (Anchoring)"
    />}>
      <Paper style={{ padding: 16 }}>
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          <MultiLangText
            language={language}
            textJa="■出展者情報"
            textEn="■Exhibitor information"
          />
        </Typography>
        <hr />
        <form onSubmit={handleSubmit}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault(); // Enterキーによるフォーム送信を防ぐ
             // アラートを表示
          }
        }}>
          <ExhibitorInfoForm
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
          />

          <br></br><br></br>
          {/* セクションスタート */}
          <Typography gutterBottom style={{ fontWeight: 'bold' }}>
            <MultiLangText
              language={language}
              textJa="■工事会社情報"
              textEn="Contractor's Information　※Company of anchoring work"
            />
          </Typography>
          <hr></hr>
          <Grid container spacing={2} alignItems="center">

            {/* カラム */}
            <Grid item xs={3} sm={3} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="装飾・工事会社名　"
                textEn="Company Name "
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>
              <TextField
                name="constructionCompanyName"
                value={formData.constructionCompanyName}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
                disabled={isReadOnly}
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="住所"
                textEn="Address"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>

              <Grid container>
                <Grid item xs={5} sm={5}>
                  <TextField
                    label={<MultiLangText
                      language={language}
                      textJa="〒"
                      textEn="Postal Code"
                    />}
                    name="constructionAddressPostal"
                    value={formData.constructionAddressPostal}
                    onChange={handleChange}
                    fullWidth
                    required
                    disabled={isReadOnly}
                  />
                </Grid>

                <Grid item xs={0} sm={0.1}></Grid>

                <Grid item xs={5} sm={5}>
                  {isJapanese && (
                    <FormControl fullWidth>
                      <InputLabel id="construction-prefecture-label">都道府県</InputLabel>
                      <Select
                        labelId="construction-prefecture-label"
                        id="construction-prefecture"
                        name="constructionAddressPrefecture"
                        value={formData.constructionAddressPrefecture}
                        label="都道府県"
                        onChange={handleChange}
                        required
                        disabled={isReadOnly}
                      >
                        {prefectures.map((prefecture, index) => (
                          <MenuItem key={index} value={prefecture}>{prefecture}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>

              </Grid>
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={9} sm={9}>
              <TextField
                label={<MultiLangText
                language={language}
                textJa="市区町村名（例：大阪市北区）"
                textEn="city"
                />}

                name="constructionAddressCity"
                value={formData.constructionAddressCity}
                onChange={handleChange}
                fullWidth
                required
                disabled={isReadOnly}
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={9} sm={9}>
              <TextField
                label={<MultiLangText
                language={language}
                textJa="番地・ビル名（例：〇〇１丁目2-3）"
                textEn="Address and Building Name"
                />}
                name="constructionAddressDetail"
                value={formData.constructionAddressDetail}
                onChange={handleChange}
                fullWidth
                required
                disabled={isReadOnly}
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="担当者携帯電話番号"
                textEn="Mobile Phone Number"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                name="constructionContactMobile"
                value={formData.constructionContactMobile}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              TEL　<RequiredLabel />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                name="constructionContactTel"
                value={formData.constructionContactTel}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
                disabled={isReadOnly}
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="担当者部署・役職"
                textEn="Title / Department of the Contact Person"
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                name="constructionContactPosition"
                value={formData.constructionContactPosition}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="担当者名"
                textEn="Name of Contact Person"
              />　<RequiredLabel />

            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                name="constructionContactName"
                value={formData.constructionContactName}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="メールアドレス"
                textEn="E-mail"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>
              <TextField
                label="XXXXX@xxxxxx.com"
                name="constructionContactEmail"
                value={formData.constructionContactEmail}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
                disabled={isReadOnly}
              />
            </Grid>


          </Grid>
          {/* セクションここまで */}
          <br></br><br></br>
          {/* セクションスタート */}
          <Typography gutterBottom style={{ fontWeight: 'bold' }}>
            <MultiLangText
              language={language}
              textJa="■ 工事内容について　（打設位置・本数を明記し、縮尺の分かる図面を添付して下さい）"
              textEn="■Details of the anchoring work　Upload the booth layout plan to scale with the location and the numbers of  anchor bolts."
            />
          </Typography>
          <hr></hr>
          <Grid container spacing={3} alignItems="center">

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="①工事日　"
                textEn="①Working date & time"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={3.3} sm={3.3} style={{ display: 'flex', alignItems: 'center' }}>
              <MultiLangText
                language={language}
                textJa="10月13日（日）"
                textEn="October 13 (Sun.)"
              />
              <FormControl style={{ marginLeft: 0 }}>
                <InputLabel id="first-hour-label"><MultiLangText
                  language={language}
                  textJa="時"
                  textEn=""
                /></InputLabel>
                <Select
                  labelId="first-hour-label"
                  id="first-hour"
                  name="firstHour"
                  value={formData.firstHour}
                  label="時"
                  onChange={handleChange}
                  style={{ width: 80 }}
                  disabled={isReadOnly}
                >
                  {hours.map(hour => (
                    <MenuItem key={hour} value={hour}>{hour}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              ：
              <FormControl style={{ marginLeft: 0 }}>
                <InputLabel id="first-minute-label"><MultiLangText
                  language={language}
                  textJa="分"
                  textEn=""
                /></InputLabel>
                <Select
                  labelId="first-minute-label"
                  id="first-minute"
                  name="firstMinute"
                  value={formData.firstMinute}
                  label="分"
                  onChange={handleChange}
                  style={{ width: 80 }}
                  disabled={isReadOnly}
                >
                  {minutes.map(minute => (
                    <MenuItem key={minute} value={minute}>{minute}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3.3} sm={3.3} style={{ display: 'flex', alignItems: 'center' }}>
              <MultiLangText
                language={language}
                textJa="10月14日（月）"
                textEn="October 14 (Mon.)"
              />
              <FormControl style={{ marginLeft: 0 }}>
                <InputLabel id="second-hour-label"><MultiLangText
                  language={language}
                  textJa="時"
                  textEn=""
                /></InputLabel>
                <Select
                  labelId="second-hour-label"
                  id="second-hour"
                  name="secondHour"
                  value={formData.secondHour}
                  label="時"
                  onChange={handleChange}
                  style={{ width: 80 }}
                  disabled={isReadOnly}
                >
                  {hours.map(hour => (
                    <MenuItem key={hour} value={hour}>{hour}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              ：
              <FormControl style={{ marginLeft: 0 }}>
                <InputLabel id="second-minute-label"><MultiLangText
                  language={language}
                  textJa="分"
                  textEn=""
                /></InputLabel>
                <Select
                  labelId="second-minute-label"
                  id="second-minute"
                  name="secondMinute"
                  value={formData.secondMinute}
                  label="分"
                  onChange={handleChange}
                  style={{ width: 80 }}
                  disabled={isReadOnly}
                >
                  {minutes.map(minute => (
                    <MenuItem key={minute} value={minute}>{minute}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3.3} sm={3.3} style={{ display: 'flex', alignItems: 'center' }}>
              <MultiLangText
                language={language}
                textJa="10月15日（火）"
                textEn="October 15 (Tue.)"
              />
              <FormControl style={{ marginLeft: 0 }}>
                <InputLabel id="third-hour-label"><MultiLangText
                  language={language}
                  textJa="時"
                  textEn=""
                /></InputLabel>
                <Select
                  labelId="third-hour-label"
                  id="third-hour"
                  name="thirdHour"
                  value={formData.thirdHour}
                  label="時"
                  onChange={handleChange}
                  style={{ width: 80 }}
                  disabled={isReadOnly}
                >
                  {hours.map(hour => (
                    <MenuItem key={hour} value={hour}>{hour}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              ：
              <FormControl style={{ marginLeft: 0 }}>
                <InputLabel id="third-minute-label"><MultiLangText
                  language={language}
                  textJa="分"
                  textEn=""
                /></InputLabel>
                <Select
                  labelId="third-minute-label"
                  id="third-minute"
                  name="thirdMinute"
                  value={formData.thirdMinute}
                  label="分"
                  onChange={handleChange}
                  style={{ width: 80 }}
                  disabled={isReadOnly}
                >
                  {minutes.map(minute => (
                    <MenuItem key={minute} value={minute}>{minute}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="アンカー詳細　"
                textEn="Details of anchor bolt "
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={10} sm={10}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={6} sm={6} style={{ display: 'flex', alignItems: 'center' }}>
                  <MultiLangText
                    language={language}
                    textJa="太さ（直径）"
                    textEn="diameter"
                  />　
                  <TextField
                    label=""
                    name="anchorDiameter"
                    value={formData.anchorDiameter}
                    onChange={handleChange}
                    sx={{ width: 70 }}
                    margin="normal"
                    required
                    disabled={isReadOnly}
                  />　mm　
                </Grid>
                <Grid item xs={6} sm={6} style={{ display: 'flex', alignItems: 'center' }}>
                  <MultiLangText
                    language={language}
                    textJa="長さ"
                    textEn="length"
                  />　
                  <TextField
                    label=""
                    name="anchorLength"
                    value={formData.anchorLength}
                    onChange={handleChange}
                    sx={{ width: 70 }}
                    margin="normal"
                    required
                    disabled={isReadOnly}
                  />　mm　
                </Grid>
              </Grid>
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="③工事詳細　"
                textEn="Working Details "
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={10} sm={10}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={4} sm={4} style={{ display: 'flex', alignItems: 'center' }}>
                  <MultiLangText
                    language={language}
                    textJa="打設箇所"
                    textEn="Location(s) of anchor bolt(s)"
                  />　
                  <TextField
                    label=""
                    name="constructionLocation"
                    value={formData.constructionLocation}
                    onChange={handleChange}
                    sx={{ width: 70 }}
                    margin="normal"
                    required
                    disabled={isReadOnly}
                  />　<MultiLangText
                    language={language}
                    textJa="箇所"
                    textEn="location(s)"
                  />　
                </Grid>
                <Grid item xs={4} sm={4} style={{ display: 'flex', alignItems: 'center' }}>
                  <MultiLangText
                    language={language}
                    textJa="１か所の打設本数"
                    textEn="Number of the bolt(s) for one location"
                  />　
                  <TextField
                    label=""
                    name="constructionLocationCount"
                    value={formData.constructionLocationCount}
                    onChange={handleChange}
                    sx={{ width: 70 }}
                    margin="normal"
                    required
                    disabled={isReadOnly}
                  />　<MultiLangText
                    language={language}
                    textJa="本"
                    textEn="bolt(s)"
                  />　
                </Grid>
                <Grid item xs={4} sm={4} style={{ display: 'flex', alignItems: 'center' }}>
                  <MultiLangText
                    language={language}
                    textJa="総打設本数"
                    textEn="Total number of anchor bolt(s)"
                  />　
                  <TextField
                    label=""
                    name="totalAnchors"
                    value={formData.totalAnchors}
                    onChange={handleChange}
                    sx={{ width: 70 }}
                    margin="normal"
                    required
                    disabled={isReadOnly}
                  />　<MultiLangText
                    language={language}
                    textJa="本"
                    textEn="bolt(s)"
                  />　
                </Grid>
              </Grid>
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="備考"
                textEn="Remarks"
              />　
            </Grid>
            <Grid item xs={10} sm={10}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} sm={12} style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    label=""
                    name="textArea"
                    value={formData.textArea}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    multiline
                    rows={4} // 行数を指定
                    variant="outlined"
                    disabled={isReadOnly}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={10} sm={10}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} sm={12} style={{ display: 'flex', alignItems: 'center' }}>
                  <MultiLangText
                    language={language}
                    textJa="
                 ※打設出来るアンカーボルトは太さΦ16mm 以下、埋め込み長さ60mm 以下の芯棒打ち込み式オールアンカーに限ります。<br />
                  ※ピット及び、その周辺部（ピット端部から200mm まで）へのアンカーボルト打設は出来ません。<br />
                  ※事前申請なしで打設した場合、床補修費負担金とは別に、未申請違反金が請求されますのでご注意下さい。<br />
                  ※アンカーボルト打設１本につき¥1,100（税込）の床補修費負担金を請求致します。<br /><br />
                  ※アンカーの打設位置を示した図面をアップロードして下さい。
                "
                    textEn="
                *Anchor bolts are  restricted to strike anchors with a diameter of 16mm or less and shield depth of 60mm or less .<br />
                *You may not affix anchor bolts to pits or an area with 200mm of the edge of a pit lid.<br />
                *Please note that if you installed anchor bolt(s) without prior application, a non-application violation fee will be charged in addition to floor repair burden charge.<br />
                *JPY1,100(Tax Included) per one bolt is charged as floor repair burden charge.<br />
                <br />
                *Upload the booth layout plan to scale with the location and the numbers of  anchor bolts.
                "
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={10} sm={10}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={4} sm={4}>
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    accept="image/*,application/pdf,application/zip"
                  />
                  <Button variant="contained" onClick={handleButtonClick} disabled={isReadOnly}>
                    <MultiLangText
                      language={language}
                      textJa="ファイルを選択"
                      textEn="Select a file"
                    />
                  </Button>
                  {fileName && <Typography variant="body2">{fileName}</Typography>}
                </Grid>
                <Grid item xs={8} sm={8}>
                  <MultiLangText
                    language={language}
                    textJa="
                アップロードが可能なサイズ容量は10MBまでとなります。<br/>
              複数のデータがある場合は１つに統合してください。<br/>
              (zip、PDF、jpg、png)
                "
                    textEn="
                The maximum upload size is 10MB.<br/>
                If you have multiple files, please combine them into one.<br/>
                (zip、PDF、jpg、png)
                "
                  />
                </Grid>
              </Grid>
            </Grid>

          </Grid>
          {/* セクションここまで */}
          <br></br><br></br>
          {/* セクションスタート */}
          <Typography gutterBottom style={{ fontWeight: 'bold' }}>
            <MultiLangText
              language={language}
              textJa="■　床補修費負担金について"
              textEn="■ Invoice Recipient 　※Billing information of the repair fee"
            />
          </Typography>
          <hr></hr>
          <Grid container spacing={2} alignItems="center">

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="お選びください"
                textEn="Please select"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>
              <RadioGroup
                row
                aria-label="decoration"
                name="decoration3mm"
                value={formData.decoration3mm}
                onChange={(e) => setFormData({ ...formData, decoration3mm: e.target.value })}
              >
                <Grid item xs={3} sm={3}>
                  <FormControlLabel value="出展者" control={<Radio required />} label={<MultiLangText
                    language={language}
                    textJa="出展者"
                    textEn="Exhibitor"
                  />} disabled={isReadOnly} />
                </Grid>
                <Grid item xs={3} sm={3}>
                  <FormControlLabel value="上記工事会社" control={<Radio required />} label={<MultiLangText
                    language={language}
                    textJa="上記工事会社"
                    textEn="Company of anchoring work"
                  />} disabled={isReadOnly} />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <FormControlLabel value="その他（下記へご記入下さい）" control={<Radio required />} label={<MultiLangText
                    language={language}
                    textJa="その他（下記にご記入ください）"
                    textEn="Others (input necessary information)"
                  />} disabled={isReadOnly} />
                </Grid>
              </RadioGroup>
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="■請求先社名"
                textEn="Invoice recipient"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>
              <TextField
                name="billingName"
                value={formData.billingName}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="担当者携帯電話番号"
                textEn="Mobile Phone Number"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                name="billingMobile"
                value={formData.billingMobile}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              TEL　<RequiredLabel />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                name="billingTel"
                value={formData.billingTel}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="担当者部署・役職"
                textEn="Title / Department of the Contact Person"
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                name="billingDepartmentPosition"
                value={formData.billingDepartmentPosition}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="担当者名"
                textEn="Name of Contact Person"
              />　<RequiredLabel />

            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                name="billingContactPerson"
                value={formData.billingContactPerson}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="住所"
                textEn="Address"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>

              <Grid container>
                <Grid item xs={5} sm={5}>
                  <TextField
                    label={<MultiLangText
                      language={language}
                      textJa="〒"
                      textEn="Postal Code"
                    />}
                    name="billingPostalCode"
                    value={formData.billingPostalCode}
                    onChange={handleChange}
                    fullWidth
                    disabled={isReadOnly}
                  />
                </Grid>

                <Grid item xs={0} sm={0.1}></Grid>

                <Grid item xs={5} sm={5}>
                  {isJapanese && (
                    <FormControl fullWidth>
                      <InputLabel id="billing-prefecture-label">都道府県</InputLabel>
                      <Select
                        labelId="billing-prefecture-label"
                        id="billing-prefecture"
                        name="billingPrefecture"
                        value={formData.billingPrefecture}
                        label="都道府県"
                        onChange={handleChange}
                        disabled={isReadOnly}
                      >
                        {prefectures.map((prefecture, index) => (
                          <MenuItem key={index} value={prefecture}>{prefecture}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>

              </Grid>
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={9} sm={9}>
              <TextField
                label={<MultiLangText
                language={language}
                textJa="市区町村名（例：大阪市北区）"
                textEn="city"
                />}

                name="billingCity"
                value={formData.billingCity}
                onChange={handleChange}
                fullWidth
                disabled={isReadOnly}
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={9} sm={9}>
              <TextField
                label={<MultiLangText
                language={language}
                textJa="番地・ビル名（例：〇〇１丁目2-3）"
                textEn="Address and Building Name"
                />}
                name="billingAddress"
                value={formData.billingAddress}
                onChange={handleChange}
                fullWidth
                disabled={isReadOnly}
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="メールアドレス"
                textEn="E-mail"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>
              <TextField
                label="XXXXX@xxxxxx.com"
                name="billingEmail"
                value={formData.billingEmail}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={9} sm={9}>
              <MultiLangText
                language={language}
                textJa="※床補修負担金は会期終了後事務局より請求させていただきます。"
                textEn="※The Management Office charges floor repair burden charge after the show."
              />
              <MultiLangText
                language={language}
                textJa="※上記の請求先が出展者、上記工事会社と異なる場合に記入してください。"
                textEn="※Please fill in above if the billing information is different from the exhibitor."
                />
            </Grid>

            <ContactInfo2 />
            {/* カラム */}
            <Grid item xs={12} sm={12} style={{ display: 'flex'}}>
              <MultiLangText
                language={language}
                textJa="
※下記、「確定」ボタンを押して、申請してください。「保存」中は修正が可能です。<br/>
              ※「リセット」ボタンがある申請書については、万一、確定後に修正が必要な場合も、内容の修正が可能です。
"
                textEn='
*Please click the "SUBMIT" button to submit your application. You can "SAVE” the form while you still need to modify it.<br/>
*Some forms have “RESET” button, which you can use when you need to reset and fill in the form again after submitting the form.
'
              />
            </Grid>
          </Grid>
          {/* セクションここまで */}
        <Grid item mt={5} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
                onClick={handleReset}
                variant="contained"
                color="secondary"
                disabled={formData.buttonState.reset}  // JSONで制御
                sx={{ filter: formData.buttonState.reset ? 'grayscale(1)' : 'none' }}  // グレーアウト
                style={{ marginRight: 8 }}
              >
                <MultiLangText
                  language={language}
                  textJa="リセット"
                  textEn="Reset"
                />
          </Button>

          <Button
                onClick={handleSave}
                variant="contained"
                color="primary"
                disabled={formData.buttonState.save}  // JSONで制御
                sx={{ filter: formData.buttonState.save ? 'grayscale(1)' : 'none' }}  // グレーアウト
                style={{ marginRight: 8 }}
              >
                <MultiLangText
                  language={language}
                  textJa="保存"
                  textEn="Save"
                />
          </Button>

          <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={formData.buttonState.submit || isDuplicate || isSubmitted}  // JSONで制御 + 重複や送信済みの場合
                sx={{ filter: (formData.buttonState.submit || isDuplicate || isSubmitted) ? 'grayscale(1)' : 'none' }}  // グレーアウト
              >
                <MultiLangText
                  language={language}
                  textJa="確定"
                  textEn="Submit"
                />
              </Button>

          </Grid>

        </form>
      </Paper>
    </Layout>
  );
};

export default Template012;
