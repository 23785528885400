import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Layout from '../templetes/Layout';
import { TextField, Button, Typography, Paper, Grid, FormControlLabel, Select, MenuItem, InputLabel, FormControl, Radio, RadioGroup, Box } from '@mui/material';
import RequiredLabel from '../templetes/RequiredLabel'; 
import ExhibitorInfoForm from '../templetes/ExhibitorInfoForm';
import ContactInfo2 from '../templetes/ContactInfo2';
import { SelectChangeEvent } from '@mui/material';
import MultiLangText from '../templetes/MultiLangText';
import { useAuth } from '../../context/AuthContext';

const Template002: React.FC = () => {
  const initialFormData = {
    exhibitorName: '',
    boothNumber: '',
    tel: '',
    departmentPosition: '',
    contactPerson: '',
    postalCode: '',
    prefecture: '',
    city: '',
    address: '',
    email: '',
    jsonData: '', // JSON用フィールドを追加
    Company_ID: '',
    // 追加部分
    decorationHeight1: 'なし',
    decorationHeight3: 'なし',
    structureArea1: '',
    structureAreaTotal1: '',
    decorationHeight2: 'なし',
    structureArea2: '',
    structureAreaTotal2: '',
    balloonInstallation: 'なし',
    structureArea3: '',
    structureAreaTotal3: '',
    exhibitorProductName1: '',
    weight1: '',
    unitMm1_1: '',
    unitMm1_2: '',
    unitMm1_3: '',
    installationMethod1: '',
    exhibitorProductName2: '',
    weight2: '',
    unitMm2_1: '',
    unitMm2_2: '',
    unitMm2_3: '',
    installationMethod2: '',
    exhibitorProductName3: '',
    weight3: '',
    unitMm3_1: '',
    unitMm3_2: '',
    unitMm3_3: '',
    installationMethod3: '',
    exhibitorProductName4: '',
    weight4: '',
    unitMm4_1: '',
    unitMm4_2: '',
    unitMm4_3: '',
    installationMethod4: '',
    exhibitorProductName5: '',
    weight5: '',
    unitMm5_1: '',
    unitMm5_2: '',
    unitMm5_3: '',
    installationMethod5: '',
    exhibitorProductName6: '',
    weight6: '',
    unitMm6_1: '',
    unitMm6_2: '',
    unitMm6_3: '',
    installationMethod6: '',
    exhibitorProductName7: '',
    weight7: '',
    unitMm7_1: '',
    unitMm7_2: '',
    unitMm7_3: '',
    installationMethod7: '',
    decorationHeight1_1: 'なし',
    decorationHeight1_2: 'なし',
    buttonState: {
      reset: false,  // リセットボタンの状態
      save: false,   // 保存ボタンの状態
      submit: false  // 確定ボタンの状態
    }
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { language } = useAuth();
  const fileInputRef1 = useRef<HTMLInputElement>(null);


  useEffect(() => {
    const loadButtonState = async () => {
      try {
        const timestamp = new Date().getTime();
        const response = await axios.get(`/json/buttonState2.json?_=${timestamp}`);  // 外部JSONのパスを指定
        const buttonStateData = response.data;
        setFormData(prevState => ({
          ...prevState,
          buttonState: buttonStateData.buttonState
        }));
        setIsReadOnly(buttonStateData.buttonState.disabled);
      } catch (error) {
        console.error('ボタン状態の読み込み中にエラーが発生しました:', error);
      }
    };
  
    loadButtonState();  // コンポーネントの初回レンダリング時に実行
  }, []);
  
  const checkDuplicate = async () => {
    try {
      const response = await fetch('https://xs525443.xsrv.jp/admin_ui/check02.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          exhibitorName: formData.exhibitorName,
          Company_ID: formData.Company_ID
        }),
      });
  
      const result = await response.json();
      setIsDuplicate(result.isDuplicate);
  
      if (result.isDuplicate && result.jsonData) {
        const parsedJsonData = JSON.parse(result.jsonData);
        setFormData(prevState => ({
          ...prevState,
          ...parsedJsonData,
        }));
        setIsReadOnly(true); // 重複がある場合、フォームを読み取り専用にする
      }
    } catch (error) {
      console.error('重複チェック中にエラーが発生しました:', error);
    }
  };
  
  

  useEffect(() => {
    const savedData = localStorage.getItem('template002Data');
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      console.log('Loaded data from localStorage:', parsedData); 
      setFormData(parsedData);
    }
  }, []);

  useEffect(() => {
    if (formData.exhibitorName) {
      checkDuplicate();
    }
  }, [formData.exhibitorName]);

  useEffect(() => {
    const fetchData = async () => {
      if (formData.exhibitorName) {
        await checkDuplicate();
      }
    };
    fetchData();
  }, []);

  
  

  const handleSave = () => {
    const decoratorData = {
      decorationHeight1: formData.decorationHeight1,
      decorationHeight3: formData.decorationHeight3,
      structureArea1: formData.structureArea1,
      structureAreaTotal1: formData.structureAreaTotal1,
      decorationHeight2: formData.decorationHeight2,
      structureArea2: formData.structureArea2,
      structureAreaTotal2: formData.structureAreaTotal2,
      balloonInstallation: formData.balloonInstallation,
      structureArea3: formData.structureArea3,
      structureAreaTotal3: formData.structureAreaTotal3,
      exhibitorProductName1: formData.exhibitorProductName1,
      weight1: formData.weight1,
      unitMm1_1: formData.unitMm1_1,
      unitMm1_2: formData.unitMm1_2,
      unitMm1_3: formData.unitMm1_3,
      installationMethod1: formData.installationMethod1,
      exhibitorProductName2: formData.exhibitorProductName2,
      weight2: formData.weight2,
      unitMm2_1: formData.unitMm2_1,
      unitMm2_2: formData.unitMm2_2,
      unitMm2_3: formData.unitMm2_3,
      installationMethod2: formData.installationMethod2,
      exhibitorProductName3: formData.exhibitorProductName3,
      weight3: formData.weight3,
      unitMm3_1: formData.unitMm3_1,
      unitMm3_2: formData.unitMm3_2,
      unitMm3_3: formData.unitMm3_3,
      installationMethod3: formData.installationMethod3,
      exhibitorProductName4: formData.exhibitorProductName4,
      weight4: formData.weight4,
      unitMm4_1: formData.unitMm4_1,
      unitMm4_2: formData.unitMm4_2,
      unitMm4_3: formData.unitMm4_3,
      installationMethod4: formData.installationMethod4,
      exhibitorProductName5: formData.exhibitorProductName5,
      weight5: formData.weight5,
      unitMm5_1: formData.unitMm5_1,
      unitMm5_2: formData.unitMm5_2,
      unitMm5_3: formData.unitMm5_3,
      installationMethod5: formData.installationMethod5,
      exhibitorProductName6: formData.exhibitorProductName6,
      weight6: formData.weight6,
      unitMm6_1: formData.unitMm6_1,
      unitMm6_2: formData.unitMm6_2,
      unitMm6_3: formData.unitMm6_3,
      installationMethod6: formData.installationMethod6,
      exhibitorProductName7: formData.exhibitorProductName7,
      weight7: formData.weight7,
      unitMm7_1: formData.unitMm7_1,
      unitMm7_2: formData.unitMm7_2,
      unitMm7_3: formData.unitMm7_3,
      installationMethod7: formData.installationMethod7,

      decorationHeight1_1: formData.decorationHeight1_1,
      decorationHeight1_2: formData.decorationHeight1_2
    };

    const mergedData = { ...formData, jsonData: JSON.stringify(decoratorData) };
    const { jsonData, ...dataToSave } = formData;
    console.log('Saving data to localStorage:', mergedData);
    localStorage.setItem('template002Data', JSON.stringify(dataToSave));
    const alertMessage = language === 'ja' ? 'データを保存しました。' : 'Save Completed';
  alert(alertMessage);
  };

  const handleReset = async () => {
  const confirmMessage = language === 'ja' ? '本当に削除していいですか？' : 'Are you sure you want to reset?';
  const resetSuccessMessage = language === 'ja' ? 'フォームとファイルがリセットされました。' : 'Reset Completed';
  const deleteErrorMessage = language === 'ja' ? 'データの削除に失敗しました。' : 'Save Completed';

    if (window.confirm(confirmMessage)) {
      try {
        const response = await fetch('https://xs525443.xsrv.jp/admin_ui/delete02.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            Company_ID: formData.Company_ID,
            file1: selectedFile1 ? selectedFile1.name : null,
            file2: selectedFile2 ? selectedFile2.name : null,
          }),
        });

        const result = await response.json();

        if (result.success) {
          setFormData(prevFormData => ({
            ...prevFormData,
            // Reset only the decoration and exhibitor product related fields
            decorationHeight1: initialFormData.decorationHeight1,
            decorationHeight3: initialFormData.decorationHeight3,
            structureArea1: initialFormData.structureArea1,
            structureAreaTotal1: initialFormData.structureAreaTotal1,
            decorationHeight2: initialFormData.decorationHeight2,
            structureArea2: initialFormData.structureArea2,
            structureAreaTotal2: initialFormData.structureAreaTotal2,
            balloonInstallation: initialFormData.balloonInstallation,
            structureArea3: initialFormData.structureArea3,
            structureAreaTotal3: initialFormData.structureAreaTotal3,
            exhibitorProductName1: initialFormData.exhibitorProductName1,
            weight1: initialFormData.weight1,
            unitMm1_1: initialFormData.unitMm1_1,
            unitMm1_2: initialFormData.unitMm1_2,
            unitMm1_3: initialFormData.unitMm1_3,
            installationMethod1: initialFormData.installationMethod1,
            exhibitorProductName2: initialFormData.exhibitorProductName2,
            weight2: initialFormData.weight2,
            unitMm2_1: initialFormData.unitMm2_1,
            unitMm2_2: initialFormData.unitMm2_2,
            unitMm2_3: initialFormData.unitMm2_3,
            installationMethod2: initialFormData.installationMethod2,
            exhibitorProductName3: initialFormData.exhibitorProductName3,
            weight3: initialFormData.weight3,
            unitMm3_1: initialFormData.unitMm3_1,
            unitMm3_2: initialFormData.unitMm3_2,
            unitMm3_3: initialFormData.unitMm3_3,
            installationMethod3: initialFormData.installationMethod3,
            exhibitorProductName4: initialFormData.exhibitorProductName4,
            weight4: initialFormData.weight4,
            unitMm4_1: initialFormData.unitMm4_1,
            unitMm4_2: initialFormData.unitMm4_2,
            unitMm4_3: initialFormData.unitMm4_3,
            installationMethod4: initialFormData.installationMethod4,
            exhibitorProductName5: initialFormData.exhibitorProductName5,
            weight5: initialFormData.weight5,
            unitMm5_1: initialFormData.unitMm5_1,
            unitMm5_2: initialFormData.unitMm5_2,
            unitMm5_3: initialFormData.unitMm5_3,
            installationMethod5: initialFormData.installationMethod5,
            exhibitorProductName6: initialFormData.exhibitorProductName6,
            weight6: initialFormData.weight6,
            unitMm6_1: initialFormData.unitMm6_1,
            unitMm6_2: initialFormData.unitMm6_2,
            unitMm6_3: initialFormData.unitMm6_3,
            installationMethod6: initialFormData.installationMethod6,
            exhibitorProductName7: initialFormData.exhibitorProductName7,
            weight7: initialFormData.weight7,
            unitMm7_1: initialFormData.unitMm7_1,
            unitMm7_2: initialFormData.unitMm7_2,
            unitMm7_3: initialFormData.unitMm7_3,
            installationMethod7: initialFormData.installationMethod7,
            decorationHeight1_1: initialFormData.decorationHeight1_1,
            decorationHeight1_2: initialFormData.decorationHeight1_2
          }));
          setIsReadOnly(false); // フォームを編集可能にする
          setIsSubmitted(false); // 確定ボタンを有効にする
          localStorage.removeItem('template002Data');
          alert(resetSuccessMessage);
          checkDuplicate(); // 再度重複チェックを実行
        } else {
          alert(deleteErrorMessage + ': ' + result.message);
        }
      } catch (error) {
        console.error('データの削除中にエラーが発生しました:', error);
        alert(deleteErrorMessage);
      }
    }
  };

  
  

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  
    const jsonData = {
      decorationHeight1: formData.decorationHeight1,
      decorationHeight2: formData.decorationHeight2,
      decorationHeight3: formData.decorationHeight3,
      structureArea1: formData.structureArea1,
      structureAreaTotal1: formData.structureAreaTotal1,
      structureArea2: formData.structureArea2,
      structureAreaTotal2: formData.structureAreaTotal2,
      balloonInstallation: formData.balloonInstallation,
      structureArea3: formData.structureArea3,
      structureAreaTotal3: formData.structureAreaTotal3,
      exhibitorProductName1: formData.exhibitorProductName1,
      weight1: formData.weight1,
      unitMm1_1: formData.unitMm1_1,
      unitMm1_2: formData.unitMm1_2,
      unitMm1_3: formData.unitMm1_3,
      installationMethod1: formData.installationMethod1,
      exhibitorProductName2: formData.exhibitorProductName2,
      weight2: formData.weight2,
      unitMm2_1: formData.unitMm2_1,
      unitMm2_2: formData.unitMm2_2,
      unitMm2_3: formData.unitMm2_3,
      installationMethod2: formData.installationMethod2,
      exhibitorProductName3: formData.exhibitorProductName3,
      weight3: formData.weight3,
      unitMm3_1: formData.unitMm3_1,
      unitMm3_2: formData.unitMm3_2,
      unitMm3_3: formData.unitMm3_3,
      installationMethod3: formData.installationMethod3,
      exhibitorProductName4: formData.exhibitorProductName4,
      weight4: formData.weight4,
      unitMm4_1: formData.unitMm4_1,
      unitMm4_2: formData.unitMm4_2,
      unitMm4_3: formData.unitMm4_3,
      installationMethod4: formData.installationMethod4,
      exhibitorProductName5: formData.exhibitorProductName5,
      weight5: formData.weight5,
      unitMm5_1: formData.unitMm5_1,
      unitMm5_2: formData.unitMm5_2,
      unitMm5_3: formData.unitMm5_3,
      installationMethod5: formData.installationMethod5,
      exhibitorProductName6: formData.exhibitorProductName6,
      weight6: formData.weight6,
      unitMm6_1: formData.unitMm6_1,
      unitMm6_2: formData.unitMm6_2,
      unitMm6_3: formData.unitMm6_3,
      installationMethod6: formData.installationMethod6,
      exhibitorProductName7: formData.exhibitorProductName7,
      weight7: formData.weight7,
      unitMm7_1: formData.unitMm7_1,
      unitMm7_2: formData.unitMm7_2,
      unitMm7_3: formData.unitMm7_3,
      installationMethod7: formData.installationMethod7,
      decorationHeight1_1: formData.decorationHeight1_1,
      decorationHeight1_2: formData.decorationHeight1_2,
    };
  
    const formDataToSend = new FormData();
    formDataToSend.append('jsonData', JSON.stringify(jsonData));
  
    if (selectedFile1) {
      formDataToSend.append('file1', selectedFile1, selectedFile1.name);
    }
    if (selectedFile2) {
      formDataToSend.append('file2', selectedFile2, selectedFile2.name);
    }
  
    for (const key in formData) {
      // jsonDataやbuttonStateなどのオブジェクト型フィールドをスキップ
      if (key !== 'jsonData' && key !== 'buttonState') {
        formDataToSend.append(key, formData[key as keyof typeof formData] as string);
      }
    }
    
  
    try {
      const response = await fetch('https://xs525443.xsrv.jp/admin_ui/submit002.php', {
        method: 'POST',
        body: formDataToSend,
      });
  
      const result = await response.json();
  
      const successMessage = language === 'ja' ? 'データが正常に送信されました。' : 'Submission Completed';
    const failureMessage = language === 'ja' ? 'データの送信に失敗しました: ' : 'Failed to submit data: ';
    const errorMessage = language === 'ja' ? 'データの送信に失敗しました。' : 'Failed to submit data.';

      if (result.success) {
        //alert(successMessage);
        setIsSubmitted(true); // 送信が成功したらボタンをグレースケールにする
        checkDuplicate(); // 再度重複チェックを実行
      } else {
        alert('データの送信に失敗しました: ' + result.message);
      }
    } catch (error) {
      console.error('Error during data submission:', error);
      alert('データの送信に失敗しました。');
    }
  };
  
  
  
  const handleChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    const { name, value } = e.target as HTMLInputElement | { name: string; value: unknown };
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    if (name === 'postalCode' && (value as string).replace('-', '').length === 7) {
      try {
        const cleanedValue = (value as string).replace('-', ''); // ハイフンを削除
        const response = await axios.get(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${cleanedValue}`);
        if (response.data.results && response.data.results.length > 0) {
          const addressData = response.data.results[0];
          setFormData(prevState => ({
            ...prevState,
            postalCode: value as string,
            prefecture: addressData.address1,
            city: addressData.address2,
            address: addressData.address3
          }));
          return;
        }
      } catch (error) {
        console.error('郵便番号から住所を取得中にエラーが発生しました:', error);
      }
    }
  };

  const [selectedFile1, setSelectedFile1] = useState<File | null>(null);
  const [selectedFile2, setSelectedFile2] = useState<File | null>(null);
  const [fileName1, setFileName1] = useState<string>('');
  const [fileName2, setFileName2] = useState<string>('');
  
  const handleFileChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      const timestamp = Math.floor(Date.now() / 1000);
      const newFile = new File([file], `${timestamp}_${file.name}`, { type: file.type });
      setSelectedFile1(newFile);
      setFileName1(file.name); // ファイル名を設定
    }
  };
  
  const handleFileChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      const timestamp = Math.floor(Date.now() / 1000);
      const newFile = new File([file], `${timestamp}_${file.name}`, { type: file.type });
      setSelectedFile2(newFile);
      setFileName2(file.name); // ファイル名を設定
    }
  };
  
  const handleButtonClick1 = () => {
    if (fileInputRef1.current) {
      fileInputRef1.current.click();
    }
  };

  const fileInputRef2 = useRef<HTMLInputElement>(null);

const handleButtonClick2 = () => {
  if (fileInputRef2.current) {
    fileInputRef2.current.click();
  }
};


  const prefectures = ["北海道", "青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県", "茨城県", "栃木県", "群馬県", "埼玉県", "千葉県", "東京都", "神奈川県", "新潟県", "富山県", "石川県", "福井県", "山梨県", "長野県", "岐阜県", "静岡県", "愛知県", "三重県", "滋賀県", "京都府", "大阪府", "兵庫県", "奈良県", "和歌山県", "鳥取県", "島根県", "岡山県", "広島県", "山口県", "徳島県", "香川県", "愛媛県", "高知県", "福岡県", "佐賀県", "長崎県", "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県"];

  return (
    <Layout title={
      <MultiLangText
        language={language}
        textJa="申請書類管理　2．装飾・出展物等 確認（小間図面の提出）※全出展者提出"
        textEn="Application Forms 2. Decoration and Exhibits Confirmation(Submission of booth drawing)　*All Exhibitors(Required)"
      />
    }>
      <Paper style={{ padding: 16 }}>
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="■出展者情報"
                textEn="■Exhibitor information"
                />
        </Typography>
        <hr/>
        <ExhibitorInfoForm
          formData={formData}
          setFormData={setFormData}
          handleChange={handleChange}
        />
        <form onSubmit={handleSubmit}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault(); // Enterキーによるフォーム送信を防ぐ
             // アラートを表示
          }
        }}>
          <Typography gutterBottom  mt={5} style={{ fontWeight: 'bold' }}>
            <MultiLangText
                language={language}
                textJa="■装飾内容の確認"
                textEn="Confirmation of the Booth Decoration Plan"
                />
          </Typography>
          <hr />

          <Grid container spacing={1} alignItems="center">
            <Grid item mt={5} xs={4} sm={4} style={{ display: 'flex' }}>
            <MultiLangText
                language={language}
                textJa="①高さが３ｍを超える装飾物　"
                textEn="①Installing decorations over 3.0m high"
                /><RequiredLabel />
            </Grid>
            <Grid item mt={5} xs={8} sm={8}>
              <RadioGroup
                row
                aria-label="decorationHeight1"
                name="decorationHeight1"
                value={formData.decorationHeight1 || ''}
                onChange={handleChange}
              >
                <FormControlLabel value="あり" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="あり"
                textEn="Yes"
                />} disabled={isReadOnly}/>
                <FormControlLabel value="なし" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="なし"
                textEn="No"
                />} disabled={isReadOnly}/>
              </RadioGroup>
            </Grid>
            
            <Grid item mt={5} xs={4} sm={4} style={{ display: 'flex' }}>
            <MultiLangText
                language={language}
                textJa="②天井張り・屋根構造
                （平立面図・素材・設置理由を提出）"
                                textEn="②Installing ceiling/ roof<br/>(Floor plan, elevation plan,materials, and installation reasons are required.)"
                                /><RequiredLabel />
            </Grid>
            <Grid item mt={2} xs={8} sm={8}>
              <RadioGroup
                row
                aria-label="decorationHeight2"
                name="decorationHeight2"
                value={formData.decorationHeight2}
                onChange={handleChange}
              >
                <Grid item xs={3} sm={3}>
                  <FormControlLabel value="あり" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="あり"
                textEn="Yes"
                />} disabled={isReadOnly}/>
                </Grid>
                <Grid item xs={1} sm={1}></Grid>
                <Grid item xs={3} sm={3}>
                  <FormControlLabel value="なし" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="なし"
                textEn="No"
                />} disabled={isReadOnly}/>
                </Grid>
                <Grid item xs={3} sm={3}></Grid>
              </RadioGroup>
            </Grid>
            <Grid item xs={3} sm={3}></Grid>
            <Grid item xs={1} sm={1}>
            <MultiLangText
                language={language}
                textJa="設置個所"
                textEn="Quantity"
                />
            </Grid>
            <Grid item xs={1} sm={1}>
              <TextField name="structureArea1" value={formData.structureArea1} onChange={handleChange} margin="normal" disabled={isReadOnly}/>
            </Grid>
            <Grid item xs={1} sm={1}>
            <MultiLangText
                language={language}
                textJa="個所"
                textEn=""
                />
            </Grid>
            <Grid item xs={1} sm={1}></Grid>
            <Grid item xs={1} sm={1}>
            <MultiLangText
                language={language}
                textJa="面積"
                textEn="Area"
                />
            </Grid>
            <Grid item xs={1} sm={1}>
              <TextField label="" name="structureAreaTotal1" value={formData.structureAreaTotal1} onChange={handleChange} margin="normal" disabled={isReadOnly}/>
            </Grid>
            <Grid item xs={1} sm={1}>㎡</Grid>
            <Grid item xs={2} sm={2}></Grid>
            <Grid item mt={5} xs={4} sm={4} style={{ display: 'flex' }}>
            <MultiLangText
                language={language}
                textJa="③2階建て構造"
                textEn="③Setting up two-story construction "
                />　<RequiredLabel />
            </Grid>
            <Grid item mt={5} xs={8} sm={8}>
              <RadioGroup
                row
                aria-label="decorationHeight3"
                name="decorationHeight3"
                value={formData.decorationHeight3}
                onChange={handleChange}
              >
                <Grid item xs={3} sm={3}>
                  <FormControlLabel value="あり" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="あり"
                textEn="Yes"
                />} disabled={isReadOnly}/>
                </Grid>
                <Grid item xs={1} sm={1}></Grid>
                <Grid item xs={3} sm={3}>
                  <FormControlLabel value="なし" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="なし"
                textEn="No"
                />} disabled={isReadOnly}/>
                </Grid>
                <Grid item xs={3} sm={3}></Grid>
              </RadioGroup>
            </Grid>
            <Grid item xs={3} sm={3}></Grid>
            <Grid item xs={1} sm={1}>
            <MultiLangText
                language={language}
                textJa="設置個所"
                textEn="Quantity"
                />
            </Grid>
            <Grid item xs={1} sm={1}>
              <TextField label="" name="structureArea2" value={formData.structureArea2} onChange={handleChange} margin="normal" disabled={isReadOnly}/>
            </Grid>
            <Grid item xs={1} sm={1}>
            <MultiLangText
                language={language}
                textJa="個所"
                textEn=""
                />
            </Grid>
            <Grid item xs={1} sm={1}></Grid>
            <Grid item xs={1} sm={1}>
            <MultiLangText
                language={language}
                textJa="面積"
                textEn="Area"
                />
            </Grid>
            <Grid item xs={1} sm={1}>
              <TextField label="" name="structureAreaTotal2" value={formData.structureAreaTotal2} onChange={handleChange} margin="normal" disabled={isReadOnly}/>
            </Grid>
            <Grid item xs={1} sm={1}>㎡</Grid>
            <Grid item xs={2} sm={2}></Grid>
            <Grid item mt={5} xs={4} sm={4} style={{ display: 'flex' }}>
            <MultiLangText
                language={language}
                textJa="④バルーンの設置"
                textEn="Installing balloons"
                />　<RequiredLabel />
            </Grid>
            <Grid item mt={5} xs={8} sm={8}>
              <RadioGroup
                row
                aria-label="balloonInstallation"
                name="balloonInstallation"
                value={formData.balloonInstallation}
                onChange={handleChange}
              >
                <Grid item xs={3} sm={3}>
                  <FormControlLabel value="あり" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="あり"
                textEn="Yes"
                />} disabled={isReadOnly}/>
                </Grid>
                <Grid item xs={1} sm={1}></Grid>
                <Grid item xs={3} sm={3}>
                  <FormControlLabel value="なし" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="なし"
                textEn="No"
                />} disabled={isReadOnly}/>
                </Grid>
                <Grid item xs={3} sm={3}></Grid>
              </RadioGroup>
            </Grid>
            <Grid item xs={3} sm={3}></Grid>
            <Grid item xs={1} sm={1}>
            <MultiLangText
                language={language}
                textJa="設置個所"
                textEn="Quantity"
                />
            </Grid>
            <Grid item xs={1} sm={1}>
              <TextField label="" name="structureArea3" value={formData.structureArea3} onChange={handleChange} margin="normal" disabled={isReadOnly}/>
            </Grid>
            <Grid item xs={1} sm={1}>
            <MultiLangText
                language={language}
                textJa="個所"
                textEn=""
                />
            </Grid>
            <Grid item xs={1} sm={1}></Grid>
            <Grid item xs={1} sm={1}>
            <MultiLangText
                language={language}
                textJa="面積"
                textEn="Area"
                />
            </Grid>
            <Grid item xs={1} sm={1}>
              <TextField label="" name="structureAreaTotal3" value={formData.structureAreaTotal3} onChange={handleChange} margin="normal" disabled={isReadOnly}/>
            </Grid>
            <Grid item xs={1} sm={1}>㎡</Grid>
            <Grid item xs={2} sm={2}></Grid>
            <Grid item xs={1} sm={1}></Grid>
            <Grid item xs={1} sm={1}></Grid>
            <Grid item xs={10} sm={10}>
            <MultiLangText
                language={language}
                textJa="※小間上空バナーはバルーンの設置と異なります。<br/>"
                textEn="*Hanging banner in booths are different from balloon installations.<br/>"
                />
              <MultiLangText
                language={language}
                textJa="※上記のいずれかに該当する場合は、下記より図面をアップロードしてください。"
                textEn="*If any of the above applies to you, please upload the drawings below."
                />
            </Grid>
            <Grid item mt={10} xs={1} sm={1}></Grid>
            <Grid item xs={1} sm={1}></Grid>
            <Grid item xs={2} sm={2}>
            <input
            type="file"
            ref={fileInputRef1}
            onChange={handleFileChange1}
            style={{ display: 'none' }}
            accept="image/*,application/pdf,application/zip"
            disabled={isReadOnly}
          />
          <Button variant="contained" onClick={handleButtonClick1} disabled={isReadOnly}>
            <MultiLangText
              language={language}
              textJa="ファイルを選択"
              textEn="Select a file"
            />
          </Button>
          {fileName1 && <Typography variant="body2">{fileName1}</Typography>}
            </Grid>
            <Grid item xs={8} sm={8}>
            <MultiLangText
                language={language}
                textJa="
                アップロードが可能なサイズ容量は10MBまでとなります。<br/>
              複数のデータがある場合は１つに統合してください。<br/>
              (zip、PDF、jpg、png)
                "
                textEn="
                The maximum upload size is 10MB.<br/>
                If you have multiple files, please combine them into one.<br/>
                (zip、PDF、jpg、png)
                "
                />
            </Grid>
          </Grid>
          <Typography gutterBottom  mt={5} style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="■出展物等の確認"
                textEn="■Confirmation of exhibits"
                />
          </Typography>
          <hr />
          <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={12} style={{ display: 'flex' }}>
            <MultiLangText
                language={language}
                textJa="①出展物についての情報を入力してください"
                textEn="Please enter exhibits information. "
                />　<RequiredLabel />
            </Grid>
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={10} sm={10}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="出展製品名"
                textEn="Exhibit Product Name"
                />}
                  name="exhibitorProductName1"
                  value={formData.exhibitorProductName1}
                  onChange={handleChange}
                  sx={{ width: 155 }}
                  margin="normal"
                  required
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="重量"
                textEn="Weight"
                />}
                  name="weight1"
                  value={formData.weight1}
                  onChange={handleChange}
                  sx={{ width: 120 }}
                  margin="normal"
                  required
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                />
                <Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>t</Box>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="単位㎜(W)"
                textEn="Unit: mm(W)"
                />}
                  name="unitMm1_1"
                  value={formData.unitMm1_1}
                  onChange={handleChange}
                  sx={{ width: 120 }}
                  margin="normal"
                  required
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                /><Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>×</Box>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="単位㎜(D)"
                textEn="Unit: mm(D)"
                />}
                  name="unitMm1_2"
                  value={formData.unitMm1_2}
                  onChange={handleChange}
                  sx={{ width: 120 }}
                  margin="normal"
                  required
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                /><Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>×</Box>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="単位㎜(H)"
                textEn="Unit: mm(H)"
                />}
                  name="unitMm1_3"
                  value={formData.unitMm1_3}
                  onChange={handleChange}
                  sx={{ width: 120 }}
                  margin="normal"
                  required
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="設置方法（集中荷重有無等）"
                textEn="Installation Method"
                />}
                  name="installationMethod1"
                  value={formData.installationMethod1}
                  onChange={handleChange}
                  sx={{ width: 200 }}
                  margin="normal"
                  required
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                />
              </Box>
            </Grid>
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={10} sm={10}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="出展製品名"
                textEn="Exhibit Product Name"
                />}
                  name="exhibitorProductName2"
                  value={formData.exhibitorProductName2}
                  onChange={handleChange}
                  sx={{ width: 150 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="重量"
                textEn="Weight"
                />}
                  name="weight2"
                  value={formData.weight2}
                  onChange={handleChange}
                  sx={{ width: 120 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                />
                <Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>t</Box>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="単位㎜(W)"
                textEn="Unit: mm(W)"
                />}
                  name="unitMm2_1"
                  value={formData.unitMm2_1}
                  onChange={handleChange}
                  sx={{ width: 120 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                /><Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>×</Box>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="単位㎜(D)"
                textEn="Unit: mm(D)"
                />}
                  name="unitMm2_2"
                  value={formData.unitMm2_2}
                  onChange={handleChange}
                  sx={{ width: 120 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                /><Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>×</Box>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="単位㎜(H)"
                textEn="Unit: mm(H)"
                />}
                  name="unitMm2_3"
                  value={formData.unitMm2_3}
                  onChange={handleChange}
                  sx={{ width: 120 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="設置方法（集中荷重有無等）"
                textEn="Installation Method"
                />}
                  name="installationMethod2"
                  value={formData.installationMethod2}
                  onChange={handleChange}
                  sx={{ width: 200 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                />
              </Box>
            </Grid>
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={10} sm={10}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="出展製品名"
                textEn="Exhibit Product Name"
                />}
                  name="exhibitorProductName3"
                  value={formData.exhibitorProductName3}
                  onChange={handleChange}
                  sx={{ width: 150 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="重量"
                textEn="Weight"
                />}
                  name="weight3"
                  value={formData.weight3}
                  onChange={handleChange}
                  sx={{ width: 120 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                />
                <Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>t</Box>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="単位㎜(W)"
                textEn="Unit: mm(W)"
                />}
                  name="unitMm3_1"
                  value={formData.unitMm3_1}
                  onChange={handleChange}
                  sx={{ width: 120 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                /><Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>×</Box>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="単位㎜(D)"
                textEn="Unit: mm(D)"
                />}
                  name="unitMm3_2"
                  value={formData.unitMm3_2}
                  onChange={handleChange}
                  sx={{ width: 120 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                /><Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>×</Box>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="単位㎜(H)"
                textEn="Unit: mm(H)"
                />}
                  name="unitMm3_3"
                  value={formData.unitMm3_3}
                  onChange={handleChange}
                  sx={{ width: 120 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="設置方法（集中荷重有無等）"
                textEn="Installation Method"
                />}
                  name="installationMethod3"
                  value={formData.installationMethod3}
                  onChange={handleChange}
                  sx={{ width: 200 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                />
              </Box>
            </Grid>
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={10} sm={10}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="出展製品名"
                textEn="Exhibit Product Name"
                />}
                  name="exhibitorProductName4"
                  value={formData.exhibitorProductName4}
                  onChange={handleChange}
                  sx={{ width: 150 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="重量"
                textEn="Weight"
                />}
                  name="weight4"
                  value={formData.weight4}
                  onChange={handleChange}
                  sx={{ width: 120 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                />
                <Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>t</Box>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="単位㎜(W)"
                textEn="Unit: mm(W)"
                />}
                  name="unitMm4_1"
                  value={formData.unitMm4_1}
                  onChange={handleChange}
                  sx={{ width: 120 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                /><Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>×</Box>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="単位㎜(D)"
                textEn="Unit: mm(D)"
                />}
                  name="unitMm4_2"
                  value={formData.unitMm4_2}
                  onChange={handleChange}
                  sx={{ width: 120 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                /><Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>×</Box>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="単位㎜(H)"
                textEn="Unit: mm(H)"
                />}
                  name="unitMm4_3"
                  value={formData.unitMm4_3}
                  onChange={handleChange}
                  sx={{ width: 120 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="設置方法（集中荷重有無等）"
                textEn="Installation Method"
                />}
                  name="installationMethod4"
                  value={formData.installationMethod4}
                  onChange={handleChange}
                  sx={{ width: 200 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                />
              </Box>
            </Grid>
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={10} sm={10}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="出展製品名"
                textEn="Exhibit Product Name"
                />}
                  name="exhibitorProductName5"
                  value={formData.exhibitorProductName5}
                  onChange={handleChange}
                  sx={{ width: 150 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="重量"
                textEn="Weight"
                />}
                  name="weight5"
                  value={formData.weight5}
                  onChange={handleChange}
                  sx={{ width: 120 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                />
                <Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>t</Box>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="単位㎜(W)"
                textEn="Unit: mm(W)"
                />}
                  name="unitMm5_1"
                  value={formData.unitMm5_1}
                  onChange={handleChange}
                  sx={{ width: 120 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                /><Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>×</Box>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="単位㎜(D)"
                textEn="Unit: mm(D)"
                />}
                  name="unitMm5_2"
                  value={formData.unitMm5_2}
                  onChange={handleChange}
                  sx={{ width: 120 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                /><Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>×</Box>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="単位㎜(H)"
                textEn="Unit: mm(H)"
                />}
                  name="unitMm5_3"
                  value={formData.unitMm5_3}
                  onChange={handleChange}
                  sx={{ width: 120 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="設置方法（集中荷重有無等）"
                textEn="Installation Method"
                />}
                  name="installationMethod5"
                  value={formData.installationMethod5}
                  onChange={handleChange}
                  sx={{ width: 200 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                />
              </Box>
            </Grid>
            
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={10} sm={10}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="出展製品名"
                textEn="Exhibit Product Name"
                />}
                  name="exhibitorProductName6"
                  value={formData.exhibitorProductName6}
                  onChange={handleChange}
                  sx={{ width: 150 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="重量"
                textEn="Weight"
                />}
                  name="weight6"
                  value={formData.weight6}
                  onChange={handleChange}
                  sx={{ width: 120 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                />
                <Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>t</Box>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="単位㎜(W)"
                textEn="Unit: mm(W)"
                />}
                  name="unitMm6_1"
                  value={formData.unitMm6_1}
                  onChange={handleChange}
                  sx={{ width: 120 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                /><Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>×</Box>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="単位㎜(D)"
                textEn="Unit: mm(D)"
                />}
                  name="unitMm6_2"
                  value={formData.unitMm6_2}
                  onChange={handleChange}
                  sx={{ width: 120 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                /><Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>×</Box>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="単位㎜(H)"
                textEn="Unit: mm(H)"
                />}
                  name="unitMm6_3"
                  value={formData.unitMm6_3}
                  onChange={handleChange}
                  sx={{ width: 120 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="設置方法（集中荷重有無等）"
                textEn="Installation Method"
                />}
                  name="installationMethod6"
                  value={formData.installationMethod6}
                  onChange={handleChange}
                  sx={{ width: 200 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                />
              </Box>
            </Grid>


            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={10} sm={10}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="出展製品名"
                textEn="Exhibit Product Name"
                />}
                  name="exhibitorProductName7"
                  value={formData.exhibitorProductName7}
                  onChange={handleChange}
                  sx={{ width: 150 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="重量"
                textEn="Weight"
                />}
                  name="weight7"
                  value={formData.weight7}
                  onChange={handleChange}
                  sx={{ width: 120 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                />
                <Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>t</Box>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="単位㎜(W)"
                textEn="Unit: mm(W)"
                />}
                  name="unitMm7_1"
                  value={formData.unitMm7_1}
                  onChange={handleChange}
                  sx={{ width: 120 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                /><Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>×</Box>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="単位㎜(D)"
                textEn="Unit: mm(D)"
                />}
                  name="unitMm7_2"
                  value={formData.unitMm7_2}
                  onChange={handleChange}
                  sx={{ width: 120 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                /><Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>×</Box>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="単位㎜(H)"
                textEn="Unit: mm(H)"
                />}
                  name="unitMm7_3"
                  value={formData.unitMm7_3}
                  onChange={handleChange}
                  sx={{ width: 120 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="設置方法（集中荷重有無等）"
                textEn="Installation Method"
                />}
                  name="installationMethod7"
                  value={formData.installationMethod7}
                  onChange={handleChange}
                  sx={{ width: 200 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  disabled={isReadOnly}
                />
              </Box>
            </Grid>

            <Grid item xs={2} sm={2} style={{ display: 'flex' }}></Grid>
              <Grid item xs={10} sm={10}>
              <MultiLangText
                language={language}
                textJa="
                ※重量、単位、設置方法に該当内容が無い場合は「na」と記入ください。<br/>
                ※出展物が7種類以上ある場合は、上記項目をExcelにまとめ、（株）ムラヤマへご提出ください。<br/>
                ※高さが３mを超える出展物がある場合、単体重量が西1-2H 50t以上/西3-4H 6t以上を超える重量物の持ち込み・展示がある場合、下記より図面をアップロードしてください。
                "
                textEn="
                *Please enter 'na' if the weights, size, and installation method are not applicable.<br/>
                *If you are displaying seven or more exhibits, please summarize exhibits in Excel and submit it to Murayama Inc."
                />
              <br/>
              <MultiLangText
                language={language}
                textJa=""
                textEn="*If any of the under either➁ or ③ applies to you, please upload the drawings below."
                />
            </Grid>

            <Grid item xs={2} sm={2} style={{ display: 'flex' }}></Grid>
            <Grid item xs={2} sm={2}>
            <input
            type="file"
            ref={fileInputRef2}
            onChange={handleFileChange2}
            style={{ display: 'none' }}
            accept="image/*,application/pdf,application/zip"
            disabled={isReadOnly}
          />
          <Button variant="contained" onClick={handleButtonClick2} disabled={isReadOnly}>
            <MultiLangText
              language={language}
              textJa="ファイルを選択"
              textEn="Select a file"
            />
          </Button>
          {fileName2 && <Typography variant="body2">{fileName2}</Typography>}
            </Grid>
            <Grid item xs={8} sm={8}>
            <MultiLangText
                language={language}
                textJa="
                アップロードが可能なサイズ容量は10MBまでとなります。<br/>
              複数のデータがある場合は１つに統合してください。<br/>
              (zip、PDF、jpg、png)
                "
                textEn="
                The maximum upload size is 10MB.<br/>
                If you have multiple files, please combine them into one.<br/>
                (zip、PDF、jpg、png)
                "
                />
            </Grid>

            <Grid item mt={5} xs={4} sm={4} style={{ display: 'flex' }}>
            <MultiLangText
                language={language}
                textJa="②高さが3mを超える出展物"
                textEn="②Displaying exhibits over 3.0m high"
                />　<RequiredLabel />
            </Grid>
            <Grid item mt={5} xs={8} sm={8}>
              <RadioGroup
                row
                aria-label="decorationHeight1_1"
                name="decorationHeight1_1"
                value={formData.decorationHeight1_1 || ''}
                onChange={handleChange}
              >
                <FormControlLabel value="あり" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="あり"
                textEn="Yes"
                />} disabled={isReadOnly}/>
                <FormControlLabel value="なし" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="なし"
                textEn="No"
                />} disabled={isReadOnly}/>
              </RadioGroup>
            </Grid>

            <Grid item mt={5} xs={4} sm={4} style={{ display: 'flex' }}>
              
              <MultiLangText
                language={language}
                textJa="③単体重量が西1-2H 50t以上/西3-4H 6t以上を超える重量物の持ち込み・展示"
                textEn="③Exhibiting item exceeding: <br/>
                50t(west Hall 1・2)<br/>
                6t(west Hall 3・4)
                "
                />　<RequiredLabel />
            </Grid>
            <Grid item mt={5} xs={8} sm={8}>
              <RadioGroup
                row
                aria-label="decorationHeight1_2"
                name="decorationHeight1_2"
                value={formData.decorationHeight1_2 || ''}
                onChange={handleChange}
              >
                <FormControlLabel value="あり" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="あり"
                textEn="Yes"
                />} disabled={isReadOnly}/>
                <FormControlLabel value="なし" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="なし"
                textEn="No"
                />} disabled={isReadOnly}/>
              </RadioGroup>
            </Grid>

            <ContactInfo2/> {/* ContactInfo2コンポーネントにdisabledを渡す場合 */}
            {/* カラム */}
            <Grid item xs={12} sm={12} style={{ display: 'flex'}}>
              <MultiLangText
                language={language}
                textJa="
※下記、「確定」ボタンを押して、申請してください。「保存」中は修正が可能です。<br/>
              ※「リセット」ボタンがある申請書については、万一、確定後に修正が必要な場合も、内容の修正が可能です。
"
                textEn='
*Please click the "SUBMIT" button to submit your application. You can "SAVE” the form while you still need to modify it.<br/>
*Some forms have “RESET” button, which you can use when you need to reset and fill in the form again after submitting the form.
'
              />
            </Grid>
            
            <Grid container mt={5} spacing={1} justifyContent="center">
              <Grid item>
                <Button
                  onClick={handleReset}
                  variant="contained"
                  color="secondary"
                  disabled={formData.buttonState.reset}  // JSONで制御
                  sx={{ filter: formData.buttonState.reset ? 'grayscale(1)' : 'none' }}
                >
                  <MultiLangText
                    language={language}
                    textJa="リセット"
                    textEn="Reset"
                  />
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={handleSave}
                  variant="contained"
                  color="primary"
                  disabled={formData.buttonState.save}  // JSONで制御
                  sx={{ filter: formData.buttonState.save ? 'grayscale(1)' : 'none' }}
                >
                  <MultiLangText
                    language={language}
                    textJa="保存"
                    textEn="Save"
                  />
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={formData.buttonState.submit || isDuplicate || isSubmitted}  // JSON制御 + 重複や送信済みの場合
                  sx={{ filter: (formData.buttonState.submit || isDuplicate || isSubmitted) ? 'grayscale(1)' : 'none' }}
                >
                  <MultiLangText
                    language={language}
                    textJa="確定"
                    textEn="Submit"
                  />
                </Button>
              </Grid>
            </Grid>



          </Grid>
        </form>
      </Paper>
    </Layout>
  );
};

export default Template002;
