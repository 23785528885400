import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Home from './component/pages/Home';
import HomeAdmin from './component/pages/HomeAdmin';
import Template001 from './component/pages/001';
import Template002 from './component/pages/002';
import Template003 from './component/pages/003';
import Template004 from './component/pages/004';
import Template005 from './component/pages/005';
import Template006 from './component/pages/006';
import Template007 from './component/pages/007';
import Template008 from './component/pages/008';
import Template009 from './component/pages/009';
import Template010 from './component/pages/010';
import Template011 from './component/pages/011';
import Template012 from './component/pages/012';
import Template013 from './component/pages/013';
import Template014 from './component/pages/014';
import Template015 from './component/pages/015';
import Template016 from './component/pages/016';
import Template017 from './component/pages/017';
import Template018 from './component/pages/018';
import Template019 from './component/pages/019';
import Template020 from './component/pages/020';
import Template021 from './component/pages/021';
import Template022 from './component/pages/022';
import Template023 from './component/pages/023';
import Template024 from './component/pages/024';
import Template025 from './component/pages/025';
import Template026 from './component/pages/026';
import UserManagement from './component/pages/Usermanagement';
import UserPage from './component/pages/UserPage';
import { useAuth } from './context/AuthContext';
import PrivateRoute from './component/PrivateRoute';
import LoginPage from './component/pages/LoginPage';

const App: React.FC = () => {
  const navigate = useNavigate();
  const { login, logout, user, setLanguage } = useAuth();

  const handleLogin = (user: { username: string; exhibitorName: string; isAdmin: boolean; Company_ID: string }, language: string) => {
    const isAdmin = user.username === 'admin';
    login({ ...user, isAdmin, Company_ID: user.Company_ID });
    setLanguage(language);
    console.log('User logged in from handleLogin:', user); // Debug message
    navigate('/');
  };
  

  const handleLogout = () => {
    logout();
    console.log('User logged out from handleLogout'); // Debug message
    navigate('/login');
  };

  return (
    <Routes>
      <Route path='/login' element={<LoginPage onLogin={handleLogin} />} />
      <Route path='/' element={<PrivateRoute>{user?.isAdmin ? <HomeAdmin /> : <Home onLogout={handleLogout} />}</PrivateRoute>} />
      <Route path='/home-admin' element={<PrivateRoute><HomeAdmin /></PrivateRoute>} />
      <Route path='/user-management' element={<PrivateRoute isAdminOnly={true}><UserManagement /></PrivateRoute>} />
      <Route path='/user-page' element={<PrivateRoute isAdminOnly={false}><UserPage /></PrivateRoute>} />
      <Route path='/template/001' element={<PrivateRoute><Template001 /></PrivateRoute>} />
      <Route path='/template/002' element={<PrivateRoute><Template002 /></PrivateRoute>} />
      <Route path='/template/003' element={<PrivateRoute><Template003 /></PrivateRoute>} />
      <Route path='/template/004' element={<PrivateRoute><Template004 /></PrivateRoute>} />
      <Route path='/template/005' element={<PrivateRoute><Template005 /></PrivateRoute>} />
      <Route path='/template/006' element={<PrivateRoute><Template006 /></PrivateRoute>} />
      <Route path='/template/007' element={<PrivateRoute><Template007 /></PrivateRoute>} />
      <Route path='/template/008' element={<PrivateRoute><Template008 /></PrivateRoute>} />
      <Route path='/template/009' element={<PrivateRoute><Template009 /></PrivateRoute>} />
      <Route path='/template/010' element={<PrivateRoute><Template010 /></PrivateRoute>} />
      <Route path='/template/011' element={<PrivateRoute><Template011 /></PrivateRoute>} />
      <Route path='/template/012' element={<PrivateRoute><Template012 /></PrivateRoute>} />
      <Route path='/template/013' element={<PrivateRoute><Template013 /></PrivateRoute>} />
      <Route path='/template/014' element={<PrivateRoute><Template014 /></PrivateRoute>} />
      <Route path='/template/015' element={<PrivateRoute><Template015 /></PrivateRoute>} />
      <Route path='/template/016' element={<PrivateRoute><Template016 /></PrivateRoute>} />
      <Route path='/template/017' element={<PrivateRoute><Template017 /></PrivateRoute>} />
      <Route path='/template/018' element={<PrivateRoute><Template018 /></PrivateRoute>} />
      <Route path='/template/019' element={<PrivateRoute><Template019 /></PrivateRoute>} />
      <Route path='/template/020' element={<PrivateRoute><Template020 /></PrivateRoute>} />
      <Route path='/template/021' element={<PrivateRoute><Template021 /></PrivateRoute>} />
      <Route path='/template/022' element={<PrivateRoute><Template022 /></PrivateRoute>} />
      <Route path='/template/023' element={<PrivateRoute><Template023 /></PrivateRoute>} />
      <Route path='/template/024' element={<PrivateRoute><Template024 /></PrivateRoute>} />
      <Route path='/template/025' element={<PrivateRoute><Template025 /></PrivateRoute>} />
      <Route path='/template/026' element={<PrivateRoute><Template026 /></PrivateRoute>} />
    </Routes>
  );
};

export default App;
