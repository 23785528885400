import React, { useState, useEffect } from 'react';
import Layout from '../templetes/Layout';
import { 
  TextField, Button, Box, Typography, Paper, Grid, FormControlLabel, Checkbox, Select, MenuItem, InputLabel, FormControl, Radio, RadioGroup
} from '@mui/material';
import ExhibitorInfoForm from '../templetes/ExhibitorInfoForm';
import { SelectChangeEvent } from '@mui/material';
import ContactInfo from '../templetes/ContactInfo';
import RequiredLabel from '../templetes/RequiredLabel';
import MultiLangText from '../templetes/MultiLangText';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';


const Template007: React.FC = () => {
  const { language } = useAuth();
  const isJapanese = language === 'ja';

  const [formData, setFormData] = useState({
    exhibitorName: '',
    Company_ID: '',
    boothNumber: '',
    tel: '',
    departmentPosition: '',
    contactPerson: '',
    postalCode: '',
    prefecture: '',
    city: '',
    address: '',
    email: '',
    decoration1: '希望しない',
    decoration2: '希望しない',
    advertisingContent: '希望しない',
    decoration3mm: '',
    billingCompanyName: '',
    billingContactMobile: '',
    billingContactTel: '',
    billingContactPosition: '',
    billingContactName: '',
    billingAddressPostal: '',
    billingAddressPrefecture: '',
    billingAddressCity: '',
    billingAddressDetails: '',
    billingContactEmail: '',
    buttonState: {
      reset: false,
      submit: false,
      save: false
    }
  });

  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // 外部JSONファイルからボタンの状態を取得する関数
const loadButtonState = async () => {
  try {
    const timestamp = new Date().getTime();
    const response = await axios.get(`/json/buttonState7.json?_=${timestamp}`);  // 外部JSONファイルのパス
    const buttonStateData = response.data;
    setFormData(prevState => ({
      ...prevState,
      buttonState: buttonStateData.buttonState
    }));
    setIsReadOnly(buttonStateData.buttonState.disabled);
  } catch (error) {
    console.error('ボタン状態の読み込み中にエラーが発生しました:', error);
  }
};

  useEffect(() => {
    const savedData = localStorage.getItem('formData007');
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);
  
  useEffect(() => {
    if (formData.exhibitorName) {
      checkDuplicate();
    }
  }, [formData.exhibitorName]);

  const checkDuplicate = async () => {
    try {
      const response = await fetch('https://xs525443.xsrv.jp/admin_ui/check07.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          exhibitorName: formData.exhibitorName,
          Company_ID: formData.Company_ID
        }),
      });
  
      const result = await response.json();
      if (result.isDuplicate && result.additionalData) {
        const parsedData = JSON.parse(result.additionalData);
        setFormData(prevState => ({
          ...prevState,
          ...parsedData,
        }));
        setIsDuplicate(true);
        setIsReadOnly(true);
      } else {
        setIsDuplicate(false);
      }
    } catch (error) {
      console.error('重複チェック中にエラーが発生しました:', error);
    }
  };

  const handleSave = () => {
    localStorage.setItem('formData007', JSON.stringify(formData));
    const alertMessage = language === 'ja' ? 'データを保存しました。' : 'Save Completed';
  alert(alertMessage);
  };

  const handleReset = async () => {
  const confirmMessage = language === 'ja' ? '本当に削除していいですか？' : 'Are you sure you want to reset?';
  const resetSuccessMessage = language === 'ja' ? 'フォームとファイルがリセットされました。' : 'Reset Completed';
  const deleteErrorMessage = language === 'ja' ? 'データの削除に失敗しました。' : 'Failed to delete data.';
    try {
      const response = await fetch('https://xs525443.xsrv.jp/admin_ui/delete07.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          exhibitorName: formData.exhibitorName,
          Company_ID: formData.Company_ID
        }),
      });
  
      if (response.ok) {
        alert('データがリセットされました。');
        setFormData(prevState => ({
          ...prevState,
          // 出展者情報はそのままにする
          decoration1: '希望しない',
          decoration2: '希望しない',
          advertisingContent: '希望しない',
          decoration3mm: '',
          billingCompanyName: '',
          billingContactMobile: '',
          billingContactTel: '',
          billingContactPosition: '',
          billingContactName: '',
          billingAddressPostal: '',
          billingAddressPrefecture: '',
          billingAddressCity: '',
          billingAddressDetails: '',
          billingContactEmail: ''
        }));
        localStorage.removeItem('formData007');
        setIsReadOnly(false);
        checkDuplicate();
      } else {
        alert('リセット中にエラーが発生しました。');
      }
    } catch (error) {
      console.error('リセットエラー:', error);
      alert('リセット中にエラーが発生しました。');
    }
  };
  

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(formData);

    try {
      const response = await fetch('https://xs525443.xsrv.jp/admin_ui/submit007.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const successMessage = language === 'ja' ? 'データが正常に送信されました。' : 'Submission Completed';
    const failureMessage = language === 'ja' ? 'データの送信に失敗しました: ' : 'Failed to submit data: ';
    const errorMessage = language === 'ja' ? 'データの送信に失敗しました。' : 'Failed to submit data.';

      if (response.ok) {
       // alert(successMessage);
        setIsSubmitted(true);
        setIsReadOnly(true);
      } else {
        alert('データの送信中にエラーが発生しました。');
      }
    } catch (error) {
      console.error('データ送信エラー:', error);
      alert('データの送信中にエラーが発生しました。');
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handlePostalCodeChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const cleanedValue = value.replace(/-/g, ''); // ハイフンを除去
  
    setFormData(prevState => ({
      ...prevState,
      [name]: cleanedValue
    }));
  
    if (cleanedValue.length === 7) {
      try {
        const response = await fetch(`https://api.zipaddress.net/?zipcode=${cleanedValue}`);
        const data = await response.json();
        if (data.code === 200) {
          if (name === 'postalCode') {
            setFormData(prevState => ({
              ...prevState,
              prefecture: data.data.pref,
              city: data.data.city,
              address: data.data.town
            }));
          } else if (name === 'billingAddressPostal') {
            setFormData(prevState => ({
              ...prevState,
              billingAddressPrefecture: data.data.pref,
              billingAddressCity: data.data.city,
              billingAddressDetails: data.data.town
            }));
          }
        } else {
          alert('郵便番号から住所を取得できませんでした。');
        }
      } catch (error) {
        console.error('郵便番号から住所を取得中にエラーが発生しました:', error);
      }
    }
  };
  
  const prefectures = ["北海道", "青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県", "茨城県", "栃木県", "群馬県", "埼玉県", "千葉県", "東京都", "神奈川県", "新潟県", "富山県", "石川県", "福井県", "山梨県", "長野県", "岐阜県", "静岡県", "愛知県", "三重県", "滋賀県", "京都府", "大阪府", "兵庫県", "奈良県", "和歌山県", "鳥取県", "島根県", "岡山県", "広島県", "山口県", "徳島県", "香川県", "愛媛県", "高知県", "福岡県", "佐賀県", "長崎県", "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県"];

  useEffect(() => {
    loadButtonState(); // コンポーネントのマウント時にボタン状態を読み込む
  }, []);

  return (
    <Layout title={<MultiLangText
      language={language}
      textJa="申請書類管理　7．公式Web サイトバナー広告・会場案内広告・来場者バッジストラップ広告掲載申込み"
      textEn="Application Forms　7.Submission of the Advertisement on Website Banner, Guide map, Visitor Badge Holder"
      />}>
      <Paper style={{ padding: 16 }}>
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="■出展者情報"
                textEn="■Exhibitor information"
                />
        </Typography>
        <hr/>
        <form onSubmit={handleSubmit}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault(); // Enterキーによるフォーム送信を防ぐ
             // アラートを表示
          }
        }}>
          <ExhibitorInfoForm
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
          />

          {/* セクションスタート */}
          <Typography gutterBottom mt={5} style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="公式Webサイトバナー広告"
                textEn="Website Banner"
                />
          </Typography>
          <hr></hr>
          <Grid container spacing={3} alignItems="center">

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="広告内容"
                textEn="Details "
                />　<RequiredLabel/>
          </Grid>
          <Grid item xs={10} sm={10}>
            <RadioGroup name="advertisingContent" value={formData.advertisingContent} onChange={handleChange}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={7} sm={7}>
                <MultiLangText
                language={language}
                textJa="①トップページ及びオンラインカタログ ￥176,000（税込）"
                textEn="① Homepage and Online Catalogue JPY 176,000(Tax included)"
                />
                </Grid>
                <Grid item xs={3} sm={3}>
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    value="トップページ及びオンラインカタログ"
                    label={<MultiLangText
                      language={language}
                      textJa="希望する"
                      textEn="Yes"
                      />}
                    disabled={isReadOnly}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3} alignItems="center">
                <Grid item xs={7} sm={7}>
                <MultiLangText
                language={language}
                textJa="②トップページのみ ￥132,000（税込）"
                textEn="② Homepage JPY 132,000(Tax included)"
                />
                </Grid>
                <Grid item xs={3} sm={3}>
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    value="トップページのみ"
                    label={<MultiLangText
                      language={language}
                      textJa="希望する"
                      textEn="Yes"
                      />}
                    disabled={isReadOnly}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3} alignItems="center">
                <Grid item xs={7} sm={7}>
                <MultiLangText
                language={language}
                textJa="③オンラインカタログのみ ￥88,000（税込）"
                textEn="③Online Catalogue JPY 88,000(Tax included)"
                />
                </Grid>
                <Grid item xs={3} sm={3}>
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    value="オンラインカタログのみ"
                    label={<MultiLangText
                      language={language}
                      textJa="希望する"
                      textEn="Yes"
                      />}
                    disabled={isReadOnly}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3} alignItems="center">
                <Grid item xs={7} sm={7}>
                </Grid>
                <Grid item xs={3} sm={3}>
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    value="希望しない"
                    label={<MultiLangText
                      language={language}
                      textJa="希望しない"
                      textEn="No"
                      />}
                    disabled={isReadOnly}
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </Grid>


      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={10} sm={10}>
        <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={12}>
        <MultiLangText
                language={language}
                textJa="原稿入稿<br/>
・入稿期限：8月13日（火）<br/>
・入稿先：㈱東京ビッグサイト2024 国際航空宇宙展事務局<br/>
・E-mail：bigsight-ja@tokyo-bigsight.co.jp<br/>
・原稿形式：バナーファイルサイズ（10KB）<br/>
　　　　　　バナーのファイル形式（GIF形式、JPEG形式）"
                textEn="Data submission<br/>
・Submission deadline：August 13(Tue.)<br/>
・Submit to:JA2024 Management Office (Tokyo Big Sight Inc.)<br/>
・E-mail：bigsight-ja@tokyo-bigsight.co.jp<br/>
・Submission format:File size: 10KB or less<br/>
File format: GIF/JPEG"
                />
        </Grid>
        </Grid>
      </Grid>

          </Grid>
          {/* セクションここまで */}
          <br></br><br></br>

          {/* セクションスタート */}
          <Typography gutterBottom mt={5} style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="会場案内広告"
                textEn="Guide Map"
                />
          </Typography>
          <hr></hr>
          <Grid container spacing={3} alignItems="center">
          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="広告内容"
                textEn="Details "
                />　<RequiredLabel/>
          </Grid>

          <Grid item xs={10} sm={10}>
          <MultiLangText
                language={language}
                textJa="日・英セット　￥198,000（税込）"
                textEn="Japanese & English Set  JPY 198,000(Tax included)"
                /><br/>
          <RadioGroup
              row
              aria-label="decoration1"
              name="decoration1"
              value={formData.decoration1}
              onChange={handleChange}
            >
          <Grid item xs={3} sm={3}>
          <FormControlLabel value="希望する" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="希望する"
                textEn="Yes"
                />} disabled={isReadOnly} />
          </Grid>
          <Grid item xs={1} sm={1}></Grid>
          <Grid item xs={3} sm={3}>
          <FormControlLabel value="希望しない" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="希望しない"
                textEn="No"
                />} disabled={isReadOnly} />
          </Grid>
          <Grid item xs={3} sm={3}></Grid>
          </RadioGroup>
          </Grid>

        {/* カラム */}
        <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
        </Grid>
        <Grid item xs={10} sm={10}>
          <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={12}>
          <MultiLangText
                language={language}
                textJa="※原稿入稿<br/>
・入稿期限：８月２３日（金）<br/>
・入稿先：㈱東京ビッグサイト２０２４ 国際航空宇宙展事務局<br/>
・E-mail：bigsight-ja＠tokyo-bigsight.co.jp<br/>
・原稿形式：illustratorまたはPDF"
                textEn="Data submission<br/>
・Submission deadline：August 23(Fri.)<br/>
・Submit to:JA2024 Management Office (Tokyo Big Sight Inc.)<br/>
・E-mail：bigsight-ja@tokyo-bigsight.co.jp<br/>
・Submission format: File format: Illustrator/PDF"
                />
          </Grid>
          </Grid>
        </Grid>


          </Grid>
          {/* セクションここまで */}

          {/* セクションスタート */}
          <Typography gutterBottom mt={5} style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="来場者バッジストラップ広告"
                textEn="Visitor Badge Holder"
                />
          </Typography>
          <hr></hr>
          <Grid container spacing={3} alignItems="center">
          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="広告内容"
                textEn="Details "
                />　<RequiredLabel/>
          </Grid>

          <Grid item xs={10} sm={10}>
          <MultiLangText
                language={language}
                textJa="①ストラップ広告 ￥3,025,000（税込）"
                textEn="①Visitor Badge Holder　JPY 3,025,000(Tax included)"
                /><br/>
          <RadioGroup
              row
              aria-label="decoration2"
              name="decoration2"
              value={formData.decoration2}
              onChange={handleChange}
            >
          <Grid item xs={3} sm={3}>
          <FormControlLabel value="希望する" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="希望する"
                textEn="Yes"
                />} disabled={isReadOnly} />
          </Grid>
          <Grid item xs={1} sm={1}></Grid>
          <Grid item xs={3} sm={3}>
          <FormControlLabel value="希望しない" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="希望しない"
                textEn="No"
                />} disabled={isReadOnly} />
          </Grid>
          <Grid item xs={3} sm={3}></Grid>
          </RadioGroup>
          </Grid>

        {/* カラム */}
        <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
        </Grid>
        <Grid item xs={10} sm={10}>
        <MultiLangText
                language={language}
                textJa="原稿入稿<br/>・別途お申込者へご案内致します。"
                textEn="Data submission<br/>We will make a contact to company after final decision."
                />
        </Grid>


          </Grid>
          {/* セクションここまで */}

          {/* セクションスタート */}
          <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="■ 請求先について"
                textEn="■ Invoice Recipient "
                />
          </Typography>
          <hr></hr>
          <Grid container spacing={2} alignItems="center">

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="お選びください"
                textEn="Please select"
                />　<RequiredLabel/>
          </Grid>
          <Grid item xs={9} sm={9}>
          <RadioGroup
              row
              aria-label="decoration"
              name="decoration3mm"
              value={formData.decoration3mm}
              onChange={(e) => setFormData({ ...formData, decoration3mm: e.target.value })}
            >
          <Grid item xs={6} sm={6}>
          <FormControlLabel value="出展者" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="出展者"
                textEn="Exhibitor"
                />} disabled={isReadOnly} />
          </Grid>
          <Grid item xs={6} sm={6}>
          <FormControlLabel value="その他（下記へご記入下さい）" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="その他（下記へご記入ください）"
                textEn="Other(Fill out the following blanks)"
                />} disabled={isReadOnly} />
          </Grid>
          </RadioGroup>
          </Grid>

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="請求先社名"
                textEn="Company Name"
                />　<RequiredLabel/>
          </Grid>
          <Grid item xs={9} sm={9}>
          <TextField
            name="billingCompanyName"
            value={formData.billingCompanyName}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={isReadOnly}
          />
          </Grid>

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="担当者携帯"
                textEn="Mobile Phone Number"
                />　<RequiredLabel/>
          </Grid>
          <Grid item xs={4} sm={4}>
          <TextField
            name="billingContactMobile"
            value={formData.billingContactMobile}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={isReadOnly}
          />
          </Grid>
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          TEL　<RequiredLabel/>
          </Grid>
          <Grid item xs={4} sm={4}>
          <TextField
            name="billingContactTel"
            value={formData.billingContactTel}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={isReadOnly}
          />
          </Grid>

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="担当者部署・役職"
                textEn="Title / Department of the Contact Person"
                />
          </Grid>
          <Grid item xs={4} sm={4}>
          <TextField
            name="billingContactPosition"
            value={formData.billingContactPosition}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={isReadOnly}
          />
          </Grid>
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="担当者名"
                textEn="Name of Contact Person"
                />　<RequiredLabel/>
          </Grid>
          <Grid item xs={4} sm={4}>
          <TextField
            name="billingContactName"
            value={formData.billingContactName}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={isReadOnly}
          />
          </Grid>

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="住所"
                textEn="Address"
                />　<RequiredLabel/>
          </Grid>
          <Grid item xs={9} sm={9}>

            <Grid container>
            <Grid item xs={5} sm={5}>
            <TextField
              label={<MultiLangText
                language={language}
                textJa="〒"
                textEn="Postal Code"
              />}
              name="billingAddressPostal"
              value={formData.billingAddressPostal}
              onChange={handlePostalCodeChange}
              fullWidth
              disabled={isReadOnly}
            />

            </Grid>

            <Grid item xs={0} sm={0.1}></Grid>

            <Grid item xs={5} sm={5}>
            {
              isJapanese && (
                <FormControl fullWidth>
                          <InputLabel id="prefecture-label">都道府県</InputLabel>
                          <Select
                            labelId="prefecture-label"
                            id="prefecture"
                            name="billingAddressPrefecture"
                            value={formData.billingAddressPrefecture}
                            label="都道府県"
                            onChange={(e) => setFormData({ ...formData, billingAddressPrefecture: e.target.value })}
                            disabled={isReadOnly}
                          >
                            {prefectures.map((prefecture, index) => (
                              <MenuItem key={index} value={prefecture}>{prefecture}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
              )
            }
            {
              !isJapanese && (
                <Grid item xs={0} sm={0}>
                </Grid>
              )
            }
            </Grid>
            
            </Grid>
          </Grid>

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}></Grid>
          <Grid item xs={9} sm={9}>
          <TextField
            label={<MultiLangText
                language={language}
                textJa="市区町村名（例：大阪市北区）"
                textEn="city"
                />}

            name="billingAddressCity"
            value={formData.billingAddressCity}
            onChange={handleChange}
            fullWidth
            disabled={isReadOnly}
          />
          </Grid>

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}></Grid>
          <Grid item xs={9} sm={9}>
          <TextField
            label={<MultiLangText
                language={language}
                textJa="番地・ビル名（例：〇〇１丁目2-3）"
                textEn="Address and Building Name"
                />}
            name="billingAddressDetails"
            value={formData.billingAddressDetails}
            onChange={handleChange}
            fullWidth
            disabled={isReadOnly}
          />
          </Grid>

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
          <MultiLangText
                language={language}
                textJa="メールアドレス"
                textEn="E-mail"
                />　<RequiredLabel/>
          </Grid>
          <Grid item xs={9} sm={9}>
          <TextField
            label="XXXXX@xxxxxx.com"
            name="billingContactEmail"
            value={formData.billingContactEmail}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={isReadOnly}
          />
          </Grid>

          {/* カラム */}
          <Grid item xs={2} sm={2} style={{ display: 'flex'}}></Grid>
          <Grid item xs={9} sm={9}>
          <MultiLangText
                language={language}
                textJa="※上記の請求先が出展者と異なる場合に記入してください。"
                textEn="*Please fill in above if the billing information is different from the exhibitor."
                />
          </Grid>
          </Grid>
          {/* セクションここまで */}
          {/* セクションスタート */}
          <Typography gutterBottom mt={5} style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="■ お問い合わせ先について　※備考・その他は、下記まで直接ご連絡ください。"
                textEn="■Contact Information　If you have any questions or concerns, please inquire with the following contact."
                />
            </Typography>
            <hr></hr>
            <Grid container spacing={3} alignItems="center">

            {/* カラム */}
            <Grid item xs={12} sm={12}>
            <MultiLangText
                language={language}
                textJa="（株）東京ビッグサイト 2024国際航空宇宙展事務局<br/>
                〒135-0063　東京都江東区有明3-11-1<br/>
                TEL：03-5530-1324<br/>
                E-mail：bigsight-ja@tokyo-bigsight.co.jp"
                textEn="JA2024 Management Office (Tokyo Big Sight Inc.)<br/>
                3-11-1, Ariake, Koto-ku, Tokyo 135-0063<br/>
                TEL：＋81-3-5530-1324<br/>
                E-mail：bigsight-ja@tokyo-bigsight.co.jp"
                />
            </Grid>
            {/* カラム */}
            <Grid item xs={12} sm={12} style={{ display: 'flex'}}>
              <MultiLangText
                language={language}
                textJa="
※下記、「確定」ボタンを押して、申請してください。「保存」中は修正が可能です。<br/>
              ※「リセット」ボタンがある申請書については、万一、確定後に修正が必要な場合も、内容の修正が可能です。
"
                textEn='
*Please click the "SUBMIT" button to submit your application. You can "SAVE” the form while you still need to modify it.<br/>
*Some forms have “RESET” button, which you can use when you need to reset and fill in the form again after submitting the form.
'
              />
            </Grid>
            </Grid> 
            {/* セクションここまで */}
            <Grid container mt={5} spacing={1} justifyContent="center">
            <Grid item>
              <Button
                onClick={handleReset}
                variant="contained"
                color="secondary"
                disabled={formData.buttonState.reset}  // JSONで制御
                sx={{ filter: formData.buttonState.reset ? 'grayscale(1)' : 'none' }}  // グレーアウト
              >
                <MultiLangText
                  language={language}
                  textJa="リセット"
                  textEn="Reset"
                />
              </Button>
            </Grid>

            <Grid item>
              <Button
                onClick={handleSave}
                variant="contained"
                color="primary"
                disabled={formData.buttonState.save}  // JSONで制御
                sx={{ filter: formData.buttonState.save ? 'grayscale(1)' : 'none' }}  // グレーアウト
              >
                <MultiLangText
                  language={language}
                  textJa="保存"
                  textEn="Save"
                />
              </Button>
            </Grid>

            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={formData.buttonState.submit || isDuplicate || isSubmitted}  // JSONで制御 + 重複や送信済みの場合
                sx={{ filter: (formData.buttonState.submit || isDuplicate || isSubmitted) ? 'grayscale(1)' : 'none' }}  // グレーアウト
              >
                <MultiLangText
                  language={language}
                  textJa="確定"
                  textEn="Submit"
                />
              </Button>
            </Grid>
          </Grid>

        </form>
      </Paper>
    </Layout>
  );
};

export default Template007;
