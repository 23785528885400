import React from 'react';
import { Grid, Typography, Paper, Container } from '@mui/material';
import MultiLangText from '../templetes/MultiLangText';
import { useAuth } from '../../context/AuthContext';

const ContactInfo: React.FC = () => {
  const { language } = useAuth();

  return (
    <Container>
        <Typography gutterBottom mt={5} style={{ fontWeight: 'bold' }}>
        <MultiLangText
                language={language}
                textJa="■ お問い合わせ先について　※備考・その他は、下記まで直接ご連絡ください。"
                textEn="■Contact Information　If you have any questions or concerns, please inquire with the following contact."
                />
          </Typography>
          <hr/>
        <Grid container spacing={2}>
          <Grid item xs={12}>
          <MultiLangText
                language={language}
                textJa="
                事務局電気施工会社 （株）鈴木電機<br/>
              〒111-0033 東京都台東区花川戸2-12-5<br/>
              TEL：03-3842-8201<br/>
              E-mail：JA2024@suzukidenki.co.jp<br/>
              担当：荒井、戸田
              "
                textEn="
                SUZUKIDENKI Co., Ltd.<br/>
                2-12-5 Hanakawado,Taito-ku, Tokyo 111-0033<br/>
                TEL: +81-3-3842-8201<br/>
                E-mail: JA2024@suzukidenki.co.jp<br/>
                Arai, Toda
                "
                />
          </Grid>
        </Grid>
    </Container>
  );
};

export default ContactInfo;
