import React from 'react';

interface MultiLangTextProps {
  language: string;
  textJa: string;
  textEn: string;
}

const MultiLangText: React.FC<MultiLangTextProps> = ({ language, textJa, textEn }) => {
  const text = language === 'ja' ? textJa : textEn;
  
  return (
    <span dangerouslySetInnerHTML={{ __html: text }} />
  );
};

export default MultiLangText;
