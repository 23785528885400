import React, { useState, useEffect } from 'react';
import Layout from '../templetes/Layout';
import { 
  TextField, Button, Typography, Paper, Grid, FormControlLabel, Checkbox, Select, MenuItem, InputLabel, FormControl
} from '@mui/material';
import ExhibitorInfoForm from '../templetes/ExhibitorInfoForm';
import { SelectChangeEvent } from '@mui/material';
import { Radio, RadioGroup } from '@mui/material';
import RequiredLabel from '../templetes/RequiredLabel';
import MultiLangText from '../templetes/MultiLangText';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';

const Template011: React.FC = () => {
  const { language } = useAuth();
  const isJapanese = language === 'ja';

  const [formData, setFormData] = useState({
    exhibitorName: '',
    boothNumber: '',
    tel: '',
    departmentPosition: '',
    contactPerson: '',
    postalCode: '',
    prefecture: '',
    city: '',
    address: '',
    email: '',
    Company_ID: '',
    decoration3mm: '',
    advertisingRequests: [
      { id: 1, checked: false, quantity: '' },
      { id: 2, checked: false, quantity: '' },
      { id: 3, checked: false, quantity: '' },
      { id: 4, checked: false, quantity: '' },
      { id: 5, checked: false, quantity: '' },
      { id: 6, checked: false, quantity: '' },
      { id: 7, checked: false, quantity: '' },
      { id: 8, checked: false, quantity: '' },
      { id: 9, checked: false, quantity: '' },
      { id: 10, checked: false, quantity: '' }
    ],
    bannerRequests:'申し込まない',
    billing: {
      billingCompanyName: '',
      billingContactMobile: '',
      billingContactTel: '',
      billingContactPosition: '',
      billingContactName: '',
      billingAddressPostal: '',
      billingAddressPrefecture: '',
      billingAddressCity: '',
      billingAddressDetails: '',
      billingContactEmail: ''
    }
  });

  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [buttonState, setButtonState] = useState<{ reset: boolean; save: boolean; submit: boolean } | null>(null);




  useEffect(() => {

    const fetchButtonState = async () => {
      try {
        const timestamp = new Date().getTime();
        const response = await axios.get(`/json/buttonState11.json?_=${timestamp}`);
        const buttonStateData = response.data.buttonState;
      setButtonState(buttonStateData);

      // JSON内のdisabledフィールドに基づいてisReadOnlyをセット
      setIsReadOnly(buttonStateData.disabled);  
      } catch (error) {
        console.error('Error fetching button state:', error);
      }
    };

    fetchButtonState();



    const savedData = localStorage.getItem('formData011');
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
    setIsSubmitted(false); // isSubmittedの初期化
    setIsDuplicate(false); // isDuplicateの初期化
  }, []);
  
  useEffect(() => {
    if (formData.exhibitorName) {
      checkDuplicate();
    }
  }, [formData.exhibitorName]);

  const checkDuplicate = async () => {
    try {
      const response = await fetch('https://xs525443.xsrv.jp/admin_ui/check011.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          exhibitorName: formData.exhibitorName,
          Company_ID: formData.Company_ID
        }),
      });
  
      const result = await response.json();
      if (result.isDuplicate && result.additionalData) {
        const parsedData = JSON.parse(result.additionalData);
        setFormData(prevState => ({
          ...prevState,
          ...parsedData,
        }));
        setIsDuplicate(true);
        setIsReadOnly(true);
      } else {
        setIsDuplicate(false);
      }
    } catch (error) {
      console.error('重複チェック中にエラーが発生しました:', error);
    }
  };

  
  const handleSave = () => {
    localStorage.setItem('formData011', JSON.stringify(formData));
    const alertMessage = language === 'ja' ? 'データを保存しました。' : 'Save Completed';
  alert(alertMessage);
  };

  const handleReset = async () => {
  const confirmMessage = language === 'ja' ? '本当に削除していいですか？' : 'Are you sure you want to reset?';
  const resetSuccessMessage = language === 'ja' ? 'フォームとファイルがリセットされました。' : 'Reset Completed';
  const deleteErrorMessage = language === 'ja' ? 'データの削除に失敗しました。' : 'Failed to delete data.';
    if (window.confirm(confirmMessage)) {
      try {
        const response = await axios.post('https://xs525443.xsrv.jp/admin_ui/delete011.php', {
          exhibitorName: formData.exhibitorName,
          Company_ID: formData.Company_ID
        });
  
        const result = response.data;
  
        if (result.success) {
          setFormData(prevState => ({
            ...prevState,
            decoration3mm: '',
            advertisingRequests: prevState.advertisingRequests.map(request => ({ ...request, checked: false, quantity: '' })),
            bannerRequests:'',
            billing: {
              billingCompanyName: '',
              billingContactMobile: '',
              billingContactTel: '',
              billingContactPosition: '',
              billingContactName: '',
              billingAddressPostal: '',
              billingAddressPrefecture: '',
              billingAddressCity: '',
              billingAddressDetails: '',
              billingContactEmail: ''
            }
          }));
          setIsReadOnly(false); // フォームを編集可能にする
          setIsSubmitted(false); // 確定ボタンを有効にする
          localStorage.removeItem('formData011');
          alert(resetSuccessMessage);
          checkDuplicate(); // 再度重複チェックを実行
        } else {
          alert(deleteErrorMessage + ': ' + result.message);
        }
      } catch (error) {
        console.error('データの削除中にエラーが発生しました:', error);
        alert(deleteErrorMessage);
      }
    }
  };
  
  
    

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(formData);

    try {
      const response = await fetch('https://xs525443.xsrv.jp/admin_ui/submit011.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const successMessage = language === 'ja' ? 'データが正常に送信されました。' : 'Submission Completed';
    const failureMessage = language === 'ja' ? 'データの送信に失敗しました: ' : 'Failed to submit data: ';
    const errorMessage = language === 'ja' ? 'データの送信に失敗しました。' : 'Failed to submit data.';

      if (response.ok) {
       // alert(successMessage);
        setIsSubmitted(true);
        setIsReadOnly(true);
      } else {
        alert('データの送信中にエラーが発生しました。');
      }
    } catch (error) {
      console.error('データ送信エラー:', error);
      alert('データの送信中にエラーが発生しました。');
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    if (isReadOnly) return;
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  

  const handleRequestChange = (index: number, section: string, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (isReadOnly) return;
    const { name, value } = e.target;
    const isChecked = e.target instanceof HTMLInputElement ? e.target.checked : false;
  
    if (section === 'advertisingRequests') {
      const updatedRequests = formData.advertisingRequests.map((request, i) => {
        if (i === index) {
          return { ...request, [name]: name === 'checked' ? isChecked : value };
        }
        return request;
      });
      setFormData(prevState => ({ ...prevState, advertisingRequests: updatedRequests }));
    } 
  };
  
  

  const handleBillingChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    if (isReadOnly) return;
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      billing: { ...prevState.billing, [name as string]: value }
    }));
  };

  const handlePostalCodeChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isReadOnly) return;
    const { name, value } = e.target;
    const cleanedValue = value.replace(/-/g, ''); // ハイフンを除去
  
    setFormData(prevState => ({
      ...prevState,
      billing: { ...prevState.billing, [name]: cleanedValue }
    }));
  
    if (cleanedValue.length === 7) {
      try {
        const response = await fetch(`https://api.zipaddress.net/?zipcode=${cleanedValue}`);
        const data = await response.json();
        if (data.code === 200) {
          setFormData(prevState => ({
            ...prevState,
            billing: {
              ...prevState.billing,
              billingAddressPrefecture: data.data.pref,
              billingAddressCity: data.data.city,
              billingAddressDetails: data.data.town
            }
          }));
        } else {
          alert('郵便番号から住所を取得できませんでした。');
        }
      } catch (error) {
        console.error('郵便番号から住所を取得中にエラーが発生しました:', error);
      }
    }
  };
  

  const prefectures = ["北海道", "青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県", "茨城県", "栃木県", "群馬県", "埼玉県", "千葉県", "東京都", "神奈川県", "新潟県", "富山県", "石川県", "福井県", "山梨県", "長野県", "岐阜県", "静岡県", "愛知県", "三重県", "滋賀県", "京都府", "大阪府", "兵庫県", "奈良県", "和歌山県", "鳥取県", "島根県", "岡山県", "広島県", "山口県", "徳島県", "香川県", "愛媛県", "高知県", "福岡県", "佐賀県", "長崎県", "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県"];

  return (
    <Layout title={<MultiLangText
      language={language}
      textJa="申請書類管理　11．会場内広告・小間上空バナー広告 申込み"
      textEn="11.Application for Onsite Advertisement and Hanging Banner Advertisement"
      />}>
      <Paper style={{ padding: 16 }}>
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="■出展者情報"
                textEn="■Exhibitor information"
                />
        </Typography>
        <hr/>
        <form onSubmit={handleSubmit}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault(); // Enterキーによるフォーム送信を防ぐ
             // アラートを表示
          }
        }}>
          <ExhibitorInfoForm
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
          />

          {/* セクションスタート */}
          <Typography gutterBottom mt={5} style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="■会場内広告（希望する種類に申込み数量をご記入下さい）"
                textEn="■On-site advertisement(Please select menus and input quantities)"
                />
          </Typography>
          <hr/>
          <Grid container alignItems="center">
          <MultiLangText
                language={language}
                textJa="メニュー内容"
                textEn="Details "
                />
          
            {formData.advertisingRequests.map((request, index) => (
              <Grid container spacing={3} alignItems="center" key={index}>
                <Grid item xs={8} sm={8}>
                  {request.id === 1 && <MultiLangText language={language} textJa="①会議棟1Fパッセージ柱巻き ¥275,000 (税込)　募集枠：４" textEn="①Pillar wrap advertisement/Conference Tower 1F Passage　<br/>JPY 275,000(Tax Included) Limited to:4" />}
                  {request.id === 2 && <MultiLangText language={language} textJa="②南コンコース柱巻き ¥308,000 (税込)　募集枠：２" textEn="②Pillar wrap advertisement/South Concourse　<br/>JPY 308,000(Tax Included) Limited to:2" />}
                  {request.id === 3 && <MultiLangText language={language} textJa="③南コンコースエスカレーター上床面シート ¥330,000 (税込)　募集枠：１" textEn="③Floor sheet/In front of landing plat form of south concourse escalators(4F)　JPY 330,000(Tax Included) Limited to:1" />}
                  {request.id === 4 && <MultiLangText language={language} textJa="④南コンコースエスカレーター下床面シート ¥330,000 (税込)　募集枠：１" textEn="④Floor sheet/In front of landing plat form of south concourse escalators(2F)　JPY 330,000(Tax Included) Limited to:1" />}
                  {request.id === 5 && <MultiLangText language={language} textJa="⑤西ホールロングエスカレーター下床面シート ¥330,000 (税込)　募集枠：１" textEn="⑤Floor sheet/In front of landing plat form of west hall long escalators(1F)　JPY 330,000(Tax Included) Limited to:1" />}
                  {request.id === 6 && <MultiLangText language={language} textJa="⑥西ホールエスカレーター下床面シート ¥330,000 (税込)　募集枠：１" textEn="⑥Floor sheet/In front of landing plat form of west hall escalators(1F)　JPY 330,000(Tax Included) Limited to:1" />}
                  {request.id === 7 && <MultiLangText language={language} textJa="⑦西アトリウムエスカレーター上空バナー(両面) ¥275,000 (税込)　募集枠：４" textEn="⑦Hanging banner above escalator/West Hall Atrium(Both sides)　JPY 275,000(Tax Included) Limited to:4" />}
                  {request.id === 8 && <MultiLangText language={language} textJa="⑧会場案内図横サインタワー ¥220,000 (税込)　募集枠：２" textEn="⑧Advertisement next to guide map　JPY 220,000(Tax Included) Limited to:2" />}
                  {request.id === 9 && <MultiLangText language={language} textJa="⑨西アトリウム柱巻き ¥330,000 (税込)　募集枠：３" textEn="⑨Pillar wrap advertisement/West Hall Atrium　JPY 330,000(Tax Included) Limited to:3" />}
                  {request.id === 10 && <MultiLangText language={language} textJa="⑩休憩所デジタルサイネージ ¥176,000 (税込)　募集枠：４" textEn="⑩Digital signage advertisement/Rest Area　JPY 176,000(Tax Included) Limited to:4" />}
                </Grid>
                <Grid item xs={2} sm={2}>
                  <FormControlLabel
                    control={<Checkbox 
                      checked={request.checked} 
                      name="checked" 
                      onChange={(e) => handleRequestChange(index, 'advertisingRequests', e)} 
                      color="primary" 
                      disabled={isReadOnly} 
                    />}
                    label={<MultiLangText
                      language={language}
                      textJa="申込"
                      textEn="Apply"
                      />}
                  />
                </Grid>
                <Grid item xs={2} sm={2}>
                  {request.checked && ( // チェックされている場合のみ表示
                    <TextField
                      label={<MultiLangText
                        language={language}
                        textJa="枠"
                        textEn="unit(s)"
                        />}
                      name="quantity"
                      value={request.quantity}
                      onChange={(e) => handleRequestChange(index, 'advertisingRequests', e)}
                      sx={{ width: 70 }}
                      margin="normal"
                      required
                      disabled={isReadOnly} 
                      inputProps={{ maxLength: 1 }}
                    />
                  )}
                </Grid>
              </Grid>
            ))}

          <Grid item xs={12} sm={12}>
          <MultiLangText
                language={language}
                textJa="※原稿入稿<br/>
                ・入稿期限：9月13日（金）<br/>
                ・入稿先：㈱ムラヤマ2024 国際航空宇宙展担当 宛<br/>
                ・E-mail：ja2024@murayama.co.jp<br/>
                ・データ形式：Illustrator または PDF"
                                textEn="Data submission<br/>
                ・Submission deadline：September 13(Fri.)<br/>
                ・Submit to:MURAYAMA INC.<br/>
                ・E-mail：ja2024@murayama.co.jp<br/>
                ・Submission format:File format: Illustrator/PDF"
                />
          </Grid>
          </Grid>

          {/* セクションスタート */}
          <Typography gutterBottom mt={5} style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="■小間上空バナー広告（16小間・144㎡以上の出展者対象 1社1枚まで）"
                textEn="■ Hanging banners in booths （For 144㎡ or more）"
                />
          </Typography>
          <hr/>
          <Grid container alignItems="center">
          <MultiLangText
                language={language}
                textJa="メニュー内容"
                textEn="Details "
                />
<RadioGroup
  row
  aria-label="decorationHeight1"
  name="decorationHeight1"
  value={formData.bannerRequests}
  onChange={(e) => {
    const value = e.target.value;
    setFormData(prevState => ({
      ...prevState,
      bannerRequests: value
    }));
  }}
>
  {/*1*/}       
  <Grid container spacing={3} alignItems="center">
    <Grid item xs={8} sm={8}>
    <MultiLangText language={language} 
    textJa="①片面基本サイズ ¥385,000 (税込)" 
    textEn="①Basic Size(One side print)　JPY 385,000(Tax Included）" 
    />
 </Grid>
    <Grid item xs={4} sm={4}>
    <FormControlLabel value="①片面基本サイズ" control={<Radio required />} label={<MultiLangText
    language={language}
    textJa="申込"
    textEn="Apply"
    />} disabled={isReadOnly}/>
    </Grid>
  </Grid>
{/*2*/}
  <Grid container spacing={3} alignItems="center">
    <Grid item xs={8} sm={8}>
    <MultiLangText language={language} 
    textJa="②両面基本サイズ ¥605,000 (税込)" 
    textEn="②Basic Size(Both sides print)　JPY 605,000(Tax Included)" 
    />
 </Grid>
    <Grid item xs={4} sm={4}>
    <FormControlLabel value="②両面基本サイズ" control={<Radio required />} label={<MultiLangText
    language={language}
    textJa="申込"
    textEn="Apply"
    />} disabled={isReadOnly}/>
    </Grid>
  </Grid>

{/*3*/}
<Grid container spacing={3} alignItems="center">
    <Grid item xs={8} sm={8}>
    <MultiLangText language={language} 
    textJa="③片面大型サイズ ¥605,000 (税込)" 
    textEn="③Large Size(One side print)  JPY 605,000(Tax Included)" 
    />
 </Grid>
    <Grid item xs={4} sm={4}>
    <FormControlLabel value="③片面大型サイズ" control={<Radio required />} label={<MultiLangText
    language={language}
    textJa="申込"
    textEn="Apply"
    />} disabled={isReadOnly}/>
    </Grid>
  </Grid>

  {/*4*/}
<Grid container spacing={3} alignItems="center">
    <Grid item xs={8} sm={8}>
    <MultiLangText language={language} 
    textJa="④両面大型サイズ ¥880,000 (税込)" 
    textEn="④Large Size(Both sides print)  JPY 880,000(Tax Included)" 
    />
 </Grid>
    <Grid item xs={4} sm={4}>
    <FormControlLabel value="④両面大型サイズ" control={<Radio required />} label={<MultiLangText
    language={language}
    textJa="申込"
    textEn="Apply"
    />} disabled={isReadOnly}/>
    </Grid>
  </Grid>

    {/*5*/}
<Grid container spacing={3} alignItems="center">
    <Grid item xs={8} sm={8}>
    <MultiLangText language={language} 
    textJa="" 
    textEn="" 
    />
 </Grid>
    <Grid item xs={4} sm={4}>
    <FormControlLabel value="申し込まない" control={<Radio required />} label={<MultiLangText
    language={language}
    textJa="申し込まない"
    textEn="Do not apply"
    />} disabled={isReadOnly}/>
    </Grid>
  </Grid>


  </RadioGroup>





          <Grid item xs={12} sm={12}>
          <MultiLangText
                language={language}
                textJa="※原稿入稿<br/>
              ・入稿期限：8月30日（金）<br/>
              ・入稿先：㈱ムラヤマ2024 国際航空宇宙展担当 宛<br/>
              ・E-mail：ja2024@murayama.co.jp<br/>
              ・データ形式：Illustrator または PDF"
                              textEn="Data submission<br/>
              ・Submission deadline：August 30 (Fri.)<br/>
              ・Submit to:MURAYAMA INC.<br/>
              ・E-mail：ja2024@murayama.co.jp<br/>
              ・Submission format:File format: Illustrator/PDF"
                /><br/><br/>
          </Grid>
          </Grid>


          {/* セクションスタート */}
          <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="■請求先について"
                textEn="■ Invoice Recipient "
                />
          </Typography>
          <hr />
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            <MultiLangText
                language={language}
                textJa="お選びください"
                textEn="Please select  "
                /> <RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>
              <RadioGroup
                row
                aria-label="decoration"
                name="decoration3mm"
                value={formData.decoration3mm}
                onChange={(e) => setFormData({ ...formData, decoration3mm: e.target.value })}
              >
                <Grid item xs={6} sm={6}>
                  <FormControlLabel value="出展者" control={<Radio required disabled={isReadOnly} />} label={<MultiLangText
                language={language}
                textJa="出展者"
                textEn="Exhibitor"
                />} />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <FormControlLabel value="その他（下記へご記入下さい）" control={<Radio required disabled={isReadOnly} />} label={<MultiLangText
                language={language}
                textJa="その他（下記へご記入下さい）"
                textEn="Other(Fill out the following blanks)"
                />} />
                </Grid>
              </RadioGroup>
            </Grid>

            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            <MultiLangText
                language={language}
                textJa="請求先社名"
                textEn="Company Name"
                /> <RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>
              <TextField
                name="billingCompanyName"
                value={formData.billing.billingCompanyName}
                onChange={handleBillingChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly} 
              />
            </Grid>

            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            <MultiLangText
                language={language}
                textJa="担当者携帯"
                textEn="Mobile Phone Number"
                /> <RequiredLabel />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                name="billingContactMobile"
                value={formData.billing.billingContactMobile}
                onChange={handleBillingChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly} 
              />
            </Grid>
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              TEL <RequiredLabel />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                
                name="billingContactTel"
                value={formData.billing.billingContactTel}
                onChange={handleBillingChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly} 
              />
            </Grid>

            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            <MultiLangText
                language={language}
                textJa="担当者部署・役職"
                textEn="Title / Department of the Contact Person"
                />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                name="billingContactPosition"
                value={formData.billing.billingContactPosition}
                onChange={handleBillingChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly} 
              />
            </Grid>
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            <MultiLangText
                language={language}
                textJa="担当者名　"
                textEn="Name of Contact Person"
                /> <RequiredLabel />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                name="billingContactName"
                value={formData.billing.billingContactName}
                onChange={handleBillingChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly} 
              />
            </Grid>

            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            <MultiLangText
                language={language}
                textJa="住所　"
                textEn="Address"
                />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>
              <Grid container>
                <Grid item xs={5} sm={5}>
                  <TextField
                    label={<MultiLangText
                      language={language}
                      textJa="〒"
                      textEn="Postal Code"
                      />}
                    name="billingAddressPostal"
                    value={formData.billing.billingAddressPostal}
                    onChange={handlePostalCodeChange}
                    fullWidth
                    disabled={isReadOnly} 
                  />
                </Grid>
                <Grid item xs={0} sm={0.1}></Grid>
                <Grid item xs={5} sm={5}>
                  {isJapanese && (
                    <FormControl fullWidth>
                      <InputLabel id="prefecture-label">都道府県</InputLabel>
                      <Select
                        labelId="prefecture-label"
                        id="prefecture"
                        name="billingAddressPrefecture"
                        value={formData.billing.billingAddressPrefecture}
                        label="都道府県"
                        onChange={handleBillingChange}
                        disabled={isReadOnly} 
                      >
                        {prefectures.map((prefecture, index) => (
                          <MenuItem key={index} value={prefecture}>{prefecture}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={2} sm={2} style={{ display: 'flex' }}></Grid>
            <Grid item xs={9} sm={9}>
              <TextField
                label={<MultiLangText
                language={language}
                textJa="市区町村名（例：大阪市北区）"
                textEn="city"
                />}

                name="billingAddressCity"
                value={formData.billing.billingAddressCity}
                onChange={handleBillingChange}
                fullWidth
                disabled={isReadOnly} 
              />
            </Grid>

            <Grid item xs={2} sm={2} style={{ display: 'flex' }}></Grid>
            <Grid item xs={9} sm={9}>
              <TextField
                label={<MultiLangText
                language={language}
                textJa="番地・ビル名（例：〇〇１丁目2-3）"
                textEn="Address and Building Name"
                />}
                name="billingAddressDetails"
                value={formData.billing.billingAddressDetails}
                onChange={handleBillingChange}
                fullWidth
                disabled={isReadOnly} 
              />
            </Grid>

            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            <MultiLangText
                language={language}
                textJa="メールアドレス"
                textEn="E-mail"
                />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>
              <TextField
                label="XXXXX@xxxxxx.com"
                name="billingContactEmail"
                value={formData.billing.billingContactEmail}
                onChange={handleBillingChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly} 
              />
            </Grid>

            <Grid item xs={2} sm={2} style={{ display: 'flex' }}></Grid>
            <Grid item xs={9} sm={9}>
            <MultiLangText
                language={language}
                textJa="※上記の請求先が出展者と異なる場合に記入してください。"
                textEn="*Please fill in above if the billing information is different from the exhibitor."
                />
            </Grid>

          </Grid>
          {/* セクションここまで */}
          {/* セクションスタート */}
          <Typography gutterBottom mt={5} style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="■ お問い合わせ先について　※備考・その他は、下記まで直接ご連絡ください。"
                textEn="■Contact Information　If you have any questions or concerns, please inquire with the following contact."
                />
            </Typography>
            <hr></hr>
            <Grid container spacing={3} alignItems="center">

            {/* カラム */}
            <Grid item xs={12} sm={12}>
            <MultiLangText
                language={language}
                textJa="（株）東京ビッグサイト 2024国際航空宇宙展事務局<br/>
                〒135-0063 東京都江東区有明3-11-1<br/>
TEL：03-5530-1324<br/>
E-mail：bigsight-ja@tokyo-bigsight.co.jp"
                textEn="JA2024 Management Office (Tokyo Big Sight Inc.)<br/>
                3-11-1, Ariake, Koto-ku, Tokyo 135-0063<br/>
TEL：＋81-3-5530-1324<br/>
E-mail：bigsight-ja@tokyo-bigsight.co.jp"
                />
            </Grid>
          {/* カラム */}
          <Grid item xs={12} sm={12} style={{ display: 'flex'}}>
              <MultiLangText
                language={language}
                textJa="
※下記、「確定」ボタンを押して、申請してください。「保存」中は修正が可能です。<br/>
              ※「リセット」ボタンがある申請書については、万一、確定後に修正が必要な場合も、内容の修正が可能です。
"
                textEn='
*Please click the "SUBMIT" button to submit your application. You can "SAVE” the form while you still need to modify it.<br/>
*Some forms have “RESET” button, which you can use when you need to reset and fill in the form again after submitting the form.
'
              />
            </Grid>
            </Grid> 
            {/* セクションここまで */}

            <Grid container justifyContent="center" spacing={2} mt={5}>
            <Grid item>
              <Button
                onClick={handleReset}
                variant="contained"
                color="secondary"
                disabled={buttonState?.reset} // 外部JSONの値を使用
                style={{ filter: buttonState?.reset ? 'grayscale(1)' : 'none' }} // グレースケール
              >
                <MultiLangText language={language} textJa="リセット" textEn="Reset" />
              </Button>
            </Grid>

            <Grid item>
              <Button
                onClick={handleSave}
                variant="contained"
                color="primary"
                disabled={buttonState?.save} // 外部JSONの値を使用
                style={{ filter: buttonState?.save ? 'grayscale(1)' : 'none' }} // グレースケール
              >
                <MultiLangText language={language} textJa="保存" textEn="Save" />
              </Button>
            </Grid>

            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={buttonState?.submit || isSubmitted || isDuplicate} // 外部JSONと送信済み、重複の状態を考慮
                style={{ filter: (buttonState?.submit || isSubmitted || isDuplicate) ? 'grayscale(1)' : 'none' }} // グレースケール
              >
                <MultiLangText language={language} textJa="確定" textEn="Submit" />
              </Button>
            </Grid>
          </Grid>

        </form>
      </Paper>
    </Layout>
  );
};

export default Template011;
