import React, { useState } from 'react';
import Layout from '../templetes/Layout';
import { 
  TextField, Button, Box, Typography, Paper, Grid, FormControlLabel, Checkbox, Select, MenuItem, InputLabel, FormControl
} from '@mui/material';
import ExhibitorInfoForm from '../templetes/ExhibitorInfoForm';
import { SelectChangeEvent } from '@mui/material';
import RequiredLabel from '../templetes/RequiredLabel';
import MultiLangText from '../templetes/MultiLangText';
import { useAuth } from '../../context/AuthContext';

const Template006: React.FC = () => {
  const { language } = useAuth();
  const [formData, setFormData] = useState({
    exhibitorName: '',
    boothNumber: '',
    tel: '',
    departmentPosition: '',
    contactPerson: '',
    postalCode: '',
    prefecture: '',
    city: '',
    address: '',
    email: '',
    Company_ID: '',
  });


  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // フォームデータの送信処理をここに追加する
    console.log(formData);
    // 例: APIにデータを送信する、またはフォームのリセットなど
    setFormData({
      exhibitorName: '',
      boothNumber: '',
      tel: '',
      departmentPosition: '',
      contactPerson: '',
      postalCode: '',
      prefecture: '',
      city: '',
      address: '',
      email: '',
      Company_ID: ''
    });
  };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name as string]: value
    }));
  };

  const prefectures = ["北海道", "青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県", "茨城県", "栃木県", "群馬県", "埼玉県", "千葉県", "東京都", "神奈川県", "新潟県", "富山県", "石川県", "福井県", "山梨県", "長野県", "岐阜県", "静岡県", "愛知県", "三重県", "滋賀県", "京都府", "大阪府", "兵庫県", "奈良県", "和歌山県", "鳥取県", "島根県", "岡山県", "広島県", "山口県", "徳島県", "香川県", "愛媛県", "高知県", "福岡県", "佐賀県", "長崎県", "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県"];

  
  return (
    <Layout title="申請書類管理　６．出展者プレゼンテーション利用申込み">
      <Paper style={{ padding: 16 }}>
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="■出展者情報"
                textEn="■Exhibitor information"
                />
        </Typography>
        <hr/>
        <form onSubmit={handleSubmit}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault(); // Enterキーによるフォーム送信を防ぐ
             // アラートを表示
          }
        }}>
        <ExhibitorInfoForm
          formData={formData}
          setFormData={setFormData}
          handleChange={handleChange}
        />


      {/* セクションスタート */}
      <Typography gutterBottom style={{ fontWeight: 'bold' }}>
      ■ 出展者プレゼンテーションについて　（タイムテーブルは、こちらをダウンロードください。）
      </Typography>
      <hr></hr>
      <Grid container spacing={3} alignItems="center">

      {/* カラム */}
      <Grid item xs={3} sm={3} style={{ display: 'flex'}}>
      ①-ⓐプレゼン内容
      </Grid>
      <Grid item xs={1} sm={1} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={8} sm={8}>
        <Grid container spacing={3} alignItems="center">
        <Grid item xs={2} sm={2}>
          タイトル
        </Grid>
        <Grid item xs={10} sm={10}>
          <TextField
          label="XXXXX@xxxxxx.com"
          name="exhibitorName"
          value={formData.exhibitorName}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
          />
        </Grid>
        </Grid>
      </Grid>

      {/* カラム */}
      <Grid item xs={3} sm={3} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={1} sm={1} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={8} sm={8}>
        <Grid container spacing={3} alignItems="center">
        <Grid item xs={2} sm={2}>
           詳細
        </Grid>
        <Grid item xs={10} sm={10}>
          <TextField
          label="XXXXX@xxxxxx.com"
          name="exhibitorName"
          value={formData.exhibitorName}
          onChange={handleChange}
          fullWidth
          required
          />
        </Grid>
        </Grid>
      </Grid>

      {/* カラム */}
      <Grid item xs={3} sm={3} style={{ display: 'flex'}}>
      ①-ⓑ希望日時
      </Grid>
      <Grid item xs={1} sm={1} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={8} sm={8}>
        <Grid container spacing={3} alignItems="center">
        <Grid item xs={2} sm={2}>
        第一希望
        </Grid>
        <Grid item xs={10} sm={10}>
          <TextField
          label="資料確認"
          name="exhibitorName"
          value={formData.exhibitorName}
          onChange={handleChange}
          required
          />
          
        </Grid>
        </Grid>
      </Grid>
      </Grid>

      <Grid container spacing={3} alignItems="center">

      {/* カラム */}
      <Grid item xs={3} sm={3} style={{ display: 'flex'}}>
      ①-ⓐプレゼン内容
      </Grid>
      <Grid item xs={1} sm={1} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={8} sm={8}>
        <Grid container spacing={3} alignItems="center">
        <Grid item xs={2} sm={2}>
          タイトル
        </Grid>
        <Grid item xs={10} sm={10}>
          <TextField
          label="XXXXX@xxxxxx.com"
          name="exhibitorName"
          value={formData.exhibitorName}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
          />
        </Grid>
        </Grid>
      </Grid>

      {/* カラム */}
      <Grid item xs={3} sm={3} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={1} sm={1} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={8} sm={8}>
        <Grid container spacing={3} alignItems="center">
        <Grid item xs={2} sm={2}>
           詳細
        </Grid>
        <Grid item xs={10} sm={10}>
          <TextField
          label="XXXXX@xxxxxx.com"
          name="exhibitorName"
          value={formData.exhibitorName}
          onChange={handleChange}
          fullWidth
          required
          />
        </Grid>
        </Grid>
      </Grid>

      {/* カラム */}
      <Grid item xs={3} sm={3} style={{ display: 'flex'}}>
      ①-ⓑ希望日時
      </Grid>
      <Grid item xs={1} sm={1} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={8} sm={8}>
        <Grid container spacing={3} alignItems="center">
        <Grid item xs={2} sm={2}>
        第一希望
        </Grid>
        <Grid item xs={10} sm={10}>
          <TextField
          label="資料確認"
          name="exhibitorName"
          value={formData.exhibitorName}
          onChange={handleChange}
          required
          />
          
        </Grid>
        </Grid>
      </Grid>

{/* カラム */}
<Grid item xs={3} sm={3} style={{ display: 'flex'}}>
      ①-ⓐプレゼン内容
      </Grid>
      <Grid item xs={1} sm={1} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={8} sm={8}>
        <Grid container spacing={3} alignItems="center">
        <Grid item xs={2} sm={2}>
          タイトル
        </Grid>
        <Grid item xs={10} sm={10}>
          <TextField
          label="XXXXX@xxxxxx.com"
          name="exhibitorName"
          value={formData.exhibitorName}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
          />
        </Grid>
        </Grid>
      </Grid>

      {/* カラム */}
      <Grid item xs={3} sm={3} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={1} sm={1} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={8} sm={8}>
        <Grid container spacing={3} alignItems="center">
        <Grid item xs={2} sm={2}>
           詳細
        </Grid>
        <Grid item xs={10} sm={10}>
          <TextField
          label="XXXXX@xxxxxx.com"
          name="exhibitorName"
          value={formData.exhibitorName}
          onChange={handleChange}
          fullWidth
          required
          />
        </Grid>
        </Grid>
      </Grid>

      {/* カラム */}
      <Grid item xs={3} sm={3} style={{ display: 'flex'}}>
      ①-ⓑ希望日時
      </Grid>
      <Grid item xs={1} sm={1} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={8} sm={8}>
        <Grid container spacing={3} alignItems="center">
        <Grid item xs={2} sm={2}>
        第一希望
        </Grid>
        <Grid item xs={10} sm={10}>
          <TextField
          label="資料確認"
          name="exhibitorName"
          value={formData.exhibitorName}
          onChange={handleChange}
          required
          />
          
        </Grid>
        </Grid>
      </Grid>
      </Grid>

      <Grid container spacing={3} alignItems="center">

      {/* カラム */}
      <Grid item xs={3} sm={3} style={{ display: 'flex'}}>
      ①-ⓐプレゼン内容
      </Grid>
      <Grid item xs={1} sm={1} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={8} sm={8}>
        <Grid container spacing={3} alignItems="center">
        <Grid item xs={2} sm={2}>
          タイトル
        </Grid>
        <Grid item xs={10} sm={10}>
          <TextField
          label="XXXXX@xxxxxx.com"
          name="exhibitorName"
          value={formData.exhibitorName}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
          />
        </Grid>
        </Grid>
      </Grid>

      {/* カラム */}
      <Grid item xs={3} sm={3} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={1} sm={1} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={8} sm={8}>
        <Grid container spacing={3} alignItems="center">
        <Grid item xs={2} sm={2}>
           詳細
        </Grid>
        <Grid item xs={10} sm={10}>
          <TextField
          label="XXXXX@xxxxxx.com"
          name="exhibitorName"
          value={formData.exhibitorName}
          onChange={handleChange}
          fullWidth
          required
          />
        </Grid>
        </Grid>
      </Grid>

      {/* カラム */}
      <Grid item xs={3} sm={3} style={{ display: 'flex'}}>
      ①-ⓑ希望日時
      </Grid>
      <Grid item xs={1} sm={1} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={8} sm={8}>
        <Grid container spacing={3} alignItems="center">
        <Grid item xs={2} sm={2}>
        第一希望
        </Grid>
        <Grid item xs={10} sm={10}>
          <TextField
          label="資料確認"
          name="exhibitorName"
          value={formData.exhibitorName}
          onChange={handleChange}
          required
          />
          
        </Grid>
        </Grid>
      </Grid>

      </Grid>  
      {/* セクションここまで */} 
      <br></br><br></br>
{/* セクションスタート */}
<Typography gutterBottom style={{ fontWeight: 'bold' }}>
      ■ 請求先について（出展者以外のご請求の場合は、下記にご記入下さい）
      </Typography>
      <hr></hr>
      <Grid container spacing={2} alignItems="center">

      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      お選び下さい　<RequiredLabel/>
      </Grid>
      <Grid item xs={9} sm={9}>
      資料確認
      </Grid>

      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      請求先社名　<RequiredLabel/>
      </Grid>
      <Grid item xs={9} sm={9}>
      <TextField
        
        name="exhibitorName"
        value={formData.exhibitorName}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      </Grid>

      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      担当者携帯　<RequiredLabel/>
      </Grid>
      <Grid item xs={4} sm={4}>
      <TextField
        
        name="exhibitorName"
        value={formData.exhibitorName}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      </Grid>
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      TEL　<RequiredLabel/>
      </Grid>
      <Grid item xs={4} sm={4}>
      <TextField
        
        name="exhibitorName"
        value={formData.exhibitorName}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      </Grid>

      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      担当者部署・役職
      </Grid>
      <Grid item xs={4} sm={4}>
      <TextField
        
        name="exhibitorName"
        value={formData.exhibitorName}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      </Grid>
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      担当者名　<RequiredLabel/>
      </Grid>
      <Grid item xs={4} sm={4}>
      <TextField
        
        name="exhibitorName"
        value={formData.exhibitorName}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      </Grid>

      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      住所　<RequiredLabel/>
      </Grid>
      <Grid item xs={9} sm={9}>

        <Grid container>
        <Grid item xs={5} sm={5}>
        <TextField
          label={<MultiLangText
                language={language}
                textJa="〒"
                textEn="Postal Code"
                />}
          name="exhibitorName"
          value={formData.exhibitorName}
          onChange={handleChange}
          fullWidth
          required
        />
        </Grid>

        <Grid item xs={0} sm={0.1}></Grid>

        <Grid item xs={5} sm={5}>
        <FormControl fullWidth>
          <InputLabel id="prefecture-label">都道府県</InputLabel>
          <Select
            labelId="prefecture-label"
            id="prefecture"
            name="prefecture"
            value={formData.prefecture}
            label="都道府県"
            onChange={(e) => setFormData({ ...formData, prefecture: e.target.value })}
            required
          >
            {prefectures.map((prefecture, index) => (
              <MenuItem key={index} value={prefecture}>{prefecture}</MenuItem>
            ))}
          </Select>
        </FormControl>
        </Grid>
        
        </Grid>
      </Grid>


      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={9} sm={9}>
      <TextField
        label={<MultiLangText
                language={language}
                textJa="市区町村名（例：大阪市北区）"
                textEn="city"
                />}

        name="exhibitorName"
        value={formData.exhibitorName}
        onChange={handleChange}
        fullWidth
        required
      />
      </Grid>

      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={9} sm={9}>
      <TextField
        label={<MultiLangText
                language={language}
                textJa="番地・ビル名（例：〇〇１丁目2-3）"
                textEn="Address and Building Name"
                />}
        name="exhibitorName"
        value={formData.exhibitorName}
        onChange={handleChange}
        fullWidth
        required
      />
      </Grid>


      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      メールアドレス　<RequiredLabel/>
      </Grid>
      <Grid item xs={9} sm={9}>
      <TextField
        label="XXXXX@xxxxxx.com"
        name="exhibitorName"
        value={formData.exhibitorName}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      </Grid>

      {/* カラム */}
      <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
      </Grid>
      <Grid item xs={9} sm={9}>
      ※〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇
      </Grid>

      </Grid> 
      {/* セクションここまで */} 

        </form>
      </Paper>
    </Layout>
  );
};

export default Template006;
