import React, { useState, useEffect } from 'react';
import Layout from '../templetes/Layout';
import { TextField, Button, Typography, Paper, Grid, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import ExhibitorInfoForm from '../templetes/ExhibitorInfoForm';
import MultiLangText from '../templetes/MultiLangText';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';


const Template010: React.FC = () => {
  const { language } = useAuth();
  const isJapanese = language === 'ja';
  
  const [formData, setFormData] = useState({
    exhibitorName: '',
    boothNumber: '',
    tel: '',
    departmentPosition: '',
    contactPerson: '',
    postalCode: '',
    prefecture: '',
    city: '',
    address: '',
    email: '',
    Company_ID: '',
    buttonState: {
      reset: false,
      submit: false
    }
  });

  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

      // 外部JSONファイルからボタンの状態を取得する関数
      const loadButtonState = async () => {
        try {
          const timestamp = new Date().getTime();
          const response = await axios.get(`/json/buttonState10.json?_=${timestamp}`);  // 外部JSONファイルのパス
          const buttonStateData = response.data;
          setFormData(prevState => ({
            ...prevState,
            buttonState: buttonStateData.buttonState
          }));
        } catch (error) {
          console.error('ボタン状態の読み込み中にエラーが発生しました:', error);
        }
      };

  useEffect(() => {
    if (formData.exhibitorName) {
      checkDuplicate();
    }
  }, [formData.exhibitorName]);

  const checkDuplicate = async () => {
    try {
      const response = await fetch('https://xs525443.xsrv.jp/admin_ui/check010.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          exhibitorName: formData.exhibitorName,
          Company_ID: formData.Company_ID
        }),
      });

      const text = await response.text();
      console.log('レスポンステキスト:', text);
      const result = JSON.parse(text);

      if (result.isDuplicate && result.additionalData) {
        try {
          const parsedData = JSON.parse(result.additionalData);
          setFormData(prevState => ({
            ...prevState,
            ...parsedData,
          }));
          setIsDuplicate(true);
          setIsReadOnly(true);
        } catch (error) {
          console.error('JSONデータのパース中にエラーが発生しました:', error);
        }
      } else {
        setIsDuplicate(false);
        setIsReadOnly(false);
      }
    } catch (error) {
      console.error('重複チェック中にエラーが発生しました:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('exhibitorName', formData.exhibitorName);
    formDataToSend.append('boothNumber', formData.boothNumber);
    formDataToSend.append('tel', formData.tel);
    formDataToSend.append('departmentPosition', formData.departmentPosition);
    formDataToSend.append('contactPerson', formData.contactPerson);
    formDataToSend.append('postalCode', formData.postalCode);
    formDataToSend.append('prefecture', formData.prefecture);
    formDataToSend.append('city', formData.city);
    formDataToSend.append('address', formData.address);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('Company_ID', formData.Company_ID);

    try {
      const response = await fetch('https://xs525443.xsrv.jp/admin_ui/submit010.php', {
        method: 'POST',
        body: formDataToSend
      });

      const successMessage = language === 'ja' ? 'データが正常に送信されました。' : 'Submission Completed';
    const failureMessage = language === 'ja' ? 'データの送信に失敗しました: ' : 'Failed to submit data: ';
    const errorMessage = language === 'ja' ? 'データの送信に失敗しました。' : 'Failed to submit data.';

      if (response.ok) {
       // alert(successMessage);
        setIsSubmitted(true);
        setIsReadOnly(true);
      } else {
        alert('データの送信中にエラーが発生しました。');
      }
    } catch (error) {
      console.error('データ送信エラー:', error);
      alert('データの送信中にエラーが発生しました。');
    }
  };

  const handleReset = async () => {
  const confirmMessage = language === 'ja' ? '本当に削除していいですか？' : 'Are you sure you want to reset?';
  const resetSuccessMessage = language === 'ja' ? 'フォームとファイルがリセットされました。' : 'Reset Completed';
  const deleteErrorMessage = language === 'ja' ? 'データの削除に失敗しました。' : 'Failed to delete data.';
    if (window.confirm(confirmMessage)) {
      try {
        const response = await fetch('https://xs525443.xsrv.jp/admin_ui/delete010.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
          exhibitorName: formData.exhibitorName,
          Company_ID: formData.Company_ID
        }),
        });
  
        const result = await response.json();
  
        if (result.success) {
          localStorage.removeItem('formData010');
          alert(resetSuccessMessage);
          checkDuplicate();
        } else {
          alert(deleteErrorMessage + ': ' + result.message);
        }
      } catch (error) {
        console.error('データの削除中にエラーが発生しました:', error);
        alert(deleteErrorMessage);
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> ) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const prefectures = ["北海道", "青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県", "茨城県", "栃木県", "群馬県", "埼玉県", "千葉県", "東京都", "神奈川県", "新潟県", "富山県", "石川県", "福井県", "山梨県", "長野県", "岐阜県", "静岡県", "愛知県", "三重県", "滋賀県", "京都府", "大阪府", "兵庫県", "奈良県", "和歌山県", "鳥取県", "島根県", "岡山県", "広島県", "山口県", "徳島県", "香川県", "愛媛県", "高知県", "福岡県", "佐賀県", "長崎県", "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県"];

  useEffect(() => {
    loadButtonState(); // コンポーネントのマウント時にボタン状態を読み込む
  }, []);
  
  return (
    <Layout title={<MultiLangText
      language={language}
      textJa="申請書類管理　10．保税展示物 申請 申込み"
      textEn="Application Forms　10.Application for Bonded Display Items"
    />}>
      <Paper style={{ padding: 16 }}>
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          <MultiLangText
            language={language}
            textJa="■出展者情報"
            textEn="■Exhibitor information"
          />
        </Typography>
        <hr />
        <form onSubmit={handleSubmit}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault(); // Enterキーによるフォーム送信を防ぐ
             // アラートを表示
          }
        }}>
          <ExhibitorInfoForm
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
          />

          {/* セクションスタート */}
          <Typography gutterBottom mt={5} style={{ fontWeight: 'bold' }}>
            <MultiLangText
              language={language}
              textJa="■保税展示物申請申込み"
              textEn="Application for Bonded Display Items"
            />
          </Typography>
          <hr />
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="内容"
                textEn="Details"
              />　
            </Grid>
            <Grid item xs={10} sm={10}>
              <MultiLangText
                language={language}
                textJa="保税展示を希望する出展者は、下記より申請書をダウンロードしていただき、直接事務局協力会社まで申請ください。"
                textEn="Exhibitor who would like to display bonded display items, please contact the official desk and submit the forms that can be downloaded from here;"
              />
            </Grid>
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={10} sm={10}>
              <MultiLangText
                language={language}
                textJa='日本語申請書は<a href="https://xs525443.xsrv.jp/admin_ui/pdf/保税届出書.xls" target="_blnk">こちら</a>からダウンロード<br>'
                textEn='Japanese application form; <a href="https://xs525443.xsrv.jp/admin_ui/pdf/保税届出書.xls" target="_blnk">here</a><br>'
              />
              <MultiLangText
                language={language}
                textJa='英語申請書は<a href="https://xs525443.xsrv.jp/admin_ui/pdf/Application.xls" target="_blnk">こちら</a>からダウンロード'
                textEn='English application form; <a href="https://xs525443.xsrv.jp/admin_ui/pdf/Application.xls" target="_blnk">here</a>'
              />
            </Grid>
          </Grid>
          {/* セクションここまで */}
          <Typography gutterBottom mt={5} style={{ fontWeight: 'bold' }}>
            <MultiLangText
              language={language}
              textJa="※備考・その他は、下記まで直接ご連絡ください"
              textEn="If you have any questions or concerns, please inquire with the following contact."
            />
          </Typography>
          <hr />
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sm={12}>
              <MultiLangText
                language={language}
                textJa="
                JA２０２４保税・輸送サービス<br/>
                株式会社石川組<br/>
                TEL：03-3437-8102<br/>
                E-mail：ishikawa-ja@ishikawa-gumi.co.jp
                "
                textEn="
                JA 2024 Bond/Trasnportation Service Desk<br/>
                ISHIKAWA-GUMI, Ltd.<br/>
                4-14-2, Higashioi, Shinagawa-ku, Tokyo 140-0011<br/>
                TEL: +81-3-3474-8102<br/>
                E-mail：ishikawa-ja@ishikawa-gumi.co.jp<br/>
                Mizunama, Eyama
                "
              />
            </Grid>
          </Grid>
          <Grid item mt={5} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginRight: 8 }}
            onClick={handleReset}
            disabled={formData.buttonState.reset} // JSONから取得した値を使用
            sx={{ filter: formData.buttonState.reset ? 'grayscale(1)' : 'none' }} // グレーアウト
          >
            <MultiLangText language={language} textJa="リセット" textEn="Reset" />
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={formData.buttonState.submit || isDuplicate || isReadOnly} // JSONと他の状態を組み合わせる
            sx={{ filter: (formData.buttonState.submit || isDuplicate || isReadOnly) ? 'grayscale(1)' : 'none' }} // グレーアウト
          >
            <MultiLangText language={language} textJa="確定" textEn="Submit" />
          </Button>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <MultiLangText
                language={language}
                textJa="
                ※申込不要のお客様も確定ボタンを押してください。リセットボタンにて再度フォームのご利用が可能です。
                "
                textEn="
                *Please click the SUBMIT button even if you do not need to apply. You can use the form again by clicking the RESET button.
                "
              />
            </Grid>
      
        </form>
      </Paper>
    </Layout>
  );
};

export default Template010;
