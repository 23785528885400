import React, { useState, useEffect } from 'react';
import Layout from '../templetes/Layout';
import { Button, TextField, Typography, Paper, Grid, FormControlLabel, Select, MenuItem, InputLabel, FormControl, RadioGroup, Radio, SelectChangeEvent } from '@mui/material';
import RequiredLabel from '../templetes/RequiredLabel';
import ExhibitorInfoForm from '../templetes/ExhibitorInfoForm';
import ContactInfo_big from '../templetes/ContactInfo_big';
import MultiLangText from '../templetes/MultiLangText';
import { useAuth } from '../../context/AuthContext';
import { FormGroup, Checkbox } from '@mui/material';
import axios from 'axios';

const exhibitorInitialData = {
  exhibitorName: '',
  boothNumber: '',
  tel: '',
  departmentPosition: '',
  contactPerson: '',
  postalCode: '',
  prefecture: '',
  city: '',
  address: '',
  email: '',
  Company_ID: '',
  buttonState: {
    reset: false,  // リセットボタンの状態
    save: false,   // 保存ボタンの状態
    submit: false  // 確定ボタンの状態
  }
};

const vehicleInitialData = {
  firstDay: '',
  firstHour: '',
  firstMinute: '',
  secondDay: '',
  secondHour: '',
  secondMinute: '',
  thirdDay: '',
  thirdHour: '',
  thirdMinute: '',
  forDay: '',
  forHour: '',
  forMinute: '',
  vehicleType1Count: '',
  vehicleType1Weight: '',
  vehicleType2Count: '',
  vehicleType2Weight: '',
  vehicleType3Count: '',
  vehicleType3Weight: '',
  vehicleType4Count: '',
  vehicleType5Count: '',
  vehicleType5Weight: '',
  vehicleType6Count: '',
  vehicleType7Count: '',
  vehicleType7Weight: '',
  vehicleType8Count: '',
  vehicleUsage: 'なし',
  vehicleUsage2: 'なし',
  vehicleUsage3: 'なし',
  vehicleType9Count: '',
  vehicleType9Weight: '',
  vehicleType10Count: '',
  vehicleType11Count: '',
  vehicleType11Weight: '',
  vehicleType12Count: '',
  exhibitPlan: 'なし',
  exhibitWeight: '',
  exhibitDetail: '',
  largeVehicle: 'なし',
  largeVehicle2: [] as string[],
  others: ''
};

const initialFormData = {
  ...exhibitorInitialData,
  ...vehicleInitialData
};

const Template004: React.FC = () => {
  const { language } = useAuth();
  const [formData, setFormData] = useState(initialFormData);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const checkDuplicate = async () => {
    try {
      const response = await fetch('https://xs525443.xsrv.jp/admin_ui/check04.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          exhibitorName: formData.exhibitorName,
          Company_ID: formData.Company_ID
        }),
      });
  
      const result = await response.json();
      console.log('APIレスポンス:', result); // デバッグ用ログ
  
      setIsDuplicate(result.isDuplicate);
  
      if (result.isDuplicate && result.additionalData) {
        let parsedJsonData: any;
        try {
          parsedJsonData = JSON.parse(result.additionalData);
          console.log('一度目のパース:', parsedJsonData); // デバッグ用ログ
          if (typeof parsedJsonData === 'string') {
            parsedJsonData = JSON.parse(parsedJsonData);
            console.log('二度目のパース:', parsedJsonData); // デバッグ用ログ
          }
        } catch (parseError) {
          console.error('JSONのパースエラー:', parseError);
          return;
        }
  
        console.log('パースされたJSONデータ:', parsedJsonData); // デバッグ用ログ
  
        setFormData(prevState => {
          const mergedData = { ...prevState, ...parsedJsonData };
          console.log('マージされたフォームデータ:', mergedData); // デバッグ用ログ
          return mergedData;
        });
        setIsReadOnly(true);
      } 
    } catch (error) {
      console.error('重複チェック中にエラーが発生しました:', error);
    }
  };
    
  useEffect(() => {
    const loadButtonState = async () => {
      try {
        const timestamp = new Date().getTime();
        const response = await axios.get(`/json/buttonState4.json?_=${timestamp}`);  // 外部JSONのパスを指定
        const buttonStateData = response.data;
        setFormData(prevState => ({
          ...prevState,
          buttonState: buttonStateData.buttonState
        }));
        setIsReadOnly(buttonStateData.buttonState.disabled);
      } catch (error) {
        console.error('ボタン状態の読み込み中にエラーが発生しました:', error);
      }
    };
  
    loadButtonState();  // コンポーネントの初回レンダリング時に実行
  }, []);
  

  useEffect(() => {
    const savedData = localStorage.getItem('template004Data');
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      console.log('Loaded data from localStorage:', parsedData);
      setFormData(parsedData);
    }
  }, []);

  useEffect(() => {
    if (formData.exhibitorName) {
      checkDuplicate();
    }
  }, [formData.exhibitorName]);

  const handleSave = () => {
    const mergedData = { ...formData };
    console.log('Saving data to localStorage:', mergedData);
    localStorage.setItem('template004Data', JSON.stringify(mergedData));
    const alertMessage = language === 'ja' ? 'データを保存しました。' : 'Save Completed';
  alert(alertMessage);
  };

  const handleReset = async () => {
  const confirmMessage = language === 'ja' ? '本当に削除していいですか？' : 'Are you sure you want to reset?';
  const resetSuccessMessage = language === 'ja' ? 'フォームとファイルがリセットされました。' : 'Reset Completed';
  const deleteErrorMessage = language === 'ja' ? 'データの削除に失敗しました。' : 'Failed to delete data.';
    if (window.confirm(confirmMessage)) {
      try {
        const response = await fetch('https://xs525443.xsrv.jp/admin_ui/delete04.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
          exhibitorName: formData.exhibitorName,
          Company_ID: formData.Company_ID
        }),
        });

        const result = await response.json();

        if (result.success) {
          setFormData(prevState => ({
            ...prevState,
            ...vehicleInitialData
          }));
          setIsReadOnly(false);
          setIsSubmitted(false);
          localStorage.removeItem('template004Data');
          alert(resetSuccessMessage);
          checkDuplicate();
        } else {
          alert(deleteErrorMessage + ': ' + result.message);
        }
      } catch (error) {
        console.error('データの削除中にエラーが発生しました:', error);
        alert(deleteErrorMessage);
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(formData);
  
    // 通常登録部分とJSON部分を分ける
    const normalData = {
      exhibitorName: formData.exhibitorName,
      boothNumber: formData.boothNumber,
      tel: formData.tel,
      departmentPosition: formData.departmentPosition,
      contactPerson: formData.contactPerson,
      postalCode: formData.postalCode,
      prefecture: formData.prefecture,
      city: formData.city,
      address: formData.address,
      Company_ID: formData.Company_ID,
      email: formData.email
    };
  
    const jsonData = {
      firstDay: formData.firstDay,
      firstHour: formData.firstHour,
      firstMinute: formData.firstMinute,
      secondDay: formData.secondDay,
      secondHour: formData.secondHour,
      secondMinute: formData.secondMinute,
      thirdDay: formData.thirdDay,
      thirdHour: formData.thirdHour,
      thirdMinute: formData.thirdMinute,
      forDay: formData.forDay,
      forHour: formData.forHour,
      forMinute: formData.forMinute,
      vehicleType1Count: formData.vehicleType1Count,
      vehicleType1Weight: formData.vehicleType1Weight,
      vehicleType2Count: formData.vehicleType2Count,
      vehicleType2Weight: formData.vehicleType2Weight,
      vehicleType3Count: formData.vehicleType3Count,
      vehicleType3Weight: formData.vehicleType3Weight,
      vehicleType4Count: formData.vehicleType4Count,
      vehicleType5Count: formData.vehicleType5Count,
      vehicleType5Weight: formData.vehicleType5Weight,
      vehicleType6Count: formData.vehicleType6Count,
      vehicleType7Count: formData.vehicleType7Count,
      vehicleType7Weight: formData.vehicleType7Weight,
      vehicleType8Count: formData.vehicleType8Count,
      vehicleUsage: formData.vehicleUsage,
      vehicleUsage2: formData.vehicleUsage2,
      vehicleUsage3: formData.vehicleUsage3,
      vehicleType9Count: formData.vehicleType9Count,
      vehicleType9Weight: formData.vehicleType9Weight,
      vehicleType10Count: formData.vehicleType10Count,
      vehicleType11Count: formData.vehicleType11Count,
      vehicleType11Weight: formData.vehicleType11Weight,
      vehicleType12Count: formData.vehicleType12Count,
      exhibitPlan: formData.exhibitPlan,
      exhibitWeight: formData.exhibitWeight,
      exhibitDetail: formData.exhibitDetail,
      largeVehicle: formData.largeVehicle,
      largeVehicle2: formData.largeVehicle2,
      others: formData.others
    };
  
    try {
      const response = await fetch('https://xs525443.xsrv.jp/admin_ui/submit004.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...normalData, jsonData: JSON.stringify(jsonData) }),
      });
  
      const responseText = await response.text(); // テキストとしてレスポンスを取得
      try {
        const result = JSON.parse(responseText); // JSONとしてパースを試みる
  
        const successMessage = language === 'ja' ? 'データが正常に送信されました。' : 'Submission Completed';
    const failureMessage = language === 'ja' ? 'データの送信に失敗しました: ' : 'Failed to submit data: ';
    const errorMessage = language === 'ja' ? 'データの送信に失敗しました。' : 'Failed to submit data.';

        if (result.success) {
          //alert(successMessage);
          setIsSubmitted(true); // 送信が成功したらボタンをグレースケールにする
          checkDuplicate(); // 再度重複チェックを実行
        } else {
          alert('データの送信に失敗しました: ' + result.message);
        }
      } catch (jsonError) {
        console.error('レスポンスのJSONパースエラー:', jsonError);
        console.error('レスポンス内容:', responseText);
        alert('サーバーからのレスポンスが無効です。');
      }
    } catch (error) {
      console.error('データ送信エラー:', error);
      alert('データの送信中にエラーが発生しました。');
    }
  };
  

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    const { name, value } = e.target as HTMLInputElement | { name: string; value: unknown };
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    if (name === 'exhibitorName') {
      await checkDuplicate();
    }
  };


  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFormData(prevState => {
      let updatedLargeVehicle2 = [...prevState.largeVehicle2];
      if (checked) {
        updatedLargeVehicle2.push(name);
      } else {
        updatedLargeVehicle2 = updatedLargeVehicle2.filter(item => item !== name);
      }
      return {
        ...prevState,
        largeVehicle2: updatedLargeVehicle2
      };
    });
  };
  
  

  return (
    <Layout title={<MultiLangText
      language={language}
      textJa="申請書類管理　４．搬入・搬出計画届 ※全出展者提出"
      textEn="Application Forms 4. Submission of the Exhibitor’s Move-In/Out Plan　*All Exhibitors(Required)"
      />}>
      <Paper style={{ padding: 16 }}>
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="■出展者情報"
                textEn="■Exhibitor information"
                />
        </Typography>
        <hr/>
        <form onSubmit={handleSubmit}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault(); // Enterキーによるフォーム送信を防ぐ
             // アラートを表示
          }
        }}>
          {/* 出展者情報フォーム */}
          <ExhibitorInfoForm
          formData={formData}
          setFormData={setFormData}
          handleChange={handleChange}
        />
          {/* セクションスタート */}
          <Typography gutterBottom mt={5} style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="■搬入計画について　(下記の通り、搬入計画を提出します)"
                textEn="■Move-In Schedule(Delivery Schedule)　We (Exhibitor) submit our move-in schedule as below:"
                />
          </Typography>
          <hr />
          <Grid container spacing={3} alignItems="center">

            {/* カラム */}
            <Grid item xs={3} sm={3} style={{ display: 'flex' }}>
            <MultiLangText
                language={language}
                textJa="①搬入車両"
                textEn='①Arriving by vehicle'
                />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9} style={{ display: 'flex', alignItems: 'center' }}>
              <RadioGroup
                row
                aria-label="vehicleUsage"
                name="vehicleUsage"
                value={formData.vehicleUsage}
                onChange={handleChange}
              >
                <FormControlLabel value="あり" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="あり"
                textEn="Yes"
                />} disabled={isReadOnly}/>
                <FormControlLabel value="なし" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="なし"
                textEn="No"
                />} disabled={isReadOnly}/>
              </RadioGroup>
            </Grid>

            {/* カラム */}
            <Grid item xs={3} sm={3} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="①-ⓐ　希望日時(搬入)"
                textEn="①-ⓐ　Preferred date and time"
                />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9} style={{ display: 'flex', alignItems: 'center' }}>
            <MultiLangText
                language={language}
                textJa="10月"
                textEn="October"
                />
              <FormControl style={{ marginLeft: 0 }}>
              <InputLabel id="first-day-label">
              {<MultiLangText
                    language={language}
                    textJa="日"
                    textEn="Date"
                    />}
              </InputLabel>
              <Select
                label={<MultiLangText
                  language={language}
                  textJa=""
                  textEn="Date"
                  />}
                labelId="first-day-label"
                id="first-day"
                name="firstDay"
                value={formData.firstDay || ''}
                onChange={handleChange}
                style={{ width: 80 }}
                disabled={isReadOnly}
              >
                <MenuItem value=""><em>ー</em></MenuItem>
                {[13, 14, 15].map(day => (
                  <MenuItem key={day} value={day}>{day}</MenuItem>
                ))}
              </Select>
            </FormControl>

              <MultiLangText
                language={language}
                textJa="日"
                textEn=""
                />　
              <FormControl style={{ marginLeft: 0 }}>
                <InputLabel id="first-hour-label">{<MultiLangText
                    language={language}
                    textJa="時"
                    textEn="Time"
                    />}</InputLabel>
                <Select
                  labelId="first-hour-label"
                  id="first-hour"
                  name="firstHour"
                  value={formData.firstHour}
                  label="時"
                  onChange={handleChange}
                  style={{ width: 80 }}
                  disabled={isReadOnly}
                >
                  <MenuItem value=""><em>ー</em></MenuItem>
                  {Array.from({ length: 11 }, (_, i) => (i + 9).toString().padStart(2, '0')).map(hour => (
                    <MenuItem key={hour} value={hour}>{hour}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              ：
              <FormControl style={{ marginLeft: 0 }}>
                <InputLabel id="first-minute-label"><MultiLangText
                    language={language}
                    textJa="分"
                    textEn="Minute"
                    /></InputLabel>
                <Select
                  labelId="first-minute-label"
                  id="first-minute"
                  name="firstMinute"
                  value={formData.firstMinute}
                  label="分"
                  onChange={handleChange}
                  style={{ width: 80 }}
                  disabled={isReadOnly}
                >
                  <MenuItem value=""><em>ー</em></MenuItem>
                  {['00', '30'].map(minute => (
                    <MenuItem key={minute} value={minute}>{minute}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* カラム */}
            <Grid item xs={3} sm={3} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="①-ⓑ・車両の種類(搬入)　"
                textEn='①-ⓑ　Type of the Vehicle　<br/>Enter the number of vehicles in the "Number" field.'
                />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9} style={{ display: 'flex', alignItems: 'center' }}>
            <MultiLangText
                language={language}
                textJa="乗用車・バン"
                textEn="Passengers Car / Van"
                />　
              <TextField
                label={<MultiLangText
                  language={language}
                  textJa=""
                  textEn="Number"
                  />}
                name="vehicleType1Count"
                value={formData.vehicleType1Count}
                onChange={handleChange}
                sx={{ width: 90 }}
                margin="normal"
                disabled={isReadOnly}
              /><MultiLangText
              language={language}
              textJa="台"
              textEn="Vehicle"
              />　　　　　
              <TextField
                label=""
                name="vehicleType1Weight"
                value={formData.vehicleType1Weight}
                onChange={handleChange}
                sx={{ width: 90 }}
                margin="normal"
                disabled={isReadOnly}
              />　<MultiLangText
              language={language}
              textJa="t トレーラー"
              textEn="ton(s) Trailer"
              />
              <TextField
                label={<MultiLangText
                  language={language}
                  textJa=""
                  textEn="Number"
                  />}
                name="vehicleType2Count"
                value={formData.vehicleType2Count}
                onChange={handleChange}
                sx={{ width: 90 }}
                margin="normal"
                disabled={isReadOnly}
              /><MultiLangText
              language={language}
              textJa="台"
              textEn="Vehicle"
              />　
            </Grid>

            {/* カラム */}
            <Grid item xs={3} sm={3} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={9} sm={9} style={{ display: 'flex', alignItems: 'center' }}>
            <MultiLangText
                language={language}
                textJa="2t～4t車"
                textEn="2-t - 4-t truck"
                />　
              <TextField
                label={<MultiLangText
                  language={language}
                  textJa=""
                  textEn="Number"
                  />}
                name="vehicleType3Count"
                value={formData.vehicleType3Count}
                onChange={handleChange}
                sx={{ width: 90 }}
                margin="normal"
                disabled={isReadOnly}
              /><MultiLangText
              language={language}
              textJa="台"
              textEn="Vehicle"
              />　　　　　
              <MultiLangText
                language={language}
                textJa="その他"
                textEn="Others"
                />（　
              <TextField
                label={<MultiLangText
                  language={language}
                  textJa="車種等"
                  textEn="vehicle type"
                  />}
                name="vehicleType3Weight"
                value={formData.vehicleType3Weight}
                onChange={handleChange}
                sx={{ width: 90 }}
                margin="normal"
                disabled={isReadOnly}
              />　）
              <TextField
               label={<MultiLangText
                language={language}
                textJa=""
                textEn="Number"
                />}
                name="vehicleType4Count"
                value={formData.vehicleType4Count}
                onChange={handleChange}
                sx={{ width: 90 }}
                margin="normal"
                disabled={isReadOnly}
              /><MultiLangText
              language={language}
              textJa="台"
              textEn="Vehicle"
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={3} sm={3} style={{ display: 'flex' }}>
            <MultiLangText
                language={language}
                textJa="②搬入車両"
                textEn='②Arriving by vehicle'
                />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9} style={{ display: 'flex', alignItems: 'center' }}>
              <RadioGroup
                row
                aria-label="vehicleUsage2"
                name="vehicleUsage2"
                value={formData.vehicleUsage2}
                onChange={handleChange}
              >
                <FormControlLabel value="あり" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="あり"
                textEn="Yes"
                />} disabled={isReadOnly}/>
                <FormControlLabel value="なし" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="なし"
                textEn="No"
                />} disabled={isReadOnly}/>
              </RadioGroup>
            </Grid>

            {/* カラム */}
            <Grid item xs={3} sm={3} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="②-ⓐ　希望日時(搬入)　"
                textEn="②-ⓐ　Preferred date and time　"
                />
            </Grid>
            <Grid item xs={9} sm={9} style={{ display: 'flex', alignItems: 'center' }}>
            <MultiLangText
                language={language}
                textJa="10月"
                textEn="October"
                />
              <FormControl style={{ marginLeft: 0 }}>
                <InputLabel id="second-day-label">
                {<MultiLangText
                    language={language}
                    textJa="日"
                    textEn="Date"
                    />}
                </InputLabel>
                <Select
                  labelId="second-day-label"
                  id="second-day"
                  name="secondDay"
                  value={formData.secondDay}
                  label="日"
                  onChange={handleChange}
                  style={{ width: 80 }}
                  disabled={isReadOnly}
                >
                  <MenuItem value=""><em>ー</em></MenuItem>
                  {[13, 14, 15].map(day => (
                    <MenuItem key={day} value={day}>{day}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <MultiLangText
                language={language}
                textJa="日"
                textEn=""
                />　
              <FormControl style={{ marginLeft: 0 }}>
                <InputLabel id="second-hour-label"><MultiLangText
                    language={language}
                    textJa="時"
                    textEn="Time"
                    /></InputLabel>
                <Select
                  labelId="second-hour-label"
                  id="second-hour"
                  name="secondHour"
                  value={formData.secondHour}
                  label="時"
                  onChange={handleChange}
                  style={{ width: 80 }}
                  disabled={isReadOnly}
                >
                  <MenuItem value=""><em>ー</em></MenuItem>
                  {Array.from({ length: 11 }, (_, i) => (i + 9).toString().padStart(2, '0')).map(hour => (
                    <MenuItem key={hour} value={hour}>{hour}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              ：
              <FormControl style={{ marginLeft: 0 }}>
                <InputLabel id="second-minute-label"><MultiLangText
                    language={language}
                    textJa="分"
                    textEn="Minute"
                    /></InputLabel>
                <Select
                  labelId="second-minute-label"
                  id="second-minute"
                  name="secondMinute"
                  value={formData.secondMinute}
                  label="分"
                  onChange={handleChange}
                  style={{ width: 80 }}
                  disabled={isReadOnly}
                >
                  <MenuItem value=""><em>ー</em></MenuItem>
                  {['00', '30'].map(minute => (
                    <MenuItem key={minute} value={minute}>{minute}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* カラム */}
            <Grid item xs={3} sm={3} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="②-ⓑ　車両の種類（搬入）"
                textEn='②-ⓑ　Type of the Vehicle<br/>Enter the number of vehicles in the "Number" field.'
                />
            </Grid>
            <Grid item xs={9} sm={9} style={{ display: 'flex', alignItems: 'center' }}>
            <MultiLangText
                language={language}
                textJa="乗用車・バン"
                textEn="Passengers Car / Van"
                />　
              <TextField
                label={<MultiLangText
                  language={language}
                  textJa=""
                  textEn="Number"
                  />}
                name="vehicleType5Count"
                value={formData.vehicleType5Count}
                onChange={handleChange}
                sx={{ width: 90 }}
                margin="normal"
                disabled={isReadOnly}
              /><MultiLangText
              language={language}
              textJa="台"
              textEn="Vehicle"
              />　　　　　
              <TextField
                label=""
                name="vehicleType5Weight"
                value={formData.vehicleType5Weight}
                onChange={handleChange}
                sx={{ width: 90 }}
                margin="normal"
                disabled={isReadOnly}
              />　<MultiLangText
              language={language}
              textJa="t トレーラー　"
              textEn="ton(s) Trailer　"
              />
              <TextField
                label={<MultiLangText
                  language={language}
                  textJa=""
                  textEn="Number"
                  />}
                name="vehicleType6Count"
                value={formData.vehicleType6Count}
                onChange={handleChange}
                sx={{ width: 90 }}
                margin="normal"
                disabled={isReadOnly}
              /><MultiLangText
              language={language}
              textJa="台"
              textEn="Vehicle"
              />　
            </Grid>

            {/* カラム */}
            <Grid item xs={3} sm={3} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={9} sm={9} style={{ display: 'flex', alignItems: 'center' }}>
            <MultiLangText
                language={language}
                textJa="2t～4t車"
                textEn="2-t - 4-t truck"
                />　
              <TextField
                label={<MultiLangText
                  language={language}
                  textJa=""
                  textEn="Number"
                  />}
                name="vehicleType7Count"
                value={formData.vehicleType7Count}
                onChange={handleChange}
                sx={{ width: 90 }}
                margin="normal"
                disabled={isReadOnly}
              /><MultiLangText
              language={language}
              textJa="台"
              textEn="Vehicle"
              />　　　　　
              <MultiLangText
                language={language}
                textJa="その他"
                textEn="Others"
                />（　
              <TextField
                label={<MultiLangText
                  language={language}
                  textJa="車種等"
                  textEn="vehicle type"
                  />}
                name="vehicleType7Weight"
                value={formData.vehicleType7Weight}
                onChange={handleChange}
                sx={{ width: 90 }}
                margin="normal"
                disabled={isReadOnly}
              />　）
              <TextField
                label={<MultiLangText
                  language={language}
                  textJa=""
                  textEn="Number"
                  />}
                name="vehicleType8Count"
                value={formData.vehicleType8Count}
                onChange={handleChange}
                sx={{ width: 90 }}
                margin="normal"
                disabled={isReadOnly}
              />　<MultiLangText
              language={language}
              textJa="台"
              textEn="Vehicle"
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={3} sm={3} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={9} sm={9} style={{ display: 'flex', alignItems: 'center' }}>
              <MultiLangText
                language={language}
                textJa="
                ※希望日時については、事務局にて調整させて頂く場合がございます。その場合は事務局より連絡いたします。<br/>
                ※複数日で搬入される場合は、②にて情報を入力ください。<br/>
                ※早期搬入について<br/>
                10 月12 日（土）12：00 ～ 20：00(残業可/有料)<br/>
                90㎡以上の出展者で希望者のみ、搬入可能です。<br/>
                早期搬入を希望される出展者は事務局にお問合せください。
                "
                textEn="
                *The delivery date and time may be adjusted by JA2024 Management Office.<br/>
                *If there is a date after the date entered in ① that has a different Move-in plan, please enter the plan in form ②.<br/>
                *Early move-in<br/>
                October 12 (Sat.) 12:00p(Noon) - 20:00(Overtime work is chargeable)<br/>
                Early move-in is possible only for exhibitors with booth size of 90㎡ or more who applied.<br/>
                Exhibitor requiring move-in should contact the Management Office.
                "
                />
            </Grid>
          </Grid>
          {/* セクションここまで */}

          {/* セクションスタート */}
          <Typography gutterBottom mt={5} style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="■搬出計画について　（下記の通り、搬出計画を提出します）"
                textEn="■Move-Out Schedule　We (Exhibitor) submit our move-out schedule as below:"
                />
          </Typography>
          <hr />
          <Grid container spacing={3} alignItems="center">
            {/* カラム */}
            <Grid item xs={3} sm={3} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="③搬出車両"
                textEn="③Arriving by vehicle"
                />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9} style={{ display: 'flex', alignItems: 'center' }}>
              <RadioGroup
                row
                aria-label="vehicleUsage3"
                name="vehicleUsage3"
                value={formData.vehicleUsage3}
                onChange={handleChange}
              >
                <FormControlLabel value="あり" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="あり"
                textEn="Yes"
                />} disabled={isReadOnly}/>
                <FormControlLabel value="なし" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="なし"
                textEn="No"
                />} disabled={isReadOnly}/>
              </RadioGroup>
            </Grid>

            {/* カラム */}
            <Grid item xs={3} sm={3} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="③-ⓐ　希望日時(搬出)"
                textEn="③-ⓐ　Preferred date and time"
                />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9} style={{ display: 'flex', alignItems: 'center' }}>
            <MultiLangText
                language={language}
                textJa="10月"
                textEn="October"
                />
              <FormControl style={{ marginLeft: 0 }}>
                <InputLabel id="third-day-label">
                {<MultiLangText
                    language={language}
                    textJa="日"
                    textEn="Date"
                    />}
                </InputLabel>
                <Select
                  labelId="third-day-label"
                  id="third-day"
                  name="thirdDay"
                  value={formData.thirdDay}
                  label="日"
                  onChange={handleChange}
                  style={{ width: 80 }}
                  disabled={isReadOnly}
                >
                  <MenuItem value=""><em>ー</em></MenuItem>
                  {[19, 20].map(day => (
                    <MenuItem key={day} value={day}>{day}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <MultiLangText
                language={language}
                textJa="日"
                textEn=""
                />　
              <FormControl style={{ marginLeft: 0 }}>
                <InputLabel id="third-hour-label"><MultiLangText
                language={language}
                textJa="時間"
                textEn="Time"
                /></InputLabel>
                <Select
                  labelId="third-hour-label"
                  id="third-hour"
                  name="thirdHour"
                  value={formData.thirdHour}
                  label="時"
                  onChange={handleChange}
                  style={{ width: 80 }}
                  disabled={isReadOnly}
                >
                  <MenuItem value=""><em>ー</em></MenuItem>
                  {Array.from({ length: 14 }, (_, i) => (i + 9).toString().padStart(2, '0')).map(hour => (
                    <MenuItem key={hour} value={hour}>{hour}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              ：
              <FormControl style={{ marginLeft: 0 }}>
                <InputLabel id="third-minute-label"><MultiLangText
                language={language}
                textJa="分"
                textEn="Minute"
                /></InputLabel>
                <Select
                  labelId="third-minute-label"
                  id="third-minute"
                  name="thirdMinute"
                  value={formData.thirdMinute}
                  label="分"
                  onChange={handleChange}
                  style={{ width: 80 }}
                  disabled={isReadOnly}
                >
                  <MenuItem value=""><em>ー</em></MenuItem>
                  {['00', '30'].map(minute => (
                    <MenuItem key={minute} value={minute}>{minute}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* カラム */}
            <Grid item xs={3} sm={3} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="③-ⓑ　車両の種類(搬出)"
                textEn='③-ⓑ　Type of the Vehicle<br/>Enter the number of vehicles in the "Number" field.'
                />
              　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9} style={{ display: 'flex', alignItems: 'center' }}>
            <MultiLangText
                language={language}
                textJa="乗用車・バン"
                textEn="Passengers Car / Van"
                />　
              <TextField
                label={<MultiLangText
                  language={language}
                  textJa=""
                  textEn="Number"
                  />}
                name="vehicleType9Count"
                value={formData.vehicleType9Count}
                onChange={handleChange}
                sx={{ width: 90 }}
                margin="normal"
                disabled={isReadOnly}
              /><MultiLangText
              language={language}
              textJa="台"
              textEn="Vehicle"
              />　　　　　
              <TextField
                label=""
                name="vehicleType9Weight"
                value={formData.vehicleType9Weight}
                onChange={handleChange}
                sx={{ width: 90 }}
                margin="normal"
                disabled={isReadOnly}
              />　<MultiLangText
              language={language}
              textJa="t トレーラー"
              textEn="ton(s) Trailer"
              />
              <TextField
                label={<MultiLangText
                  language={language}
                  textJa=""
                  textEn="Number"
                  />}
                name="vehicleType10Count"
                value={formData.vehicleType10Count}
                onChange={handleChange}
                sx={{ width: 90 }}
                margin="normal"
                disabled={isReadOnly}
              />　<MultiLangText
              language={language}
              textJa="台"
              textEn="Vehicle"
              />　
            </Grid>

            {/* カラム */}
            <Grid item xs={3} sm={3} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={9} sm={9} style={{ display: 'flex', alignItems: 'center' }}>
            <MultiLangText
                language={language}
                textJa="2t～4t車"
                textEn="2-t - 4-t truck"
                />　
              <TextField
                label={<MultiLangText
                  language={language}
                  textJa=""
                  textEn="Number"
                  />}
                name="vehicleType11Count"
                value={formData.vehicleType11Count}
                onChange={handleChange}
                sx={{ width: 90 }}
                margin="normal"
                disabled={isReadOnly}
              /><MultiLangText
              language={language}
              textJa="台"
              textEn="Vehicle"
              />　　　　　
              <MultiLangText
                language={language}
                textJa="その他"
                textEn="Others"
                />（　
              <TextField
                label={<MultiLangText
                  language={language}
                  textJa="車種等"
                  textEn="vehicle type"
                  />}
                name="vehicleType11Weight"
                value={formData.vehicleType11Weight}
                onChange={handleChange}
                sx={{ width: 90 }}
                margin="normal"
                disabled={isReadOnly}
              />　）
              <TextField
                label={<MultiLangText
                  language={language}
                  textJa=""
                  textEn="Number"
                  />}
                name="vehicleType12Count"
                value={formData.vehicleType12Count}
                onChange={handleChange}
                sx={{ width: 90 }}
                margin="normal"
                disabled={isReadOnly}
              />　<MultiLangText
              language={language}
              textJa="台"
              textEn="Vehicle"
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={3} sm={3} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={9} sm={9} style={{ display: 'flex', alignItems: 'center' }}>
              {/*
              <RadioGroup
                row
                aria-label="vehicleReturn"
                name="vehicleReturn"
                value={formData.vehicleReturn}
                onChange={handleChange}
              >
                <FormControlLabel value="使用車両なし" control={<Radio required />} label={<MultiLangText
              language={language}
              textJa="使用車両なし"
              textEn="No Vehicle Used"
              />} disabled={isReadOnly}/>
                <FormControlLabel value="宅配業者利用" control={<Radio required />} label={<MultiLangText
              language={language}
              textJa="宅配業者利用"
              textEn="Using a Courier Service"
              />} disabled={isReadOnly}/>
              </RadioGroup>
              */}
            </Grid>

            {/* カラム */}
            <Grid item xs={3} sm={3} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={9} sm={9} style={{ display: 'flex', alignItems: 'center' }}>
            <MultiLangText
                language={language}
                textJa="館内車両進入開始は10月19日（土）18時からを予定。<br/>
                ※希望日時については、事務局にて調整させて頂く場合がございます。その場合は事務局より連絡いたします。"
                textEn="
                *Vehicles will be able to begin entering the halls on Saturday, October 19, at 6:00 p.m.<br/>
                *JA2024 Management Office may adjust the applied schedule.
                "
                />
          </Grid>
        </Grid>
        {/* セクションここまで */}

        {/* セクションスタート */}
        <Typography gutterBottom mt={5} style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="■出展物について"
                textEn="■ Exhibits"
                />
        </Typography>
        <hr />
        <Grid container spacing={3} alignItems="center">
          {/* カラム */}
          <Grid item xs={3} sm={3} style={{ display: 'flex' }}>
          <MultiLangText
                language={language}
                textJa="①1t以上の出展物や大型展示物を展示する予定がある"
                textEn="①We plan the exhibit over 1t and/or big exhibit "
                />　<RequiredLabel />
          </Grid>
          <Grid item xs={9} sm={9}>
            <RadioGroup
              row
              aria-label="exhibitPlan"
              name="exhibitPlan"
              value={formData.exhibitPlan}
              onChange={handleChange}
            >
              <Grid item xs={3} sm={3}>
                <FormControlLabel value="あり" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="あり"
                textEn="Yes"
                />} disabled={isReadOnly}/>
              </Grid>
              <Grid item xs={1} sm={1}>
              </Grid>
              <Grid item xs={3} sm={3}>
                <FormControlLabel value="なし" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="なし"
                textEn="No"
                />} disabled={isReadOnly}/>
              </Grid>
              <Grid item xs={3} sm={3}>
              </Grid>
            </RadioGroup>
          </Grid>

          {/* カラム */}
          <Grid item xs={3} sm={3} style={{ display: 'flex' }}>
            <MultiLangText
                language={language}
                textJa="②出展物詳細について　（①にて「あり」を選択した場合）"
                textEn='Details of Exhibits　(If ① is "Yes”)'
                />
          </Grid>
          <Grid item xs={9} sm={9} style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              label={<MultiLangText
                language={language}
                textJa="重量"
                textEn="Weight"
                />}
              name="exhibitWeight"
              value={formData.exhibitWeight}
              onChange={handleChange}
              sx={{ width: 90 }}
              margin="normal"
              disabled={isReadOnly}
            />　t　
            <TextField
              label={<MultiLangText
                language={language}
                textJa="展示物"
                textEn="Products to be exhibited"
                />}
              name="exhibitDetail"
              value={formData.exhibitDetail}
              onChange={handleChange}
              fullWidth
              margin="normal"
              disabled={isReadOnly}
            />　
          </Grid>

          {/* カラム */}
          <Grid item xs={3} sm={3} style={{ display: 'flex' }}>
            <MultiLangText
                language={language}
                textJa="③出展物の搬入にラフタークレーン、10t車等の大型車両を使う予定の場合"
                textEn="③We use a rafter type crane or large vehicle such as 10t truck."
                />
            　<RequiredLabel />
          </Grid>
          <Grid item xs={9} sm={9}>
            <RadioGroup
              row
              aria-label="largeVehicle"
              name="largeVehicle"
              value={formData.largeVehicle}
              onChange={handleChange}
            >
              <Grid item xs={3} sm={3}>
                <FormControlLabel value="あり" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="あり"
                textEn="Yes"
                />} disabled={isReadOnly}/>
              </Grid>
              <Grid item xs={1} sm={1}>
              </Grid>
              <Grid item xs={3} sm={3}>
                <FormControlLabel value="なし" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="なし"
                textEn="No"
                />} disabled={isReadOnly}/>
              </Grid>
              <Grid item xs={3} sm={3}>
              </Grid>
            </RadioGroup>
          </Grid>

          {/* カラム */}
<Grid item xs={3} sm={3} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={9} sm={9} style={{ display: 'flex', alignItems: 'center' }}>
            <MultiLangText
                language={language}
                textJa="10月"
                textEn="October"
                />
              <FormControl style={{ marginLeft: 0 }}>
                <InputLabel id="for-day-label">
                {<MultiLangText
                    language={language}
                    textJa="日"
                    textEn="Date"
                    />}
                </InputLabel>
                <Select
                  labelId="for-day-label"
                  id="for-day"
                  name="forDay"
                  value={formData.forDay}
                  onChange={handleChange}
                  style={{ width: 80 }}
                  disabled={isReadOnly}
                >
                  <MenuItem value=""><em>ー</em></MenuItem>
                  {[13, 14, 15].map(day => (
                    <MenuItem key={day} value={day}>{day}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <MultiLangText
                language={language}
                textJa="日"
                textEn=""
                />　
              <FormControl style={{ marginLeft: 0 }}>
                <InputLabel id="for-hour-label">{<MultiLangText
                    language={language}
                    textJa="時"
                    textEn="Time"
                    />}</InputLabel>
                <Select
                  labelId="for-hour-label"
                  id="for-hour"
                  name="forHour"
                  value={formData.forHour}
                  label="時"
                  onChange={handleChange}
                  style={{ width: 80 }}
                  disabled={isReadOnly}
                >
                  <MenuItem value=""><em>ー</em></MenuItem>
                  {Array.from({ length: 11 }, (_, i) => (i + 9).toString().padStart(2, '0')).map(hour => (
                    <MenuItem key={hour} value={hour}>{hour}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              ：
              <FormControl style={{ marginLeft: 0 }}>
                <InputLabel id="for-minute-label"><MultiLangText
                    language={language}
                    textJa="分"
                    textEn="Minute"
                    /></InputLabel>
                <Select
                  labelId="for-minute-label"
                  id="for-minute"
                  name="forMinute"
                  value={formData.forMinute}
                  label="分"
                  onChange={handleChange}
                  style={{ width: 80 }}
                  disabled={isReadOnly}
                >
                  <MenuItem value=""><em>ー</em></MenuItem>
                  {['00', '30'].map(minute => (
                    <MenuItem key={minute} value={minute}>{minute}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>


{/* カラム */}
<Grid item xs={3} sm={3} style={{ display: 'flex' }}>
</Grid>
<Grid item xs={9} sm={9} style={{ display: 'flex', alignItems: 'center' }}>
  <FormGroup row>
    <Grid item xs={4} sm={4}>
      <FormControlLabel
        control={<Checkbox 
          checked={formData.largeVehicle2.includes('ラフタークレーン')} 
          onChange={handleCheckboxChange} 
          name="ラフタークレーン"
          disabled={isReadOnly}
        />}
        label={<MultiLangText
          language={language}
          textJa="ラフタークレーン"
          textEn="Rafter Type Crane"
        />}
      />
    </Grid>
    <Grid item xs={4} sm={4}>
      <FormControlLabel
        control={<Checkbox 
          checked={formData.largeVehicle2.includes('トレーラー')} 
          onChange={handleCheckboxChange} 
          name="トレーラー"
          disabled={isReadOnly}
        />}
        label={<MultiLangText
          language={language}
          textJa="トレーラー"
          textEn="Trailer"
        />}
      />
    </Grid>
    <Grid item xs={4} sm={4}>
      <FormControlLabel
        control={<Checkbox 
          checked={formData.largeVehicle2.includes('ユニック')} 
          onChange={handleCheckboxChange} 
          name="ユニック"
          disabled={isReadOnly}
        />}
        label={<MultiLangText
          language={language}
          textJa="ユニック"
          textEn="Crane Truck"
        />}
      />
    </Grid>
    <Grid item xs={2} sm={2}>
      <FormControlLabel
        control={<Checkbox 
          checked={formData.largeVehicle2.includes('その他')} 
          onChange={handleCheckboxChange} 
          name="その他"
          disabled={isReadOnly}
        />}
        label={<MultiLangText
          language={language}
          textJa="その他"
          textEn="Others"
        />}
      />
    </Grid>
    <Grid item xs={10} sm={10}>
      <TextField
        name="others"
        value={formData.others}
        onChange={handleChange}
        margin="normal"
        disabled={isReadOnly}
      />　
    </Grid>
  </FormGroup>
</Grid>



{/* カラム */}
<Grid item xs={3} sm={3} style={{ display: 'flex' }}>
          </Grid>
          <Grid item xs={9} sm={9}>
          <MultiLangText
                language={language}
                textJa="※上記のいずれかに該当する場合は、計画搬入デスクより図面のご提出をお願いする場合がなします。"
                textEn="*If any of the above applies to you, Move-in / Move-out Desk may request exhibitors to submit the booth layout plan."
                />
          </Grid>

          <ContactInfo_big />
              {/* カラム */}
              <Grid item xs={12} sm={12} style={{ display: 'flex'}}>
              <MultiLangText
                language={language}
                textJa="
※下記、「確定」ボタンを押して、申請してください。「保存」中は修正が可能です。<br/>
              ※「リセット」ボタンがある申請書については、万一、確定後に修正が必要な場合も、内容の修正が可能です。
"
                textEn='
*Please click the "SUBMIT" button to submit your application. You can "SAVE” the form while you still need to modify it.<br/>
*Some forms have “RESET” button, which you can use when you need to reset and fill in the form again after submitting the form.
'
              />
            </Grid>
        </Grid>

        
        {/* セクションここまで */}
        <Grid container mt={5} spacing={1} justifyContent="center">
            <Grid item>
              <Button
                onClick={handleReset}
                variant="contained"
                color="secondary"
                disabled={formData.buttonState.reset}  // JSONで制御
                sx={{ filter: formData.buttonState.reset ? 'grayscale(1)' : 'none' }}  // グレーアウト
              >
                <MultiLangText
                  language={language}
                  textJa="リセット"
                  textEn="Reset"
                />
              </Button>
            </Grid>

            <Grid item>
              <Button
                onClick={handleSave}
                variant="contained"
                color="primary"
                disabled={formData.buttonState.save}  // JSONで制御
                sx={{ filter: formData.buttonState.save ? 'grayscale(1)' : 'none' }}  // グレーアウト
              >
                <MultiLangText
                  language={language}
                  textJa="保存"
                  textEn="Save"
                />
              </Button>
            </Grid>

            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={formData.buttonState.submit || isDuplicate || isSubmitted}  // JSONで制御 + 重複や送信済みの場合
                sx={{ filter: (formData.buttonState.submit || isDuplicate || isSubmitted) ? 'grayscale(1)' : 'none' }}  // グレーアウト
              >
                <MultiLangText
                  language={language}
                  textJa="確定"
                  textEn="Submit"
                />
              </Button>
            </Grid>
          </Grid>

      </form>
    </Paper>
  </Layout>
  );
};

export default Template004;
