import React, { useState, useEffect } from 'react';
import Layout from '../templetes/Layout';
import { 
  TextField, Button, Box, Typography, Paper, Grid, FormControlLabel, Radio, RadioGroup
} from '@mui/material';
import RequiredLabel from '../templetes/RequiredLabel'; 
import ExhibitorInfoForm from '../templetes/ExhibitorInfoForm';
import { SelectChangeEvent } from '@mui/material';
import ContactInfo2 from '../templetes/ContactInfo2';
import axios from 'axios';
import MultiLangText from '../templetes/MultiLangText';
import { useAuth } from '../../context/AuthContext';

const exhibitorInitialData = {
  exhibitorName: '',
  boothNumber: '',
  tel: '',
  departmentPosition: '',
  contactPerson: '',
  postalCode: '',
  prefecture: '',
  city: '',
  address: '',
  email: '',
  Company_ID: '',
  buttonState: {
    save: false,   // 保存ボタンの状態
    submit: false  // 確定ボタンの状態
  }
};

const fireHazardInitialData = {
  fireUsage: 'なし',
  fireDeviceType1: '',
  fireDeviceType2: '',
  fireDeviceType3: '',
  fireDeviceType4: '',
  fireDeviceType5: '',
  fireDeviceType6: '',
  fireDeviceType7: '',
  fireSource1: '',
  fireSource2: '',
  fireSource3: '',
  fireSource4: '',
  fireSource5: '',
  fireSource6: '',
  fireSource7: '',
  fireConsumption1: '',
  fireConsumption2: '',
  fireConsumption3: '',
  fireConsumption4: '',
  fireConsumption5: '',
  fireConsumption6: '',
  fireConsumption7: '',
  firePurpose1: '',
  firePurpose2: '',
  firePurpose3: '',
  firePurpose4: '',
  firePurpose5: '',
  firePurpose6: '',
  firePurpose7: '',
  hazardousMaterials: 'なし',
  hazardousType1: '',
  hazardousType2: '',
  hazardousType3: '',
  hazardousType4: '',
  hazardousType5: '',
  hazardousType6: '',
  hazardousType7: '',
  hazardousName1: '',
  hazardousName2: '',
  hazardousName3: '',
  hazardousName4: '',
  hazardousName5: '',
  hazardousName6: '',
  hazardousName7: '',
  hazardousAmount1: '',
  hazardousAmount2: '',
  hazardousAmount3: '',
  hazardousAmount4: '',
  hazardousAmount5: '',
  hazardousAmount6: '',
  hazardousAmount7: '',
  hazardousNumber1: '',
  hazardousNumber2: '',
  hazardousNumber3: '',
  hazardousNumber4: '',
  hazardousNumber5: '',
  hazardousNumber6: '',
  hazardousNumber7: '',
  hazardousContainer1: '',
  hazardousContainer2: '',
  hazardousContainer3: '',
  hazardousContainer4: '',
  hazardousContainer5: '',
  hazardousContainer6: '',
  hazardousContainer7: '',
  hazardousPurpose1: '',
  hazardousPurpose2: '',
  hazardousPurpose3: '',
  hazardousPurpose4: '',
  hazardousPurpose5: '',
  hazardousPurpose6: '',
  hazardousPurpose7: '',
};

const initialFormData = {
  ...exhibitorInitialData,
  ...fireHazardInitialData
};

const Template003: React.FC = () => {
  const { language } = useAuth();
  const [formData, setFormData] = useState(initialFormData);
  const [isDuplicate, setIsDuplicate] = useState(true);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const checkDuplicate = async () => {
    try {
      const response = await fetch('https://xs525443.xsrv.jp/admin_ui/check03.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          exhibitorName: formData.exhibitorName,
          Company_ID: formData.Company_ID
        }),
      });
  
      const result = await response.json();
      console.log('APIレスポンス:', result); // デバッグ用ログ
  
      setIsDuplicate(result.isDuplicate);
  
      if (result.isDuplicate && result.additionalData) {
        const parsedJsonData = JSON.parse(result.additionalData);
        console.log('パースされたJSONデータ:', parsedJsonData); // デバッグ用ログ
  
        setFormData(prevState => {
          const mergedData = { ...prevState, ...parsedJsonData };
          console.log('マージされたフォームデータ:', mergedData); // デバッグ用ログ
          return mergedData;
        });
        setIsReadOnly(true);
      } 
    } catch (error) {
      console.error('重複チェック中にエラーが発生しました:', error);
    }
  };
  
  useEffect(() => {
    const loadButtonState = async () => {
      try {
        const timestamp = new Date().getTime();
        const response = await axios.get(`/json/buttonState3.json?_=${timestamp}`);  // 外部JSONのパスを指定
        const buttonStateData = response.data;
        setFormData(prevState => ({
          ...prevState,
          buttonState: buttonStateData.buttonState
        }));
        setIsReadOnly(buttonStateData.buttonState.disabled);
      } catch (error) {
        console.error('ボタン状態の読み込み中にエラーが発生しました:', error);
      }
    };
  
    loadButtonState();  // コンポーネントの初回レンダリング時に実行
  }, []);
  
  
  useEffect(() => {
    const savedData = localStorage.getItem('template003Data');
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      console.log('Loaded data from localStorage:', parsedData);
      setFormData(parsedData);
    }
  }, []);
  
  useEffect(() => {
    if (formData.exhibitorName) {
      checkDuplicate();
    }
  }, [formData.exhibitorName]);

  const handleSave = () => {
    const mergedData = { ...formData };
    console.log('Saving data to localStorage:', mergedData);
    localStorage.setItem('template003Data', JSON.stringify(mergedData));
    const alertMessage = language === 'ja' ? 'データを保存しました。' : 'Save Completed';
  alert(alertMessage);
  };

  const handleReset = async () => {
  const confirmMessage = language === 'ja' ? '本当に削除していいですか？' : 'Are you sure you want to reset?';
  const resetSuccessMessage = language === 'ja' ? 'フォームとファイルがリセットされました。' : 'Reset Completed';
  const deleteErrorMessage = language === 'ja' ? 'データの削除に失敗しました。' : 'Failed to delete data.';
    if (window.confirm(confirmMessage)) {
      try {
        const response = await fetch('https://xs525443.xsrv.jp/admin_ui/delete03.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
          Company_ID: formData.Company_ID
        }),
        });
  
        const result = await response.json();
  
        if (result.success) {
          setFormData(prevState => ({
            ...prevState,
            ...fireHazardInitialData
          }));
          setIsReadOnly(false);
          setIsSubmitted(false);
          localStorage.removeItem('template003Data');
          alert(resetSuccessMessage);
          checkDuplicate();
        } else {
          alert(deleteErrorMessage + ': ' + result.message);
        }
      } catch (error) {
        console.error('データの削除中にエラーが発生しました:', error);
        alert(deleteErrorMessage);
      }
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  
    const additionalData = {
      fireUsage: formData.fireUsage,
      fireDeviceType1: formData.fireDeviceType1,
      fireDeviceType2: formData.fireDeviceType2,
      fireDeviceType3: formData.fireDeviceType3,
      fireDeviceType4: formData.fireDeviceType4,
      fireDeviceType5: formData.fireDeviceType5,
      fireDeviceType6: formData.fireDeviceType6,
      fireDeviceType7: formData.fireDeviceType7,
      fireSource1: formData.fireSource1,
      fireSource2: formData.fireSource2,
      fireSource3: formData.fireSource3,
      fireSource4: formData.fireSource4,
      fireSource5: formData.fireSource5,
      fireSource6: formData.fireSource6,
      fireSource7: formData.fireSource7,
      fireConsumption1: formData.fireConsumption1,
      fireConsumption2: formData.fireConsumption2,
      fireConsumption3: formData.fireConsumption3,
      fireConsumption4: formData.fireConsumption4,
      fireConsumption5: formData.fireConsumption5,
      fireConsumption6: formData.fireConsumption6,
      fireConsumption7: formData.fireConsumption7,
      firePurpose1: formData.firePurpose1,
      firePurpose2: formData.firePurpose2,
      firePurpose3: formData.firePurpose3,
      firePurpose4: formData.firePurpose4,
      firePurpose5: formData.firePurpose5,
      firePurpose6: formData.firePurpose6,
      firePurpose7: formData.firePurpose7,
      hazardousMaterials: formData.hazardousMaterials,
      hazardousType1: formData.hazardousType1,
      hazardousType2: formData.hazardousType2,
      hazardousType3: formData.hazardousType3,
      hazardousType4: formData.hazardousType4,
      hazardousType5: formData.hazardousType5,
      hazardousType6: formData.hazardousType6,
      hazardousType7: formData.hazardousType7,
      hazardousName1: formData.hazardousName1,
      hazardousName2: formData.hazardousName2,
      hazardousName3: formData.hazardousName3,
      hazardousName4: formData.hazardousName4,
      hazardousName5: formData.hazardousName5,
      hazardousName6: formData.hazardousName6,
      hazardousName7: formData.hazardousName7,
      hazardousAmount1: formData.hazardousAmount1,
      hazardousAmount2: formData.hazardousAmount2,
      hazardousAmount3: formData.hazardousAmount3,
      hazardousAmount4: formData.hazardousAmount4,
      hazardousAmount5: formData.hazardousAmount5,
      hazardousAmount6: formData.hazardousAmount6,
      hazardousAmount7: formData.hazardousAmount7,
      hazardousNumber1: formData.hazardousNumber1,
      hazardousNumber2: formData.hazardousNumber2,
      hazardousNumber3: formData.hazardousNumber3,
      hazardousNumber4: formData.hazardousNumber4,
      hazardousNumber5: formData.hazardousNumber5,
      hazardousNumber6: formData.hazardousNumber6,
      hazardousNumber7: formData.hazardousNumber7,
      hazardousContainer1: formData.hazardousContainer1,
      hazardousContainer2: formData.hazardousContainer2,
      hazardousContainer3: formData.hazardousContainer3,
      hazardousContainer4: formData.hazardousContainer4,
      hazardousContainer5: formData.hazardousContainer5,
      hazardousContainer6: formData.hazardousContainer6,
      hazardousContainer7: formData.hazardousContainer7,
      hazardousPurpose1: formData.hazardousPurpose1,
      hazardousPurpose2: formData.hazardousPurpose2,
      hazardousPurpose3: formData.hazardousPurpose3,
      hazardousPurpose4: formData.hazardousPurpose4,
      hazardousPurpose5: formData.hazardousPurpose5,
      hazardousPurpose6: formData.hazardousPurpose6,
      hazardousPurpose7: formData.hazardousPurpose7,
    };
  
    const submitData = {
      exhibitorName: formData.exhibitorName,
      boothNumber: formData.boothNumber,
      tel: formData.tel,
      departmentPosition: formData.departmentPosition,
      contactPerson: formData.contactPerson,
      postalCode: formData.postalCode,
      prefecture: formData.prefecture,
      city: formData.city,
      address: formData.address,
      email: formData.email,
      Company_ID: formData.Company_ID,
      additionalData: JSON.stringify(additionalData),
    };
  
    try {
      const response = await fetch('https://xs525443.xsrv.jp/admin_ui/submit003.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(submitData),
      });
  
      const result = await response.json();
  
      const successMessage = language === 'ja' ? 'データが正常に送信されました。' : 'Submission Completed';
    const failureMessage = language === 'ja' ? 'データの送信に失敗しました: ' : 'Failed to submit data: ';
    const errorMessage = language === 'ja' ? 'データの送信に失敗しました。' : 'Failed to submit data.';

      if (result.success) {
        //alert(successMessage);
        setIsSubmitted(true); // 送信が成功したらボタンをグレースケールにする
        checkDuplicate(); // 再度重複チェックを実行
      } else {
        alert('データの送信に失敗しました: ' + result.message);
      }
    } catch (error) {
      alert('データの送信に失敗しました。');
      console.error('Error:', error);
    }
  };
  

  
  const handleChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    const { name, value } = e.target as HTMLInputElement | { name: string; value: unknown };
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  
    if (name === 'exhibitorName') {
      await checkDuplicate();
    }
  };


  return (
    <Layout title={<MultiLangText
      language={language}
      textJa="申請書類管理　3.火気・危険物等に関する申請 ※全出展者提出"
      textEn="Application Forms 3. Fire and Hazardous Materials Application　*All Exhibitors(Required)"
    />}>
      <Paper style={{ padding: 16 }}>
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="■出展者情報"
                textEn="■Exhibitor information"
                />
        </Typography>
        <hr/>
        <ExhibitorInfoForm
          formData={formData}
          setFormData={setFormData}
          handleChange={handleChange}
        />
       <MultiLangText
                language={language}
                textJa="※「火元責任者」が上記と異なる場合、9月6日（金）までに、（株）ムラヤマへご連絡ください。"
                textEn="*If the person in charge of fire safety is different from the person listed above, please contact Murayama Inc. by Friday, September 6th."
                />
        <form onSubmit={handleSubmit}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault(); // Enterキーによるフォーム送信を防ぐ
             // アラートを表示
          }
        }}>
          <Typography gutterBottom mt={5} style={{ fontWeight: 'bold' }}>
          <MultiLangText
                language={language}
                textJa="■火気・危険物等に関する申請"
                textEn="Fire and Hazardous Materials Application"
                />　
          </Typography>
          <hr />
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={3} sm={3} style={{ display: 'flex' }}>
            <MultiLangText
                language={language}
                textJa="①裸火の使用有無"
                textEn="Use of Fire"
                />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>
              <RadioGroup
                row
                aria-label="fireUsage"
                name="fireUsage"
                value={formData.fireUsage}
                onChange={handleChange}
              >
                <FormControlLabel value="あり" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="あり"
                textEn="Yes"
                />} disabled={isReadOnly}/>
                <FormControlLabel value="なし" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="なし"
                textEn="No"
                />} disabled={isReadOnly}/>
              </RadioGroup>
            </Grid>

            <Grid item xs={1} sm={1} style={{ display: 'flex'}}>
            </Grid>
            <Grid item xs={1} sm={1} style={{ display: 'flex'}}>
            </Grid>
            <Grid item xs={10} sm={10}>
            <MultiLangText
                language={language}
                textJa="※ありの場合は、下記にご記載ください。"
                textEn='*If "Yes", please fill in the box below: Type of equipment, heat source, maximum heat consumption per hour, purpose.<br/>'
                />
                <MultiLangText
                language={language}
                textJa="※裸火の使用（電熱器・電気コンロ・湯沸器・ガス器具・ボイラー・厨房設備など）"
                textEn="*Use of Fire (including electric heaters, electric stoves, water heaters, gas appliances, boilers, kitchen equipment, etc.)"
                />
            </Grid>

            <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
            </Grid>
            <Grid item xs={10} sm={10}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="持ち込み物品"
                textEn="Type of Equipment"
                />}
                  name="fireDeviceType1"
                  value={formData.fireDeviceType1}
                  onChange={handleChange}
                  sx={{ width: 150 }}
                  margin="normal"
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="熱源"
                textEn="Heat Source"
                />}
                  name="fireSource1"
                  value={formData.fireSource1}
                  onChange={handleChange}
                  sx={{ width: 90 }}
                  margin="normal"
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="１時間の最大消費熱量「kW」"
                textEn="Maximum Heat Consumption per Hour (kW)"
                />}
                  name="fireConsumption1"
                  value={formData.fireConsumption1}
                  onChange={handleChange}
                  sx={{ width: 300 }}
                  margin="normal"
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="使用目的"
                textEn="Purpose of Use"
                />}
                  name="firePurpose1"
                  value={formData.firePurpose1}
                  onChange={handleChange}
                  sx={{ width: 300 }}
                  margin="normal"
                  disabled={isReadOnly}
                />
              </Box>
            </Grid>

            <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
            </Grid>
            <Grid item xs={10} sm={10}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="持ち込み物品"
                textEn="Type of Equipment"
                />}
                  name="fireDeviceType2"
                  value={formData.fireDeviceType2}
                  onChange={handleChange}
                  sx={{ width: 150 }}
                  margin="normal"
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="熱源"
                textEn="Heat Source"
                />}
                  name="fireSource2"
                  value={formData.fireSource2}
                  onChange={handleChange}
                  sx={{ width: 90 }}
                  margin="normal"
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="１時間の最大消費熱量「kW」"
                textEn="Maximum Heat Consumption per Hour (kW)"
                />}
                  name="fireConsumption2"
                  value={formData.fireConsumption2}
                  onChange={handleChange}
                  sx={{ width: 300 }}
                  margin="normal"
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="使用目的"
                textEn="Purpose of Use"
                />}
                  name="firePurpose2"
                  value={formData.firePurpose2}
                  onChange={handleChange}
                  sx={{ width: 300 }}
                  margin="normal"
                  disabled={isReadOnly}
                />
              </Box>
            </Grid>

            <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
            </Grid>
            <Grid item xs={10} sm={10}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="持ち込み物品"
                textEn="Type of Equipment"
                />}
                  name="fireDeviceType3"
                  value={formData.fireDeviceType3}
                  onChange={handleChange}
                  sx={{ width: 150 }}
                  margin="normal"
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="熱源"
                textEn="Heat Source"
                />}
                  name="fireSource3"
                  value={formData.fireSource3}
                  onChange={handleChange}
                  sx={{ width: 90 }}
                  margin="normal"
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="１時間の最大消費熱量「kW」"
                textEn="Maximum Heat Consumption per Hour (kW)"
                />}
                  name="fireConsumption3"
                  value={formData.fireConsumption3}
                  onChange={handleChange}
                  sx={{ width: 300 }}
                  margin="normal"
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="使用目的"
                textEn="Purpose of Use"
                />}
                  name="firePurpose3"
                  value={formData.firePurpose3}
                  onChange={handleChange}
                  sx={{ width: 300 }}
                  margin="normal"
                  disabled={isReadOnly}
                />
              </Box>
            </Grid>

            <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
            </Grid>
            <Grid item xs={10} sm={10}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="持ち込み物品"
                textEn="Type of Equipment"
                />}
                  name="fireDeviceType4"
                  value={formData.fireDeviceType4}
                  onChange={handleChange}
                  sx={{ width: 150 }}
                  margin="normal"
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="熱源"
                textEn="Heat Source"
                />}
                  name="fireSource4"
                  value={formData.fireSource4}
                  onChange={handleChange}
                  sx={{ width: 90 }}
                  margin="normal"
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="１時間の最大消費熱量「kW」"
                textEn="Maximum Heat Consumption per Hour (kW)"
                />}
                  name="fireConsumption4"
                  value={formData.fireConsumption4}
                  onChange={handleChange}
                  sx={{ width: 300 }}
                  margin="normal"
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="使用目的"
                textEn="Purpose of Use"
                />}
                  name="firePurpose4"
                  value={formData.firePurpose4}
                  onChange={handleChange}
                  sx={{ width: 300 }}
                  margin="normal"
                  disabled={isReadOnly}
                />
              </Box>
            </Grid>

            <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
            </Grid>
            <Grid item xs={10} sm={10}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="持ち込み物品"
                textEn="Type of Equipment"
                />}
                  name="fireDeviceType5"
                  value={formData.fireDeviceType5}
                  onChange={handleChange}
                  sx={{ width: 150 }}
                  margin="normal"
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="熱源"
                textEn="Heat Source"
                />}
                  name="fireSource5"
                  value={formData.fireSource5}
                  onChange={handleChange}
                  sx={{ width: 90 }}
                  margin="normal"
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="１時間の最大消費熱量「kW」"
                textEn="Maximum Heat Consumption per Hour (kW)"
                />}
                  name="fireConsumption5"
                  value={formData.fireConsumption5}
                  onChange={handleChange}
                  sx={{ width: 300 }}
                  margin="normal"
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="使用目的"
                textEn="Purpose of Use"
                />}
                  name="firePurpose5"
                  value={formData.firePurpose5}
                  onChange={handleChange}
                  sx={{ width: 300 }}
                  margin="normal"
                  disabled={isReadOnly}
                />
              </Box>
            </Grid>

            <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
            </Grid>
            <Grid item xs={10} sm={10}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="持ち込み物品"
                textEn="Type of Equipment"
                />}
                  name="fireDeviceType6"
                  value={formData.fireDeviceType6}
                  onChange={handleChange}
                  sx={{ width: 150 }}
                  margin="normal"
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="熱源"
                textEn="Heat Source"
                />}
                  name="fireSource6"
                  value={formData.fireSource6}
                  onChange={handleChange}
                  sx={{ width: 90 }}
                  margin="normal"
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="１時間の最大消費熱量「kW」"
                textEn="Maximum Heat Consumption per Hour (kW)"
                />}
                  name="fireConsumption6"
                  value={formData.fireConsumption6}
                  onChange={handleChange}
                  sx={{ width: 300 }}
                  margin="normal"
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="使用目的"
                textEn="Purpose of Use"
                />}
                  name="firePurpose6"
                  value={formData.firePurpose6}
                  onChange={handleChange}
                  sx={{ width: 300 }}
                  margin="normal"
                  disabled={isReadOnly}
                />
              </Box>
            </Grid>

            <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
            </Grid>
            <Grid item xs={10} sm={10}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="持ち込み物品"
                textEn="Type of Equipment"
                />}
                  name="fireDeviceType7"
                  value={formData.fireDeviceType7}
                  onChange={handleChange}
                  sx={{ width: 150 }}
                  margin="normal"
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="熱源"
                textEn="Heat Source"
                />}
                  name="fireSource7"
                  value={formData.fireSource7}
                  onChange={handleChange}
                  sx={{ width: 90 }}
                  margin="normal"
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="１時間の最大消費熱量「kW」"
                textEn="Maximum Heat Consumption per Hour (kW)"
                />}
                  name="fireConsumption7"
                  value={formData.fireConsumption7}
                  onChange={handleChange}
                  sx={{ width: 300 }}
                  margin="normal"
                  disabled={isReadOnly}
                />　
                <TextField
                  label={<MultiLangText
                language={language}
                textJa="使用目的"
                textEn="Purpose of Use"
                />}
                  name="firePurpose7"
                  value={formData.firePurpose7}
                  onChange={handleChange}
                  sx={{ width: 300 }}
                  margin="normal"
                  disabled={isReadOnly}
                />
              </Box>
            </Grid>

            <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
            </Grid>
            <Grid item xs={10} sm={10}>
            
            </Grid>

            <hr></hr>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={4} sm={4} style={{ display: 'flex'}}>
              <MultiLangText
                language={language}
                textJa="②危険物品（インク・アルコール・各種油・ガスなど）の持ち込み有無"
                textEn="Use of Hazardous Materials (ink, alcohol, oils, gas and others) "
                />　<RequiredLabel/>
              </Grid>
              <Grid item xs={8} sm={8}>
                <RadioGroup
                  row
                  aria-label="hazardousMaterials"
                  name="hazardousMaterials"
                  value={formData.hazardousMaterials}
                  onChange={handleChange}
                >
                  <Grid item xs={3} sm={3}>
                    <FormControlLabel value="あり" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="あり"
                textEn="Yes"
                />} disabled={isReadOnly} />
                  </Grid>
                  <Grid item xs={1} sm={1}></Grid>
                  <Grid item xs={3} sm={3}>
                    <FormControlLabel value="なし" control={<Radio required />} label={<MultiLangText
                language={language}
                textJa="なし"
                textEn="No"
                />} disabled={isReadOnly} />
                  </Grid>
                  <Grid item xs={3} sm={3}></Grid>
                </RadioGroup>
              </Grid>

              <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
              </Grid>
              <Grid item xs={10} sm={10}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="類別・品名"
                textEn="Type/Property"
                />}
                    name="hazardousType1"
                    value={formData.hazardousType1}
                    onChange={handleChange}
                    sx={{ width: 150 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />　
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="アイテム名"
                textEn="Product Name"
                />}
                    name="hazardousName1"
                    value={formData.hazardousName1}
                    onChange={handleChange}
                    sx={{ width: 200 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />　
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="持込量"
                textEn="Qty."
                />}
                    name="hazardousAmount1"
                    value={formData.hazardousAmount1}
                    onChange={handleChange}
                    sx={{ width: 90 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />
                  <Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>L×</Box>
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="持込数"
                textEn="bottle(s)"
                />}
                    name="hazardousNumber1"
                    value={formData.hazardousNumber1}
                    onChange={handleChange}
                    sx={{ width: 90 }}
                    margin="normal"
                    disabled={isReadOnly}
                  /><Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>
<MultiLangText
                language={language}
                textJa="本"
                textEn=""
                />
</Box>
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="収納容器"
                textEn="Container"
                />}
                    name="hazardousContainer1"
                    value={formData.hazardousContainer1}
                    onChange={handleChange}
                    sx={{ width: 150 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />　
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="用途・目的"
                textEn="Purpose of Use"
                />}
                    name="hazardousPurpose1"
                    value={formData.hazardousPurpose1}
                    onChange={handleChange}
                    sx={{ width: 150 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />
                </Box>
              </Grid>

              <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
              </Grid>
              <Grid item xs={10} sm={10}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="類別・品名"
                textEn="Type/Property"
                />}
                    name="hazardousType2"
                    value={formData.hazardousType2}
                    onChange={handleChange}
                    sx={{ width: 150 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />　
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="アイテム名"
                textEn="Product Name"
                />}
                    name="hazardousName2"
                    value={formData.hazardousName2}
                    onChange={handleChange}
                    sx={{ width: 200 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />　
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="持込量"
                textEn="Qty."
                />}
                    name="hazardousAmount2"
                    value={formData.hazardousAmount2}
                    onChange={handleChange}
                    sx={{ width: 90 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />
                  <Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>L×</Box>
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="持込数"
                textEn="bottle(s)"
                />}
                    name="hazardousNumber2"
                    value={formData.hazardousNumber2}
                    onChange={handleChange}
                    sx={{ width: 90 }}
                    margin="normal"
                    disabled={isReadOnly}
                  /><Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>
<MultiLangText
                language={language}
                textJa="本"
                textEn=""
                />
</Box>
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="収納容器"
                textEn="Container"
                />}
                    name="hazardousContainer2"
                    value={formData.hazardousContainer2}
                    onChange={handleChange}
                    sx={{ width: 150 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />　
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="用途・目的"
                textEn="Purpose of Use"
                />}
                    name="hazardousPurpose2"
                    value={formData.hazardousPurpose2}
                    onChange={handleChange}
                    sx={{ width: 150 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />
                </Box>
              </Grid>

              <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
              </Grid>
              <Grid item xs={10} sm={10}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="類別・品名"
                textEn="Type/Property"
                />}
                    name="hazardousType3"
                    value={formData.hazardousType3}
                    onChange={handleChange}
                    sx={{ width: 150 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />　
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="アイテム名"
                textEn="Product Name"
                />}
                    name="hazardousName3"
                    value={formData.hazardousName3}
                    onChange={handleChange}
                    sx={{ width: 200 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />　
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="持込量"
                textEn="Qty."
                />}
                    name="hazardousAmount3"
                    value={formData.hazardousAmount3}
                    onChange={handleChange}
                    sx={{ width: 90 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />
                  <Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>L×</Box>
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="持込数"
                textEn="bottle(s)"
                />}
                    name="hazardousNumber3"
                    value={formData.hazardousNumber3}
                    onChange={handleChange}
                    sx={{ width: 90 }}
                    margin="normal"
                    disabled={isReadOnly}
                  /><Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>
<MultiLangText
                language={language}
                textJa="本"
                textEn=""
                />
</Box>
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="収納容器"
                textEn="Container"
                />}
                    name="hazardousContainer3"
                    value={formData.hazardousContainer3}
                    onChange={handleChange}
                    sx={{ width: 150 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />　
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="用途・目的"
                textEn="Purpose of Use"
                />}
                    name="hazardousPurpose3"
                    value={formData.hazardousPurpose3}
                    onChange={handleChange}
                    sx={{ width: 150 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />
                </Box>
              </Grid>

              <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
              </Grid>
              <Grid item xs={10} sm={10}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="類別・品名"
                textEn="Type/Property"
                />}
                    name="hazardousType4"
                    value={formData.hazardousType4}
                    onChange={handleChange}
                    sx={{ width: 150 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />　
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="アイテム名"
                textEn="Product Name"
                />}
                    name="hazardousName4"
                    value={formData.hazardousName4}
                    onChange={handleChange}
                    sx={{ width: 200 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />　
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="持込量"
                textEn="Qty."
                />}
                    name="hazardousAmount4"
                    value={formData.hazardousAmount4}
                    onChange={handleChange}
                    sx={{ width: 90 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />
                  <Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>L×</Box>
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="持込数"
                textEn="bottle(s)"
                />}
                    name="hazardousNumber4"
                    value={formData.hazardousNumber4}
                    onChange={handleChange}
                    sx={{ width: 90 }}
                    margin="normal"
                    disabled={isReadOnly}
                  /><Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>
<MultiLangText
                language={language}
                textJa="本"
                textEn=""
                />
</Box>
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="収納容器"
                textEn="Container"
                />}
                    name="hazardousContainer4"
                    value={formData.hazardousContainer4}
                    onChange={handleChange}
                    sx={{ width: 150 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />　
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="用途・目的"
                textEn="Purpose of Use"
                />}
                    name="hazardousPurpose4"
                    value={formData.hazardousPurpose4}
                    onChange={handleChange}
                    sx={{ width: 150 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />
                </Box>
              </Grid>

              <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
              </Grid>
              <Grid item xs={10} sm={10}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="類別・品名"
                textEn="Type/Property"
                />}
                    name="hazardousType5"
                    value={formData.hazardousType5}
                    onChange={handleChange}
                    sx={{ width: 150 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />　
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="アイテム名"
                textEn="Product Name"
                />}
                    name="hazardousName5"
                    value={formData.hazardousName5}
                    onChange={handleChange}
                    sx={{ width: 200 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />　
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="持込量"
                textEn="Qty."
                />}
                    name="hazardousAmount5"
                    value={formData.hazardousAmount5}
                    onChange={handleChange}
                    sx={{ width: 90 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />
                  <Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>L×</Box>
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="持込数"
                textEn="bottle(s)"
                />}
                    name="hazardousNumber5"
                    value={formData.hazardousNumber5}
                    onChange={handleChange}
                    sx={{ width: 90 }}
                    margin="normal"
                    disabled={isReadOnly}
                  /><Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>
<MultiLangText
                language={language}
                textJa="本"
                textEn=""
                />
</Box>
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="収納容器"
                textEn="Container"
                />}
                    name="hazardousContainer5"
                    value={formData.hazardousContainer5}
                    onChange={handleChange}
                    sx={{ width: 150 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />　
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="用途・目的"
                textEn="Purpose of Use"
                />}
                    name="hazardousPurpose5"
                    value={formData.hazardousPurpose5}
                    onChange={handleChange}
                    sx={{ width: 150 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />
                </Box>
              </Grid>

              <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
              </Grid>
              <Grid item xs={10} sm={10}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="類別・品名"
                textEn="Type/Property"
                />}
                    name="hazardousType6"
                    value={formData.hazardousType6}
                    onChange={handleChange}
                    sx={{ width: 150 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />　
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="アイテム名"
                textEn="Product Name"
                />}
                    name="hazardousName6"
                    value={formData.hazardousName6}
                    onChange={handleChange}
                    sx={{ width: 200 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />　
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="持込量"
                textEn="Qty."
                />}
                    name="hazardousAmount6"
                    value={formData.hazardousAmount6}
                    onChange={handleChange}
                    sx={{ width: 90 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />
                  <Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>L×</Box>
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="持込数"
                textEn="bottle(s)"
                />}
                    name="hazardousNumber6"
                    value={formData.hazardousNumber6}
                    onChange={handleChange}
                    sx={{ width: 90 }}
                    margin="normal"
                    disabled={isReadOnly}
                  /><Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>
<MultiLangText
                language={language}
                textJa="本"
                textEn=""
                />
</Box>
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="収納容器"
                textEn="Container"
                />}
                    name="hazardousContainer6"
                    value={formData.hazardousContainer6}
                    onChange={handleChange}
                    sx={{ width: 150 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />　
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="用途・目的"
                textEn="Purpose of Use"
                />}
                    name="hazardousPurpose6"
                    value={formData.hazardousPurpose6}
                    onChange={handleChange}
                    sx={{ width: 150 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />
                </Box>
              </Grid>

              <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
              </Grid>
              <Grid item xs={10} sm={10}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="類別・品名"
                textEn="Type/Property"
                />}
                    name="hazardousType7"
                    value={formData.hazardousType7}
                    onChange={handleChange}
                    sx={{ width: 150 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />　
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="アイテム名"
                textEn="Product Name"
                />}
                    name="hazardousName7"
                    value={formData.hazardousName7}
                    onChange={handleChange}
                    sx={{ width: 200 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />　
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="持込量"
                textEn="Qty."
                />}
                    name="hazardousAmount7"
                    value={formData.hazardousAmount7}
                    onChange={handleChange}
                    sx={{ width: 90 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />
                  <Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>L×</Box>
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="持込数"
                textEn="bottle(s)"
                />}
                    name="hazardousNumber7"
                    value={formData.hazardousNumber7}
                    onChange={handleChange}
                    sx={{ width: 90 }}
                    margin="normal"
                    disabled={isReadOnly}
                  /><Box component="span" sx={{ mx: 1, lineHeight: 'normal' }}>
<MultiLangText
                language={language}
                textJa="本"
                textEn=""
                />
</Box>
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="収納容器"
                textEn="Container"
                />}
                    name="hazardousContainer7"
                    value={formData.hazardousContainer7}
                    onChange={handleChange}
                    sx={{ width: 150 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />　
                  <TextField
                    label={<MultiLangText
                language={language}
                textJa="用途・目的"
                textEn="Purpose of Use"
                />}
                    name="hazardousPurpose7"
                    value={formData.hazardousPurpose7}
                    onChange={handleChange}
                    sx={{ width: 150 }}
                    margin="normal"
                    disabled={isReadOnly}
                  />
                </Box>
              </Grid>

              <Grid item xs={2} sm={2} style={{ display: 'flex'}}>
              </Grid>
              <Grid item xs={10} sm={10}>
              <MultiLangText
                language={language}
                textJa="※持ち込み量は、同時に持ち込む容量をご記入ください。"
                textEn="*Input the total qty. of the hazardous materials at same time."
                /><br/>
                <MultiLangText
                language={language}
                textJa="※持ち込み量は、「○○L×〇本」と数量の内訳がわかるようにご記入ください。"
                textEn="*Input the details of the qty. like as (xx litres x xx bottles). L=litre(s)"
                /><br/>
                 <MultiLangText
                language={language}
                textJa="※①②の仕様がわかる資料、カタログ、SDS、及び①②の使用位置を示した小間平面図をご提出ください。<br>
                　提出先→株式会社ムラヤマ＜ja2024@murayama.co.jp＞"
                textEn="• Submit the catalogue, brochure, SDS, that make the description of the
                materials clear and the booth layout plan with the exact place where the materials are located.
                Submit to Murayama Inc. ＜ja2024@murayama.co.jp＞"
                />
              </Grid>
              </Grid>

            <ContactInfo2 />
              {/* カラム */}
              <Grid item xs={12} sm={12} style={{ display: 'flex'}}>
              <MultiLangText
                language={language}
                textJa="
※下記、「確定」ボタンを押して、申請してください。「保存」中は修正が可能です。<br/>
              ※「リセット」ボタンがある申請書については、万一、確定後に修正が必要な場合も、内容の修正が可能です。
"
                textEn='
*Please click the "SUBMIT" button to submit your application. You can "SAVE” the form while you still need to modify it.<br/>
*Some forms have “RESET” button, which you can use when you need to reset and fill in the form again after submitting the form.
'
              />
            </Grid>
            <Grid container mt={5} spacing={1} justifyContent="center">
              {/*
              <Grid item>
                <Button onClick={handleReset} variant="contained" color="secondary">
                <MultiLangText
                language={language}
                textJa="リセット"
                textEn="Reset"
                />
                </Button>
              </Grid>
              */}
              <Grid item>
              <Button
                  onClick={handleSave}
                  variant="contained"
                  color="primary"
                  disabled={formData.buttonState.save}  // JSONで制御
                  sx={{ filter: formData.buttonState.save ? 'grayscale(1)' : 'none' }}
                >
                  <MultiLangText
                    language={language}
                    textJa="保存"
                    textEn="Save"
                  />
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={formData.buttonState.submit || isDuplicate || isSubmitted}  // JSON制御 + 重複や送信済みの場合
                  sx={{ filter: (formData.buttonState.submit || isDuplicate || isSubmitted) ? 'grayscale(1)' : 'none' }}
                >
                  <MultiLangText
                    language={language}
                    textJa="確定"
                    textEn="Submit"
                  />
                </Button>
              </Grid>
            </Grid>

          </Grid>
        </form>
      </Paper>
    </Layout>
  );
};

export default Template003;
