import React, { useState, useEffect } from 'react';
import Layout from '../templetes/Layout';
import { 
  TextField, Button, Typography, Paper, Grid, FormControlLabel, Radio, RadioGroup, Select, MenuItem, InputLabel, FormControl
} from '@mui/material';
import ExhibitorInfoForm from '../templetes/ExhibitorInfoForm';
import { SelectChangeEvent } from '@mui/material';
import MultiLangText from '../templetes/MultiLangText';
import { useAuth } from '../../context/AuthContext';
import RequiredLabel from '../templetes/RequiredLabel';
import axios from 'axios';


const Template017: React.FC = () => {
  const { language } = useAuth();
  const isJapanese = language === 'ja';

  const [formData, setFormData] = useState({
    exhibitorName: '',
    boothNumber: '',
    tel: '',
    departmentPosition: '',
    contactPerson: '',
    postalCode: '',
    prefecture: '',
    city: '',
    address: '',
    email: '',
    Company_ID: '',
    decoration3mm: '',
    leafletJapanese: '',
    leafletEnglish: '',
    invitationPublicDay: '',
    shippingCompanyName: '',
    shippingMobile: '',
    shippingTel: '',
    shippingDepartmentPosition: '',
    shippingContactPerson: '',
    shippingPostalCode: '',
    shippingPrefecture: '',
    shippingCity: '',
    shippingAddress: '',
    shippingEmail: '',
    billingCompanyName: '',
    billingMobile: '',
    billingTel: '',
    billingDepartmentPosition: '',
    billingContactPerson: '',
    billingPostalCode: '',
    billingPrefecture: '',
    billingCity: '',
    billingAddress: '',
    billingEmail: '',
    buttonState: {
      reset: false,  // リセットボタンの状態
      save: false,   // 保存ボタンの状態
      submit: false  // 確定ボタンの状態
    }
  });

  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);


  useEffect(() => {
    const loadButtonState = async () => {
      try {
        const timestamp = new Date().getTime();
        const response = await axios.get(`/json/buttonState17.json?_=${timestamp}`);  // 外部JSONのパスを指定
        const buttonStateData = response.data;
        setFormData(prevState => ({
          ...prevState,
          buttonState: buttonStateData.buttonState
        }));
        setIsReadOnly(buttonStateData.buttonState.disabled);
      } catch (error) {
        console.error('ボタン状態の読み込み中にエラーが発生しました:', error);
      }
    };
  
    loadButtonState();  // コンポーネントの初回レンダリング時に実行
  }, []);

  useEffect(() => {
    const savedData = localStorage.getItem('formData017');
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);

  useEffect(() => {
    if (formData.exhibitorName) {
      checkDuplicate();
    }
  }, [formData.exhibitorName]);

  const checkDuplicate = async () => {
    try {
      const response = await fetch('https://xs525443.xsrv.jp/admin_ui/check017.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          exhibitorName: formData.exhibitorName,
          Company_ID: formData.Company_ID
        }),
      });

      const result = await response.json();
      if (result.isDuplicate && result.additionalData) {
        const parsedData = JSON.parse(result.additionalData);
        setFormData(prevState => ({
          ...prevState,
          ...parsedData,
        }));
        setIsDuplicate(true);
        setIsReadOnly(true);
      } else {
        setIsDuplicate(false);
      }
    } catch (error) {
      console.error('重複チェック中にエラーが発生しました:', error);
    }
  };

  const handleSave = () => {
    localStorage.setItem('formData017', JSON.stringify(formData));
    const alertMessage = language === 'ja' ? 'データを保存しました。' : 'Save Completed';
  alert(alertMessage);
  };

  const handleReset = async () => {
  const confirmMessage = language === 'ja' ? '本当に削除していいですか？' : 'Are you sure you want to reset?';
  const resetSuccessMessage = language === 'ja' ? 'フォームとファイルがリセットされました。' : 'Reset Completed';
  const deleteErrorMessage = language === 'ja' ? 'データの削除に失敗しました。' : 'Failed to delete data.';
    if (window.confirm(confirmMessage)) {
      try {
        const response = await fetch('https://xs525443.xsrv.jp/admin_ui/delete017.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
          exhibitorName: formData.exhibitorName,
          Company_ID: formData.Company_ID
        }),
        });

        const result = await response.json();
        if (result.success) {
          setFormData(prevState => ({
            ...prevState,
            decoration3mm: '',
            leafletJapanese: '',
            leafletEnglish: '',
            invitationPublicDay: '',
            shippingCompanyName: '',
            shippingMobile: '',
            shippingTel: '',
            shippingDepartmentPosition: '',
            shippingContactPerson: '',
            shippingPostalCode: '',
            shippingPrefecture: '',
            shippingCity: '',
            shippingAddress: '',
            shippingEmail: '',
            billingCompanyName: '',
            billingMobile: '',
            billingTel: '',
            billingDepartmentPosition: '',
            billingContactPerson: '',
            billingPostalCode: '',
            billingPrefecture: '',
            billingCity: '',
            billingAddress: '',
            billingEmail: ''
          }));
          localStorage.removeItem('formData017');
          setIsReadOnly(false);
          setIsSubmitted(false);
          alert(resetSuccessMessage);
          checkDuplicate();
        } else {
          alert(deleteErrorMessage + ': ' + result.message);
        }
      } catch (error) {
        console.error('データの削除中にエラーが発生しました:', error);
        alert(deleteErrorMessage);
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!formData.exhibitorName) {
        alert('出展者名を入力してください。');
        return;
    }

    const formDataToSend = new FormData();

    const jsonData = {
      decoration3mm: formData.decoration3mm,
      leafletJapanese: formData.leafletJapanese,
      leafletEnglish: formData.leafletEnglish,
      invitationPublicDay: formData.invitationPublicDay,
      shippingCompanyName: formData.shippingCompanyName,
      shippingMobile: formData.shippingMobile,
      shippingTel: formData.shippingTel,
      shippingDepartmentPosition: formData.shippingDepartmentPosition,
      shippingContactPerson: formData.shippingContactPerson,
      shippingPostalCode: formData.shippingPostalCode,
      shippingPrefecture: formData.shippingPrefecture,
      shippingCity: formData.shippingCity,
      shippingAddress: formData.shippingAddress,
      shippingEmail: formData.shippingEmail,
      billingCompanyName: formData.billingCompanyName,
      billingMobile: formData.billingMobile,
      billingTel: formData.billingTel,
      billingDepartmentPosition: formData.billingDepartmentPosition,
      billingContactPerson: formData.billingContactPerson,
      billingPostalCode: formData.billingPostalCode,
      billingPrefecture: formData.billingPrefecture,
      billingCity: formData.billingCity,
      billingAddress: formData.billingAddress,
      billingEmail: formData.billingEmail
    };

    formDataToSend.append('exhibitorName', formData.exhibitorName);
    formDataToSend.append('boothNumber', formData.boothNumber);
    formDataToSend.append('tel', formData.tel);
    formDataToSend.append('departmentPosition', formData.departmentPosition);
    formDataToSend.append('contactPerson', formData.contactPerson);
    formDataToSend.append('postalCode', formData.postalCode);
    formDataToSend.append('prefecture', formData.prefecture);
    formDataToSend.append('city', formData.city);
    formDataToSend.append('address', formData.address);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('Company_ID', formData.Company_ID);
    formDataToSend.append('jsonData', JSON.stringify(jsonData));

    try {
      const response = await fetch('https://xs525443.xsrv.jp/admin_ui/submit017.php', {
        method: 'POST',
        body: formDataToSend
      });

      const result = await response.json();

      const successMessage = language === 'ja' ? 'データが正常に送信されました。' : 'Submission Completed';
      const failureMessage = language === 'ja' ? 'データの送信に失敗しました: ' : 'Failed to submit data: ';
      const errorMessage = language === 'ja' ? 'データの送信に失敗しました。' : 'Failed to submit data.';

      if (response.ok) {
       // alert(successMessage);
        setIsSubmitted(true);
        setIsReadOnly(true);
      } else {
        alert('データの送信中にエラーが発生しました: ' + result.message);
      }
    } catch (error) {
      console.error('データ送信エラー:', error);
      alert('データの送信中にエラーが発生しました。');
    }
};


  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handlePostalCodeChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const cleanedValue = value.replace(/-/g, ''); // ハイフンを除去

    setFormData(prevState => ({
      ...prevState,
      [name]: cleanedValue
    }));

    if (cleanedValue.length === 7) {
      try {
        const response = await fetch(`https://api.zipaddress.net/?zipcode=${cleanedValue}`);
        const data = await response.json();
        if (data.code === 200) {
          if (name === 'postalCode') {
            setFormData(prevState => ({
              ...prevState,
              prefecture: data.data.pref,
              city: data.data.city,
              address: data.data.town
            }));
          } else if (name === 'shippingPostalCode') {
            setFormData(prevState => ({
              ...prevState,
              shippingPrefecture: data.data.pref,
              shippingCity: data.data.city,
              shippingAddress: data.data.town
            }));
          } else if (name === 'billingPostalCode') {
            setFormData(prevState => ({
              ...prevState,
              billingPrefecture: data.data.pref,
              billingCity: data.data.city,
              billingAddress: data.data.town
            }));
          }
        } else {
          alert('郵便番号から住所を取得できませんでした。');
        }
      } catch (error) {
        console.error('郵便番号から住所を取得中にエラーが発生しました:', error);
      }
    }
  };

  const prefectures = ["北海道", "青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県", "茨城県", "栃木県", "群馬県", "埼玉県", "千葉県", "東京都", "神奈川県", "新潟県", "富山県", "石川県", "福井県", "山梨県", "長野県", "岐阜県", "静岡県", "愛知県", "三重県", "滋賀県", "京都府", "大阪府", "兵庫県", "奈良県", "和歌山県", "鳥取県", "島根県", "岡山県", "広島県", "山口県", "徳島県", "香川県", "愛媛県", "高知県", "福岡県", "佐賀県", "長崎県", "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県"];

  return (
    <Layout title={<MultiLangText
      language={language}
      textJa="申請書類管理　17．来場案内リーフレット・パブリックデー招待券追加 申込み"
      textEn="Application Forms 17.Application for the Additional Leaflets and Invitation Ticket for Public Day"
    />}>
      <Paper style={{ padding: 16 }}>
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          <MultiLangText
            language={language}
            textJa="■出展者情報"
            textEn="■Exhibitor information"
          />
        </Typography>
        <hr />
        <form onSubmit={handleSubmit}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault(); // Enterキーによるフォーム送信を防ぐ
             // アラートを表示
          }
        }}>
          <ExhibitorInfoForm
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
          />

          <br /><br />
          {/* セクションスタート */}
          <Typography gutterBottom style={{ fontWeight: 'bold' }}>
            <MultiLangText
              language={language}
              textJa="■来場案内リーフレット・パブリックデー招待券の追加購入"
              textEn="■Additional leaflets and invitation ticket for public day"
            />
          </Typography>
          <hr />
          <Grid container alignItems="center">
            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="内容"
                textEn="Details "
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={10} sm={10}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={8} sm={8}>
                  <MultiLangText
                    language={language}
                    textJa="① 来場案内リーフレット（日本語版） ¥1,100 (税込)/セット"
                    textEn="① leaflets (Japanese)JPY 1,100/set (Tax Included)"
                  />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <TextField
                    label={<MultiLangText
                      language={language}
                      textJa="セット"
                      textEn="set"
                    />}
                    name="leafletJapanese"
                    value={formData.leafletJapanese}
                    onChange={handleChange}
                    sx={{ width: 100 }}
                    margin="normal"
                    required
                    disabled={isReadOnly}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={10} sm={10}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={8} sm={8}>
                  <MultiLangText
                    language={language}
                    textJa="② 来場案内リーフレット（英語版） ¥1,100 (税込)/セット"
                    textEn="② leaflets (English)JPY 1,100/set (Tax Included)"
                  />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <TextField
                    label={<MultiLangText
                      language={language}
                      textJa="セット"
                      textEn="set"
                    />}
                    name="leafletEnglish"
                    value={formData.leafletEnglish}
                    onChange={handleChange}
                    sx={{ width: 100 }}
                    margin="normal"
                    required
                    disabled={isReadOnly}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={10} sm={10}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={8} sm={8}>
                  <MultiLangText
                    language={language}
                    textJa="③ パブリックデー招待券 ¥550 (税込)/枚"
                    textEn="③ invitation ticket for public day JPY 550/ticket (Tax Included)"
                  />
                </Grid>
                <Grid item xs={4} sm={4}>
                  <TextField
                    label={
                      <MultiLangText
                        language={language}
                        textJa="枚"
                        textEn="ticket"
                      />
                    }
                    name="invitationPublicDay"
                    value={formData.invitationPublicDay}
                    onChange={handleChange}
                    sx={{ width: 100 }}
                    margin="normal"
                    required
                    disabled={isReadOnly}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* カラム */}
            <Grid item mt={5} xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={10} sm={10}>
              <Grid container spacing={3} alignItems="center">
                <MultiLangText
                  language={language}
                  textJa="※規定発行枚数は「出展者マニュアル」をご参照ください。<br/>
                ※来場案内リーフレットの追加は、１セット１００枚（封筒１０枚付）から承ります。(必ずセット数を入力してください。)<br/>
                ※申請しない項目がある場合は「０」(数字)と入力してください。
                "
                  textEn="*Please refer to the exhibitor manual about specified quantity.<br/>
                *Additional leaflets are to be order per 100(with 10 envelopes)Please purchase in sets.<br/>
                *If there are items you do not wish to apply for, enter “0” (number).
                "
                />
              </Grid>
            </Grid>
          </Grid>
          {/* セクションここまで */}
          <br /><br />
          {/* セクションスタート */}
          <Typography gutterBottom style={{ fontWeight: 'bold' }}>
            <MultiLangText
              language={language}
              textJa="■送付先（上記担当者情報と異なる場合のみ）"
              textEn='■Address to send leaflets and invitation ticket for public day(*If address is different from "Exhibitor information" listed above, please fill boxes in below.)'
            />
          </Typography>
          <hr />
          <Grid container spacing={2} alignItems="center">
            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="送付先会社名"
                textEn="Company Name"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>
              <TextField
                name="shippingCompanyName"
                value={formData.shippingCompanyName}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="担当者携帯番号"
                textEn="Mobile Phone Number"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                name="shippingMobile"
                value={formData.shippingMobile}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              TEL　<RequiredLabel />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                name="shippingTel"
                value={formData.shippingTel}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="担当者部署・役職"
                textEn="Title / Department of the Contact Person"
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                name="shippingDepartmentPosition"
                value={formData.shippingDepartmentPosition}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="担当者"
                textEn="Name of Contact Person"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                name="shippingContactPerson"
                value={formData.shippingContactPerson}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="住所"
                textEn="Address"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>
              <Grid container>
                <Grid item xs={5} sm={5}>
                  <TextField
                    label={<MultiLangText
                      language={language}
                      textJa="〒"
                      textEn="Postal Code"
                    />}
                    name="shippingPostalCode"
                    value={formData.shippingPostalCode}
                    onChange={handlePostalCodeChange}
                    fullWidth
                    disabled={isReadOnly}
                  />
                </Grid>
                <Grid item xs={0} sm={0.1}></Grid>

                {
                  isJapanese && (
                    <Grid item xs={5} sm={5}>
                    <FormControl fullWidth>
                      <InputLabel id="shipping-prefecture-label">都道府県</InputLabel>
                      <Select
                        labelId="shipping-prefecture-label"
                        id="shipping-prefecture"
                        name="shippingPrefecture"
                        value={formData.shippingPrefecture}
                        label="都道府県"
                        onChange={handleChange}
                        disabled={isReadOnly}
                      >
                        {prefectures.map((prefecture, index) => (
                          <MenuItem key={index} value={prefecture}>{prefecture}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  )
                }
                {
                  !isJapanese && (
                    <Grid item xs={5} sm={5}>
                    </Grid>
                  )
                }



              </Grid>
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={9} sm={9}>
              <TextField
                label={<MultiLangText
                language={language}
                textJa="市区町村名（例：大阪市北区）"
                textEn="city"
                />}

                name="shippingCity"
                value={formData.shippingCity}
                onChange={handleChange}
                fullWidth
                disabled={isReadOnly}
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={9} sm={9}>
              <TextField
                label={<MultiLangText
                language={language}
                textJa="番地・ビル名（例：〇〇１丁目2-3）"
                textEn="Address and Building Name"
                />}
                name="shippingAddress"
                value={formData.shippingAddress}
                onChange={handleChange}
                fullWidth
                disabled={isReadOnly}
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="メールアドレス　"
                textEn="E-mail"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>
              <TextField
                label="XXXXX@xxxxxx.com"
                name="shippingEmail"
                value={formData.shippingEmail}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={9} sm={9}>
            <MultiLangText
                language={language}
                textJa="※上記の送付先が出展者と異なる場合に記入してください。"
                textEn="*Please fill in above if the billing information is different from the exhibitor."
                />
            </Grid>

          </Grid>
          {/* セクションここまで */}
          <br /><br />
          {/* セクションスタート */}
          <Typography gutterBottom style={{ fontWeight: 'bold' }}>
            <MultiLangText
              language={language}
              textJa="■請求先について"
              textEn="■Invoice Recipient "
            />
          </Typography>
          <hr />
          <Grid container spacing={2} alignItems="center">
            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="お選びください"
                textEn="Please select"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>
              <RadioGroup
                row
                aria-label="decoration"
                name="decoration3mm"
                value={formData.decoration3mm}
                onChange={(e) => setFormData({ ...formData, decoration3mm: e.target.value })}
              >
                <Grid item xs={6} sm={6}>
                  <FormControlLabel value="出展者" control={<Radio required />} label={<MultiLangText
                    language={language}
                    textJa="出展者"
                    textEn="Exhibitor"
                  />} disabled={isReadOnly} />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <FormControlLabel value="その他（下記へご記入下さい）" control={<Radio required />} label={<MultiLangText
                    language={language}
                    textJa="その他（下記へご記入下さい）"
                    textEn="Other(Fill out the following blanks)"
                  />} disabled={isReadOnly} />
                </Grid>
              </RadioGroup>
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="請求先社名"
                textEn="Company Name"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>
              <TextField
                name="billingCompanyName"
                value={formData.billingCompanyName}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="担当者携帯"
                textEn="Mobile Phone Number"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                name="billingMobile"
                value={formData.billingMobile}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              TEL　<RequiredLabel />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                name="billingTel"
                value={formData.billingTel}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="担当者部署・役職"
                textEn="Title / Department of the Contact Person"
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                name="billingDepartmentPosition"
                value={formData.billingDepartmentPosition}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="担当者"
                textEn="Name of Contact Person"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                name="billingContactPerson"
                value={formData.billingContactPerson}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="住所"
                textEn="Address"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>
              <Grid container>
                <Grid item xs={5} sm={5}>
                  <TextField
                    label={<MultiLangText
                      language={language}
                      textJa="〒"
                      textEn="Postal Code"
                    />}
                    name="billingPostalCode"
                    value={formData.billingPostalCode}
                    onChange={handlePostalCodeChange}
                    fullWidth
                    disabled={isReadOnly}
                  />
                </Grid>
                <Grid item xs={0} sm={0.1}></Grid>

                {
                  isJapanese && (
                    <Grid item xs={5} sm={5}>
                    <FormControl fullWidth>
                      <InputLabel id="billing-prefecture-label">都道府県</InputLabel>
                      <Select
                        labelId="billing-prefecture-label"
                        id="billing-prefecture"
                        name="billingPrefecture"
                        value={formData.billingPrefecture}
                        label="都道府県"
                        onChange={handleChange}
                        disabled={isReadOnly}
                      >
                        {prefectures.map((prefecture, index) => (
                          <MenuItem key={index} value={prefecture}>{prefecture}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  )
                }
                {
                  !isJapanese && (
                    <Grid item xs={5} sm={5}>
                    </Grid>
                  )
                }

              </Grid>
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={9} sm={9}>
              <TextField
                label={<MultiLangText
                language={language}
                textJa="市区町村名（例：大阪市北区）"
                textEn="city"
                />}

                name="billingCity"
                value={formData.billingCity}
                onChange={handleChange}
                fullWidth
                disabled={isReadOnly}
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={9} sm={9}>
              <TextField
                label={<MultiLangText
                language={language}
                textJa="番地・ビル名（例：〇〇１丁目2-3）"
                textEn="Address and Building Name"
                />}
                name="billingAddress"
                value={formData.billingAddress}
                onChange={handleChange}
                fullWidth
                disabled={isReadOnly}
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
              <MultiLangText
                language={language}
                textJa="メールアドレス"
                textEn="E-mail"
              />　<RequiredLabel />
            </Grid>
            <Grid item xs={9} sm={9}>
              <TextField
                label="XXXXX@xxxxxx.com"
                name="billingEmail"
                value={formData.billingEmail}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isReadOnly}
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={2} sm={2} style={{ display: 'flex' }}>
            </Grid>
            <Grid item xs={9} sm={9}>
            <MultiLangText
                language={language}
                textJa="※上記の請求先が出展者と異なる場合に記入してください。"
                textEn="*Please fill in above if the billing information is different from the exhibitor."
                />
            </Grid>
          </Grid>
          {/* セクションここまで */}
          {/* セクションスタート */}
          <Typography gutterBottom mt={5} style={{ fontWeight: 'bold' }}>
            <MultiLangText
              language={language}
              textJa="■お問い合わせ先について　※備考・その他は、下記まで直接ご連絡ください。"
              textEn="■Contact Information"
            />
          </Typography>
          <hr></hr>
          <Grid container spacing={3} alignItems="center">
            {/* カラム */}
            <Grid item xs={12} sm={12}>
              <MultiLangText
                language={language}
                textJa="
                            （株）東京ビッグサイト 2024国際航空宇宙展事務局<br/>
                            〒135-0063 東京都江東区有明3-11-1<br/>
      TEL：03-5530-1324<br/>
      Email：bigsight-ja@tokyo-bigsight.co.jp
                "
                textEn="JA2024 Management Office (Tokyo Big Sight Inc.)<br/>
                3-11-1, Ariake, Koto-ku, Tokyo 135-0063<br/>
TEL：+81-3-5530-1324<br/>
E-mail：bigsight-ja@tokyo-bigsight.co.jp"
              />
            </Grid>

            {/* カラム */}
            <Grid item xs={12} sm={12} style={{ display: 'flex'}}>
              <MultiLangText
                language={language}
                textJa="
※下記、「確定」ボタンを押して、申請してください。「保存」中は修正が可能です。<br/>
              ※「リセット」ボタンがある申請書については、万一、確定後に修正が必要な場合も、内容の修正が可能です。
"
                textEn='
*Please click the "SUBMIT" button to submit your application. You can "SAVE” the form while you still need to modify it.<br/>
*Some forms have “RESET” button, which you can use when you need to reset and fill in the form again after submitting the form.
'
              />
            </Grid>

          </Grid>
          {/* セクションここまで */}
          <Grid container mt={5} spacing={1} justifyContent="center">
            <Grid item>
              <Button
                onClick={handleReset}
                variant="contained"
                color="secondary"
                disabled={formData.buttonState.reset}  // JSONで制御
                sx={{ filter: formData.buttonState.reset ? 'grayscale(1)' : 'none' }}  // グレーアウト
              >
                <MultiLangText
                  language={language}
                  textJa="リセット"
                  textEn="Reset"
                />
              </Button>
            </Grid>

            <Grid item>
              <Button
                onClick={handleSave}
                variant="contained"
                color="primary"
                disabled={formData.buttonState.save}  // JSONで制御
                sx={{ filter: formData.buttonState.save ? 'grayscale(1)' : 'none' }}  // グレーアウト
              >
                <MultiLangText
                  language={language}
                  textJa="保存"
                  textEn="Save"
                />
              </Button>
            </Grid>

            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={formData.buttonState.submit || isDuplicate || isSubmitted}  // JSONで制御 + 重複や送信済みの場合
                sx={{ filter: (formData.buttonState.submit || isDuplicate || isSubmitted) ? 'grayscale(1)' : 'none' }}  // グレーアウト
              >
                <MultiLangText
                  language={language}
                  textJa="確定"
                  textEn="Submit"
                />
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Layout>
  );
};

export default Template017;
